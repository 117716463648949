var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
var ExportService = /** @class */ (function () {
    function ExportService(datePipe) {
        this.datePipe = datePipe;
        this.text_truncate = function (str, length, ending) {
            if (ending == null) {
                ending = '...';
            }
            if (str.length > length) {
                return str.substring(0, length - ending.length) + ending;
            }
            else {
                return str;
            }
        };
    }
    /**
     * Check if a given object is a Firebase timestamp.
     *
     * @param obj - The object to check.
     * @returns - True if the object is a Firebase timestamp; otherwise, false.
     */
    ExportService.prototype.isFirebaseTimestamp = function (obj) {
        // Date to Timestamp        
        var isTimestamp = obj && typeof obj === 'object' && 'seconds' in obj && 'nanoseconds' in obj;
        if (!isTimestamp) {
            ///console.debug(`Object is not a Firebase timestamp: ${JSON.stringify(obj)}`);
        }
        return isTimestamp;
    };
    /**
     * Convert a Firebase timestamp to a JavaScript Date object.
     *
     * @param timestamp - The Firebase timestamp to convert.
     * @returns - The converted Date object.
     */
    ExportService.prototype.convertTimestamp = function (timestamp) {
        //console.debug(`Converting Firebase timestamp: ${JSON.stringify(timestamp)}`);
        return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    };
    /**
     * Flatten an object and its nested properties into a single-level object.
     *
     * @param obj - The object to flatten.
     * @param prefix - The prefix to use for flattened property names.
     * @returns - The flattened object.
     */
    ExportService.prototype.flattenObject = function (obj, prefix) {
        var _this = this;
        if (prefix === void 0) { prefix = ''; }
        //console.debug(`Flattening object: ${JSON.stringify(obj)}`);
        return Object.keys(obj).reduce(function (acc, k) {
            var pre = prefix.length ? prefix + '.' : '';
            if (_this.isFirebaseTimestamp(obj[k])) {
                acc[pre + k] = _this.convertTimestamp(obj[k]).toLocaleString();
            }
            else if (Array.isArray(obj[k])) {
                acc[pre + k] = obj[k].map(function (item) { return _this.flattenObject(item); });
            }
            else if (typeof obj[k] === 'object' && obj[k] !== null && !(obj[k] instanceof Date)) {
                Object.assign(acc, _this.flattenObject(obj[k], pre + k));
            }
            else {
                acc[pre + k] = obj[k];
            }
            return acc;
        }, {});
    };
    // let arrayOfStringArrays: string[][] = [
    //    ["apple", "banana", "cherry"],
    //    ["dog", "cat", "rabbit"],
    //    ["red", "green", "blue"]
    //    ];
    /**
     * Export data to an Excel file.
     *
     * @param data - The data to export.
     * @param fileName - The name of the file to create.
     * @param sheetName - The name of the worksheet to create.
     * @param exclude - An array of property names to exclude from the export.
     */
    ExportService.prototype.exportToExcel = function (data, fileName, sheetName, exclude) {
        //console.debug(`Exporting data to Excel: ${JSON.stringify({ fileName, sheetName, exclude })}`);
        var _this = this;
        if (exclude === void 0) { exclude = []; }
        // Preprocess the data to exclude certain properties and flatten objects
        var filteredData = data.map(function (obj) {
            var newObj = _this.flattenObject(obj);
            for (var key in newObj) {
                if (exclude.includes(key)) {
                    delete newObj[key];
                }
            }
            return newObj;
        });
        /*    var test = JsonToTable(data);
   
           debugger; */
        //convert to array of array
        var dataArr = [];
        var headers = Object.keys(filteredData[0]); // Assuming non-empty dataset
        dataArr.push(headers);
        var _loop_1 = function (obj) {
            var row = headers.map(function (header) { return obj[header] === undefined ? null : obj[header]; });
            dataArr.push(row);
        };
        for (var _i = 0, filteredData_1 = filteredData; _i < filteredData_1.length; _i++) {
            var obj = filteredData_1[_i];
            _loop_1(obj);
        }
        // Create a new worksheet, with the created data
        //const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredData);
        var worksheet = XLSX.utils.aoa_to_sheet(dataArr);
        // Create a new workbook, with the created worksheet
        var workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        try {
            // Write the workbook to a file
            XLSX.writeFile(workbook, fileName + '.xlsx');
            console.debug("Export successful: " + fileName + ".xlsx");
        }
        catch (err) {
            console.error("Export failed: " + err);
        }
    };
    ExportService.prototype.exportMultipleClientsToExcel = function (responseServices, responders, clientNOCActivity, fileName, exclude, include) {
        if (exclude === void 0) { exclude = []; }
        if (include === void 0) { include = []; }
        var responseServices_1, responseServices_1_1;
        var e_1, _a, e_2, _b, e_3, _c;
        return __awaiter(this, void 0, void 0, function () {
            var workbook, filteredData, dataArr, clientwscols, headers, _loop_2, _i, filteredData_2, obj, i, worksheet, responseService, filteredResponders, _d, _e, responder, responderDetail, responderDetailToShow, key, e_2_1, respondersArr, wscols, responderHeaders, i, filteredResponders_1, filteredResponders_1_1, obj, row, e_3_1, responseServiceWorksheet, e_1_1, date;
            var _this = this;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        workbook = XLSX.utils.book_new();
                        filteredData = clientNOCActivity.map(function (obj) {
                            var newObj = _this.flattenObject(obj);
                            for (var key in newObj) {
                                if (exclude.includes(key)) {
                                    delete newObj[key];
                                }
                            }
                            return newObj;
                        });
                        dataArr = [];
                        clientwscols = [];
                        headers = Object.keys(filteredData[0]);
                        dataArr.push(headers);
                        _loop_2 = function (obj) {
                            var row = headers.map(function (header) { return obj[header] == undefined ? null : obj[header]; });
                            dataArr.push(row);
                        };
                        for (_i = 0, filteredData_2 = filteredData; _i < filteredData_2.length; _i++) {
                            obj = filteredData_2[_i];
                            _loop_2(obj);
                        }
                        for (i = 0; i < headers.length; i++) { // columns length added
                            clientwscols.push({ wch: headers[i].length + 25 });
                        }
                        worksheet = XLSX.utils.aoa_to_sheet(dataArr);
                        worksheet["!cols"] = clientwscols;
                        XLSX.utils.book_append_sheet(workbook, worksheet, "Client NOC Activity");
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, 30, 31, 36]);
                        responseServices_1 = __asyncValues(responseServices);
                        _f.label = 2;
                    case 2: return [4 /*yield*/, responseServices_1.next()];
                    case 3:
                        if (!(responseServices_1_1 = _f.sent(), !responseServices_1_1.done)) return [3 /*break*/, 29];
                        responseService = responseServices_1_1.value;
                        filteredResponders = [];
                        _f.label = 4;
                    case 4:
                        _f.trys.push([4, 9, 10, 15]);
                        _d = __asyncValues(responseService.responders);
                        _f.label = 5;
                    case 5: return [4 /*yield*/, _d.next()];
                    case 6:
                        if (!(_e = _f.sent(), !_e.done)) return [3 /*break*/, 8];
                        responder = _e.value;
                        responderDetail = responders.find(function (x) { return x.key == responder.key; });
                        if (responderDetail) {
                            responderDetailToShow = this.flattenObject(responderDetail);
                            for (key in responderDetailToShow) {
                                if (!include.includes(key)) {
                                    delete responderDetailToShow[key];
                                }
                            }
                            filteredResponders.push(responderDetailToShow);
                        }
                        _f.label = 7;
                    case 7: return [3 /*break*/, 5];
                    case 8: return [3 /*break*/, 15];
                    case 9:
                        e_2_1 = _f.sent();
                        e_2 = { error: e_2_1 };
                        return [3 /*break*/, 15];
                    case 10:
                        _f.trys.push([10, , 13, 14]);
                        if (!(_e && !_e.done && (_b = _d.return))) return [3 /*break*/, 12];
                        return [4 /*yield*/, _b.call(_d)];
                    case 11:
                        _f.sent();
                        _f.label = 12;
                    case 12: return [3 /*break*/, 14];
                    case 13:
                        if (e_2) throw e_2.error;
                        return [7 /*endfinally*/];
                    case 14: return [7 /*endfinally*/];
                    case 15:
                        ;
                        respondersArr = [];
                        wscols = [];
                        if (!(filteredResponders.length >= 1)) return [3 /*break*/, 27];
                        responderHeaders = Object.keys(filteredResponders[0]);
                        respondersArr.push(responderHeaders);
                        for (i = 0; i < responderHeaders.length; i++) { // columns length added
                            wscols.push({ wch: responderHeaders[i].length + 30 });
                        }
                        _f.label = 16;
                    case 16:
                        _f.trys.push([16, 21, 22, 27]);
                        filteredResponders_1 = __asyncValues(filteredResponders);
                        _f.label = 17;
                    case 17: return [4 /*yield*/, filteredResponders_1.next()];
                    case 18:
                        if (!(filteredResponders_1_1 = _f.sent(), !filteredResponders_1_1.done)) return [3 /*break*/, 20];
                        obj = filteredResponders_1_1.value;
                        row = responderHeaders.map(function (header) { return obj[header] === undefined ? null : obj[header]; });
                        respondersArr.push(row);
                        _f.label = 19;
                    case 19: return [3 /*break*/, 17];
                    case 20: return [3 /*break*/, 27];
                    case 21:
                        e_3_1 = _f.sent();
                        e_3 = { error: e_3_1 };
                        return [3 /*break*/, 27];
                    case 22:
                        _f.trys.push([22, , 25, 26]);
                        if (!(filteredResponders_1_1 && !filteredResponders_1_1.done && (_c = filteredResponders_1.return))) return [3 /*break*/, 24];
                        return [4 /*yield*/, _c.call(filteredResponders_1)];
                    case 23:
                        _f.sent();
                        _f.label = 24;
                    case 24: return [3 /*break*/, 26];
                    case 25:
                        if (e_3) throw e_3.error;
                        return [7 /*endfinally*/];
                    case 26: return [7 /*endfinally*/];
                    case 27:
                        responseServiceWorksheet = XLSX.utils.aoa_to_sheet(respondersArr);
                        responseServiceWorksheet["!cols"] = wscols;
                        XLSX.utils.book_append_sheet(workbook, responseServiceWorksheet, "" + this.text_truncate(responseService.clientName + " - " + responseService.key, 30, null));
                        _f.label = 28;
                    case 28: return [3 /*break*/, 2];
                    case 29: return [3 /*break*/, 36];
                    case 30:
                        e_1_1 = _f.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 36];
                    case 31:
                        _f.trys.push([31, , 34, 35]);
                        if (!(responseServices_1_1 && !responseServices_1_1.done && (_a = responseServices_1.return))) return [3 /*break*/, 33];
                        return [4 /*yield*/, _a.call(responseServices_1)];
                    case 32:
                        _f.sent();
                        _f.label = 33;
                    case 33: return [3 /*break*/, 35];
                    case 34:
                        if (e_1) throw e_1.error;
                        return [7 /*endfinally*/];
                    case 35: return [7 /*endfinally*/];
                    case 36:
                        ;
                        try {
                            date = this.datePipe.transform(new Date(), 'dd-mm-yyyy-hh-mm-ss');
                            XLSX.writeFile(workbook, fileName + (" - " + date + ".xlsx"));
                            console.debug("Export successful: " + fileName + ".xlsx");
                        }
                        catch (err) {
                            console.error("Export failed: " + err);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return ExportService;
}());
export { ExportService };
