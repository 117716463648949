<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width" />
    <title>Privacy Policy</title>
    <style>
      body {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        padding: 1em;
      }
    </style>
  </head>
  <body>
    <p>
      Response24 built the Response 24 Plus app as a Commercial app. This
      SERVICE is provided by Response24 and is intended for use as is.
    </p>
    <p>
      This page is used to inform visitors regarding our policies with the
      collection, use, and disclosure of Personal Information if anyone decided
      to use our Service.
    </p>
    <p>
      If you choose to use our Service, then you agree to the collection and use
      of information in relation to this policy. The Personal Information that
      we collect is used for providing and improving the Service. We will not
      use or share your information with anyone except as described in this
      Privacy Policy.
    </p>
    <p>
      The terms used in this Privacy Policy have the same meanings as in our
      Terms and Conditions, which are accessible at Response 24 Plus unless
      otherwise defined in this Privacy Policy.
    </p>
    <p><strong>Information Collection and Use</strong></p>
    <p>
      For a better experience, while using our Service, we may require you to
      provide us with certain personally identifiable information, including but
      not limited to Contact List information, Geolocation, Live location. The
      information that we request will be retained by us and used as described
      in this privacy policy.
    </p>
    <div>
      <p>
        The app does use third-party services that may collect information used
        to identify you.
      </p>
      <p>
        Link to the privacy policy of third-party service providers used by the
        app
      </p>
      <ul>
        <li>
          <a
            href="https://www.google.com/policies/privacy/"
            target="_blank"
            rel="noopener noreferrer"
            >Google Play Services</a
          >
        </li>
        <!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!---->
      </ul>
    </div>
    <p><strong>Log Data</strong></p>
    <p>
      We want to inform you that whenever you use our Service, in a case of an
      error in the app we collect data and information (through third-party
      products) on your phone called Log Data. This Log Data may include
      information such as your device Internet Protocol (“IP”) address, device
      name, operating system version, the configuration of the app when
      utilizing our Service, the time and date of your use of the Service, and
      other statistics.
    </p>
    <p><strong>Cookies</strong></p>
    <p>
      Cookies are files with a small amount of data that are commonly used as
      anonymous unique identifiers. These are sent to your browser from the
      websites that you visit and are stored on your device's internal memory.
    </p>
    <p>
      This Service does not use these “cookies” explicitly. However, the app may
      use third-party code and libraries that use “cookies” to collect
      information and improve their services. You have the option to either
      accept or refuse these cookies and know when a cookie is being sent to
      your device. If you choose to refuse our cookies, you may not be able to
      use some portions of this Service.
    </p>
    <p><strong>Service Providers</strong></p>
    <p>
      We may employ third-party companies and individuals due to the following
      reasons:
    </p>
    <ul>
      <li>To facilitate our Service;</li>
      <li>To provide the Service on our behalf;</li>
      <li>To perform Service-related services; or</li>
      <li>To assist us in analyzing how our Service is used.</li>
    </ul>
    <p>
      We want to inform users of this Service that these third parties have
      access to their Personal Information. The reason is to perform the tasks
      assigned to them on our behalf. However, they are obligated not to
      disclose or use the information for any other purpose.
    </p>
    <p><strong>Security</strong></p>
    <p>
      We value your trust in providing us your Personal Information, thus we are
      striving to use commercially acceptable means of protecting it. But
      remember that no method of transmission over the internet, or method of
      electronic storage is 100% secure and reliable, and we cannot guarantee
      its absolute security.
    </p>
    <p><strong>Links to Other Sites</strong></p>
    <p>
      This Service may contain links to other sites. If you click on a
      third-party link, you will be directed to that site. Note that these
      external sites are not operated by us. Therefore, we strongly advise you
      to review the Privacy Policy of these websites. We have no control over
      and assume no responsibility for the content, privacy policies, or
      practices of any third-party sites or services.
    </p>
    <p><strong>Children’s Privacy</strong></p>
    <div>
      <p>
        These Services do not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from children
        under 13 years of age. In the case we discover that a child under 13 has
        provided us with personal information, we immediately delete this from
        our servers. If you are a parent or guardian and you are aware that your
        child has provided us with personal information, please contact us so
        that we will be able to do the necessary actions.
      </p>
    </div>
    <!---->
    <p><strong>Changes to This Privacy Policy</strong></p>
    <p>
      We may update our Privacy Policy from time to time. Thus, you are advised
      to review this page periodically for any changes. We will notify you of
      any changes by posting the new Privacy Policy on this page.
    </p>
    <p>This policy is effective as of 2022-06-01</p>
    <p><strong>Contact Us</strong></p>
    <p>
      If you have any questions or suggestions about our Privacy Policy, do not
      hesitate to contact us at tech@response24.co.za.
    </p>
    <p>
      This privacy policy page was created at
      <a
        href="https://privacypolicytemplate.net"
        target="_blank"
        rel="noopener noreferrer"
        >privacypolicytemplate.net </a
      >and modified/generated by
      <a
        href="https://app-privacy-policy-generator.nisrulz.com/"
        target="_blank"
        rel="noopener noreferrer"
        >App Privacy Policy Generator</a
      >
    </p>
  </body>
</html>

<div id="privacy_content">
  <h2>PRIVACY POLICY for 911 RESPONSE24 SaaS App White Label Clients</h2>

  
  <h2 style="color: #5e9ca0">
    Google specific Privacy policy Blue Security Connect
  </h2>
  <h4 style="color: #2e6c80">
    Reference&nbsp;location data&nbsp;and your app&rsquo;s use and handling of
    location data
  </h4>
  <p style="color: #2e6c80">
    Response 24 uses user location to send emergency help and when the user
    wants to share their location with each other. Other than that, user
    location is not stored and used for any other reason.
  </p>
  <h3 style="color: #2e6c80">Do we collect Image data?:</h3>
  <p style="color: #2e6c80">Yes, but you have to send it or add your own profile photo.</p>
  <h3 style="color: #2e6c80">
    Clearly label that the page is a privacy policy, for example, listed as
    "privacy policy" in the title or has &ldquo;privacy&rdquo; in the URL and
    body of the page
  </h3>
  <p style="color: #2e6c80">Yes, it is</p>
  <h3 style="color: #2e6c80">Be available on an active, visible URL:</h3>
  <p style="color: #2e6c80">Yes, it is</p>
  <h3 style="color: #2e6c80">
    Be readable in a standard browser without any plug-ins or special handlers
    (for example, no PDFs, no uncommon MIME types):
  </h3>
  <p style="color: #2e6c80">Yes, it is</p>
  <h3 style="color: #2e6c80">Be non-editable</h3>
  <p style="color: #2e6c80">Yes, it is</p>
  <h3 style="color: #2e6c80">Refer to your app by name:</h3>
  <p style="color: #2e6c80">BlueSecurity Connect</p>
  <h3 style="color: #2e6c80">
    Be linked on your app's Store listing page and/or within your app
  </h3>
  <p style="color: #2e6c80">Yes, it is</p>
  <h3 style="color: #2e6c80">
    Have a URL that links to a single governing privacy policy in your website
    and/or Play Store listing rather than one that points to multiple privacy
    policies
  </h3>
  <p style="color: #2e6c80">Yes, it is, please view below</p>



  <h2 style="color: #5e9ca0">
    Google specific Privacy policy AfriForum 911-noodknoppie
  </h2>
  <h4 style="color: #2e6c80">
    Reference&nbsp;location data&nbsp;and your app&rsquo;s use and handling of
    location data
  </h4>
  <p style="color: #2e6c80">
    Response 24 uses user location to send emergency help and when the user
    wants to share their location with each other. Other than that, user
    location is not stored and used for any other reason.
  </p>
  <h3 style="color: #2e6c80">
    Clearly label that the page is a privacy policy, for example, listed as
    "privacy policy" in the title or has &ldquo;privacy&rdquo; in the URL and
    body of the page
  </h3>
  <p style="color: #2e6c80">Yes, it is</p>
  <h3 style="color: #2e6c80">Be available on an active, visible URL:</h3>
  <p style="color: #2e6c80">Yes, it is</p>
  <h3 style="color: #2e6c80">
    Be readable in a standard browser without any plug-ins or special handlers
    (for example, no PDFs, no uncommon MIME types):
  </h3>
  <p style="color: #2e6c80">Yes, it is</p>
  <h3 style="color: #2e6c80">Be non-editable</h3>
  <p style="color: #2e6c80">Yes, it is</p>
  <h3 style="color: #2e6c80">Refer to your app by name:</h3>
  <p style="color: #2e6c80">AfriForum 911-noodknoppie</p>
  <h3 style="color: #2e6c80">
    Be linked on your app's Store listing page and/or within your app
  </h3>
  <p style="color: #2e6c80">Yes, it is</p>
  <h3 style="color: #2e6c80">
    Have a URL that links to a single governing privacy policy in your website
    and/or Play Store listing rather than one that points to multiple privacy
    policies
  </h3>
  <p style="color: #2e6c80">Yes, it is, please view below</p>

  <h2 style="color: #5e9ca0">Google specific Privacy policy Lady Shield</h2>
  <h4 style="color: #2e6c80">
    Reference&nbsp;location data&nbsp;and your app&rsquo;s use and handling of
    location data
  </h4>
  <p style="color: #2e6c80">
    Response 24 uses user location to send emergency help and when the user
    wants to share their location with each other. Other than that, user
    location is not stored and used for any other reason.
  </p>
  <h3 style="color: #2e6c80">
    Clearly label that the page is a privacy policy, for example, listed as
    "privacy policy" in the title or has &ldquo;privacy&rdquo; in the URL and
    body of the page
  </h3>
  <p style="color: #2e6c80">Yes, it is</p>
  <h3 style="color: #2e6c80">Be available on an active, visible URL:</h3>
  <p style="color: #2e6c80">Yes, it is</p>
  <h3 style="color: #2e6c80">
    Be readable in a standard browser without any plug-ins or special handlers
    (for example, no PDFs, no uncommon MIME types):
  </h3>
  <p style="color: #2e6c80">Yes, it is</p>
  <h3 style="color: #2e6c80">Be non-editable</h3>
  <p style="color: #2e6c80">Yes, it is</p>
  <h3 style="color: #2e6c80">Refer to your app by name:</h3>
  <p style="color: #2e6c80">Lady Shield</p>
  <h3 style="color: #2e6c80">
    Be linked on your app's Store listing page and/or within your app
  </h3>
  <p style="color: #2e6c80">Yes, it is</p>
  <h3 style="color: #2e6c80">
    Have a URL that links to a single governing privacy policy in your website
    and/or Play Store listing rather than one that points to multiple privacy
    policies
  </h3>
  <p style="color: #2e6c80">Yes, it is, please view below</p>
  <p>
    DEFINITIONS “Application” shall mean the 911 Response24 SaaS Application
    that allows Users to request emergency assistance and downloaded to your
    phone/device. “Child” shall mean a natural person under the age of 18 years.
    “Competent Person” shall mean a person who is legally competent to consent
    to any action or decision being taken in respect of any matter concerning a
    child. This includes the legal definition of a guardian. “Cookies” shall
    mean to be files with a small amount of data that are commonly used as
    anonymous unique identifiers. These are sent to your browser from the
    websites that you visit and are stored on your device's internal memory.
    This Service does not use these cookies explicitly. However, the app may use
    third-party code and libraries that use cookies to collect information and
    improve their services. You have the option to either accept or refuse these
    cookies and know when a cookie is being sent to your device. If you choose
    to refuse our cookies, you may not be able to use some portions of this
    Service. “Your Emergency Services Provider” shall mean the service provider
    whose logos and details are displayed on the Application, using the white
    labelled version of the 911 Response24 SaaS App. “Log Data” Whenever you use
    our Service, in a case of an error in the app, we collect data and
    information (through third-party products) on your phone called Log Data.
    This Log Data may include information such as your device’s internet
    protocol (IP) address, device name, operating system version, the
    configuration of the app when utilizing our Service, the time and date of
    your use of the Service, and other statistics. “Personal Information” shall
    mean personal information as defined in the Protection of Personal
    Information Act, South Africa, including information relating to the race,
    gender, sex, pregnancy, marital status, national, ethnic or social origin,
    colour, sexual orientation, age, physical or mental health, well-being,
    disability, religion, conscience, belief, culture, language and birth of the
    person; information relating to the education or the medical, financial,
    criminal or employment history of the person; any identifying number,
    symbol, e-mail address, physical address, telephone number, location
    information, online identifier or other particular assignment to the person;
    the biometric information of the person; the personal opinions, views or
    preferences of the person; correspondence sent by the person that is
    implicitly or explicitly of a private or confidential nature or further
    correspondence that would reveal the contents of the original
    correspondence; the views or opinions of another individual about the
    person; and the name of the person if it appears with other Personal
    Information relating to the person or if the disclosure of the name itself
    would reveal information about the person. “Special Personal Information”
    shall mean personal information as defined in the Protection of Personal
    Information Act, South Africa including information relating to the
    religious or philosophical beliefs, race or ethnic origin, trade union
    membership, political persuasion, health or sex life or biometric
    information of the person; or the criminal behaviour of a data subject to
    the extent that such information relates a) to the alleged commission by the
    person of any offence; or b) any proceedings in respect of any offence
    allegedly committed by the person or the disposal of such proceedings.
    “User/s” shall mean any individuals who make use of or access the
    Application. Note that this is a wider definition than merely the one paying
    for the Response24 SaaS App. PRE-AMBLE This Mobile Application End User
    Agreement (“Agreement”) is a binding agreement between you (“you”) - the
    user (“User”) - and Your Emergency Services Provider whose logos and details
    are displayed on the Application (“Your Emergency Services Provider”). This
    privacy policy governs your use of the 911 Rapid Response SaaS application
    (the “Application” which is white labelled and authorised for reselling to
    Your Emergency Services Provider) on your mobile device (“Mobile Devices”;
    either Android or IOS), including all documentation related to the
    Application. Note that the nature of the Response24 SaaS Security App is
    such that we/Your Emergency Services Provider must be able to use the
    Personal Information supplied voluntarily by you to us to assist you in time
    of need. We cannot use the information without your permission which is
    granted by you irretrievably and without preconditions by downloading and
    using the Response24 SaaS App. The permissions so granted will never be
    abused by us, sold to Third Parties, or acted with outside the scope of this
    document. We collect data either directly from you with what you provide us,
    or through our website using cookies. JURISDICTION This Privacy Policy
    (“Policy”) is subject to South African law and governs the collection,
    management and disclosure of personal information that 911 Rapid Response
    t/a Response24 (“we”, “us”, “our” or Your Emergency Services Provider)
    collects through the 911 Response24 SaaS Application (“application”) as well
    as the Your Emergency Services Provider control room (“control room”). We
    respect your rights to responsible management of your personal information
    under the protection of personal information act 4 of 2013, aka POPI or
    POPIA (“the Act”). For the purposes of this policy, personal information is
    defined in accordance with the definition provided in the act. Your use of
    the application is subject to this policy which is permission based. You
    decide what we can use and how we use it. By using this application, you
    signify your consent, or if you are younger than 18 years when downloading
    the App, you acknowledge that you have obtained consent from a parent or
    legal guardian and that you are not in any way obscuring the duty to obtain
    such consent, to processing of your personal information in accordance with
    this privacy policy. Note that Your Emergency Services Provider/Your
    Emergency Services Provider may amend this policy at any time. All amended
    terms shall be effective immediately upon the revised policy being made
    available and any subsequent activity in relation to the application shall
    be governed by such amended terms and conditions of usage. Important: if you
    do not agree with any term in this policy, please do not use this
    application or submit any personally identifiable information through this
    application. This Agreement was last revised on 29 July 2020. You are
    advised to regularly check with Your Emergency Services Provider for any
    amendments or updates. 1.UNDERSTANDING PERSONAL INFORMATION The term
    “Personal Information” here has the meaning given to it in the Act.
    Generally, it is any information that can be used to personally identify
    you. This may include your name, surname, address, telephone number and/or
    email address as described above. Should the information we collect
    personally identify you, or you are reasonably identifiable from it, the
    information will be considered personal information. Your Personal
    Information will not be shared, sold, rented or disclosed other than as
    described in this Privacy Policy and according to the permissions that you
    grant to us by using the Response24 SaaS App. The processing of Personal
    Information shall include the collection, receipt, recording, organisation,
    collation, storage, updating or modification, retrieval, alteration,
    consultation, use; dissemination by means of transmission, distribution or
    making available in any other form; or merging, linking, as well as
    blocking, degradation, erasure or destruction of information. Your Emergency
    Services Provider strives to ensure that the conditions set out in the Act
    and all the measures that give effect to such conditions, are complied with
    when processing Personal Information. In particular, Your Emergency Services
    Provider strives to ensure that Personal Information and Special Personal
    Information is processed in a manner that is lawful, adequate, relevant and
    not excessive given the purpose of collection. 2.COLLECTION OF PERSONAL
    INFORMATION 2.1 Your Emergency Services Provider collects and processes
    information that can be used to identify you personally that is necessary to
    providing, facilitating and maintaining the Application and associated
    services. 2.2 Subject to consent, we collect Personal Information/Special
    Personal Information from the following entities: a) natural persons over
    18; b) natural persons under the age of 18, we collect Special Personal
    Information with the consent of the parent, legal guardian or Competent
    Person; c) Registered Application users; and d) people who send enquiries or
    requests to our contact email address. 2.3 Children's Privacy: We do not
    knowingly collect personally identifiable information from children younger
    than 18 years. Where we discover that a child under 18 has provided us with
    personal information, we will immediately delete this from our servers. If
    you are a parent or guardian and you are aware that your child has provided
    us with personal information, please contact us so that we will be able to
    perform the necessary actions. As a parent, guardian or Competent Person, on
    behalf of the child, you consent to the collection, processing, sharing and
    storage of Special Personal Information for the purpose of facilitating
    access to the Application. 2.4 Personal Information that may be collected by
    Your Emergency Services Provider includes: 2.4.1 First and last name; 2.4.2
    Age; 2.4.3 Country of residence; 2.4.4 Location information; 2.4.5 Province;
    2.4.6 Physical address; 2.4.7 Mobile phone number; 2.4.8 Email address; 2.5
    Your Emergency Services Provider collects and processes Personal Information
    that is provided to them by Users and or parents, guardians and/or Competent
    Person in the following instances: 2.5.1 On registration for use of the
    Application and associated services; 2.5.2 Upon use of the Application;
    2.5.3 Upon requesting emergency assistance; and 2.5.4 Via correspondence via
    e-mail, fax, telephone and post. 2.6 Your Emergency Services Provider
    retains records for a period that is longer than is required by law and you
    hereby consent to such retention of Personal Information/Special Personal
    Information. 2.7 There may be instances where we may also collect some
    information that is not Personal Information/Special Personal Information
    because it does not identify you or anyone else (we may collect anonymous
    answers to surveys or aggregated information about how you use our
    Application). 2.8 If You use our Application, we use tracking technologies
    to collect information such your activity or the information you request.
    This information collected is kept confidential. The use of tracking
    technologies enables us to provide the emergency response service. 2.9 Your
    location and privacy are of utmost importance to us and you. We have given
    you the option to share your location so that we are able to determine your
    location in an emergency. Your location is only shared with providers that
    you choose to share it with, including medical response providers, armed
    response providers or friends and family. During an emergency, your location
    will be sent to our providers to assist you as quickly as possible but will
    be unshared as soon as an emergency is completed. 3. USE AND PURPOSE OF
    COLLECTING PERSONAL INFORMATION 3.1 The primary purpose for collecting and
    processing Your Personal Information is to enable us to provide you access
    to the Application and the emergency response service. Your Emergency
    Services Provider also collects and processes Personal Information in order:
    3.1.1 to identify the User to effect the Application registration process;
    3.1.2 to provide information to emergency response operators when assistance
    is requested; 3.1.3 to re-confirm user information whenever the User logs
    onto a new device; 3.1.4 to communicate with the User; 3.1.5 to verify the
    User's identity; 3.1.6 to process and respond to any complaint made by You;
    3.1.7 to enable ease of use of the Application; 3.1.8 to assess the
    performance of the Application and emergency response service; 3.1.9 to
    engage in legitimate electronic communications and transactions with You;
    3.1.10 to provide feedback on any related enquiries; 3.1.11 to provide You
    with direct marketing material; 3.1.12 to update our records and keep Your
    contact details up to date; 3.1.13 to comply with any law, rule, regulation,
    lawful and binding determination, decision or direction of a regulator, or
    in co-operation with any governmental authority of any country. 3.2 We are
    also able to use the information collected, including the voice recordings,
    to improve our internal technical and business processes. We research users'
    habits, measure traffic patterns in the aggregate, analyse trends,
    administer the Application, track users' actions and gather broad
    demographic information. 3.3 In some instances, Your Emergency Services
    Provider may also collect sensitive information about users, such as
    information about their racial or ethnic origin. Consent is obtained for the
    collection of such information. 4.CONSENT FOR PROCESSING PERSONAL
    INFORMATION 4.1 By using the 911 Response24 SaaS Application, you hereby
    consent to Your Personal Information (including Special Personal
    Information) for the abovementioned purposes,associated purposes and to (a)
    update our records; (b) keep Your contact details up to date; (c) to comply
    with any law, rule, regulation, lawful and binding determination, decision
    or direction of a regulator, or co-operate with any governmental authority
    of any country. 4.2 By Using the Application, you represent that you are of
    the age of 18 years or where you are under 18, a Competent Person has given
    consent to allow you to utilise this Application. This consent is effective
    on access to the Application. 4.3 You, or a Competent Person in the case of
    a child, consent/s to the processing of Personal Information/Special
    Personal Information. As a Competent Person you agree that giving such
    consent means that You have given Your Emergency Services Provider
    permission to collect and process the child's Special Personal Information
    in the ways described in this document. 4.4 You understand that your consent
    to the processing of Your Personal Information/Special Personal Information
    by Your Emergency Services Provider pertains to the provision of information
    and the Application; as well as associated purposes. 4.5 Personal
    Information/Special Personal Information may be processed by Your Emergency
    Services Provider in the country where it was collected as well as other
    countries where laws regarding processing of Personal Information differ.
    4.6 Should you select the option to join the Your Emergency Services
    Provider mailing list and receive news alerts, you consent to the receipt of
    direct marketing communications regarding all business activities and news.
    4.7 You expressly consent to Your Emergency Services Provider retaining Your
    Personal Information/Special Personal Information once your relationship
    with Your Emergency Services Provider has been terminated and using the
    information for aggregate, statistical and reporting purposes. 5. HANDLING
    OF PERSONAL INFORMATION 5.1 Your Emergency Services Provider endeavours to
    comply with all laws and regulations applicable to Your Emergency Services
    Provider pertaining to information and communications privacy including, but
    not limited to, the 1996 South African Constitution, and the Protection of
    Personal Information Act 4 of 2013 (“the Act”). Your Emergency Services
    Provider Privacy Policy strives to be compliant to the EU General Data
    Protection Regulations (GDPR) and is. However, should there be a clash
    between the legal systems, then the South African Act will be followed since
    Your Emergency Services Provider is a South African based and registered
    company. 5.2 Your Emergency Services Provider seeks to ensure the quality,
    accuracy and confidentiality of Personal Information in its possession. You
    warrant that all Personal Information supplied by you is both true and
    correct at the time of provision. In the event of any aspect of this
    Personal Information changing post submission, it is Your responsibility to
    immediately notify Your Emergency Services Provider directly of the said
    changes by email. 5.3 You agree to indemnify and hold Your Emergency
    Services Provider, its officers, directors, employees, agents, and suppliers
    harmless from and against any claims, damages, actions and liabilities
    including without limitation, indirect, incidental, special, consequential
    or punitive damages arising out of Your Emergency Services Provider'
    reliance on your personal information should your personal information
    contain any errors or inaccuracies. 5.4 You warrant that you have the
    authority, permissions and consent to provide Your Emergency Services
    Provider with any third-party information submitted to Your Emergency
    Services Provider. 5.5 Your Emergency Services Provider will take all
    reasonable measures in order to ensure that Your Personal Information is
    appropriately safeguarded. These precautions include, but are not limited
    to: firewalls, access control mechanisms via private keys, and software
    protection for information security. 6.THIRD PARTY ACCESS TO PERSONAL
    INFORMATION 6.1 Your Emergency Services Provider may share Your Personal
    Information with authorised third parties such as service providers to Your
    Emergency Services Provider. These include, but are not limited to, data
    hosting service providers, emergency service providers like ambulances and
    private security companies. 6.2 When emergency assistance is requested via
    the Application, Your Emergency Services Provider will share your Personal
    Information with third parties such as the emergency responder. You consent
    to this sharing of information. They cannot assist you without the data. 6.3
    Your Emergency Services Provider does not permit these parties to use such
    information for any other purpose than to perform the services that Your
    Emergency Services Provider has instructed them to provide. All processing
    is compatible with such purpose. 6.4 Your Emergency Services Provider may
    appoint certain agents, third parties and/or service providers which operate
    outside the borders of the Republic of South Africa. In these circumstances
    Your Emergency Services Provider will be required to transmit Your Personal
    Information outside South Africa. The purpose of the trans-border transfer
    of Your Personal Information may include but is not limited to: data hosting
    and storage. You expressly consent to the trans-border flow of Your Personal
    Information. All reasonable measures in order to ensure that Your Personal
    Information is appropriately safeguarded will be taken. These precautions
    include, but are not limited to firewalls, access control mechanisms via
    private keys, and software protection for information security. 7. RETENTION
    AND RESTRICTION OF RECORDS 7.1 On receipt of instructions from you, Your
    Emergency Services Provider will destroy or delete a record of Personal
    Information about you which Your Emergency Services Provider is no longer
    authorised to retain. This is subject to certain exceptions such as
    retaining records that Your Emergency Services Provider is required by law
    to retain. Your Emergency Services Provider is entitled to retain
    de-identified information for authorised or legitimate purposes. 7.2 In the
    event that you wish to revoke all consent pertaining to Your Personal
    Information and/or you would like Your Emergency Services Provider to remove
    and/or delete Your Personal Information entirely, you must contact Your
    Emergency Services Provider directly via email. 7.3 Records relating to the
    provision of information to and/or by you can be retained by Your Emergency
    Services Provider in terms of legislative records retention provisions and
    includes Your Personal Information and/or Special Personal Information. 7.4
    Your Emergency Services Provider retains records for a period that is longer
    than is required by law for statistical or research purposes. 7.5 Your
    Emergency Services Provider will not sell or abuse your records outside what
    is agreed to and explained here. 8. UPDATING OF PERSONAL INFORMATION 8.1 You
    may request access to any Personal Information/Special Personal Information
    we hold about you at any time by contacting us on info@response24.co.za. 8.2
    If You believe that Personal Information/Special Personal Information we
    hold about you is incorrect, incomplete or inaccurate, or outdated (say
    where you got married or moved houses or medical changes), then you may
    request us to amend it. We will consider if the information requires
    amendment. If we do not agree that there are grounds for amendment or that
    we cannot amend the information as required, we will retain a note about the
    request. 8.3 Where we hold information that you are entitled to access, we
    will try to provide you with suitable means of accessing it (for example, by
    mailing or emailing it to you). We may charge you a fee (as allowed for
    under the Promotion of Access to Information Act) for our administrative and
    other reasonable costs in providing the information to you. We will not
    charge for making the request and will not charge for making any corrections
    or updates to Your Personal Information/Special Personal Information. 8.4
    There may be instances where we cannot grant you access to the Personal
    Information/Special Personal Information we hold. We may need to refuse
    access if granting access would interfere with the privacy of others or if
    it would result in a breach of confidentiality. Should this be the case, we
    will give you written reasons for any refusal. 9.DISCLOSURE OF PERSONAL
    INFORMATION 9.1 Business records relating to the provision of information or
    the Application to You can be retained by Your Emergency Services Provider
    in terms of legislative records retention provisions and includes Your
    Personal Information. Records can be retained for operational purposes or
    for evidential purposes. Where Your Emergency Services Provider shares Your
    Personal Information/Special Personal Information with Employees and other
    Users, Your Emergency Services Provider does not permit such parties to use
    the information for any other purpose than those pertaining using,
    maintaining and/or running to the Application and associated services. 9.2
    We may disclose Your Personal Information/Special Personal Information to:
    9.2.1 our employees to enable us to operate; 9.2.2 fulfil requests by You,
    and to otherwise provide information and services to You; 9.2.3 suppliers
    and other third parties with whom we have commercial relationships, for
    business and related purposes; and/or 9.2.4 any organisation for any
    authorised purpose with Your express consent. 9.3 We may further disclose
    your Personal Information/Special Personal Information in the following
    instances: a) To comply with the law or legal process; b) To protect Your
    Emergency Services Provider ' rights, facilities and other property; c) To
    protect Your Emergency Services Provider against any misuse or unauthorised
    use of the Application; and d) To protect Users or third parties affected
    negatively by Your action in using the Application. 9.4 We will disclose
    Personal Information/Special Personal Information to our service providers
    who use software programmes and hardware devices to monitor usage in order
    to identify unauthorised attempts to access, upload, damage or alter
    information that it has stored. 9.5 Personal Information is stored on
    servers both inside and outside of South Africa. 9.6 We have placed security
    safeguards to protect Your Personal Information/Special Personal Information
    through agreements concluded with third party service providers. We will not
    be held liable for any illegal activity or other activity that may result in
    a claim for damages due to lack of protection of Your Personal
    Information/Special Personal Information whilst under the care of the third
    party. 10. SECURITY OF PERSONAL INFORMATION 10.1 We take reasonable steps to
    ensure Your Personal Information/Special Personal Information is protected
    from misuse, loss and from unauthorised access, modification or disclosure.
    We may hold Your information in either electronic or hard copy form. We use
    third parties who may have access to Your Personal Information and who have
    undertaken to protect Your Personal Information. 10.2 Should an unauthorised
    person/s gain access to Your Personal Information Your Emergency Services
    Provider will contact you within a reasonable time to inform you of such
    access/breach. 10.3 As our Application is linked to the internet, and the
    internet is inherently insecure, we cannot provide any assurance regarding
    the security of transmission of information that you communicate to us
    online. We also cannot guarantee that the information that you supply will
    not be intercepted while being transmitted over the internet. Accordingly,
    any Personal Information/Special Personal Information or other information
    which You transmit to us online is transmitted at your own risk. 10.4 Links
    to Other Sites: This Service may contain links to other sites. If you click
    on a third-party link, you will be directed to that site. These external
    sites are not operated by us. Therefore, we strongly advise you to review
    the privacy policies of these websites. We have no control over and assume
    no responsibility for the content, privacy policies, or practices of any
    third-party sites or services. 11. DATA SUBJECT PARTICIPATION Should You
    have enquiries concerning Personal Information processed by Your Emergency
    Services Provider, you can request feedback that describes the Personal
    Information held by Your Emergency Services Provider, and information about
    third parties, or categories of third parties who provide services to Your
    Emergency Services Provider, who have, or have had, access to the
    information. You may request Your Emergency Services Provider correct or
    delete Your Personal Information in its possession or under its control that
    is inaccurate, irrelevant, excessive, misleading or obtained unlawfully; or
    destroy or delete a Personal Information that Your Emergency Services
    Provider is no longer authorised to retain. As a User, you have the right to
    request access, correction or deletion of Your Personal Information. 12.
    INDEMNITY You agree to indemnify and hold Your Emergency Services Provider ,
    its officers, directors, employees, agents, licensors, suppliers and members
    harmless from and against any claims, damages, actions and liabilities
    including without limitation, loss of profits, direct, indirect, incidental,
    special, consequential or punitive damages arising out of Your Emergency
    Services Provider' reliance Your Personal Information should Your Personal
    Information contain any errors, inaccuracies or be irrelevant to the use of
    the Application. 13. CONTACTING US 13.1 Should you have any questions about
    this Privacy Policy, or require a correction to be made to Your Personal
    Information that Your Emergency Services Provider keeps on record, or a copy
    of the record itself, or should You feel that you would like to object to
    the collection, use or processing of Your Personal Information by Your
    Emergency Services Provider, or that you would like us to delete your
    personally identifiable information, then send an e-mail directly to Your
    emergency Services Provider. They will endeavour to make the correction,
    provide you with the record, cease processing of Your Personal Information
    and/or delete it, in accordance with your request. 13.2 All requests or
    complaints will be treated confidentially.
  </p>
</div>
