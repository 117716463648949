import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssetManagementObject, FuelType, VehicleStatus } from 'app/interfaces/asset-management/asset-management-object.interface';
import { AssetManagementService } from 'app/services/asset-management/asset-management.service';

@Component({
    selector: 'asset-management-dialog.component',
    templateUrl: './asset-management-dialog.component.html',
    styleUrls: [
        './asset-management-dialog.component.css'
    ]
})
export class AssetManagementDialogComponent {
    dialogTitle: string;

    assetManagementForm: FormGroup;

    fuelTypes: string[] = Object.values(FuelType);
    vehicleStatuses: string[] = Object.values(VehicleStatus);

    constructor(public dialogRef: MatDialogRef<AssetManagementDialogComponent>, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data?: AssetManagementObject) {
        this.dialogTitle = data ? 'Update Asset' : 'Add Asset';
        
        this.assetManagementForm = this.formBuilder.group({
            vehicleType: ['', Validators.required],
            make: ['', Validators.required],
            model: ['', Validators.required],
            year: [null, Validators.pattern('^[0-9]{4}$')],
            registrationNumber: ['', Validators.required],
            capacity: [null, Validators.required],
            fuelType: ['', Validators.required],
            state: ['', Validators.required],
        });

        if (this.data) {
            this.assetManagementForm.patchValue(this.data);
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        if (this.assetManagementForm.valid) {
            const assetManagementObject: AssetManagementObject = this.assetManagementForm.value;

            if (this.data) {
                assetManagementObject.key = this.data.key;
            }

            this.dialogRef.close(assetManagementObject);
        }
    }
}