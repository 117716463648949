import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { PeachPaymentResponse, Receipt } from 'app/interfaces/general/payment.interface';
import Swal from 'sweetalert2';
import { CommunicationService } from 'app/services/communication/communication.service';
import { DataService } from 'app/services/dataService/data.service';
import { apiUrlEnum } from 'app/enums/api-endpoints.enums';
import { timeStampDataService } from "app/services/function/function.timestamp.service";

@Component({
  selector: 'app-payment-completed',
  templateUrl: './payment-completed.component.html',
  styleUrls: ['./payment-completed.component.scss'],
})
export class PaymentCompletedComponent implements OnInit {

  peachResponseVerify: PeachPaymentResponse;

  constructor(
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    public http: HttpClient,
    private communicationService: CommunicationService,
    private dataService: DataService,
    public timestampService: timeStampDataService
  ) { }

  ngOnInit() {
    JSON.parse(this.route.snapshot.paramMap.get('client'))
    this.activatedRoute.queryParams.subscribe(params => {
      localStorage.setItem('peachPaymentCheckoutId', params['id']);
    });

    Swal.fire({
      title: 'Processing Receipt',
      text: `Please Wait`,
      icon: 'info',
      cancelButtonText: 'Ok',
      buttonsStyling: true,
      heightAuto: false
    })

    this.checkReceipt();
  }


  async checkReceipt() {

    console.log('Verifying purchase');
    const proxyurl = 'https://cors-anywhere.herokuapp.com/';
    // tslint:disable-next-line: max-line-length

    const path = environment.paymentGateway.hostUrl + 'v1/checkouts/' +
      localStorage.getItem('peachPaymentCheckoutId') +
      '/payment?entityId=' +
      environment.paymentGateway.entityId;

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + environment.paymentGateway.bearerAuthToken,
      })
    };

    await this.http.get(proxyurl + path, httpOptions).toPromise().then(
      async response => {
        this.peachResponseVerify = <PeachPaymentResponse>response;

        switch (this.peachResponseVerify.result.code) {
          // Transaction succeeded
          case '000.000.000':
            await this.setSuccessfulTransaction();
            this.communicationService.sendEmail(
              'tech@response24.co.za', 'Subscription has been bought for user with email: ' +
              this.peachResponseVerify.customer['email'] +
              ' for client : ' +
              localStorage.getItem('peachPaymentUserClientName') +
              ' AMOUNT: R' + localStorage.getItem('peachPaymentUserMobilePrice'), 'R24 Transaction succeeded'
            );
            break;

          // Request successfully processed in 'Merchant in Integrator Test Mode'
          case '000.100.110':
            await this.setSuccessfulTransaction();
            this.communicationService.sendEmail(
              'tech@response24.co.za', 'Subscription has been bought for user with email: ' +
              this.peachResponseVerify.customer['email'] +
              ' for client : ' +
            localStorage.getItem('peachPaymentUserClientName'), 'Request successfully processed in Merchant in Integrator Test Mode'
            );
            break;

          // Request successfully processed in 'Merchant in Validator Test Mode'
          case '000.100.111':
            await this.setSuccessfulTransaction();
            this.communicationService.sendEmail(
              'tech@response24.co.za', 'Subscription has been bought for user with email: ' +
              this.peachResponseVerify.customer['email'] +
              ' for client : ' +
            localStorage.getItem('peachPaymentUserClientName'), 'Request successfully processed in Merchant in Validator Test Mode'
            );
            break;

          // Request successfully processed in 'Merchant in Connector Test Mode'
          case '000.100.112':
            await this.setSuccessfulTransaction();
            this.communicationService.sendEmail(
              'tech@response24.co.za', 'Subscription has been bought for user with email: ' +
              this.peachResponseVerify.customer['email'] +
              ' for client : ' +
            localStorage.getItem('peachPaymentUserClientName'), 'Request successfully processed in Merchant in Connector Test Mode'
            );
            break;

          default:
            this.errorModal(this.peachResponseVerify.result.description);
            break;
        }

      }).catch(error => {
        console.log(error.message);
      });
  }

  async setSuccessfulTransaction() {

    console.log('PAYMENT SUCCEED!')
    let timestamp;
    await this.timestampService.getServerTimeStampData().then(data => { 
      timestamp = data;
    }).catch(rejection => {
        console.error(rejection);
        Swal.fire({
            title: "Error Creating Timestamp for Transaction.",
            text: rejection,
            icon: "error",
            confirmButtonText: "Ok",
        });
        return;
    });

    const subscriptionReciept: Receipt = {
      userKey: localStorage.getItem('peachPaymentUserId'),
      userFirstName: localStorage.getItem('peachPaymentUserFirstName'),
      userLastName: localStorage.getItem('peachPaymentUserLastName'),
      reciept: JSON.stringify(this.peachResponseVerify),
      recieptTransationId: this.peachResponseVerify.id,
      peachPaymentResponseId: localStorage.getItem('peachPaymentCheckoutId'),
      peachPaymentTransactionId: localStorage.getItem('peachPaymentReceiptId'),
      groupKey: localStorage.getItem('peachPaymentGroupKey'),
      peachPaymentRegistrationId: this.peachResponseVerify.registrationId,
      // peachPaymentEntityId: this.peachResponseVerify.id,
      paymentGateway: 'Peach Payment',
      productId: 'mobile.peach.payment',
      productName: 'Monthly Premium Subscription (911 Response 24)',
      dateSubscribed: timestamp,
      paymentDate: timestamp,
      // tslint:disable-next-line: radix
      amount: parseInt(localStorage.getItem('peachPaymentUserMobilePrice')),
      paymentType: 'INITIAL',
      paymentStatus: 'PAID',
      currency: 'ZAR',
      gateWayUrl: environment.paymentGateway.hostUrl,
      production: environment.production
    };

    console.log(subscriptionReciept)

    // Update roles
    const that = this;

    // Remove all promo codes claimed for this user.
    // const userKey: UserKey = {
    //   key: subscriptionReciept.userKey,
    // }
    // this.dataService.postToFunctions(apiUrlEnum.userAuth_v1.user_remove_promo_codes, userKey)

    this.dataService.postToFunctions(apiUrlEnum.subscription_v1.start_subscription, subscriptionReciept)
      .then(reqResponse => {
        if (reqResponse.success) {
          that.success();
        } else {
          this.dataService.teamsLog('web', 'criticalException', 'codeExecution', 'Failed to start subscription')
        }
      }).catch(error => {
        console.error(error.message)
        this.dataService.teamsLog('web', 'criticalException', 'codeExecution', 'Failed to start subscription')
        that.errorPurchase(error.message);
      });
  }

  success() {
    Swal.fire({
      title: 'Success!',
      text: `You have been successfully subscribed`,
      icon: 'success',
      cancelButtonText: 'Ok',
      buttonsStyling: true,
      heightAuto: false
    })
  }

  errorPurchase(error) {
    Swal.fire({
      title: 'Error Occurred.',
      text: error,
      icon: 'error',
      confirmButtonText: 'Ok',
      cancelButtonText: 'Contact Support',
      showCancelButton: true,
      buttonsStyling: true,
      heightAuto: false
    }).then(result => {
      if (result.dismiss) {
        window.open('mailto:tech@response24.co.za?subject=In%20App%20Purchase%20Failure');
      }
    });
  }

  errorModal(reason: string): void {
    Swal.fire({
      title: 'Error Occurred.',
      text: reason,
      icon: 'error',
      confirmButtonText: 'Ok',
      showCancelButton: true,
      buttonsStyling: true,
      heightAuto: false
    }).then(result => {
      if (result.dismiss) {
        window.open('mailto:tech@response24.co.za?subject=UserKey: ' +
          localStorage.getItem('peachPaymentUserId') +
          '. Payment Error: ' + reason);
      }
    });
  }
}
