var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { environment } from "environments/environment";
import 'firebase/auth'; // for authentication
import 'firebase/storage'; // for storage
import 'firebase/database'; // for realtime database
import 'firebase/firestore'; // for cloud firestore
import 'firebase/messaging'; // for cloud messaging
import 'firebase/functions'; // for cloud functions
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var AuthService = /** @class */ (function () {
    //private userSubscription: Subscription | null = null;
    function AuthService(angularFirestore, angularFireAuth, 
    //private angularFireAuth: AngularFireAuth, 
    //private angularFirestore: AngularFirestore, 
    router) {
        var _this = this;
        this.angularFirestore = angularFirestore;
        this.angularFireAuth = angularFireAuth;
        this.router = router;
        this.plusFirebaseFunctionsUrls = [];
        angularFireAuth.authState.subscribe(function (user) { return _this._user = user; });
        this.plusFirebaseFunctionsUrls = environment.plusFirebaseFunctionsUrls;
        // Retrieve appUser from session storage
        var storedAppUser = sessionStorage.getItem('appUser');
        if (storedAppUser) {
            this.appUser = JSON.parse(storedAppUser);
        }
        console.debug('AuthService: instantiated');
    }
    Object.defineProperty(AuthService.prototype, "user", {
        get: function () {
            return this._user;
        },
        set: function (value) {
            this._user = value;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.loadUserData = function (userCredential) {
        return __awaiter(this, void 0, void 0, function () {
            var doc, query, snapshot;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.appUser = {
                            userDetail: null,
                            userClient: null,
                            plusEnvironment: null
                        };
                        return [4 /*yield*/, this.angularFirestore.doc("users/" + userCredential.user.uid).get().toPromise()];
                    case 1:
                        doc = _a.sent();
                        if (!doc.exists) {
                            throw Error('User details not found!');
                        }
                        this.appUser.userDetail = doc.data();
                        this.appUser.plusEnvironment = this.plusFirebaseFunctionsUrls.find(function (x) { return x.clientKey === _this.appUser.userDetail.clientKey; });
                        query = this.angularFirestore.collection('clients', function (ref) { return ref.where('key', '==', _this.appUser.userDetail.clientKey).limit(1); });
                        return [4 /*yield*/, query.get().toPromise()];
                    case 2:
                        snapshot = _a.sent();
                        if (snapshot.empty) {
                            throw Error('Client details not found!');
                        }
                        this.appUser.userClient = snapshot.docs[0].data();
                        /* this.dataService.loggedInUserDisplayName = this.appUser.userDetail.firstName + ' ' + this.appUser.userDetail.lastName;
                        this.dataService.loggedInUserKey = userCredential.user.uid; */
                        // Store appUser in session storage
                        sessionStorage.setItem('appUser', JSON.stringify(this.appUser));
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.emailLogin = function (email, password) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            /* this.angularFireAuth.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
                
            }) */
            return _this.angularFireAuth.auth.signInWithEmailAndPassword(email, password).then(function (userCredential) { return __awaiter(_this, void 0, void 0, function () {
                var error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this.loadUserData(userCredential)];
                        case 1:
                            _a.sent();
                            resolve(); // Login process completed successfully
                            return [3 /*break*/, 3];
                        case 2:
                            error_1 = _a.sent();
                            console.error('Login error', error_1);
                            reject(error_1);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); }).catch(function (error) {
                console.error('Login error', error);
                reject(error);
            });
        });
    };
    /*  private subscribeForUserChanges(userId: string) {
            if (this.userSubscription) {
                this.userSubscription.unsubscribe();
            }
    
            this.userSubscription = this.angularFirestore.doc(`users/${userId}`).valueChanges().subscribe(user => {
                this.appUser.userDetail = user;
            });
        }
    
        private unsubscribeForUserChanges() {
            if (this.userSubscription) {
                this.userSubscription.unsubscribe();
                this.userSubscription = null;
            }
        } */
    AuthService.prototype.emailRegister = function (user, password) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            return _this.angularFireAuth.auth.createUserWithEmailAndPassword(user.email, password).then(function (userCredential) { return __awaiter(_this, void 0, void 0, function () {
                var error_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this.loadUserData(userCredential)];
                        case 1:
                            _a.sent();
                            resolve(); // Registration process completed successfully
                            return [3 /*break*/, 3];
                        case 2:
                            error_2 = _a.sent();
                            console.error('Registration error', error_2);
                            reject(error_2);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); }).catch(function (error) {
                console.error('Registration error', error);
                reject(error);
            });
        });
    };
    /* emailRegister2(user: UserDetail, password: string): Promise<firebase.auth.UserCredential> {
        return this.angularFireAuth.auth.createUserWithEmailAndPassword(user.email, password).then((credential) => {
            return credential;
        })
        .catch((error) => {
            throw error;
        });
    } */
    AuthService.prototype.resetPasswordWithMail = function (email) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.angularFireAuth.auth.sendPasswordResetEmail(email).then(function () {
                resolve("Reset Email has been sent to " + email);
            }).catch(function (error) {
                reject(error);
                // An error happened.
            });
        });
    };
    AuthService.prototype.signOut = function (returnUrl) {
        var _this = this;
        this.angularFireAuth.auth.signOut().then(function () {
            // Clear appUser from session storage
            sessionStorage.clear();
            //sessionStorage.removeItem('appUser');
            /* this.dataService.postToFunctions(
                apiUrlEnum.info.log,
                {
                    userKey: this.appUser.userDetail.key,
                    action: "Logged out",
                },
                false,
                environment.functionsUrl
            ); */
            delete _this.appUser;
            /* delete this.dataService.loggedInUserKey;
            delete this.dataService.loggedInUserDisplayName; */
            _this.router.navigate([returnUrl]);
        });
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject("project-one-firestore"), i0.ɵɵinject("project-one-fireauth"), i0.ɵɵinject(i1.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
