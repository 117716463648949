var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AssetRoles, ResourceRoles } from 'app/interfaces/journey-management/journey-management-object.interface';
import * as short from 'short-uuid';
import { ItemTypeEnum } from 'app/interfaces/booking-management/booking-management-object.interface';
import { ConfirmationDialogComponent } from 'app/pages/Common/confirmation-dialog.component';
var AdminDashboardJourneyManagementAssignmentDialogComponent = /** @class */ (function () {
    function AdminDashboardJourneyManagementAssignmentDialogComponent(dialogRef, dialog, data) {
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.data = data;
        this.resourceRoles = Object.values(ResourceRoles);
        this.selectedResources = [];
        this.resourceRoleSelections = {};
        this.assetRoles = Object.values(AssetRoles);
        this.selectedAssets = [];
        this.assetRoleSelections = {};
        this.assetResourceAssignments = {};
        this.isLinear = true;
        this.canSave = false;
        this.selectedResourceKeys = new Set();
        this.selectedAssetKeys = new Set();
        this.extraConfirmation = false;
        this.assetKeys = [];
    }
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.ngOnInit = function () {
        if (this.data && this.data.serviceInformation.linkedAssetsResources) {
            this.populateLinkedAssetResources(this.data.serviceInformation.linkedAssetsResources);
            this.additionalNotes = this.data.serviceInformation.additionalNotes;
            this.autoDispatch = this.data.serviceInformation.autoDispatch ? this.data.serviceInformation.autoDispatch : false;
            this.autoDispatchTime = this.data.serviceInformation.autoDispatchTime ? this.data.serviceInformation.autoDispatchTime : 0;
        }
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.populateLinkedAssetResources = function (linkedAssetResources) {
        var _this = this;
        this.isLinear = false;
        var _loop_1 = function (linkedAssetResource) {
            if (linkedAssetResource.asset) {
                this_1.selectedAssets.push(linkedAssetResource.asset.asset);
                this_1.selectedAssetKeys.add(linkedAssetResource.asset.asset.key);
                this_1.assetRoleSelections[linkedAssetResource.asset.asset.key] = linkedAssetResource.asset.assetRole;
            }
            linkedAssetResource.associatedResources.forEach(function (linkedResourceRole) {
                if (!linkedResourceRole || !linkedResourceRole.resource) {
                    return;
                }
                if (_this.selectedResources.findIndex(function (r) { return r.key === linkedResourceRole.resource.key; }) === -1) {
                    _this.selectedResources.push(linkedResourceRole.resource);
                    _this.selectedResourceKeys.add(linkedResourceRole.resource.key);
                }
                _this.resourceRoleSelections[linkedResourceRole.resource.key] = linkedResourceRole.resourceRole;
                if (linkedAssetResource.asset && linkedAssetResource.asset.asset) {
                    _this.assetResourceAssignments[linkedAssetResource.asset.asset.key] = _this.assetResourceAssignments[linkedAssetResource.asset.asset.key] || [];
                    _this.assetResourceAssignments[linkedAssetResource.asset.asset.key].push(linkedResourceRole.resource);
                }
            });
        };
        var this_1 = this;
        for (var _i = 0, linkedAssetResources_1 = linkedAssetResources; _i < linkedAssetResources_1.length; _i++) {
            var linkedAssetResource = linkedAssetResources_1[_i];
            _loop_1(linkedAssetResource);
        }
        this.assetKeys = Object.keys(this.assetResourceAssignments);
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.toggleSelection = function (items, item, selections, keySet) {
        var index = items.findIndex(function (i) { return i.key === item.key; });
        if (index !== -1) {
            items.splice(index, 1);
            keySet.delete(item.key);
            if (selections.hasOwnProperty(item.key)) {
                delete selections[item.key];
            }
        }
        else {
            items.push(item);
            keySet.add(item.key);
        }
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.isItemSelected = function (keySet, item) {
        return keySet.has(item.key);
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.toggleResourceSelection = function (resource) {
        this.toggleSelection(this.selectedResources, resource, this.resourceRoleSelections, this.selectedResourceKeys);
        if (this.assetResourceAssignments) {
            for (var assetKey in this.assetResourceAssignments) {
                this.assetResourceAssignments[assetKey] = this.assetResourceAssignments[assetKey].filter(function (user) { return user.key !== resource.key; });
            }
        }
        this.assetKeys = Object.keys(this.assetResourceAssignments);
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.isResourceSelected = function (resource) {
        return this.isItemSelected(this.selectedResourceKeys, resource);
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.assignResourceRole = function (resource, role) {
        this.resourceRoleSelections[resource.key] = role;
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.getResourceRole = function (resourceKey) {
        var resourceRole = this.resourceRoleSelections[resourceKey];
        return resourceRole ? resourceRole : 'Unknown Role';
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.getResourceSchedule = function (resourceKey) {
        var times = [];
        this.data.existingBookings.forEach(function (booking) {
            if (booking.itemType === ItemTypeEnum.Resource && booking.itemKey === resourceKey) {
                times.push(booking.time);
            }
        });
        if (times.length === 0) {
            //return 'The resource is not scheduled for today.';
            return '';
        }
        else {
            var timesString = times.join(', ');
            return " - This resource has been scheduled for " + times.length + " job" + (times.length > 1 ? 's' : '') + " at the following times: " + timesString;
        }
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.toggleAssetSelection = function (asset) {
        this.toggleSelection(this.selectedAssets, asset, this.assetRoleSelections, this.selectedAssetKeys);
        if (this.assetResourceAssignments && this.assetResourceAssignments[asset.key]) {
            delete this.assetResourceAssignments[asset.key];
        }
        this.assetKeys = Object.keys(this.assetResourceAssignments);
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.toggleAutoDispatch = function () {
        if (this.autoDispatch) {
            this.autoDispatch = false;
        }
        else {
            this.autoDispatch = true;
            if (this.autoDispatchTime == 0) {
                this.autoDispatchTime = 5;
            }
        }
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.setAutoDispatchTime = function (event) {
        this.autoDispatchTime = event.value;
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.isAssetSelected = function (asset) {
        return this.isItemSelected(this.selectedAssetKeys, asset);
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.assignAssetRole = function (asset, role) {
        this.assetRoleSelections[asset.key] = role;
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.getAssetRole = function (assetKey) {
        var assetRole = this.assetRoleSelections[assetKey];
        return assetRole ? assetRole : 'Unknown Role';
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.getAssetSchedule = function (assetKey) {
        var times = [];
        this.data.existingBookings.forEach(function (booking) {
            if (booking.itemType === ItemTypeEnum.Asset && booking.itemKey === assetKey) {
                times.push(booking.time);
            }
        });
        if (times.length === 0) {
            //return 'The asset is not scheduled for today.';
            return '';
        }
        else {
            var timesString = times.join(', ');
            return " - This asset has been scheduled for " + times.length + " job" + (times.length > 1 ? 's' : '') + " at the following times: " + timesString;
        }
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.isMaxCountReached = function (asset) {
        if (!this.assetResourceAssignments[asset.key]) {
            this.assetResourceAssignments[asset.key] = [];
        }
        return this.assetResourceAssignments[asset.key].length >= asset.capacity;
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.isResourceAssignedToAsset = function (asset, resource) {
        var assignedResources = this.assetResourceAssignments[asset.key] || [];
        return assignedResources.includes(resource);
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.toggleAssetResourceAssignment = function (asset, resource) {
        if (!this.assetResourceAssignments[asset.key]) {
            this.assetResourceAssignments[asset.key] = [];
        }
        var currentAssignmentsForAsset = this.assetResourceAssignments[asset.key].length;
        var isAlreadyAssignedToAsset = this.assetResourceAssignments[asset.key].includes(resource);
        if (!isAlreadyAssignedToAsset && currentAssignmentsForAsset < asset.capacity) {
            for (var key in this.assetResourceAssignments) {
                var index = this.assetResourceAssignments[key].findIndex(function (r) { return r === resource; });
                if (index !== -1) {
                    this.assetResourceAssignments[key].splice(index, 1);
                }
            }
            this.assetResourceAssignments[asset.key].push(resource);
        }
        else if (isAlreadyAssignedToAsset) {
            var index = this.assetResourceAssignments[asset.key].findIndex(function (r) { return r === resource; });
            if (index !== -1) {
                this.assetResourceAssignments[asset.key].splice(index, 1);
            }
        }
        this.assetKeys = Object.keys(this.assetResourceAssignments);
    };
    /* get assetKeys() {
        return Object.keys(this.assetResourceAssignments);
    } */
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.getAssetName = function (assetKey) {
        var asset = this.selectedAssets.find(function (a) { return a.key === assetKey; });
        return asset ? asset.make + " " + asset.model + " " + asset.registrationNumber : 'Unknown Asset';
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.onStepChange = function (event) {
        if (event.selectedIndex === 6) {
            this.canSave = true;
        }
        else {
            this.canSave = false;
        }
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.onConfirm = function () {
        var _this = this;
        var linkedAssetResources = [];
        var message = '';
        var allSelectedResources = new Set(this.selectedResources.map(function (resource) { return resource.key; }));
        for (var i = 0; i < this.selectedResources.length; i++) {
            var resource = this.selectedResources[i];
            if (!this.resourceRoleSelections[resource.key]) {
                message += 'Resource exists but no resource role is associated. ';
                break;
            }
        }
        this.selectedAssets.forEach(function (asset) {
            var assetRole = {
                asset: asset,
                assetRole: _this.assetRoleSelections[asset.key]
            };
            if (asset && !assetRole.assetRole) {
                message += 'Asset exists but no asset role is associated. ';
            }
            var associatedResources = [];
            var assetResourceList = _this.assetResourceAssignments[asset.key] || [];
            assetResourceList.forEach(function (resource) {
                var resourceRole = {
                    resource: resource,
                    resourceRole: _this.resourceRoleSelections[resource.key]
                };
                if (resource && !resourceRole.resourceRole) {
                    message += 'Resource exists but no resource role is associated. ';
                }
                associatedResources.push(resourceRole);
                allSelectedResources.delete(resource.key);
            });
            if (associatedResources.length > 0 && !asset) {
                message += 'Associated resources exist but no asset is linked. ';
            }
            if (asset && associatedResources.length === 0) {
                message += 'Asset exists but no associated resources are linked. ';
            }
            linkedAssetResources.push({
                key: short.generate(),
                asset: assetRole,
                associatedResources: associatedResources
            });
        });
        if (allSelectedResources.size > 0) {
            message += 'Some resources were selected but not assigned to any assets. ';
        }
        if (message.length > 0) {
            this.extraConfirmation = true;
            message += 'Would you still like to save?';
            var dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                width: '25%',
                disableClose: true,
                data: {
                    message: message
                }
            });
            dialogRef.afterClosed().subscribe(function (result) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.extraConfirmation = false;
                    if (result) {
                        this.dialogRef.close({
                            additionalNotes: this.additionalNotes,
                            autoDispatch: this.autoDispatch,
                            autoDispatchTime: this.autoDispatchTime,
                            linkedAssetResources: linkedAssetResources
                        });
                    }
                    return [2 /*return*/];
                });
            }); });
        }
        else {
            this.dialogRef.close({
                additionalNotes: this.additionalNotes,
                autoDispatch: this.autoDispatch,
                autoDispatchTime: this.autoDispatchTime,
                linkedAssetResources: linkedAssetResources
            });
        }
    };
    AdminDashboardJourneyManagementAssignmentDialogComponent.prototype.onCancel = function () {
        this.dialogRef.close();
    };
    return AdminDashboardJourneyManagementAssignmentDialogComponent;
}());
export { AdminDashboardJourneyManagementAssignmentDialogComponent };
