import { Inject, Injectable } from '@angular/core';
import { FirestoreCollection, LogPlatform, LogType, LogAction } from 'app/types/data.type';
import { HttpClient } from '@angular/common/http';
import { ReqResponse } from 'app/interfaces/general/response.interface';
import { environment } from 'environments/environment';
import * as firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
import { TeamsMessageRequest } from 'app/interfaces/communication.interface';
import { apiUrlEnum } from 'app/enums/api-endpoints.enums';
import { DeleteFireStoreDataRequest, SetFireStoreDataRequest, GetFireStoreDataRequest, GetFireStoreDataStartAtRequest } from 'app/interfaces/data.interface';
import { CommunicationService } from '../communication/communication.service';

import { AuthService } from "app/core/auth.service";
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FIREBASE_REFERENCES } from 'app/core/firebase/firebase.module';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
    providedIn: 'root'
})
export class DataPlusService {

    loggedInUserKey: string;
    loggedInUserDisplayName: string;

    constructor(
        private authService: AuthService, 
        private communicationService: CommunicationService, 
        private http: HttpClient,
        @Inject(FIREBASE_REFERENCES.ONE_FIRESTORE) private readonly afs: AngularFirestore,
        @Inject(FIREBASE_REFERENCES.TWO_FIRESTORE) private readonly plusDB: AngularFirestore,
        @Inject(FIREBASE_REFERENCES.TWO_FIREAUTH) private readonly plusDBAuth: AngularFireAuth,
    ) { }

    getFirestoreDataFromFunctions(
        userKey: string,
        collection: FirestoreCollection,
        filterName: string = '',
        filterValue: any = '',
        whereFilterOp: firebase.firestore.WhereFilterOp = '==',
        limit: number = -1,
        orderBy: string = '',
        orderbyDirection: firebase.firestore.OrderByDirection = 'asc',
        startAfter: any = -1,
        retryCount: number = 1,
        environmentUrl = environment.functionsUrlPlusGroup1,
    ): Promise<any> {

        const getFireStoreDataRequest: GetFireStoreDataRequest = {
            collection,
            filterName,
            filterValue,
            whereFilterOp,
            limit,
            orderBy,
            orderbyDirection,
            startAfter,
            apiKey: environment.apiKey,
            userKey: userKey
        };

        console.log('-> Getting from Functions')
        console.log(getFireStoreDataRequest)

        return this.postToFunctions(apiUrlEnum.data_v1.get_fire_store_data, getFireStoreDataRequest, true, environmentUrl)
            .then(reqResponse => {

                if (reqResponse.success) {
                    return reqResponse.data;
                } else {
                    console.error(reqResponse);
                    if (retryCount <= 1) {
                        return this.getFirestoreDataFromFunctions(userKey, collection, filterName, filterValue, whereFilterOp, limit, orderBy, orderbyDirection, startAfter, 2)
                    } else {
                        throw (reqResponse.error)
                    }
                }
            }).catch(exception => {
                console.error(exception.message)
                //    alert('Failed to fetch data, please reload page.')
                // throw (exception)
            });
    }

    getFirestoreDataFromFunctionsStartAt(
        userKey: string,
        collection: FirestoreCollection,
        startAt: any = 0,
        orderBy: string = '',
        limit: number = -1,
    ): Promise<any> {

        const getFireStoreDataRequest: GetFireStoreDataStartAtRequest = {
            collection,
            limit,
            orderBy,
            startAt,
            userKey: userKey
        };
        console.log('-> Getting from Functions')
        console.log(getFireStoreDataRequest)

        return this.postToFunctions(apiUrlEnum.data_v1.get_fire_store_data_start_at, getFireStoreDataRequest)
            .then(reqResponse => {

                if (reqResponse.success) {
                    return reqResponse.data;
                } else {
                    console.error(reqResponse);
                }
            }).catch(exception => {
                console.error(exception.message)
                // alert('Failed to fetch data, please reload page.')
                //  throw (exception)
            });
    }

    setFireStoreDataFromFunctions(userKey: string, collection: FirestoreCollection, data: any, environmentUrl = environment.functionsUrlPlusGroup1): Promise<string> {
        const setFireStoreDataRequest: SetFireStoreDataRequest = {
            collection,
            apiKey: environment.apiKey,
            userKey: userKey,
            data
        };

        return this.postToFunctions(apiUrlEnum.data_v1.set_fire_store_data, setFireStoreDataRequest, false, environmentUrl).then(reqResponse => {
            if (reqResponse.success) {
                return reqResponse.data;
            }
            else {
                console.error(reqResponse);
            }
        }).catch(exception => {
            this.teamsLog('mobile', 'exception', 'databaseCallSet', collection);
            console.log(exception.message);
        });
    }

    updateFireStoreDataFromFunctions(userKey: string, collection: FirestoreCollection, data: any, environmentUrl = environment.functionsUrlPlusGroup1): Promise<string> {
        const setFireStoreDataRequest: SetFireStoreDataRequest = {
            collection,
            apiKey: environment.apiKey,
            userKey: userKey,
            data
        };

        return this.postToFunctions(apiUrlEnum.data_v1.update_fire_store_data, setFireStoreDataRequest, false, environmentUrl).then(reqResponse => {
            if (reqResponse.success) {
                return reqResponse.data.key;
            }
            else {
                console.error(reqResponse);
            }
        }).catch(exception => {
            this.teamsLog('mobile', 'exception', 'databaseCallSet', collection);
            console.log(exception.message);
        });
    }

    deleteFireStoreDataFromFunctions(userKey: string, collection: FirestoreCollection, key: string): Promise<void> {
        const deleteFireStoreDataRequest: DeleteFireStoreDataRequest = {
            collection,
            key,
            apiKey: environment.apiKey,
            userKey: userKey,
        };

        return this.postToFunctions(apiUrlEnum.data_v1.delete_fire_store_data, deleteFireStoreDataRequest).then(reqResponse => {
            if (reqResponse.success) {
                return reqResponse.data.key;
            }
            else {
                console.error(reqResponse.message);
            }
        }).catch(exception => {
            this.teamsLog('mobile', 'exception', 'databaseCallSet', collection);
            console.log(exception.message);
        });
    }

    async postToFunctions(apiUrlEnm: string, body: any, sendToTeams = true, functionURL = environment.functionsUrlPlusGroup1): Promise<ReqResponse> {

        /* return this.http.post(environmentUrl + apiUrlEnm, body).toPromise().then(
          data => {
            return <ReqResponse>data;
          },
        ).catch(exception => {
          console.error(exception)
          if (sendToTeams) {
            this.teamsLog('mobile', 'exception', 'ApiFunctionsCall', environment.functionsUrlPlusGroup1 + apiUrlEnm)
          }
          console.error('exception ', exception);
          const response: ReqResponse = {
            success: false,
            message: exception.message,
            error: exception
          };
          return response;
        }); */

        let response: ReqResponse = {
            success: null,
            message: null,
            error: null,
            data: null,
            dataExtended: null,
            dataExtended2: null,
            refId: null,
            responseCode: null,
        };

        try {
            const idToken = await this.authService.user.getIdToken(false);

            if (!idToken) {
                throw new Error('ID token is null');
            }

            response = await this.http.post<ReqResponse>(functionURL + apiUrlEnm, body, {
                headers: {
                    'Authorization': 'Bearer ' + idToken,
                    'Content-Type': 'application/json',
                    'Project-Id': environment.firebase['PROJECT-ONE'].projectId
                }
            }).toPromise();
        }
        catch (error) {
            if (sendToTeams) {
                this.teamsLog("mobile", "exception", "ApiFunctionsCall", functionURL + apiUrlEnm);
            }

            console.error("Error:", error);

            response = {
                success: false,
                message: error.message,
                error: error,
            };
        }

        return response;
    }

    querySnapshotToObject(snapshot: firebase.firestore.QuerySnapshot): any {
        const returnObj = [];
        snapshot.forEach(queryDocSnap => {
            returnObj.push(queryDocSnap.data());
        });

        return returnObj;
    }

    getFirestoreDataSubscription(
        collection: FirestoreCollection,
        filterName: string = "",
        filterValue: any = "",
        whereFilterOp: firebase.firestore.WhereFilterOp = "==",
        limit: number = -1,
        orderBy: string = "",
        orderbyDirection: firebase.firestore.OrderByDirection = "desc"
    ): AngularFirestoreCollection<any> {
        if (filterName !== "") {
            if (limit === -1) {
                
                return this.plusDB.collection(collection, (ref) =>
                    ref.where(filterName, whereFilterOp, filterValue)
                );
            } else {
                return this.plusDB.collection(collection, (ref) =>
                    ref.where(filterName, whereFilterOp, filterValue).limit(limit)
                );
            }
        } else if (orderBy !== "") {
            if (limit === -1) {
                return this.plusDB.collection(collection, (ref) =>
                    ref.orderBy(orderBy, orderbyDirection)
                );
            } else {
                return this.plusDB.collection(collection, (ref) =>
                    ref.orderBy(orderBy, orderbyDirection).limit(limit)
                );
            }
        } else {
            if (limit === -1) {
                return this.plusDB.collection(collection, (ref) => ref);
            } else {
                return this.plusDB.collection(collection, (ref) => ref.limit(limit));
            }
        }
    }

    teamsLog(platform: LogPlatform, type: LogType, action: LogAction, detail: string) {
        const log = {
            userDisplayName: this.loggedInUserDisplayName,
            userKey: this.loggedInUserKey,
            version: environment.version
        };

        if (!log.userDisplayName) {
            log.userDisplayName = 'Un Registered User';
        }
        if (!log.userKey) {
            log.userKey = 'NA';
        }

        const teamsRequest: TeamsMessageRequest = {
            preText: platform + ' - ' + type + ' ( ' + action + ' ) ',
            title: 'Initator : *' + log.userDisplayName + '* (' + log.userKey + ')',
            text: 'Action Initated Detail : ' + detail + ', on Version : ' + log.version,
            teamsGroupUrl: environment.teams.systemLogsUrl
        };
        this.postToFunctions(apiUrlEnum.communication_v1.teams_send, teamsRequest, false);
    }
}
