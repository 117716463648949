var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { apiUrlEnum } from 'app/enums/api-endpoints.enums';
import { AuthService } from '../../core/auth.service';
import { DataService } from '../dataService/data.service';
import { CustomClientService } from '../customClients/customClient.service';
import Swal from 'sweetalert2';
import { timeStampDataService } from "app/services/function/function.timestamp.service";
var ResponseServiceService = /** @class */ (function () {
    // Constructor for the ResponseServiceService class
    function ResponseServiceService(dataService, authService, customClientService, timestampService) {
        this.dataService = dataService;
        this.authService = authService;
        this.customClientService = customClientService;
        this.timestampService = timestampService;
        console.debug('ResponseServiceService: instantiated');
    }
    // Method to add history to a response service action
    ResponseServiceService.prototype.addResponderActionSaveStateHistory = function (message, state, responseServiceAction, data) {
        return __awaiter(this, void 0, void 0, function () {
            var timestamp_1, historyObject, exception_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.debug('ResponseServiceService: addResponderActionSaveStateHistory - start');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.timestampService.getServerTimeStampData().then(function (data) {
                                timestamp_1 = data;
                            }).catch(function (rejection) {
                                console.error(rejection);
                                Swal.fire({
                                    title: "Error Creating Timestamp for State History Update.",
                                    text: rejection,
                                    icon: "error",
                                    confirmButtonText: "Ok",
                                });
                                return;
                            })];
                    case 2:
                        _a.sent();
                        historyObject = {
                            changeOwnerDisplayName: this.authService.appUser.userDetail.firstName + ' ' + this.authService.appUser.userDetail.lastName,
                            changeOwnerKey: this.authService.appUser.userDetail.key,
                            changeOwnerClientName: this.authService.appUser.userClient.name,
                            changeOwnerClientKey: this.authService.appUser.userClient.key,
                            dateUpdated: timestamp_1,
                            state: state,
                            message: message
                        };
                        // If additional data is provided, include it in the history object
                        if (data) {
                            historyObject.data = data;
                        }
                        // Push history object into response service action's state history
                        responseServiceAction.stateHistory.push(historyObject);
                        // Notify custom client service about response service status change
                        this.customClientService.stratPayResponseServiceStatusChange(responseServiceAction);
                        console.debug('ResponseServiceService: addResponderActionSaveStateHistory - end');
                        return [4 /*yield*/, this.dataService.updateFireStoreData('responseServiceActions', responseServiceAction)];
                    case 3: return [2 /*return*/, _a.sent()];
                    case 4:
                        exception_1 = _a.sent();
                        console.error('ResponseServiceService: addResponderActionSaveStateHistory - error', exception_1);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    // Method to add a chat message to a response service action
    ResponseServiceService.prototype.addResponderActionChat = function (message, responseServiceAction, responders) {
        return __awaiter(this, void 0, void 0, function () {
            var timestamp_2, chatObject, exception_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.debug('ResponseServiceService: addResponderActionChat - start');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.timestampService.getServerTimeStampData().then(function (data) {
                                timestamp_2 = data;
                            }).catch(function (rejection) {
                                console.error(rejection);
                                Swal.fire({
                                    title: "Error Creating Timestamp for Chat.",
                                    text: rejection,
                                    icon: "error",
                                    confirmButtonText: "Ok",
                                });
                                return;
                            })];
                    case 2:
                        _a.sent();
                        chatObject = {
                            responderKey: this.authService.appUser.userDetail.key,
                            responderDisplayName: this.authService.appUser.userDetail.firstName + " " + this.authService.appUser.userDetail.lastName,
                            clientName: this.authService.appUser.userClient.name,
                            clientKey: this.authService.appUser.userClient.key,
                            date: timestamp_2,
                            message: message
                        };
                        // If the responseChats array in the responseServiceAction object is null (i.e., no chats exist yet),
                        // we initialize it to an empty array to avoid null pointer exceptions.
                        if (responseServiceAction.responseChats == null) {
                            responseServiceAction.responseChats = [];
                        }
                        // The chat object is then added to the responseChats array
                        responseServiceAction.responseChats.push(chatObject);
                        // Notify custom client service about response service status change.
                        this.customClientService.stratPayResponseServiceStatusChange(responseServiceAction);
                        responders.forEach(function (responder) {
                            var pushToUser = {
                                message: message,
                                title: _this.authService.appUser.userDetail.displayName,
                                fcmToken: responder.userDetail.fcmToken,
                                userKey: _this.authService.appUser.userClient.key,
                            };
                            _this.dataService.postToFunctions(apiUrlEnum.communication_v1.push_user, pushToUser);
                        });
                        // [Alucard] Consider this to the above as a performance improvement and add to custom function
                        /* let promises = responders.map(responder => {
                            const pushToUser: PushToUser = {
                                message: message,
                                title: this.authService.appUser.userDetail.displayName,
                                fcmToken: responder.userDetail.fcmToken,
                                userKey: this.authService.appUser.userClient.key,
                            };
                        
                            // `postToFunctions` needs to return a promise
                            return this.dataService.postToFunctions(
                                apiUrlEnum.communication_v1.push_user,
                                pushToUser,
                            );
                        });
                        
                        // Run the promises in parallel
                        Promise.all(promises).then((responses) => {
                            // responses is an array of all the responses
                            console.debug('All push notifications sent successfully');
                        })
                        .catch((error) => {
                            console.error('Error sending push notifications', error);
                        }); */
                        console.debug('ResponseServiceService: addResponderActionChat - end');
                        return [4 /*yield*/, this.dataService.updateFireStoreData('responseServiceActions', responseServiceAction)];
                    case 3: return [2 /*return*/, _a.sent()];
                    case 4:
                        exception_2 = _a.sent();
                        console.error('ResponseServiceService: addResponderActionChat - error', exception_2);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    // Method to update a chat message status
    ResponseServiceService.prototype.updateMessageStatus = function (chatMessage, responseServiceAction) {
        return __awaiter(this, void 0, void 0, function () {
            var exception_3;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.debug('ResponseServiceService: updateMessageStatus - start');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        // Find the chat message in the response service action's chats, and update its read status
                        responseServiceAction.responseChats.find(function (foundChatMessage) { return __awaiter(_this, void 0, void 0, function () {
                            var timestamp_3;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(foundChatMessage == chatMessage)) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.timestampService.getServerTimeStampData().then(function (data) {
                                                timestamp_3 = data;
                                            }).catch(function (rejection) {
                                                console.error(rejection);
                                                Swal.fire({
                                                    title: "Error Creating Timestamp for Chate Message Update.",
                                                    text: rejection,
                                                    icon: "error",
                                                    confirmButtonText: "Ok",
                                                });
                                                return;
                                            })];
                                    case 1:
                                        _a.sent();
                                        foundChatMessage.read = timestamp_3;
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); });
                        // Notify custom client service about response service status change
                        this.customClientService.stratPayResponseServiceStatusChange(responseServiceAction);
                        console.debug('ResponseServiceService: updateMessageStatus - end');
                        return [4 /*yield*/, this.dataService.updateFireStoreData('responseServiceActions', responseServiceAction)];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3:
                        exception_3 = _a.sent();
                        console.error('ResponseServiceService: updateMessageStatus - error', exception_3);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return ResponseServiceService;
}());
export { ResponseServiceService };
