import { Injectable } from '@angular/core';

import { apiUrlEnum } from 'app/enums/api-endpoints.enums';
import { LocationRequest } from 'app/interfaces/location.interface';
import { ReqResponse } from 'app/interfaces/general/response.interface';

import { DataService } from '../dataService/data.service';

@Injectable()
export class LocationService {

    constructor(private dataService: DataService) { }

    async getAddress(lat: number, long: number): Promise<string> {
        const locationRequest: LocationRequest = {
            lat: lat,
            long: long
        };

        return this.dataService.postToFunctions(apiUrlEnum.location_v1.address_from_lat_long, locationRequest).then(returnData => {
            const reqResponse: ReqResponse = returnData

            if (reqResponse.success) {
                if (reqResponse.data['results'].length === 0) {
                    return ('Address not found.');
                }
                else {
                    const formatted_address = reqResponse.data['results'][0]['formatted_address'];

                    if (formatted_address === undefined) {
                        return 'Address Not Found'
                    }
                    else {
                        return (formatted_address);
                    }
                }
            }
        });
    }

    distanceBetweenCooKM(latFrom: number, longFrom: number, latTo: number, longTo: number): number {
        if (!latFrom || !longFrom || !latTo || !longTo) {
            return -1;
        }

        const dist = this.calcDistance(latFrom,
            longFrom,
            latTo,
            longTo,
            'K'
        );

        return Math.round(dist * 100) / 100;
    }

    private calcDistance(lat1, lon1, lat2, lon2, unit): number {
        if ((lat1 === lat2) && (lon1 === lon2)) {
            return 0;
        }
        else {
            const radlat1 = Math.PI * lat1 / 180;
            const radlat2 = Math.PI * lat2 / 180;
            const theta = lon1 - lon2;
            const radtheta = Math.PI * theta / 180;

            let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

            if (dist > 1) {
                dist = 1;
            }

            dist = Math.acos(dist);
            dist = dist * 180 / Math.PI;
            dist = dist * 60 * 1.1515;

            if (unit === 'K') {
                dist = dist * 1.609344;
            }

            if (unit === 'N') {
                dist = dist * 0.8684;
            }

            return dist;
        }
    }
}
