import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'terms-and-conditions-usa',
  templateUrl: './terms-and-conditions-usa.component.html',
  styleUrls: ['./terms-and-conditions-usa.component.scss'],
})
export class TermsAndConditionsUsa implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
