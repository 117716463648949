import { NgModule } from '@angular/core';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import { environment } from 'environments/environment';
import { ENV, ENV_CONFIG } from './env';
import { AuthService } from 'app/core/auth.service';
import { AuthGuardClient } from 'app/core/auth.guard.client';
import { AuthGuardAdmin } from 'app/core//auth.guard.admin';
import { AuthGuardProvider } from 'app/core//auth.guard.provider';
import { FirebaseModule } from './firebase/firebase.module';

@NgModule({
  imports: [
    FirebaseModule,
  ],
  providers: [
    {provide: ENV_CONFIG, useValue: environment},
    AuthService,
    AuthGuardClient,
    AuthGuardAdmin,
    AuthGuardProvider
  ]
})

export class CoreModule { }
