var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'app/core/auth.service';
import { FirebaseHelper } from 'app/helpers/firebase.helper';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as moment from 'moment';
var BookingManagementService = /** @class */ (function () {
    function BookingManagementService(authService, firestore) {
        this.authService = authService;
        this.firestore = firestore;
        this.collectionName = 'bookings';
        this.batchLimit = 250;
        console.debug('BookingManagementService: instantiated');
    }
    BookingManagementService.prototype.chunkArray = function (array, chunkSize) {
        var results = [];
        for (var i = 0; i < array.length; i += chunkSize) {
            results.push(array.slice(i, i + chunkSize));
        }
        return results;
    };
    BookingManagementService.prototype.watchBookings = function (serviceKey, itemKey) {
        var collection;
        if (serviceKey && itemKey) {
            collection = this.firestore.collection(this.collectionName, function (ref) {
                return ref.where('serviceKey', '==', serviceKey)
                    .where('itemKey', '==', itemKey);
            });
        }
        else {
            collection = this.firestore.collection(this.collectionName, function (ref) { return ref.orderBy('date'); });
        }
        return collection.snapshotChanges().pipe(map(function (events) {
            return events.map(function (event) {
                return FirebaseHelper.convertFromFirebase(event.payload.doc.data());
            });
        }));
    };
    BookingManagementService.prototype.watchBookingsByDate = function (specificDate) {
        var collection = this.firestore.collection(this.collectionName, function (ref) {
            return ref.where('date', '==', specificDate);
        });
        return collection.snapshotChanges().pipe(map(function (events) { return events.map(function (event) {
            return FirebaseHelper.convertFromFirebase(event.payload.doc.data());
        }); }), filter(function (bookings) { return bookings.length > 0; }));
    };
    BookingManagementService.prototype.watchBookingsByDates = function (specificDates, clientKey) {
        var _this = this;
        if (specificDates.length === 0) {
            throw new Error('Dates array should not be empty');
        }
        var dateChunks = this.chunkArray(specificDates, 10);
        var observables = dateChunks.map(function (dateChunk) {
            var collection;
            if (!clientKey && _this.authService.appUser.userClient.isJourneyManagementOversightAdmin) {
                collection = _this.firestore.collection(_this.collectionName, function (ref) { return ref.where('date', 'in', dateChunk); });
            }
            else {
                var chosenClientKey_1 = clientKey || _this.authService.appUser.userDetail.clientKey;
                collection = _this.firestore.collection(_this.collectionName, function (ref) { return ref.where('clientKey', '==', chosenClientKey_1).where('date', 'in', dateChunk); });
            }
            return collection.snapshotChanges().pipe(map(function (events) {
                return events.map(function (event) {
                    return FirebaseHelper.convertFromFirebase(event.payload.doc.data());
                });
            }), filter(function (bookings) { return bookings.length > 0; }));
        });
        return combineLatest(observables).pipe(map(function (arrays) { return [].concat.apply([], arrays); }));
    };
    BookingManagementService.prototype.addBookingManagementObjects = function (bookings) {
        return __awaiter(this, void 0, void 0, function () {
            var clientKey_1, clientName_1, creatorClientKey_1, creatorClientName_1, initiatorKey_1, initiatorName_1, timestamp_1, batch_1, addedBookings_1, error_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (bookings.length > this.batchLimit) {
                            throw new Error("Exceeded the batch limit of " + this.batchLimit);
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        clientKey_1 = this.authService.appUser.userClient.key;
                        clientName_1 = this.authService.appUser.userClient.name;
                        creatorClientKey_1 = this.authService.appUser.userClient.key;
                        creatorClientName_1 = this.authService.appUser.userClient.name;
                        initiatorKey_1 = this.authService.appUser.userDetail.key;
                        initiatorName_1 = this.authService.appUser.userDetail.firstName + " " + this.authService.appUser.userDetail.lastName;
                        timestamp_1 = moment();
                        batch_1 = this.firestore.firestore.batch();
                        addedBookings_1 = [];
                        bookings.forEach(function (booking) {
                            var newId = _this.firestore.createId();
                            booking.key = newId;
                            booking.clientKey = clientKey_1;
                            booking.clientName = clientName_1;
                            booking.creatorClientKey = creatorClientKey_1;
                            booking.creatorClientName = creatorClientName_1;
                            booking.initiatorKey = initiatorKey_1;
                            booking.initiatorName = initiatorName_1;
                            booking.dateInitiated = timestamp_1;
                            booking = FirebaseHelper.convertForFirebase(booking);
                            var newDocRef = _this.firestore.collection(_this.collectionName).doc(newId);
                            batch_1.set(newDocRef.ref, booking);
                            addedBookings_1.push(booking);
                        });
                        return [4 /*yield*/, batch_1.commit()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, addedBookings_1];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Error creating new bookings:', error_1);
                        throw error_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    BookingManagementService.prototype.addBookingManagementObject = function (booking) {
        return __awaiter(this, void 0, void 0, function () {
            var newId, newDocRef, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        newId = this.firestore.createId();
                        booking.key = newId;
                        booking.clientKey = this.authService.appUser.userClient.name;
                        ;
                        booking.clientName = this.authService.appUser.userClient.name;
                        booking.creatorClientKey = this.authService.appUser.userClient.key;
                        booking.creatorClientName = this.authService.appUser.userClient.name;
                        booking.initiatorKey = this.authService.appUser.userDetail.key;
                        booking.initiatorName = this.authService.appUser.userDetail.firstName + " " + this.authService.appUser.userDetail.lastName;
                        booking.dateInitiated = moment();
                        booking = FirebaseHelper.convertForFirebase(booking);
                        newDocRef = this.firestore.collection(this.collectionName).doc(newId);
                        return [4 /*yield*/, newDocRef.set(booking)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, booking];
                    case 2:
                        error_2 = _a.sent();
                        console.error('Error creating new booking:', error_2);
                        throw error_2;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BookingManagementService.prototype.updateBookingManagementObjects = function (bookings) {
        return __awaiter(this, void 0, void 0, function () {
            var batch_2, error_3;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (bookings.length > this.batchLimit) {
                            throw new Error("Exceeded the batch limit of " + this.batchLimit);
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        batch_2 = this.firestore.firestore.batch();
                        bookings.forEach(function (booking) {
                            var docRef = _this.firestore.firestore.collection(_this.collectionName).doc(booking.key);
                            booking = FirebaseHelper.convertForFirebase(booking);
                            batch_2.update(docRef, booking);
                        });
                        return [4 /*yield*/, batch_2.commit()];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3:
                        error_3 = _a.sent();
                        console.error('Error updating booking:', error_3);
                        throw error_3;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    BookingManagementService.prototype.updateBookingManagementObject = function (booking) {
        return __awaiter(this, void 0, void 0, function () {
            var error_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        booking = FirebaseHelper.convertForFirebase(booking);
                        return [4 /*yield*/, this.firestore.collection(this.collectionName).doc(booking.key).update(booking)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        error_4 = _a.sent();
                        console.error('Error updating booking:', error_4);
                        throw error_4;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BookingManagementService.prototype.deleteBookingManagementObjects = function (keys) {
        return __awaiter(this, void 0, void 0, function () {
            var batch_3, error_5;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (keys.length > this.batchLimit) {
                            throw new Error("Exceeded the batch limit of " + this.batchLimit);
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        batch_3 = this.firestore.firestore.batch();
                        keys.forEach(function (key) {
                            var docRef = _this.firestore.firestore.collection(_this.collectionName).doc(key);
                            batch_3.delete(docRef);
                        });
                        return [4 /*yield*/, batch_3.commit()];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3:
                        error_5 = _a.sent();
                        console.error('Error deleting bookings in batch:', error_5);
                        throw error_5;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    BookingManagementService.prototype.deleteBookingManagementObject = function (key) {
        return __awaiter(this, void 0, void 0, function () {
            var error_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.firestore.collection(this.collectionName).doc(key).delete()];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        error_6 = _a.sent();
                        console.error("Error deleting booking with key " + key + ":", error_6);
                        throw error_6;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return BookingManagementService;
}());
export { BookingManagementService };
