<h2 mat-dialog-title>{{ dialogTitle }}</h2>

<mat-dialog-content>
  <form [formGroup]="assetManagementForm" autocomplete="off">

    <!-- Vehicle Details -->
    <div class="vehicle-details">
      <!-- <h3>Vehicle Details</h3> -->
      <mat-grid-list cols="8" rowHeight="66.5px" gutterSize="8px">
        <mat-grid-tile colspan="2">
          <mat-form-field>
            <input matInput formControlName="vehicleType" placeholder="Vehicle Type *">
            <mat-error *ngIf="assetManagementForm.get('vehicleType').invalid && assetManagementForm.get('vehicleType').touched">
              Vehicle Type is required.
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <mat-form-field>
            <input matInput formControlName="make" placeholder="Make *">
            <mat-error *ngIf="assetManagementForm.get('make').invalid && assetManagementForm.get('make').touched">
              Make is required.
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <mat-form-field>
            <input matInput formControlName="model" placeholder="Model *">
            <mat-error *ngIf="assetManagementForm.get('model').invalid && assetManagementForm.get('model').touched">
              Model is required.
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <mat-form-field>
            <input matInput formControlName="year" placeholder="Year (e.g. 2023)">
            <mat-error *ngIf="assetManagementForm.get('year').invalid && assetManagementForm.get('year').touched">
              A valid Year is required.
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <mat-form-field>
            <input matInput formControlName="registrationNumber" placeholder="Registration Number *">
            <mat-error *ngIf="assetManagementForm.get('registrationNumber').invalid && assetManagementForm.get('registrationNumber').touched">
              Registration Number is required.
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <mat-form-field>
            <input matInput formControlName="capacity" type="number" placeholder="Capacity *">
            <mat-error *ngIf="assetManagementForm.get('capacity').invalid && assetManagementForm.get('capacity').touched">
              Capacity is required.
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <mat-button-toggle-group formControlName="fuelType" aria-label="Fuel Type">
            <mat-button-toggle *ngFor="let fuel of fuelTypes" [value]="fuel">
                {{fuel}}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <mat-button-toggle-group formControlName="state" aria-label="Vehicle State">
            <mat-button-toggle *ngFor="let state of vehicleStatuses" [value]="state">
                {{state}}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="right-aligned-actions">
  <button mat-raised-button (click)="onCancel()" class="btn">Cancel</button>
  <button mat-raised-button (click)="onSubmit()" class="btn btn-primary">Save Changes</button>
</mat-dialog-actions>