import { Inject, Injectable } from "@angular/core";
import { ObjectTransformations } from "app/providers/object-transformations";
import { AngularFirestore } from "@angular/fire/firestore";
import { AppUser, UserDetail, UserGroup, UsersClientAuth } from "app/interfaces/user.interface";
import { DataService } from "../dataService/data.service";
import { Client } from "app/interfaces/client.interface";
import { ResponseService, ResponseServiceAction, ResponseServiceMovementHistory } from "app/interfaces/response-service.interface";
import { environment } from "environments/environment";
import { GlobalService } from "../global/global.service";
import { Device } from "app/interfaces/devices.interface";
import { Tower } from "app/interfaces/general/tower.interface";
import { IconColorEnum } from "app/enums/responseService.enums";
import { AuthService } from "../../core/auth.service"
import * as firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions

import { DataPlusService } from "../dataService/data.plus.service";
import { FIREBASE_REFERENCES } from "app/core/firebase/firebase.module";

declare const $: any;
declare const require: any;
const uploadFiles = require("./uploadFiles.json");

// This service will be used to
// 1. Upload data from Excell spreadsheets
// 2. Gett data over from old database to new databse
// 3. Batch Update records

@Injectable()
export class UploadService {
  public appUser: AppUser;

  constructor(
    //private afs: AngularFirestore,
    @Inject(FIREBASE_REFERENCES.ONE_FIRESTORE) private readonly afs: AngularFirestore,
    private ot: ObjectTransformations,
    private dataServicePlus: DataPlusService,
    private dataService: DataService,
    private globalService: GlobalService,
    /* private userService: UserService */
    private authService: AuthService
  ) {}

  async uploadStuff() {
    // -----------------------------------
    // const _files = (<any>uploadFiles);
    // const currentImport = _files
    // for (const key in currentImport) {
    //   if (currentImport.hasOwnProperty(key)) {
    //     const element: Tower = currentImport[key]
    //     element.key = this.fire.database().ref('providers/-LQ9eCV56R_qrRy4x124/responders').push().key
    //     if (element) {
    //       await this.fire.database().ref('providers/-LQ9eCV56R_qrRy4x124/responders/' + element.key).update(element);
    //       console.log('Added Element ' + element.key)
    //     }
    //   }
    // }
    // -----------------------------------
    // ------------- Delete records where---------------
    // this.afDB.firestore.collection('users')
    // .where('educatorUid', '==', 'vfzeB5AHUaRAgmj1rvk0zqJv4KN2-')
    // .get().then(function (querySnapshot) {
    //   querySnapshot.forEach(async function (doc) {
    //    await doc.ref.delete();
    //     console.log('deleted : ' + doc.data().uid)
    //   });
    // });
    // ------------- Delete records where---------------
  }

  async countDBUsers() {
    const allClientUsers: UserDetail[] = await this.dataServicePlus.getFirestoreDataFromFunctions(
      "this.authService.appUser.userDetail.key",
      "users",
      "clientKey",
      "-LpeIFZjbEAKWnrK-qap",
      "==",
      -1,
      "",
      "asc",
      -1,
      1,
      environment.functionsUrlPlusGroup3
    );

    const androidUsers = allClientUsers.filter((x) => x.devicePlatform === "Android");
    const iosUsers = allClientUsers.filter((x) => x.devicePlatform !== "Android");

    console.log("android users : " + androidUsers.length);
    console.log("ios users : " + iosUsers.length);
  }

  // -LmK34ULX5d7WhOpmGi7
  async uploadTowersFireStore() {
    const _files = <any>uploadFiles;

    const currentImport = _files;

    var clients: Client[] = [];
    var responseeServices: ResponseService[] = [];

    for (const _element of currentImport) {
      let element: Tower = _element;

      const towers: Tower[] = this.dataService.querySnapshotToObject(
        await this.dataService.getFirestoreData("towers", "siteId", element.siteId).toPromise()
      );

      if (towers && towers[0]) {
        var xx = ":::sdfsd";
        for (const iterator of towers) {
          if (iterator.siteId === element.siteId) {
            await this.dataService.deleteFireStoreDataFromFunctions("", "towers", iterator.key);
            var x = "";
          }
        }
      }

      var towerClient = clients.find((x) => x.key === element.clientKey);

      if (!towerClient) {
        towerClient = this.dataService.querySnapshotToObject(
          await this.dataService.getFirestoreData("clients", "key", element.clientKey).toPromise()
        )[0];
        clients.push(towerClient);
      }

      let responseService = towerClient.responseServices.find((x) => x.name === "Armed Response" || x.name === "Armed Response ");

      if (!responseService) {
        responseService = towerClient.responseServices.find((x) => x.category === "armedResponse");
      }
      let responseServiceKey = responseService.key;
      element.providerKey = responseServiceKey;
      element.providerName = responseServiceKey;
      element.clientName = towerClient.name;

      if (!element.providerKey || element.providerKey.length <= 0) {
        var ohshit = "";
      }
      element.key = this.afs.createId();

      element = this.setTowerSearchName(element);

      await this.afs.collection("towers").doc(element.key).set(element);

      console.log("Added Element " + element.key);
    }
  }

  async updateSheetTowersToBidvest_Jaques() {
    const _files = <any>uploadFiles;

    const currentImport = _files;

    var clients: Client[] = [];
    var responseeServices: ResponseService[] = [];

    var xxx: String[] = [];
    for (const _element of currentImport) {
      let element: Device = _element;

      var xxx = element["siteId"];

      const towers: Tower[] = this.dataService.querySnapshotToObject(
        await this.dataService.getFirestoreData("towers", "siteId", element["siteId"]).toPromise()
      );

      element.name = element.name + " - " + element["siteIdWithPrefix"] + " - " + element["batterySerialNumber"];

      if (towers && towers.length === 2) {
        var newTower = towers.find((x) => x.providerLinkedResponseServiceName === "Battery Tracking Team");

        if (!newTower) {
          console.log("no Battery Tracking Team :  " + element.serialNumber);
        } else {
          element.linkedEntity = newTower;
          element.linkedEntityInitiatorType = "tower";
          element.linkedEntityKey = newTower.key;
          element.linkedEntityName = newTower.siteName;

          delete element["siteId"];
          delete element["siteIdWithPrefix"];
          delete element["batterySerialNumber"];

          await this.dataService.setFireStoreData("devices", element);
          console.log("setting Device :  " + element.name);
        }
      } else {
        var shit = towers;
        var p = "shit happened here";
        console.log("no TOWERS1 :  " + element["siteId"]);
      }
    }

    var xx = xxx;
    console.log(JSON.stringify(xxx));
  }

  async addTowerPrefixToBidvest() {
    let  towers: Tower[] = []

    while(towers) {
      towers = await this.loadTowers(30, towers[towers.length-1], 'clientKey','-Lh0LtkaBPxE0mU2K1np');
     
     for (const tower of towers) {

      let firstletter = tower.siteId.charAt(0);
      let secondletter = tower.siteId.charAt(1);

      if(secondletter === 'T'){
        tower.siteId  = tower.siteId.replace('T', '')
        tower.siteId_search = [];
        let word = "";
        for (let i = 0; i < tower.siteId.length; i++) {
          word = word.concat(tower.siteId.charAt(i)).toLocaleLowerCase();
          tower.siteId_search.push(word);
        }

        await this.dataService.updateFireStoreData('towers', tower)
      }  else if (firstletter === 'T' && tower.siteId_search[0] !== 't'){
        tower.siteId_search = [];
        let word = "";
        for (let i = 0; i < tower.siteId.length; i++) {
          word = word.concat(tower.siteId.charAt(i)).toLocaleLowerCase();
          tower.siteId_search.push(word);
        }

        await this.dataService.updateFireStoreData('towers', tower)
      } else if (firstletter !== 'T') {
        tower.siteId = 'T' + tower.siteId
        tower.siteId_search = [];
        let word = "";
        for (let i = 0; i < tower.siteId.length; i++) {
          word = word.concat(tower.siteId.charAt(i)).toLocaleLowerCase();
          tower.siteId_search.push(word);
        }
        await this.dataService.updateFireStoreData('towers', tower)
      }
    }
     
      
    }
  }

  async loadTowers(limit: number, startAfter: any, filterName: string = '', filterValue: string = '',
  whereFilterOp: firebase.firestore.WhereFilterOp = '==') : Promise<Array<Tower>> {

  const that = this;
  let  towers: Tower[] = []

  if (limit < 0) {
      towers = await this.dataService.getFirestoreDataFromFunctions('', 'towers', filterName, filterValue, '==', -1, '', 'asc', -1, 1)
  } else {
      towers = await this.dataService.getFirestoreDataFromFunctions('', 'towers', filterName, filterValue, whereFilterOp, limit, 'key', 'asc', startAfter)
  }

  console.log(towers)
  towers = towers.filter(x => x.clientKey === '-Lh0LtkaBPxE0mU2K1np');
  return towers
}

  async ImportTowersToBidvest_Jaques_2022() {
    const _files = <any>uploadFiles;

    const currentImport = _files;

    var clients: Client[] = [];
    var responseeServices: ResponseService[] = [];

    var nobatteryTrackingTeam: String[] = [];
    var xxx: String[] = [];
    for (const _element of currentImport) {
      let element: Device = _element;

      var xxx = element["siteId"];

      const towers: Tower[] = this.dataService.querySnapshotToObject(
        await this.dataService.getFirestoreData("towers", "siteId", element["siteId"]).toPromise()
      );

      element.name = element.name + " - " + element["siteIdWithPrefix"] + " - " + element["batterySerialNumber"];

      if (towers && towers.length === 2) {
        var newTower = towers.find((x) => x.providerLinkedResponseServiceName === "Battery Tracking Team");

        if (!newTower) {
          console.log("no Battery Tracking Team :  " + element["siteId"]);
          nobatteryTrackingTeam.push(element["siteId"]);
        } else {
          const devices: Device[] = this.dataService.querySnapshotToObject(
            await this.dataService.getFirestoreData("devices", "serialNumber", element.serialNumber).toPromise()
          );

          if(devices && devices.length >= 2){
          console.log("MORE THAN 1 DEVICE :  " + element.serialNumber);
          const shit = devices;
          } else {
            if (devices && devices.length === 1) {
              element.key = devices[0].key;
            }
  
            element.linkedEntity = newTower;
            element.linkedEntityInitiatorType = "tower";
            element.linkedEntityKey = newTower.key;
            element.linkedEntityName = newTower.siteName;
  
            delete element["siteId"];
            delete element["siteIdWithPrefix"];
            delete element["batterySerialNumber"];
  
            await this.dataService.setFireStoreData("devices", element);
            console.log("setting Device :  " + element.name);
          }

        
        }
      } else {
        var shit = towers;
        var p = "shit happened here";
        console.log("NO TOWERS1 :  " + element["siteId"]);
      }
    }
    var ppp = nobatteryTrackingTeam;
    console.log("ssss");
    console.log("ssss");
    var xx = xxx;
    console.log(JSON.stringify(xxx));
  }

  async deleteDevicesToBidvest_Jaques() {
    const _files = <any>uploadFiles;

    const currentImport = _files;

    var clients: Client[] = [];
    var responseeServices: ResponseService[] = [];

    var xxx: String[] = [];
    var nobatteryTrackingTeam: String[] = [];
    var noTower: String[] = [];

    for (const _element of currentImport) {
      let element: Device = _element;

      var xxx = element["siteId"];

      const devices: Device[] = this.dataService.querySnapshotToObject(
        await this.dataService.getFirestoreData("devices", "serialNumber", element.serialNumber).toPromise()
      );

      if (devices && devices.length === 1) {
        await this.dataService.deleteFireStoreData("devices", devices[0].key);
        console.log("deleted " + devices[0].key);
      }

      if (!devices || devices.length <= 0) {
        console.log("No device! ");
      }

      if (devices.length > 1) {
        for (const iterator of devices) {
          await this.dataService.deleteFireStoreData("devices", iterator.key);
          console.log("device more than 1! deleted " + iterator.key);
        }
      }
    }
    var xxx = noTower;
    var ppp = nobatteryTrackingTeam;
    console.log("ssss");
    console.log("ssss");
  }

  async updateSheetTowersToBidvest_Jaques_negativeTest() {
    const _files = <any>uploadFiles;

    const currentImport = _files;

    var clients: Client[] = [];
    var responseeServices: ResponseService[] = [];

    var xxx: String[] = [];
    var nobatteryTrackingTeam: String[] = [];
    var noTower: String[] = [];

    for (const _element of currentImport) {
      let element: Device = _element;

      var xxx = element["siteId"];

      const towers: Tower[] = this.dataService.querySnapshotToObject(
        await this.dataService.getFirestoreData("towers", "siteId", element["siteId"]).toPromise()
      );

      element.name = element.name + " - " + element["siteIdWithPrefix"] + " - " + element["batterySerialNumber"];

      if (towers && towers.length === 2) {
        var newTower = towers.find((x) => x.providerLinkedResponseServiceName === "Battery Tracking Team");

        if (!newTower) {
          console.log("no Battery Tracking Team :  " + element["siteId"]);

          nobatteryTrackingTeam.push(element["siteId"]);
        } else if (towers && towers.length <= 1) {
          console.log("ONLY 1 TOWER " + towers[0].siteId);

          //   await this.dataService.setFireStoreData("towers", towers[0]);
          //   await this.dataService.setFireStoreData("towers", towers[1]);
        } else {
          console.log("NO TOWER FOUND FOR SITE ID : " + xxx);
          // element.linkedEntity = newTower;
          // element.linkedEntityInitiatorType = "tower";
          // element.linkedEntityKey = newTower.key;
          // element.linkedEntityName = newTower.siteName;

          // delete element["siteId"];
          // delete element["siteIdWithPrefix"];
          // delete element["batterySerialNumber"];

          // await this.dataService.setFireStoreData("devices", element);
          // console.log("setting Device :  " + element.name);
        }
      } else {
        noTower.push(element["siteId"]);
        console.log("no TOWERS1 :  " + element["siteId"]);
      }
    }

    var xxx = noTower;
    var ppp = nobatteryTrackingTeam;
    console.log("ssss");
    console.log("ssss");
  }

  async updateSheetTowersToBidvest() {
    const _files = <any>uploadFiles;

    const currentImport = _files;

    var clients: Client[] = [];
    var responseeServices: ResponseService[] = [];

    var xxx: String[] = [];
    for (const _element of currentImport) {
      let element: Tower = _element;

      //96 118 84  299  1  ONLY TILL 721
      if (element.siteId !== "299" && element.siteId !== "96" && element.siteId !== "118" && element.siteId !== "84" && element.siteId !== "1") {
        const towers: Tower[] = this.dataService.querySnapshotToObject(
          await this.dataService.getFirestoreData("towers", "siteId", element.siteId).toPromise()
        );

        if (towers && towers[0]) {
          // newTower.providerLinkedResponseServiceKey = "DsSj3e4R6USmb0b7Cu7Y";
          // newTower.providerLinkedResponseServiceName = "Battery Tracking Team";
          // newTower.providerKey = "-Lh0LtkaBPxE0mU2K1np";
          // newTower.providerName = "Bidvest";

          if (towers[0].providerLinkedResponseServiceName === "Armed Response") {
            var cccc = towers[0].siteId;
          }

          var first = JSON.parse(JSON.stringify(towers[0].providerLinkedResponseServiceKey));
          var first1 = JSON.parse(JSON.stringify(towers[0].providerLinkedResponseServiceName));
          var first2 = JSON.parse(JSON.stringify(towers[0].providerName));
          var second = JSON.parse(JSON.stringify(towers[1].providerLinkedResponseServiceKey));
          var second1 = JSON.parse(JSON.stringify(towers[1].providerLinkedResponseServiceName));
          var second2 = JSON.parse(JSON.stringify(towers[1].providerName));

          towers[0].providerLinkedResponseServiceKey = second;
          towers[0].providerLinkedResponseServiceName = second1;
          towers[0].providerName = second2;

          towers[1].providerLinkedResponseServiceKey = first;
          towers[1].providerLinkedResponseServiceName = first1;
          towers[1].providerName = first2;

          // for (const iterator of towers) {
          towers[0].clientName = "Bidvest";
          towers[1].clientName = "Bidvest";
          towers[0].clientKey = "-Lh0LtkaBPxE0mU2K1np";
          towers[1].clientKey = "-Lh0LtkaBPxE0mU2K1np";

          if (towers[0].providerLinkedResponseServiceKey === "DsSj3e4R6USmb0b7Cu7Y") {
            towers[0].siteName = towers[0].siteName + " - Battery Tracking";
          }
          if (towers[1].providerLinkedResponseServiceKey === "DsSj3e4R6USmb0b7Cu7Y") {
            towers[1].siteName = towers[1].siteName + " - Battery Tracking";
          }

          //   await this.dataService.setFireStoreData("towers", towers[0]);
          //   await this.dataService.setFireStoreData("towers", towers[1]);
          // }
        }

        //delete newTower.key;

        // if (towers[0].clientKey !== "-Lh0LtkaBPxE0mU2K1np") {
        //   xxx.push(towers[0].siteId);
        //   console.log("Not Bidvest, siteId :  " + towers[0].siteId);
        // }
      }
    }

    var xx = xxx;
    console.log(JSON.stringify(xxx));
  }

  async checkIfDeviceshasLinkedTower() {
    const devices: Device[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("devices").toPromise());

    for (const device of devices) {
      console.log(device.linkedEntityInitiatorType);
      const towers: Tower[] = this.dataService.querySnapshotToObject(
        await this.dataService.getFirestoreData("towers", "key", device.linkedEntityKey).toPromise()
      );

      if (towers && towers[0]) {
        // element = towers[0];
      } else if (device.linkedEntity) {
        console.log("NO TOWER! - " + device.linkedEntity.siteId + " - " + device.linkedEntityName);

        if (device.linkedEntity) {
          const towersSearch: Tower[] = this.dataService.querySnapshotToObject(
            await this.dataService.getFirestoreData("towers", "siteId", device.linkedEntity.siteId).toPromise()
          );
          if (towersSearch && towersSearch[0]) {
            console.log("TOWER FOUND!  Old key : " + device.linkedEntityKey + " New key : " + towersSearch[0].key + " - " + device.key);

            device.linkedEntityKey = towersSearch[0].key;
            device.linkedEntity = towersSearch[0];

            if (device.linkedEntity.siteId === towersSearch[0].siteId) {
              await this.afs.collection("devices").doc(device.key).set(device);
            } else {
              console.log("WTF!?!?!");
              console.log(device.linkedEntity.siteId);
              console.log(towersSearch[0].siteId);
            }
          }
        }
      }
    }
  }

  async editTowersFireStore() {
    const _files = <any>uploadFiles;

    const currentImport = _files;

    var clients: Client[] = [];
    var responseeServices: ResponseService[] = [];

    for (const _element of currentImport) {
      let element: Tower = _element;

      const towers: Tower[] = this.dataService.querySnapshotToObject(
        await this.dataService.getFirestoreData("towers", "siteId", element.siteId).toPromise()
      );

      if (towers && towers[0]) {
        element = towers[0];

        var towerClient = clients.find((x) => x.key === element.clientKey);

        if (!towerClient) {
          towerClient = this.dataService.querySnapshotToObject(
            await this.dataService.getFirestoreData("clients", "key", element.clientKey).toPromise()
          )[0];
          clients.push(towerClient);
        }

        let responseService = towerClient.responseServices.find((x) => x.name === "Armed Response" || x.name === "Armed Response ");

        if (!responseService) {
          responseService = towerClient.responseServices.find((x) => x.category === "armedResponse");
        }
        element.providerKey = towerClient.key;
        element.providerName = towerClient.name;

        element.providerLinkedResponseServiceKey = responseService.key;
        element.providerLinkedResponseServiceName = responseService.name;
        element.clientName = towerClient.name;

        if (!element.providerKey || element.providerKey.length <= 0) {
          var ohshit = "";
        }
        element = this.setTowerSearchName(element);

        await this.afs.collection("towers").doc(element.key).set(element);

        console.log("Added Element " + element.key);
      }
    }
  }

  async runGroupsUpdateUsersClientAuthToBooleans() {
    const userAuths: UsersClientAuth[] = this.dataService.querySnapshotToObject(
      await this.dataService.getFirestoreData("usersClientAuth").toPromise()
    );

    for (const userAuth of userAuths) {
      userAuth.isActive = userAuth.isActive.toString() === "true";
      userAuth.isAdmin = userAuth.isAdmin.toString() === "true";

      await this.dataService.setFireStoreData("usersClientAuth", userAuth);
    }
  }

  async fixTowerLatsAndLongss() {
    // const towers: Tower[] = await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, 'userGroups')
    // for (const usergroup of userGroups) {
    //     delete usergroup['users']
    //     console.log('removing -- ' + usergroup.name)
    //     await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, 'userGroups', usergroup)
    // }
    // for (const key in currentImport) {
    //     if (currentImport.hasOwnProperty(key)) {
    //         const element: Tower = currentImport[key]
    //         element.key = this.afs.createId();
    //         await this.afs.collection('towers').doc(element.key).set(element)
    //         console.log('Added Element ' + element.key)
    //     }
    // }
  }

  async deleteGroupUsers() {
    const userGroups: UserGroup[] = await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups");

    for (const usergroup of userGroups) {
      delete usergroup["users"];
      console.log("removing -- " + usergroup.name);
      await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups", usergroup);
    }
  }

  // async checkClientsExist() {
  //     console.log('starting')
  //     const that = this;
  //     await this.fire.database()
  //         .ref('clients')
  //         .once('value').then(async function (snapshot) {
  //             const clients = ((<Client[]>that.ot.snapshotToArray(snapshot)))
  //             const newClients: Client[] = await that.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, 'clients').toPromise()
  //         }).catch(a => {
  //             console.log(a)
  //         })
  // }

  async checkUsersNotExisting() {
    console.log("starting");
    const that = this;
    
    await this.afs.firestore.app
      .database()
      .ref("users")
      .once("value")
      .then(async function (snapshot) {
        const userDetails = <UserDetail[]>that.ot.snapshotToArray(snapshot);
        const newUserDetails: UserDetail[] = this.dataService.querySnapshotToObject(await that.dataService.getFirestoreData("users").toPromise());

        console.log(userDetails);
        let total = userDetails.length;
        let count = 0;

        console.log("Starting Check");
        for (const userDetail of userDetails) {
          count++;

          //  const newUserDetail: UserDetail = that.dataService.querySnapshotToObject(await that.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, 'users', 'email', userDetail.email).toPromise())[0]
          // console.log('-------' + count + '/' + total + '---------')
          const newUserDetail: UserDetail = newUserDetails.find((x) => x.key === userDetail.key);

          if (!newUserDetail) {
            // console.log('NOT FOUND!!!!!')
            const client: Client = (
              await that.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "clients", "key", userDetail.clientKey)
            )[0];
            const clientGroups: UserGroup[] = await that.dataService.getFirestoreDataFromFunctions(
              this.authService.appUser.userDetail.key,
              "userGroups",
              "clientKey",
              userDetail.clientKey
            );

            const premiumGroup = clientGroups.find((x) => x.name === "Premium");
            const defaultGroup = clientGroups.find((x) => x.name === "Default");
            let selectedGroup: UserGroup;

            if (client) {
              if (userDetail.peachPaymentRegistrationId) {
                selectedGroup = premiumGroup;
              } else {
                selectedGroup = defaultGroup;
              }
              const newUser: UserDetail = {
                key: userDetail.key,
                displayName: userDetail.displayName,
                email: userDetail.email,
                firstName: userDetail.firstName,
                lastName: userDetail.lastName,
                msisdn: userDetail.msisdn,
                addressLine1: userDetail.addressLine1,
                addressLine2: userDetail.addressLine2,
                roles: {
                  admin: false,
                  clientAdmin: false,
                  manager: false,
                  providerAdmin: false,
                  user: true,
                },
                title: userDetail.title,
                isActive: userDetail.isActive,
                city: userDetail.city,
                country: userDetail.country,
                countryCode: userDetail.countryCode,
                gender: userDetail.gender,
                idNumber: userDetail.idNumber,
                dateOfBirth: userDetail.dateOfBirth,
                dateUpdated: userDetail.dateUpdated,
                groupKey: selectedGroup.key,
                personalizedApiKeyGenerated: false,
                lat: userDetail.lat,
                long: userDetail.long,
                locationAccuracy: userDetail.locationAccuracy,

                clientKey: userDetail.clientKey,

                redirectToStartup: false,
                subscriptionActive: userDetail.subscriptionActive,
                subscriptionActiveManualPayment: userDetail.subscriptionActiveManualPayment,
                subscriptionFreeDays: userDetail.subscriptionFreeDays,
                subscriptionLastPayedDate: userDetail.subscriptionLastPayedDate,
                subscriptionCancellationDate: userDetail.subscriptionCancellationDate,
                subscriptionPendingCancellation: userDetail.subscriptionPendingCancellation,

                mobileAppPrice: userDetail.mobileAppPrice,
                peachPaymentRegistrationId: userDetail.peachPaymentRegistrationId,
                peachPaymentEntityId: userDetail.peachPaymentEntityId,
                peachPaymentCurrency: userDetail.peachPaymentCurrency,

                responseServiceActionsResponder: [],
                responseServiceActionsInitiated: [],
                responseServiceActionActiveAndListening: false,
              };

              if (!newUser.isActive) {
                newUser.isActive = true;
              }
              if (!newUser.displayName) {
                newUser.displayName = newUser.firstName + " " + newUser.lastName;
              }

              that.clean(newUser);

              // if (!selectedGroup.users) {
              //     selectedGroup.users = [];
              // }
              // selectedGroup.users.push(newUser)

              //    await that.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key,'users', newUser)
              //    await that.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key,'userGroups', selectedGroup)

              console.log("SAVING NEW USER ");
              // console.log(that.clean(newUser))
              console.log("Key -> " + newUser.key);
              console.log("GrouKey -> " + selectedGroup.key);
            } else {
              // console.log('CLIENT NOT FOUND!!!')
              // console.log('CLIENT NOT FOUND!!!')
              // console.log('CLIENT NOT FOUND for useer!!!')
              // console.log(userDetail)
            }
          }
        }
      })
      .catch((a) => {
        console.log(a);
      });
  }

  clean(obj) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }

  async transferFromOldToNewDatabase() {
    console.log("Starting Premium Check");
    const that = this;
    await this.afs.firestore.app
      .database()
      .ref("users")
      .once("value")
      .then(async function (snapshot) {
        let userDetails = <UserDetail[]>that.ot.snapshotToArray(snapshot);
        const newUserDetails: UserDetail[] = await that.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "users");

        console.log("Starting Check");
        for (const userDetail of userDetails) {
          if (userDetail.peachPaymentRegistrationId) {
            console.log("ACTIVE USER -> " + userDetail.firstName);
            const newUserDetail: UserDetail = newUserDetails.find((x) => x.key === userDetail.key);

            if (newUserDetail) {
              if (newUserDetail.subscriptionActive !== userDetail.subscriptionActive) {
                console.log("NOT ACTIVE! ENABLE!");
                console.log(userDetail);
                console.log(newUserDetail);
                newUserDetail.subscriptionActive = userDetail.subscriptionActive;
                newUserDetail.subscriptionActiveManualPayment = userDetail.subscriptionActiveManualPayment;
                newUserDetail.subscriptionFreeDays = userDetail.subscriptionFreeDays;
                newUserDetail.subscriptionLastPayedDate = userDetail.subscriptionLastPayedDate;
                newUserDetail.subscriptionCancellationDate = userDetail.subscriptionCancellationDate;
                newUserDetail.subscriptionPendingCancellation = userDetail.subscriptionPendingCancellation;
                newUserDetail.mobileAppPrice = userDetail.mobileAppPrice;
                newUserDetail.peachPaymentRegistrationId = userDetail.peachPaymentRegistrationId;
                newUserDetail.peachPaymentEntityId = userDetail.peachPaymentEntityId;
                newUserDetail.peachPaymentCurrency = userDetail.peachPaymentCurrency;
                //    await that.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key,'users', newUserDetail)
                console.log("ACTIVATED");
              }
              {
                console.log("fine");
              }
            } else {
              console.log("USER NOT FOUND > " + userDetail.key);
              console.log("USER NOT FOUND");
              console.log("USER NOT FOUND");
              console.log("USER NOT FOUND");
              console.log("USER NOT FOUND");
              console.log("USER NOT FOUND");
              console.log("USER NOT FOUND");
              console.log("USER NOT FOUND");
              console.log("USER NOT FOUND");
              console.log("USER NOT FOUND");
              console.log("USER NOT FOUND");
            }
          }
        }
      })
      .catch((a) => {
        console.log(a);
      });
  }

  async transferFromOldToNewDatabase_client() {
    console.log("Starting Premium Check");
    const that = this;
    await this.afs.firestore.app
      .database()
      .ref("clients")
      .once("value")
      .then(async function (snapshot) {
        let clients = <Client[]>that.ot.snapshotToArray(snapshot);

        console.log("Starting Check");
        console.log(clients);
        const o = clients.find((x) => x.key === "-Lv50acI1fu7tjIHr68r");

        const client: Client = {
          email: o.email,
          address: o.address,
          apiKey: o.apiKey,
          creatorClientKey: o.creatorClientKey,
          creatorClientName: o.creatorClientName,
          dateUpated: o.dateUpated,
          isActive: o.isActive,
          key: o.key,
          management: o.management,
          mobileProducts: ["friend", "news", "supportService"],
          name: o.name,
          phoneNumber: o.phoneNumber,
          responseServices: [],
        };

        that.clean(client);

        console.log("new client saving");
        console.log(client);

        // const newClients: Client = that.dataService.querySnapshotToObject(await that.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, 'clients', 'key', userDetail.email).toPromise())[0]
      })
      .catch((a) => {
        console.log(a);
      });
  }

  async editGroups() {
    const stuffs: UserGroup[] = await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups");

    for (const stuff of stuffs) {
      for (const aaa of stuff.responseServices) {
        if (aaa.name === "Armed Resonse" || aaa.name === "Armed Reponse") {
          const index = stuff.responseServices.findIndex((x) => x.key === aaa.key);
          aaa.name = "Armed Response";
          aaa.description = "Armed Response";
          stuff.responseServices[index] = aaa;

          await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups", stuff);
          console.log("SET FOR " + stuff.clientKey);
        }
      }
    }
  }

  async makeSurePaidUsersAreInPremium() {
    const users: UserDetail[] = await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "users");
    const groups: UserGroup[] = await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups");

    const total = users.length;
    let count = 1;
    for (const user of users) {
      count++;

      const thisUserGroup: UserGroup = groups.find((x) => x.key === user.groupKey);

      // if(user.clientKey && user.clientKey === '-xxxxx'){
      //     user.clientKey = '-LT75cDlOhB3sr6T3P7A'
      //     user.groupKey = 'WYXbmJmjzPPiKvdxmqWz'
      //     console.log(user)
      //     await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key,'users', user)
      // }

      console.log(count + " / " + total);

      if (thisUserGroup.name === "Premium" && thisUserGroup.autoGenerated === true) {
        console.log("Setting Premium User -> " + user.firstName);
        user.subscriptionActive = true;
        user.subscriptionActiveManualPayment = true;
        await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "users", user);
      }
    }
  }

  async makeSureResponseServiceClientKeyisNotUndefined() {
    const responseSerivce: ResponseService[] = await this.dataService.getFirestoreDataFromFunctions("asdfasdf,", "responseServices");

    const total = responseSerivce.length;
    let count = 1;
    for (const resservice of responseSerivce) {
      count++;

      if (!resservice.providerKey) {
        console.log("Provider Key empty" + resservice.clientName);

        resservice.providerKey = resservice.clientKey;
        resservice.providerName = resservice.clientName;
        await this.dataService.setFireStoreDataFromFunctions("this.authService.appUser.userDetail.key", "responseServices", resservice);
      }
    }
  }

  async editResonseServices() {
    const responseServices: ResponseService[] = await this.dataService.getFirestoreDataFromFunctions(
      this.authService.appUser.userDetail.key,
      "responseServices"
    );

    for (const responseService of responseServices) {
      if (responseService.name === "Armed Resonse") {
        responseService.name = "Armed Response";
        await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", responseService);
        console.log("SET FOR " + responseService.name);
      }
    }
  }
  async editStuffFirestore() {
    const stuffs: ResponseService[] = await this.dataService.getFirestoreDataFromFunctions(
      this.authService.appUser.userDetail.key,
      "responseServices"
    );

    for (const stuff of stuffs) {
      if (stuff.clientKey !== "-LT75cDlOhB3sr6T3P7A") {
        if (stuff.name === "Police" || stuff.description === "Public Police services") {
          stuff.isServiceProvider = false;
          stuff.providerLinkedResponseServiceKey = "Dm42fceZJNPBUTeZcGvB";
          stuff.providerLinkedResponseServiceName = "Police";
          stuff.providerKey = "-LT75cDlOhB3sr6T3P7A";
          stuff.providerName = "Response 24";
        }

        if (stuff.description === "Public Ambulance Services") {
          stuff.isServiceProvider = false;
          stuff.providerLinkedResponseServiceKey = "GccgqaXxpRy15wK40anR";
          stuff.providerLinkedResponseServiceName = "Ambulance";
          stuff.providerKey = "-LT75cDlOhB3sr6T3P7A";
          stuff.providerName = "Response 24";
        }

        if (stuff.description === "ER 24") {
          stuff.isServiceProvider = false;
          stuff.name = "ER 24";
          delete stuff.providerLinkedResponseServiceKey;
          delete stuff.providerLinkedResponseServiceName;
          delete stuff.providerKey;
          delete stuff.providerName;
        }

        await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", stuff);
        console.log("Set for " + stuff);
      }
    }
  }

  // async upgradePayedUsers() {
  //     const allClientUsers: UserDetail[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, 'users').toPromise())

  //     for (const userDetail of allClientUsers) {

  //         if (userDetail.subscriptionActive) {
  //             const clientGroups: UserGroup[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, 'userGroups', 'clientKey', userDetail.clientKey).toPromise())

  //             const premium: UserGroup = clientGroups.find(x => x.name === 'Premium')

  //             userDetail.groupKey = premium.key

  //             console.log('Setting : >' + userDetail.firstName + ' -> ' + premium.name)
  //             //  await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key,'users', userDetail)
  //             premium.users.push(userDetail)
  //             //    await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key,'userGroups', premium)

  //             console.log(userDetail)

  //         }

  //     }

  // }

  async ccheck() {
    const responseServices: ResponseService[] = await this.dataService.getFirestoreDataFromFunctions(
      this.authService.appUser.userDetail.key,
      "responseServices"
    );

    for (const responseService of responseServices) {
      if (responseService.isServiceProvider) {
        if (responseService.name !== "Friends & Family" && responseService.clientKey !== "-LT75cDlOhB3sr6T3P7A") {
          console.log("IS SERVCIE PROVIDER => " + responseService.clientName);
          console.log("Other provider => " + responseService.isServiceProvider);
          responseService.isServiceProvider = false;
          await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", responseService);
        }
      }
    }
  }

  async addDevices() {
    const devicesArr: string[] = [
      "95094144667975",
      "65429044667785",
      "65429044625809",
      "65429044626203",
      "65429044623077",
      "65429044626146",
      "65429044623549",
      "65429044626229",
      "65429044624901",
      "65429044625841",
      "65429044625338",
      "65429044624984",
      "65429044626237",
      "65429044642226",
      "65429044623275",
      "65429044624810",
      "65429044666654",
      "65429044664600",
      "65429044625767",
      "63070019071462",
      "65429044624695",
      "65429044624711",
      "65429044623309",
      "65429044623325",
      "65429044623531",
      "65429044625320",
      "65429044624729",
      "65429044625213",
      "65429044666696",
      "65429044625866",
      "65429044626286",
      "65429044619471",
      "65429044623853",
      "65429044618853",
      "65429044625346",
      "65429044626088",
      "65429044667371",
      "65429044624422",
      "65429044667447",
      "65429044622947",
      "65429044623283",
      "65429044621477",
      "65429044623895",
      "65429044623341",
      "65429044623366",
      "65429044623051",
      "65429044624752",
      "65429044624760",
      "65429044623176",
      "65429044624414",
      "65429044623069",
      "65429044623168",
      "65429044667736",
      "65429044625429",
      "65429044667975",
      "65429044625700",
      "65429044666670",
      "65429044622889",
      "65429044666688",
      "65429044627029",
      "65429044624372",
      "65429044623432",
      "65429044625304",
      "65429044623846",
      "65429044666704",
      "63070019070886",
      "63070018754399",
      "63070019072775",
      "234234243242424",
      "7809274348",
      "63070018754282",
      "63070018751890",
      "63070018752161",
      "63070019071454",
      "65429044666712",
      "65429044623903",
      "123456789012345",
      "14495000027595",
      "14495000028031",
      "65429044623085",
      "65429044622996",
      "14495000028221",
      "8809008695",
      "8809008669",
      "8809008581",
      "2104025992",
      "8809008616",
      "8809008536",
      "8809008642",
      "8809008625",
      "8809008595",
      "8809008572",
      "8809008635",
      "5425758046119800000",
      "13672910325643500000",
      "8809008573",
      "2104019322",
      "65429044624216",
      "65429044623101",
      "8809008698",
      "8809008671",
      "8809008674",
      "8809008614",
      "6542004665CDC5",
      "65429044623804",
      "8391170515907470000",
      "2322296596999970000",
      "15723775669448600000",
      "5995751501093290000",
      "14495000027587",
      "95094144667371",
      "6542904466738F",
      "65429044667751",
      "65429044625197",
    ];

    let counter = 1;
    for (const serial of devicesArr) {
      // const tower: Tower = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, 'towers', 'key', 'vzX9ykUgXk3YbARtdUPP').toPromise())[0]
      const device: Device = {
        clientKey: "-Lh0LtkaBPxE0mU2K1np",
        clientName: "Bidvest",
        name: "Netshield Device " + counter,
        serialNumber: serial,
      };

      counter++;

      console.log("Adding " + "Bidvest");
      await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "devices", device);
    }
  }

  async deleteDataWhere() {
    const things: ResponseService[] = await this.dataService.getFirestoreDataFromFunctions(
      this.authService.appUser.userDetail.key,
      "responseServices"
    );

    for (const thing of things) {
      if (thing.name === "Premium" || thing.name === "Default") {
        console.log("deleteing");
        console.log(thing);
        await this.dataService.deleteFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", thing.key);
      }
    }
  }

  async removeAllOpenResponseServiceActions() {
    console.log("starting");
    const userdetails: UserDetail[] = await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "users");

    const total = userdetails.length;
    let counter = 1;
    for (const userdetail of userdetails) {
      console.log("Running - " + counter + " of " + total);
      counter++;
      if (
        (userdetail.responseServiceActionsInitiated && userdetail.responseServiceActionsInitiated.length > 0) ||
        (userdetail.responseServiceActionsResponder && userdetail.responseServiceActionsResponder.length > 0)
      ) {
        userdetail.responseServiceActionsInitiated = [];
        userdetail.responseServiceActionsResponder = [];

        //     await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key,'users', userdetail);
        console.log("clearing out for -> " + userdetail.firstName);
      }
    }
  }

  async deleteAllClientData() {
    const clientNameArr: string[] = ["aaaaaa", "bbbbb", "cccc", "dddddd", "Marlize Sec", "test"];

    for (const clientName of clientNameArr) {
      const client: Client = (
        await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "clients", "name", clientName)
      )[0];
      const userGroups: UserGroup[] = await this.dataService.getFirestoreDataFromFunctions(
        this.authService.appUser.userDetail.key,
        "userGroups",
        "clientKey",
        client.key
      );
      const responseServices: ResponseService[] = await this.dataService.getFirestoreDataFromFunctions(
        this.authService.appUser.userDetail.key,
        "responseServices",
        "clientKey",
        client.key
      );

      for (const userGroup of userGroups) {
        console.log("deleteing " + userGroup.name);
        await this.dataService.deleteFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups", userGroup.key);
      }
      for (const responseService of responseServices) {
        console.log("deleteing " + responseService.name);
        await this.dataService.deleteFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", responseService.key);
      }

      console.log("deleteing " + client.name);
      await this.dataService.deleteFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "clients", client.key);
    }
  }

  // async updatePremiumusersDetails() {
  //     console.log('updating starting')
  //     const userdetails: UserDetail[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, 'users', 'email', usermails).toPromise())[0]

  //     for (const usermails of userEmails) {
  //         const userdetail: UserDetail = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, 'users', 'email', usermails).toPromise())[0]
  //         console.log(userdetail)

  //         if (userdetail) {
  //             userdetail.groupKey = 'cUB3nnzGH46ki1CKKafj'
  //             userdetail.subscriptionActive = true;
  //             userdetail.subscriptionPendingCancellation = false;
  //             userdetail.peachPaymentRegistrationId = 'NA'
  //             userdetail.peachPaymentEntityId = 'NA'

  //             console.log('updating ' + usermails)
  //             await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key,'users', userdetail)
  //         }
  //     }
  // }

  async giveUsersPremiumAccess() {
    console.log("updating starting");
    const userEmails: string[] = [
      "andri@response24.co.za",
      "lizelleh@response24.co.za",
      "monya@response24.co.za",
      "john@response24.co.za",
      "brenda@response24.co.za",
      "maryke@response24.co.za",
      "marlize@response24.co.za",
      "jacquesk@response24.co.za",
      "amy@response24.co.za",
      "lizelle@response24.co.za",
      "lizelleh@response24.co.za",
      "werner@response24.co.za",
      "phillip@response24.co.za",
    ];

    const group: UserGroup = (
      await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups", "key", "cUB3nnzGH46ki1CKKafj")
    )[0];

    for (const usermails of userEmails) {
      const userdetail: UserDetail = (
        await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "users", "email", usermails)
      )[0];
      console.log(userdetail);

      if (userdetail) {
        userdetail.groupKey = "cUB3nnzGH46ki1CKKafj";
        userdetail.subscriptionActive = true;
        userdetail.subscriptionPendingCancellation = false;
        userdetail.peachPaymentRegistrationId = "NA";
        userdetail.peachPaymentEntityId = "NA";

        console.log("GROPU =>  " + group.clientKey);

        await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "users", userdetail);
        await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups", group);
      }
    }
  }

  async regenInviteCodes() {
    const groups: UserGroup[] = await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups");
    for (const group of groups) {
      const intviteCount: UserGroup[] = groups.filter((x) => x.invitationCode === group.invitationCode);

      if (intviteCount.length > 1) {
        const grp: UserGroup = {
          key: group.key,
          invitationCode: this.globalService.uniqueInviteCode(),
        };

        await this.dataService.updateFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups", grp).catch((ex) => {
          console.error(ex);
        });
      }
    }
  }

  async deleteFreeServices() {
    const responseServices: ResponseService[] = await this.dataService.getFirestoreDataFromFunctions(
      this.authService.appUser.userDetail.key,
      "responseServices"
    );
    for (const responseService of responseServices) {
      if (
        responseService.providerLinkedResponseServiceKey &&
        responseService.clientKey !== environment.response24ClientKey &&
        responseService.isPublicServiceProvider
      ) {
        // uncomment to run update
        // responseService.category = "armedResponse"
        // responseService.description = "Armed Response"
        // responseService.icon = IconEnum.shield.value;
        // responseService.iconColor = IconColorEnum.blue.value;

        console.log(responseService);
        responseService.isPublicServiceProvider = false;
        await this.dataService.updateFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", responseService);
      }
    }
  }

  async updateFreeServices() {
    const responseServices: ResponseService[] = await this.dataService.getFirestoreDataFromFunctions(
      this.authService.appUser.userDetail.key,
      "responseServices"
    );

    for (const responseService of responseServices) {
      if (responseService.name === "Test Alert" && responseService.clientKey !== "-LT75cDlOhB3sr6T3P7A") {
        // uncomment to run update
        console.log(responseService.clientName + " - " + responseService.name);
        responseService.name = "Test Alert";
        responseService.description = "Test Alert";
        responseService.providerLinkedResponseServiceName = "Test Alert";
        responseService.iconColor = IconColorEnum.orange.value;
        await this.dataService.updateFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", responseService);
      }
    }
  }

  async updateHistoricalResponseActions() {
    const responseServiceActions: ResponseServiceAction[] = this.dataService.querySnapshotToObject(
      await this.dataService.getFirestoreData("responseServiceActionsHistory3").toPromise()
    );

    for (const responseServiceAction of responseServiceActions) {
      let changeDates = false;

      console.log("Running");
      for (const history of responseServiceAction.movementHistory) {
        try {
          const x = history.dateUpdated.toDate();
        } catch (error) {
          changeDates = true;
          break;
        }
      }

      if (changeDates) {
        for (const hist of responseServiceAction.movementHistory) {
          const histaaa: ResponseServiceMovementHistory = hist;
          const aaa = histaaa.dateUpdated;
          let seconds = aaa["_seconds"];

          if (!seconds) {
            seconds = aaa["seconds"];
          }
          const newDate: Date = this.toDateTime(seconds);

          hist.dateUpdated = firebase.firestore.Timestamp.fromDate(newDate);
        }
        const x = history;
        console.log("CHANGES FOR " + responseServiceAction.movementHistory[0]);
        await this.dataService.updateFireStoreData("responseServiceActionsHistory3", responseServiceAction);
      }
    }
  }

  toDateTime(secs) {
    const t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    return t;
  }

  async updateClientRESServices() {
    const clients: Client[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("clients").toPromise());
    const users: UserDetail[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("users").toPromise());
    let responseServices: ResponseService[] = this.dataService.querySnapshotToObject(
      await this.dataService.getFirestoreData("responseServices").toPromise()
    );

    responseServices = responseServices.filter((x) => x.category !== "armedResponse" && x.category !== "medical" && x.name === "Friends & Family");
    const emptyUsers = users.filter((x) => !x.responseServiceFriendsKey);
    const populatedUsers = users.filter((x) => x.responseServiceFriendsKey);
    for (const user of emptyUsers) {
      console.log(user.key);
      const friendRepsonseService: ResponseService = {
        category: "publicService",
        clientKey: user.clientKey,
        clientName: clients.find((x) => x.key === user.clientKey).name,
        icon: "/assets/icon/generic/friends_fam.svg",
        iconColor: "icon-orange",
        isAllowedToHandleOtherActionsInParallel: true,
        isAvailableOnMobile: true,
        isDirectRequestToResponders: true,
        isMoreThanOneResponderAllowed: true,
        isNOCInChargeOfCompletion: false,
        isPublicServiceProvider: false,
        isServiceProvider: true,
        respondRadiusKm: 100,
        responders: [],
        isAutoGenerated: true,
        // DONT change this variable -> Doing lookups on Friends & Family.
        name: "Friends & Family",
        settings: {
          isCloseReasonFromList: false,
        },
        type: "friend",
      };

      console.log(friendRepsonseService);

      await this.dataService.setFireStoreDataFromFunctions(user.key, "responseServices", friendRepsonseService).then(async (firestoreKey) => {
        user.responseServiceFriendsKey = firestoreKey;
        await this.dataService.updateFireStoreDataFromFunctions(user.key, "users", user);
        return friendRepsonseService;
      });
    }

    // let useLessResService: ResponseService[] = [];
    // let inUsesService: ResponseService[] = [];

    // for (const resService of responseServices) {

    //     const xa = populatedUsers.find(x => x.responseServiceFriendsKey === resService.key)

    //     if (xa) {
    //         console.log('found')
    //         inUsesService.push(resService)
    //     } else {
    //         console.log('Not found')
    //         useLessResService.push(resService)
    //     }

    // }

    // console.log('-----END ')
    // console.log(inUsesService)
    // console.log(useLessResService)

    // for (const res of useLessResService) {
    //     console.log('DELETEING  ' + res.name)
    //     //      await this.dataService.deleteFireStoreDataFromFunctions('this.authService.appUser.userDetail.ke', 'responseServices', res.key)
    // }
  }
  async updateFriendsResponseServices() {
    console.log("-----START ");
    let responseServices: ResponseService[] = this.dataService.querySnapshotToObject(
      await this.dataService.getFirestoreData("responseServices").toPromise()
    );

    responseServices = responseServices.filter((x) => x.category !== "armedResponse" && x.category !== "medical" && x.name === "Friends & Family");
    for (const resService of responseServices) {
      console.log(resService.providerName);
      console.log(resService.providerKey);

      resService.providerKey = resService.clientKey;
      resService.providerName = resService.clientName;
      await this.dataService.updateFireStoreData("responseServices", resService);

      console.log("updating " + resService.key);
    }
  }

  async checkIsDirectTorespondersIsNotNull() {
    console.log("-----START ");
    const responseServices: ResponseService[] = this.dataService.querySnapshotToObject(
      await this.dataService.getFirestoreData("responseServices").toPromise()
    );
    const users: UserDetail[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("users").toPromise());

    for (const resService of responseServices) {
      if (resService.isDirectRequestToResponders === null || resService.isDirectRequestToResponders === undefined) {
        const userLIst = users.filter((x) => x.clientKey === resService.clientKey);

        if (!userLIst || userLIst.length <= 0) {
          console.log("EMPTY!  " + resService.clientKey);

          const respServices: ResponseService[] = this.dataService.querySnapshotToObject(
            await this.dataService.getFirestoreData("responseServices", "clientKey", resService.clientKey).toPromise()
          );

          for (const iterator of respServices) {
            console.log("deleteing rEs -=> " + iterator.name);
            //   await this.dataService.deleteFireStoreDataFromFunctions('', 'responseServices', iterator.key)
          }
          console.log("Deleting Cleitn ->  " + resService.clientKey);
          console.log("Deleting Cleitn ->  " + resService.clientName);
          //  await this.dataService.deleteFireStoreDataFromFunctions('', 'clients', resService.clientKey)
        } else {
          console.log(">> NOT EMPTY!  " + resService.clientKey);
          console.log(">> NOT EMPTY!  " + resService.clientName);
        }
      }
      // await this.dataService.updateFireStoreData('responseServices', resService)
    }
  }

  // if (client.key !== '-LT75cDlOhB3sr6T3P7A') {
  //     // uncomment to run update
  //     console.log(client.name)
  //     console.log(client.key)

  //     //   client.responseServices = client.responseServices.filter(x => x.name !== 'Ambulance')

  //     // const index = client.responseServices.findIndex(x => x.name === 'Police')

  //     // if (index > 0) {
  //     //     client.responseServices[index].name = 'Test Alert';
  //     //     client.responseServices[index].description = 'Test Alert';
  //     //     client.responseServices[index].providerLinkedResponseServiceName = 'Test Alert';
  //     //     client.responseServices[index].iconColor = IconColorEnum.orange.value;
  //     // }

  //     console.log(client.responseServices)
  //     //  await this.dataService.updateFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, 'clients', client)
  // }

  async updateGroups() {
    const userGroups: UserGroup[] = await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups");
    for (const userGroup of userGroups) {
      // uncomment to run update

      //    userGroup.mobileProducts = ['friend', 'news', 'supportService']

      const index = userGroup.responseServices.findIndex((x) => x.providerLinkedResponseServiceKey === "GccgqaXxpRy15wK40anR");
      console.log(index);

      // if (index >= 0) {
      //     userGroup.responseServices[index].name = 'Test Alert';
      //     userGroup.responseServices[index].description = 'Test Alert';
      //     userGroup.responseServices[index].providerLinkedResponseServiceName = 'Test Alert';
      //     userGroup.responseServices[index].iconColor = IconColorEnum.orange.value;
      // }

      console.log(userGroup.responseServices);
      //   await this.dataService.updateFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, 'userGroups', userGroup)
    }
  }

  async deleteAllResponseServiceExceptR24() {
    const responseServices: ResponseService[] = await this.dataService.getFirestoreDataFromFunctions(
      this.authService.appUser.userDetail.key,
      "responseServices"
    );
    for (const responseService of responseServices) {
      if (responseService.clientKey !== environment.response24ClientKey) {
        await this.dataService.deleteFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", responseService.key);
        console.log("deleted - > " + responseService.name);
      }
    }
    console.log("done");
  }

  async createDefaultGroupsAndAssignUsers() {
    const allClients: Client[] = await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "clients");

    for (const client of allClients) {
      console.log("################### " + client.name);

      const er24 = await this.createOwnResponseService(client, "aQv6o5p3G4nQF6NQ2qh4");
      const armedRes = await this.createOwnResponseService(client, "GNlsXNbZ54wcBJVDlwH4");
      const ambulance = await this.createLinkedResponseService(client, "GccgqaXxpRy15wK40anR");
      const police = await this.createLinkedResponseService(client, "Dm42fceZJNPBUTeZcGvB");
      const allClientUsers: UserDetail[] = await this.dataService.getFirestoreDataFromFunctions(
        this.authService.appUser.userDetail.key,
        "users",
        "clientKey",
        client.key
      );
      await this.generateDefaultGroups(client, allClientUsers, er24, armedRes, ambulance, police);

      client.responseServices = [];
      client.responseServices.push(er24, armedRes, ambulance, police);
      await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "clients", client);
    }
  }

  async createOwnResponseService(client: Client, key: string): Promise<ResponseService> {
    const aaService: ResponseService = await this.dataService.getFirestoreDataFromFunctions(
      this.authService.appUser.userDetail.key,
      "responseServices",
      "key",
      key
    );

    if (client.key === environment.response24ClientKey) {
      return aaService;
    }

    delete aaService.key;
    aaService.isPublicServiceProvider = true;
    aaService.clientKey = client.key;
    aaService.clientName = client.name;

    await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", aaService);
    console.log("Done with AA " + client.name);
    return aaService;
  }

  async createLinkedResponseService(client: Client, key: string): Promise<ResponseService> {
    const responseService: ResponseService = await this.dataService.getFirestoreDataFromFunctions(
      this.authService.appUser.userDetail.key,
      "responseServices",
      "key",
      key
    );

    if (client.key === environment.response24ClientKey) {
      return responseService;
    }

    delete responseService.key;
    responseService.isPublicServiceProvider = false;
    responseService.clientKey = client.key;
    responseService.clientName = client.name;
    responseService.providerKey = environment.response24ClientKey;
    responseService.providerLinkedResponseServiceKey = key;
    responseService.providerLinkedResponseServiceName = responseService.name;

    await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", responseService);
    return responseService;
  }

  public async generateDefaultGroups(
    client: Client,
    clientUsers: UserDetail[],
    er24: ResponseService,
    armedRes: ResponseService,
    ambulance: ResponseService,
    police: ResponseService
  ): Promise<void> {
    const defaultGroup: UserGroup = {
      key: this.afs.createId(),
      mobilePrice: 0,
      subscriptionFreeDays: 0,
      autoGenerated: true,
      clientKey: client.key,
      responseServices: [ambulance, police],
      // users: clientUsers,
      mobileProducts: ["news", "supportService"],
      name: "Default",
      invitationCode: this.globalService.uniqueInviteCode(),
    };

    const premiumGroup: UserGroup = {
      key: this.afs.createId(),
      mobilePrice: 59.99,
      subscriptionFreeDays: 0,
      autoGenerated: true,
      clientKey: client.key,
      mobileProducts: ["news", "supportService", "friend"],
      responseServices: [er24, armedRes],
      // users: [],
      name: "Premium",
      invitationCode: this.globalService.uniqueInviteCode(),
    };

    for (const clientUser of clientUsers) {
      clientUser.groupKey = defaultGroup.key;
      await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "users", clientUser);
      console.log("----> user : " + clientUser.firstName);
    }

    await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups", defaultGroup);
    await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups", premiumGroup);

    console.log("Groups Added for : " + client.name);
  }

  async setUserDetails() {
    const userGroups: UserGroup[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("userGroups").toPromise());
    const users: UserDetail[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("users").toPromise());

    for (const userGroup of userGroups) {
      let groupHasUsers = false;

      if (userGroup.name === "Premium" && userGroup.clientKey !== environment.response24ClientKey) {
        console.log(userGroup.autoGenerated);
        userGroup.autoGenerated = false;
        await this.dataService.updateFireStoreData("userGroups", userGroup);
      }

      //   await this.dataService.updateFireStoreData('users', user)
    }
  }

  async removeEmptyPremiumGroups() {
    const users: UserDetail[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("users").toPromise());
    console.log(users);
    for (const user of users) {
      user.personalizedApiKeyGenerated = false;
      console.log("setting for user + " + user.firstName);
      await this.dataService.updateFireStoreData("users", user);
    }
  }

  async setClientSearchName() {
    const that = this;
    const clients: Client[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("clients").toPromise());

    for (const client of clients) {
      console.log(client.name);

      client.name_search = [];
      let word = "";
      for (let i = 0; i < client.name.length; i++) {
        word = word.concat(client.name.charAt(i)).toLocaleLowerCase();
        client.name_search.push(word);
      }

      console.log(client);
      await this.dataService.updateFireStoreData("clients", client);
    }
  }

  setTowerSearchName(tower: Tower) {
    // const limit = 1000;

    // const towers: Tower[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData('towers', 'clientKey', 'odMFzSy9Wo0zx1jOpVUH').toPromise())

    // for (const tower of towers) {
    console.log(tower.siteId);

    tower.siteId_search = [];
    let word = "";
    for (let i = 0; i < tower.siteId.length; i++) {
      word = word.concat(tower.siteId.charAt(i)).toLocaleLowerCase();
      tower.siteId_search.push(word);
    }

    tower.latitude = parseFloat(tower.latitude.toString().replace(",", "."));
    tower.longitude = parseFloat(tower.longitude.toString().replace(",", "."));

    console.log("UPDATING >>>>>> " + tower.key);
    //       await this.dataService.updateFireStoreData('towers', tower)
    //  }

    // if (towers.length === limit) {
    //     this.setTowerSearchName(towers[towers.length - 1].key)
    // };
    return tower;
  }

  async deleteTestClients() {
    console.log("STARTING");
    const limit = 1000;

    // const clientNames = [
    //     '-asdfasdf'
    // ]

    const clientKeys = ["rar6UH1UnfDwjudp1dvz"];

    for (const clientKey of clientKeys) {
      const client: Client = this.dataService.querySnapshotToObject(
        await this.dataService.getFirestoreData("clients", "key", clientKey).toPromise()
      )[0];
      const userGroups: UserGroup[] = this.dataService.querySnapshotToObject(
        await this.dataService.getFirestoreData("userGroups", "clientKey", client.key).toPromise()
      );

      console.log("DELETING client >>>>>> " + client.name + " " + client.key);
      await this.dataService.deleteFireStoreDataFromFunctions("", "clients", client.key);

      for (const group of userGroups) {
        console.log("DELETING group name >>>>>> " + group.name);
        console.log("DELETING group clientKey >>>>>> " + group.clientKey);
        await this.dataService.deleteFireStoreDataFromFunctions("", "userGroups", group.key);
      }
      // await this.dataService.updateFireStoreData('towers', tower)
    }
  }

  async findDuplicateClients() {
    console.log("STARTING");
    const limit = 1000;

    const clients: Client[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("clients").toPromise());

    const lookup = clients.reduce((a, e) => {
      a[e.name] = ++a[e.name] || 0;
      return a;
    }, {});

    const duplicates = clients.filter((e) => lookup[e.name]);

    console.log(duplicates);

    for (const duplicate of duplicates) {
      console.log(duplicate.key + " " + duplicate.name);

      const client: Client = this.dataService.querySnapshotToObject(
        await this.dataService.getFirestoreData("clients", "key", duplicate.key).toPromise()
      )[0];
      const userGroups: UserGroup[] = this.dataService.querySnapshotToObject(
        await this.dataService.getFirestoreData("userGroups", "clientKey", client.key).toPromise()
      );
      const users: UserDetail[] = this.dataService.querySnapshotToObject(
        await this.dataService.getFirestoreData("users", "clientKey", client.key).toPromise()
      );

      console.log("---------------------------------------------------");
      console.log("CLIENT : " + client.key + " --> " + client.name);
      console.log("GROUPS : " + userGroups.length);
      console.log("USERS : " + users.length);
      // console.log('DELETING client >>>>>> ' + client.name + ' ' + client.key)
      // await this.dataService.deleteFireStoreDataFromFunctions('', 'clients', client.key)

      // for (const group of userGroups) {
      //     console.log('DELETING group name >>>>>> ' + group.name)
      //     console.log('DELETING group clientKey >>>>>> ' + group.clientKey)
      //     await this.dataService.deleteFireStoreDataFromFunctions('', 'userGroups', group.key)
      // }
    }
  }

  // async addMissingUsers() {
  //     console.log('STARTING')

  //     const clientKey = '-LT75cDlOhB3sr6T3P7A'
  //     const groupKey = 'WYXbmJmjzPPiKvdxmqWz'

  //     const userList: UserDetail[] = [
  //         {
  //             key: 'TQYRpLAUc5CKPrly6gAr',
  //             firstName: 'Barendine',
  //             lastName: '',
  //             msisdn: '+27829907770',
  //             email: 'barendine@yahoo.com',
  //             displayName: 'Barendine',
  //             isActive: true,
  //             mobileAppPrice: 59.99,
  //             roles: {
  //                 admin: false,
  //                 clientAdmin: false,
  //                 manager: false,
  //                 providerAdmin: false,
  //                 user: true
  //             },
  //             groupKey,
  //             clientKey
  //         },
  //         {
  //             key: 'QkkbtNOa2zWc5KmGZOVAy8jzdTx1',
  //             firstName: 'cihequip',
  //             lastName: '',
  //             msisdn: '+27760379705',
  //             email: 'cihequip@telkimsa.net',
  //             displayName: 'cihequip',
  //             isActive: true,
  //             mobileAppPrice: 59.99,
  //             roles: {
  //                 admin: false,
  //                 clientAdmin: false,
  //                 manager: false,
  //                 providerAdmin: false,
  //                 user: true
  //             },
  //             groupKey,
  //             clientKey
  //         },
  //         {
  //             key: 'QQLEWaWPrHVC4ZRG6qxepEnKyUD3',
  //             firstName: 'franche',
  //             lastName: 'vandenberg',
  //             msisdn: '+27735658279',
  //             email: 'franche.vandenberg@gmail.com',
  //             displayName: 'franche vandenberg',
  //             isActive: true,
  //             mobileAppPrice: 59.99,
  //             roles: {
  //                 admin: false,
  //                 clientAdmin: false,
  //                 manager: false,
  //                 providerAdmin: false,
  //                 user: true
  //             },
  //             groupKey,
  //             clientKey
  //         },
  //         {
  //             key: 'NoiSdtkhmAY5tGiBx34e257IDw02',
  //             firstName: 'manager',
  //             lastName: 'crimestp',
  //             msisdn: '+27664973839',
  //             email: 'manager@crimestp.co.za',
  //             displayName: 'manager crimestp',
  //             isActive: true,
  //             mobileAppPrice: 59.99,
  //             roles: {
  //                 admin: false,
  //                 clientAdmin: false,
  //                 manager: false,
  //                 providerAdmin: false,
  //                 user: true
  //             },
  //             groupKey,
  //             clientKey
  //         },
  //         {
  //             key: 'LEQmElDiIMYFDcqpO4qMuuJoMWG2',
  //             firstName: 'yolandie',
  //             lastName: 'venter',
  //             msisdn: '+27678934536',
  //             email: 'yolandie.venter@gmail.com',
  //             displayName: 'yolandie venter',
  //             isActive: true,
  //             mobileAppPrice: 59.99,
  //             roles: {
  //                 admin: false,
  //                 clientAdmin: false,
  //                 manager: false,
  //                 providerAdmin: false,
  //                 user: true
  //             },
  //             groupKey,
  //             clientKey
  //         },
  //         {
  //             key: 'L56jLI4ArzSGNE3Q7ixvr7BtNaD3',
  //             firstName: 'operations',
  //             lastName: 'crimestp',
  //             msisdn: '+27665600501',
  //             email: 'operations@crimestp.co.za',
  //             displayName: 'operations crimestp',
  //             isActive: true,
  //             mobileAppPrice: 59.99,
  //             roles: {
  //                 admin: false,
  //                 clientAdmin: false,
  //                 manager: false,
  //                 providerAdmin: false,
  //                 user: true
  //             },
  //             groupKey,
  //             clientKey
  //         },
  //         {
  //             key: 'JRzgJOwrVJWSrgpkxl0nUART7S93',
  //             firstName: 'wolfie',
  //             lastName: 'vanderwesthuizen',
  //             msisdn: '+27725651703',
  //             email: 'wolfie.vanderwesthuizen@gmail.com',
  //             displayName: 'wolfie vanderwesthuizen',
  //             isActive: true,
  //             mobileAppPrice: 59.99,
  //             roles: {
  //                 admin: false,
  //                 clientAdmin: false,
  //                 manager: false,
  //                 providerAdmin: false,
  //                 user: true
  //             },
  //             groupKey,
  //             clientKey
  //         },
  //         {
  //             key: 'IPaWkg6wqUOLiJEEmPAxzxTCnLX2',
  //             firstName: 'suzan',
  //             lastName: 'crime',
  //             msisdn: '+27725239657',
  //             email: 'suzancrime@mweb.co.za',
  //             displayName: 'suzan crime ',
  //             isActive: true,
  //             mobileAppPrice: 59.99,
  //             roles: {
  //                 admin: false,
  //                 clientAdmin: false,
  //                 manager: false,
  //                 providerAdmin: false,
  //                 user: true
  //             },
  //             groupKey,
  //             clientKey
  //         },
  //         {
  //             key: 'FfbRJCQc9mai7mDsi1ryY3rhiyY2',
  //             firstName: 'jeandre',
  //             lastName: 'senforce',
  //             msisdn: '+27791824562',
  //             email: 'jeandre@senforce.co.za',
  //             displayName: 'jeandre senforce',
  //             isActive: true,
  //             mobileAppPrice: 59.99,
  //             roles: {
  //                 admin: false,
  //                 clientAdmin: false,
  //                 manager: false,
  //                 providerAdmin: false,
  //                 user: true
  //             },
  //             groupKey,
  //             clientKey
  //         },
  //         {
  //             key: 'FcQ2pnuG6TQoPmqh33bkfdPwQZp1',
  //             firstName: 'operation',
  //             lastName: 'crimestp',
  //             msisdn: '+27820894606',
  //             email: 'operation@crimestp.co.za',
  //             displayName: 'operation crimestp',
  //             isActive: true,
  //             mobileAppPrice: 59.99,
  //             roles: {
  //                 admin: false,
  //                 clientAdmin: false,
  //                 manager: false,
  //                 providerAdmin: false,
  //                 user: true
  //             },
  //             groupKey,
  //             clientKey
  //         },
  //         {
  //             key: 'DBrgDoS26OcGfUXXIWdkQLG2l3L2',
  //             firstName: 'hrdepartment',
  //             lastName: 'crimestp',
  //             msisdn: '+27768164930',
  //             email: 'hrdepartment@crimestp.co.za',
  //             displayName: 'hrdepartment crimestp',
  //             isActive: true,
  //             mobileAppPrice: 59.99,
  //             roles: {
  //                 admin: false,
  //                 clientAdmin: false,
  //                 manager: false,
  //                 providerAdmin: false,
  //                 user: true
  //             },
  //             groupKey,
  //             clientKey
  //         },
  //         {
  //             key: 'BkWoeY2h67dVKLQ9wB1n',
  //             firstName: 'vanheerdenv',
  //             lastName: 'vanheerdenv',
  //             msisdn: '+27718805332',
  //             email: 'vanheerdenv121@gmail.com',
  //             displayName: 'vanheerdenv',
  //             isActive: true,
  //             mobileAppPrice: 59.99,
  //             roles: {
  //                 admin: false,
  //                 clientAdmin: false,
  //                 manager: false,
  //                 providerAdmin: false,
  //                 user: true
  //             },
  //             groupKey,
  //             clientKey
  //         },
  //         {
  //             key: '9LSa5QGEwWjNoIwCqjSS',
  //             firstName: 'phillip',
  //             lastName: '',
  //             msisdn: '+27766023251',
  //             email: 'phillip123@gmail.com',
  //             displayName: 'phillip ',
  //             isActive: true,
  //             mobileAppPrice: 59.99,
  //             roles: {
  //                 admin: false,
  //                 clientAdmin: false,
  //                 manager: false,
  //                 providerAdmin: false,
  //                 user: true
  //             },
  //             groupKey,
  //             clientKey
  //         },
  //         {
  //             key: '7lPQ8OVQ0hVxGMgl2AXW',
  //             firstName: 'meyer',
  //             lastName: 'chris',
  //             msisdn: '+27836596347',
  //             email: 'meyer.chris2@gmail.com',
  //             displayName: 'meyer chris',
  //             isActive: true,
  //             mobileAppPrice: 59.99,
  //             roles: {
  //                 admin: false,
  //                 clientAdmin: false,
  //                 manager: false,
  //                 providerAdmin: false,
  //                 user: true
  //             },
  //             groupKey,
  //             clientKey
  //         },
  //         {
  //             key: '7Uv4iBApZihG0vN4zwCJ3NrFTz42',
  //             firstName: 'hannelie',
  //             lastName: 'venter',
  //             msisdn: '+27827541796',
  //             email: 'hannelie.venter@gmail.com',
  //             displayName: 'hannelie venter',
  //             isActive: true,
  //             mobileAppPrice: 59.99,
  //             roles: {
  //                 admin: false,
  //                 clientAdmin: false,
  //                 manager: false,
  //                 providerAdmin: false,
  //                 user: true
  //             },
  //             groupKey,
  //             clientKey
  //         }
  //     ]

  //     for (const user of userList) {
  //         console.log('adding ->  ' + user.firstName)
  //         await this.dataService.setFireStoreData('users', user)
  //     }

  // }

  async runLater_CheckEmtpyProviderKeys(writeDatabase = false) {
    console.log("-----START ");
    const responseServices: ResponseService[] = this.dataService.querySnapshotToObject(
      await this.dataService.getFirestoreData("responseServices").toPromise()
    );

    for (const resService of responseServices) {
      if (!resService.providerKey) {
        console.log("NO KEY " + resService.name);

        console.log("client : " + resService.clientName);
        console.log("key : " + resService.key);
        console.log("clientKey : " + resService.clientKey);
        console.log("clientName : " + resService.clientName);

        resService.providerKey = resService.clientKey;
        resService.providerName = resService.clientName;

        if (writeDatabase) {
          await this.dataService.updateFireStoreData("responseServices", resService);
        }
      }
    }
    console.log("END");
  }

  async runLater_FindResponseServicesWithNoLinkedClients(writeDatabase = false) {
    console.log(">> Running : Check Response Services With no Linked Clients");

    const responseServices: ResponseService[] = this.dataService.querySnapshotToObject(
      await this.dataService.getFirestoreData("responseServices").toPromise()
    );
    const clients: Client[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("clients").toPromise());
    const users: UserDetail[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("users").toPromise());

    for (const responseService of responseServices) {
      if (!responseService.clientKey) {
        //  console.log('No CLIIENT KEY : ' + responseService.clientName)
        console.log("DELETING : " + responseService.clientName);
        if (writeDatabase) {
          await this.dataService.deleteFireStoreDataFromFunctions("", "responseServices", responseService.key);
        }
      } else {
        const client = clients.find((x) => x.key === responseService.clientKey);
        if (!client) {
          const foundUsers = users.filter((x) => x.clientKey === responseService.clientKey);

          if (foundUsers && foundUsers.length > 0) {
            console.log("No CLIIENT BUT HAS USERS!!!! : " + responseService.clientName);
          } else {
            //console.log('No CLIIENT : ' + responseService.clientName)
            console.log("DELETING : " + responseService.clientName);
            if (writeDatabase) {
              await this.dataService.deleteFireStoreDataFromFunctions("", "responseServices", responseService.key);
            }
          }
        }
      }
    }
    console.log("END");
  }

  async runLater_FindUsersWithMissingData() {
    console.log(">> Running : Check Missing data for users");

    const users: UserDetail[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("users").toPromise());

    const missingDataUsersFirstName = users.filter((x) => !x.firstName || x.firstName === "");
    const missingDataUsersLastName = users.filter((x) => !x.firstName || x.firstName === "");
    const missingDataUsersDisplayname = users.filter((x) => !x.displayName || x.displayName === "");

    console.log(missingDataUsersFirstName.length);
    console.log(missingDataUsersLastName.length);
    console.log(missingDataUsersDisplayname.length);
    console.log("END");
  }

  async runLater_CheckIfUsersHaveMapsAsPaymentDates(writeDatabase = false) {
    console.log(">> Running : Check User Dates are Not Map Objects");

    const users: UserDetail[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("users").toPromise());

    for (const user of users) {
      let update = false;

      if (user.dateUpdated) {
        console.log(user.dateUpdated);

        const aaa = user.dateUpdated;
        let seconds = aaa["_seconds"];

        if (!seconds) {
          seconds = aaa["seconds"];
        }

        if (seconds) {
          const newDate: Date = this.toDateTime(seconds);
          user.dateUpdated = firebase.firestore.Timestamp.fromDate(newDate);
        } else {
          console.log(user.dateUpdated);
          const x: any = user.dateUpdated;
          const newDate: Date = new Date(x);
          user.dateUpdated = firebase.firestore.Timestamp.fromDate(newDate);
          console.log(">>>>>>" + user.dateUpdated);
          update = true;
        }
      }
      if (user.subscriptionCancellationDate) {
        console.log(user.subscriptionCancellationDate);
        const aaa = user.subscriptionCancellationDate;
        let seconds = aaa["_seconds"];

        if (!seconds) {
          seconds = aaa["seconds"];
        }

        if (seconds) {
          const newDate: Date = this.toDateTime(seconds);
          user.subscriptionCancellationDate = firebase.firestore.Timestamp.fromDate(newDate);
          //  console.log(user.subscriptionCancellationDate)
        } else {
          console.log(user.subscriptionCancellationDate);
          const x: any = user.subscriptionCancellationDate;
          const newDate: Date = new Date(x);
          user.subscriptionCancellationDate = firebase.firestore.Timestamp.fromDate(newDate);
          console.log(">>>>>>" + user.subscriptionCancellationDate);
          update = true;
        }
      }
      if (user.subscriptionLastPayedDate) {
        console.log(">>> " + user.subscriptionLastPayedDate);
        const aaa = user.subscriptionLastPayedDate;
        let seconds = aaa["_seconds"];

        if (!seconds) {
          seconds = aaa["seconds"];
        } else {
          console.log(">>> " + user.subscriptionLastPayedDate);
          update = true;
        }

        if (seconds) {
          const newDate: Date = this.toDateTime(seconds);
          user.subscriptionLastPayedDate = firebase.firestore.Timestamp.fromDate(newDate);
          // console.log('>>> ' + user.subscriptionActive)
        } else {
          const x: any = user.subscriptionLastPayedDate;
          const newDate: Date = new Date(x);
          user.subscriptionLastPayedDate = firebase.firestore.Timestamp.fromDate(newDate);
          //  console.log('>>>>>>' + user.subscriptionLastPayedDate)
          update = true;
        }
      }

      if (writeDatabase && update) {
        console.log("-->>> " + user.subscriptionLastPayedDate);
        await this.dataService.updateFireStoreData("users", user);
        console.log("UPDATED ->>>>>");
      }
    }
    console.log("END");
  }

  async runLater_FindAndDeleteEmptyClientsWithNoUsers(writeDatabase = false) {
    console.log(">> Running : Empty Clients with no Users Check");

    const clients: Client[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("clients").toPromise());
    const users: UserDetail[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("users").toPromise());
    const responseServices: ResponseService[] = this.dataService.querySnapshotToObject(
      await this.dataService.getFirestoreData("responseServices").toPromise()
    );

    for (const client of clients) {
      const userLIst = users.filter((x) => x.clientKey === client.key);

      if (!userLIst || userLIst.length <= 0) {
        // console.log(client.key + ' - ' + client.name)

        // check if any one else is using their response service
        const resCheck = responseServices.filter((x) => x.providerLinkedResponseServiceKey === client.key);

        if (resCheck && resCheck.length > 0) {
          console.log(">> SOME ONE USEING ");
        } else {
          console.log("delete " + client.name);
          if (writeDatabase) {
            await this.dataService.deleteFireStoreDataFromFunctions("", "clients", client.key);
          }
          const resSErvices = responseServices.filter((x) => x.clientKey === client.key);

          for (const resSrvc of resSErvices) {
            console.log("delete Service " + resSrvc.key);
            if (writeDatabase) {
              await this.dataService.deleteFireStoreDataFromFunctions("", "responseServices", resSrvc.key);
            }
          }
        }
      } else {
        console.log("nothing");
      }
    }
    console.log("END");
  }

  async runLater_FindAndDeleteUnusedGroups(writeDatabase = false) {
    console.log(">> Running : Unused UserGroups Check");

    const clients: Client[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("clients").toPromise());
    const userGroups: UserGroup[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("userGroups").toPromise());

    for (const userGrp of userGroups) {
      const usedClient = clients.filter((x) => x.key === userGrp.clientKey);

      if (!usedClient || usedClient.length <= 0) {
        console.log("Delete group For : " + userGrp.clientKey);
        if (writeDatabase) {
          await this.dataService.deleteFireStoreDataFromFunctions("", "userGroups", userGrp.key);
        }
      }
    }
    console.log("END");
  }

  async runLater_CheckIfClientGroupsAreCorrectWithDefaultGroup(writeDatabase = false) {
    console.log(">> Running : Client UserGroups Check");

    const clients: Client[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("clients").toPromise());
    const userGroups: UserGroup[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("userGroups").toPromise());

    for (const client of clients) {
      const clientGroups = userGroups.filter((x) => x.clientKey === client.key);

      console.log("----------------");
      for (const group of clientGroups) {
        console.log("Count : " + clientGroups.length);

        const defaultCount = clientGroups.filter((x) => x.autoGenerated);

        if (!defaultCount || defaultCount.length <= 0) {
          console.log(">>>>>> ERROR : " + client.name);
          console.log(">>>>>> ERROR : " + client.key);
        } else if (defaultCount.length === 1) {
        }
        if (defaultCount.length > 1) {
          console.log(">>>>>> ERROR : " + client.name);
          console.log(">>>>>> ERROR : " + client.key);
        }
      }
    }
    console.log("END");
  }

  async runLater_CheckIfUserFriendsResponseServiceExist(writeDatabase = false) {
    console.log(">> Running : Empty Friends Res Service Check");

    const users: UserDetail[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("users").toPromise());
    const responseServices: ResponseService[] = this.dataService.querySnapshotToObject(
      await this.dataService.getFirestoreData("responseServices").toPromise()
    );
    const clients: Client[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData("clients").toPromise());

    for (const user of users) {
      const userResService = responseServices.find((x) => x.key === user.responseServiceFriendsKey);

      if (!userResService) {
        console.log("NOT FOUND !: " + user.key);

        console.log(user.key);
        const friendRepsonseService: ResponseService = {
          category: "publicService",
          clientKey: user.clientKey,
          clientName: clients.find((x) => x.key === user.clientKey).name,
          icon: "/assets/icon/generic/friends_fam.svg",
          iconColor: "icon-orange",
          isAllowedToHandleOtherActionsInParallel: true,
          isAvailableOnMobile: true,
          isDirectRequestToResponders: true,
          isMoreThanOneResponderAllowed: true,
          isNOCInChargeOfCompletion: false,
          isPublicServiceProvider: false,
          isServiceProvider: true,
          providerKey: clients.find((x) => x.key === user.clientKey).key,
          providerName: clients.find((x) => x.key === user.clientKey).name,
          respondRadiusKm: 100,
          responders: [],
          isAutoGenerated: true,
          // DONT change this variable -> Doing lookups on Friends & Family.
          name: "Friends & Family",
          settings: {
            isCloseReasonFromList: false,
          },
          type: "friend",
        };

        console.log(friendRepsonseService);

        await this.dataService.setFireStoreDataFromFunctions(user.key, "responseServices", friendRepsonseService).then(async (firestoreKey) => {
          user.responseServiceFriendsKey = firestoreKey;
          await this.dataService.updateFireStoreDataFromFunctions(user.key, "users", user);
          return friendRepsonseService;
        });
      }
    }
    console.log("END");
  }
}
