import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DataService } from "../dataService/data.service";
import { apiUrlEnum } from "app/enums/api-endpoints.enums";
import {
  EmailRequest,
  PushToAllClientUsersRequest,
  SMSRequest,
} from "app/interfaces/communication.interface";
import { environment } from "environments/environment";

@Injectable()
export class CommunicationService {
  constructor(public http: HttpClient, public dataService: DataService) {}

  async sendSms(_message: string, _msisdn: string) {
    const smsRequest: SMSRequest = {
      message: _message,
      msisdn: _msisdn,
    };

    this.dataService
      .postToFunctions(apiUrlEnum.communication_v1.sms_send, smsRequest)
      .then((data) => {
        if (data.success) {
          console.log("SENT!");
        } else {
          console.error("NOT SENT!");
        }
      });
  }

  async sendEmail(
    emailTo: string,
    message: string,
    subject: string,
    from?: string
  ): Promise<void> {
    if (!from) {
      from = environment.fromEmail;
    }

    this.dataService
      .postToFunctions(apiUrlEnum.communication_v1.email_send, {
        to: emailTo,
        textHtml: message,
        subject: subject,
        from: from,
      } as EmailRequest)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  async pushToAllClientUsers(
    title: string,
    message: string,
    clientKey: string
  ): Promise<void> {
    const notificationRequest: PushToAllClientUsersRequest = {
      message: message,
      title: title,
      clientKey: clientKey,
    };
    this.dataService.postToFunctions(
      apiUrlEnum.communication_v1.push_all_client_users,
      notificationRequest
    );
  }
}
