
// globals.ts
import { Injectable } from '@angular/core';
import { UserDetail } from './interfaces/user.interface';

@Injectable()
export class Globals {

    loggedInUser: UserDetail

    loading = false;

    constructor() { }

    // getLoggedInUser(): UserDetail {
    //     const loggedInUser = localStorage.getItem('loggedInUser');
    //     return <UserDetail>(JSON.parse(loggedInUser));
    // }
    // setLoggedInUser(user: UserDetail) {
    //     localStorage.setItem('loggedInUser', JSON.stringify(user));
    // }

    setSelectedPage(page: string) {
        localStorage.setItem('selectedPage', page);
    }

    // setSelectedProvider(provider: Provider) {
    //     if (!provider) {
    //         localStorage.removeItem('provider')
    //     } else {
    //         localStorage.setItem('provider', JSON.stringify(provider));
    //     }
    // }

    // getSelectedProvider(): Provider{
    //     const selectedMember = localStorage.getItem('provider');
    //     return <Provider>(JSON.parse(selectedMember));
    // }
}
