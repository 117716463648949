import { InjectionToken } from "@angular/core";

export const ENV_CONFIG: InjectionToken<ENV> = new InjectionToken<ENV>('env_file');

export interface ENV {
    production: boolean,
    firebase: {
        [projectKey: string]: {
            apiKey: string,
            authDomain: string,
            projectId: string,
            storageBucket: string,
            messagingSenderId: string,
            appId: string,
            measurementId?: string
        }
    }
}