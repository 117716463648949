import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from './auth.service'

@Injectable()
export class AuthGuardProvider implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {
        console.debug('AuthGuardProvider: instantiated');
    }

    canActivate(): boolean {
        if (!this.isAuthenticated()) {
            console.debug('canActivate: not authenticated');

            this.router.navigate(['/provider/pgs/login']);

            return false;
        }

        return true;
    }

    private isAuthenticated(): boolean {
        if (!this.authService.appUser || !this.authService.appUser.userDetail || !this.authService.appUser.userDetail.roles) {
            return false;
        }

        return this.authService.appUser.userDetail.roles.providerAdmin;
    }
}
