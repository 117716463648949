import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { PeachPaymentResponse } from 'app/interfaces/general/payment.interface';
import { ScriptService } from 'ngx-script-loader';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {

  peachResponseVerify: PeachPaymentResponse;
  hostedUrlNoHttp = environment.hostedUrlNoHttp;

  constructor(
    private activatedRoute: ActivatedRoute,
    public http: HttpClient,
    private scriptService: ScriptService,
  ) { }

  ngOnInit() {

    let checkoutResponseId;

    this.activatedRoute.queryParams.subscribe(params => {
      localStorage.setItem('peachPaymentUserId', params['userId']);
      localStorage.setItem('peachPaymentUserClientName', params['clientName']);
      localStorage.setItem('peachPaymentUserMobilePrice', params['mobilePrice']);
      localStorage.setItem('peachPaymentUserFirstName', params['userFirstName']);
      localStorage.setItem('peachPaymentUserLastName', params['userLastName']);
      localStorage.setItem('peachPaymentReceiptId', params['peachPaymentReceiptId']);
      localStorage.setItem('peachPaymentGroupKey', params['groupKey']);

      checkoutResponseId = params['checkoutResponseId'];

      console.log(checkoutResponseId);
    });

    this.scriptService
      // tslint:disable-next-line: max-line-length
      .loadScript(environment.paymentGateway.hostUrl + 'v1/paymentWidgets.js?checkoutId=' + environment.paymentGateway.hostUrl + 'v1/paymentWidgets.js?checkoutId=' + checkoutResponseId)
      .subscribe(() => {
      });

    // this.client = JSON.parse(this.route.snapshot.paramMap.get('client'))
  }
}
