/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./loader.component";
import * as i4 from "../../globals";
var styles_LoaderComponent = [i0.styles];
var RenderType_LoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoaderComponent, data: {} });
export { RenderType_LoaderComponent as RenderType_LoaderComponent };
function View_LoaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "circular"], ["viewBox", "25 25 50 50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:circle", [["class", "path"], ["cx", "50"], ["cy", "50"], ["fill", "none"], ["r", "20"], ["stroke-miterlimit", "10"], ["stroke-width", "2"]], null, null, null, null, null))], null, null); }
function View_LoaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loaderBackground"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoaderComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.g.loading; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.g.loading; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, View_LoaderComponent_0, RenderType_LoaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoaderComponent, [i4.Globals], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoaderComponentNgFactory = i1.ɵccf("app-loader", i3.LoaderComponent, View_LoaderComponent_Host_0, {}, {}, []);
export { LoaderComponentNgFactory as LoaderComponentNgFactory };
