var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import { environment } from "environments/environment";
import { AuthService } from 'app/core/auth.service';
import * as firebase from 'firebase/app';
import 'firebase/auth'; // for authentication
import 'firebase/storage'; // for storage
import 'firebase/database'; // for realtime database
import 'firebase/firestore'; // for cloud firestore
import 'firebase/messaging'; // for cloud messaging
import 'firebase/functions'; // for cloud functions
import { apiUrlEnum } from 'app/enums/api-endpoints.enums';
import * as i0 from "@angular/core";
import * as i1 from "../../core/auth.service";
import * as i2 from "@angular/common/http";
var timeStampDataService = /** @class */ (function () {
    // Constructor for the FunctionDataService class
    function timeStampDataService(authService, http) {
        this.authService = authService;
        this.http = http;
        console.debug('TimeStampDataService: instantiated');
    }
    /*
        get_fire_store_key: '/data/get-firestore-key/v1', - Does not exist in functions [Alucard]
    */
    timeStampDataService.prototype.getServerTimeStampData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var exception_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.debug("Getting timestamp...");
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.functionGet().then(function (data) {
                                // string to "any" object first
                                console.debug("Timestamp returned.");
                                if (data.timestamp) {
                                    var timestamp = data;
                                    console.debug("Timestamp value is: " + timestamp.timestamp);
                                    console.debug("Server time returned sucessfully. Using now.");
                                    return firebase.firestore.Timestamp.fromMillis(timestamp.timestamp);
                                }
                                else {
                                    console.debug("Server time not returned - cannot continue.");
                                    return Promise.reject("Server time not returned - cannot continue.");
                                }
                            })];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3:
                        exception_1 = _a.sent();
                        console.debug("No timestamp returned. Error on fetch: " + exception_1);
                        return [2 /*return*/, Promise.reject(exception_1)];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    timeStampDataService.prototype.functionGet = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url, idToken;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = environment.functionsUrl;
                        return [4 /*yield*/, this.authService.user.getIdToken(false)];
                    case 1:
                        idToken = _a.sent();
                        if (!idToken) {
                            throw new Error('ID token is null');
                        }
                        return [2 /*return*/, this.http.get("" + url + apiUrlEnum.timestamp_v1.get_server_timestamp, {
                                headers: {
                                    'Authorization': 'Bearer ' + idToken,
                                    'Content-Type': 'application/json',
                                    'Project-Id': environment.firebase['PROJECT-ONE'].projectId
                                },
                            }).toPromise()];
                }
            });
        });
    };
    timeStampDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function timeStampDataService_Factory() { return new timeStampDataService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.HttpClient)); }, token: timeStampDataService, providedIn: "root" });
    return timeStampDataService;
}());
export { timeStampDataService };
