import { Component, OnInit } from '@angular/core';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'intercom',
  templateUrl: './intercom.component.html',
  styleUrls: ['./intercom.component.scss'],
})
export class IntercomComponent implements OnInit {
  constructor(
    public intercom: Intercom

  ) { }

  ngOnInit() {
   
  }

  ngAfterContentInit() {
    this.intercom.boot({
      app_id: 'xxxxxx',
      // Supports all optional configuration.
      widget: {
        "activator": "#intercom" 
      }
    });
  }
}
