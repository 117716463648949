
import { Inject, Injectable } from '@angular/core';
import { DataService } from '../dataService/data.service';
import { ResponseService } from 'app/interfaces/response-service.interface';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirebaseHelper } from 'app/helpers/firebase.helper';
import { AuthService } from 'app/core/auth.service';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs';
import { Client } from 'app/interfaces/client.interface';
import { UserDetail } from 'app/interfaces/user.interface';
import { FIREBASE_REFERENCES } from 'app/core/firebase/firebase.module';

export interface UserClientUpdate {
    key: string;
    clientKey: string;
    clientName: string;
}

@Injectable()
export class ClientService {
    private readonly collectionName: string = 'responseServices';

    constructor(
        private authService: AuthService, 
        @Inject(FIREBASE_REFERENCES.ONE_FIRESTORE) private readonly firestore: AngularFirestore,
        //private firestore: AngularFirestore,
        public dataService: DataService) { 
        console.debug('ClientService: instantiated');
    }

    async changeUserLinkedClient(clientKey: string, clientName: string): Promise<UserClientUpdate> {
        const user = this.authService.appUser.userDetail;

        try {
            const newDocRef = this.firestore.collection('users').doc(user.key);

            const changes: UserClientUpdate = {
                key: user.key,
                clientKey: clientKey,
                clientName: clientName,
            }

            await newDocRef.update(changes);

            return changes;
        }
        catch (error) {
            console.error('Error creating new asset:', error);
            
            throw error;
        }
    }
    
    

    // public async linkUserToClient(user: UserDetail, invitationCode: string = '123456', ): Promise<ReqResponse> {

    //     return await this.dataService.getFirestoreDataFromFunctions(this.userService.appUser.userDetail.key, 'clients', 'invitationCode', invitationCode)
    //         .toPromise()
    //         .then(querySnapshot => {

    //             const clientArr: Client[] = this.dataService.querySnapshotToObject(querySnapshot)

    //             if (clientArr && clientArr.length > 0) {
    //                 const client: Client = clientArr[0];
    //                 user.clientKey = client.key;
    //                 // user.clientName = client.name;

    //                 this.dataService.setFireStoreDataFromFunctions(this.userService.appUser.userDetail.key,'users', user)
    //                     .then(() => {
    //                         return {
    //                             success: true,
    //                             message: 'You have been linked to ' + client.name,
    //                         };
    //                     }).catch(error => {
    //                         return {
    //                             success: false,
    //                             error: error,
    //                             message: error.message,
    //                         };
    //                     });
    //             } else {
    //                 return {
    //                     success: false,
    //                     message: 'Incorrect Invitation Code',
    //                 };
    //             }
    //         });
    // }
}
