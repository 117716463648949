import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
import { FirestoreCollection, LogPlatform, LogType, LogAction } from "app/types/data.type";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ReqResponse } from "app/interfaces/general/response.interface";
import { environment } from "environments/environment";
import * as firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
import { HttpOption } from "app/interfaces/global.interface";
import { TeamsMessageRequest } from "app/interfaces/communication.interface";
import { apiUrlEnum } from "app/enums/api-endpoints.enums";
import { DeleteFireStoreDataRequest, SetFireStoreDataRequest, GetFireStoreDataRequest, GetFireStoreDataStartAtRequest } from "app/interfaces/data.interface";
import { ResponseServiceAction, ResponseServiceActionRequest } from "app/interfaces/response-service.interface";
import { FunctionDataService } from "app/services/function/function.data.service";
import { AuthService } from "app/core/auth.service";
import { AngularFireModule } from "@angular/fire";
import { FIREBASE_REFERENCES } from "app/core/firebase/firebase.module";

@Injectable({
    providedIn: "root",
})
export class DataService {
    loggedInUserKey: string;
    loggedInUserDisplayName: string;

    constructor(
        private authService: AuthService, 
        @Inject(FIREBASE_REFERENCES.ONE_FIRESTORE) private readonly afs: AngularFirestore,
        @Inject(FIREBASE_REFERENCES.TWO_FIRESTORE) private readonly plusDB: AngularFirestore,
        //private afs: AngularFirestore, 
        private functionDataService: FunctionDataService, 
        private http: HttpClient) 
        { }

    getFirestoreData(
        collection: FirestoreCollection,
        filterName: string = "",
        filterValue: any = "",
        whereFilterOp: firebase.firestore.WhereFilterOp = "==",
        limit: number = 10000
    ): Observable<firebase.firestore.QuerySnapshot> {
        if (filterName !== "") {
            return this.afs
                .collection(collection, (ref) =>
                    ref.where(filterName, whereFilterOp, filterValue).limit(limit)
                )
                .get();
        } else {
            return this.afs.collection(collection, (ref) => ref.limit(limit)).get();
        }
    }

    async queryFirestore(collection: FirestoreCollection, filters: { filterName: string, filterValue: any, whereFilterOp: firebase.firestore.WhereFilterOp }[] = [], limit?: number): Promise<firebase.firestore.QuerySnapshot> {
        // Create base query
        let query: firebase.firestore.Query = this.afs.collection(collection).ref;

        console.debug(`Getting data from collection: ${collection}`);

        // Add each filter to the query
        for (let filter of filters) {
            query = query.where(filter.filterName, filter.whereFilterOp, filter.filterValue);
            console.debug(`Applied filter - ${filter.filterName} ${filter.whereFilterOp} ${filter.filterValue}`);
        }

        // Limit the number of documents
        if (limit) {
            query = query.limit(limit);
            console.debug(`Limiting results to ${limit} documents`);
        }

        // Get the data
        const snapshot = await query.get();
        console.debug(`Received ${snapshot.size} documents`);

        return snapshot;
    }

    async queryFirestorePaged(collection: FirestoreCollection, filters: { filterName: string, filterValue: any, whereFilterOp: firebase.firestore.WhereFilterOp }[] = [], limit: number = 5, startAfterDocument: firebase.firestore.DocumentSnapshot | null = null, startBeforeDocument: firebase.firestore.DocumentSnapshot | null = null, orderBy: string, direction: firebase.firestore.OrderByDirection = 'asc'): Promise<firebase.firestore.QuerySnapshot> {
        // Create base query
        let query: firebase.firestore.Query = this.afs.collection(collection).ref;

        // Set query order direction
        query = query.orderBy(orderBy, direction);

        console.debug(`Getting data from collection: ${collection}`);

        // Add each filter to the query
        for (let filter of filters) {
            query = query.where(filter.filterName, filter.whereFilterOp, filter.filterValue);
            console.debug(`Applied filter - ${filter.filterName} ${filter.whereFilterOp} ${filter.filterValue}`);
        }

        // Add startAfter or startBefore to the query if applicable
        if (startAfterDocument && direction === 'asc') {
            query = query.startAfter(startAfterDocument);
        } else if (startBeforeDocument && direction === 'desc') {
            query = query.startAfter(startBeforeDocument);
        }

        // Limit the number of documents
        query = query.limit(limit);
        console.debug(`Limiting results to ${limit} documents`);

        // Get the data
        const snapshot = await query.get();
        console.debug(`Received ${snapshot.size} documents`);

        // If direction is 'desc', reverse the order of the documents
        if (direction === 'desc') {
            snapshot.docs.reverse();
        }

        return snapshot;
    }

    getFirestoreDataFromFunctions(
        userKey: string,
        collection: FirestoreCollection,
        filterName: string = "",
        filterValue: any = "",
        whereFilterOp: firebase.firestore.WhereFilterOp = "==",
        limit: number = -1,
        orderBy: string = "",
        orderbyDirection: firebase.firestore.OrderByDirection = "asc",
        startAfter: any = -1,
        retryCount: number = 1
    ): Promise<any> {
        const getFireStoreDataRequest: GetFireStoreDataRequest = {
            collection,
            filterName,
            filterValue,
            whereFilterOp,
            limit,
            orderBy,
            orderbyDirection,
            startAfter,
            apiKey: environment.apiKey,
            userKey: userKey,
        };

        console.log("-> Getting from Functions");
        // Commented out because its logging user uuid's
        //console.log(getFireStoreDataRequest);

        return this.postToFunctions(
            apiUrlEnum.data_v1.get_fire_store_data,
            getFireStoreDataRequest
        )
            .then((reqResponse) => {
                if (reqResponse.success) {
                    return reqResponse.data;
                } else {
                    console.error(reqResponse);
                    if (retryCount <= 1) {
                        return this.getFirestoreDataFromFunctions(
                            userKey,
                            collection,
                            filterName,
                            filterValue,
                            whereFilterOp,
                            limit,
                            orderBy,
                            orderbyDirection,
                            startAfter,
                            2
                        );
                    } else {
                        throw reqResponse.error;
                    }
                }
            })
            .catch((exception) => {
                console.error(exception.message);
                // alert('Failed to fetch data, please reload page.')
                //   throw (exception)
            });
    }

    getFirestoreDataFromFunctionsStartAt(
        userKey: string,
        collection: FirestoreCollection,
        startAt: any = 0,
        orderBy: string = "",
        limit: number = -1
    ): Promise<any> {
        const getFireStoreDataRequest: GetFireStoreDataStartAtRequest = {
            collection,
            limit,
            orderBy,
            startAt,
            userKey: userKey,
        };
        console.log("-> Getting from Functions");
        console.log(getFireStoreDataRequest);

        return this.postToFunctions(
            apiUrlEnum.data_v1.get_fire_store_data_start_at,
            getFireStoreDataRequest
        )
            .then((reqResponse) => {
                if (reqResponse.success) {
                    return reqResponse.data;
                } else {
                    console.error(reqResponse);
                }
            })
            .catch((exception) => {
                console.error(exception.message);
                //    alert('Failed to fetch data, please reload page.')
                //  throw (exception)
            });
    }

    getFirestoreDataSubscription(
        collection: FirestoreCollection,
        filterName: string = "",
        filterValue: any = "",
        whereFilterOp: firebase.firestore.WhereFilterOp = "==",
        limit: number = -1,
        orderBy: string = "",
        orderbyDirection: firebase.firestore.OrderByDirection = "desc"
    ): AngularFirestoreCollection<any> {
        if (filterName !== "") {
            if (limit === -1) {
                return this.afs.collection(collection, (ref) =>
                    ref.where(filterName, whereFilterOp, filterValue)
                );
            } else {
                return this.afs.collection(collection, (ref) =>
                    ref.where(filterName, whereFilterOp, filterValue).limit(limit)
                );
            }
        } else if (orderBy !== "") {
            if (limit === -1) {
                return this.afs.collection(collection, (ref) =>
                    ref.orderBy(orderBy, orderbyDirection)
                );
            } else {
                return this.afs.collection(collection, (ref) =>
                    ref.orderBy(orderBy, orderbyDirection).limit(limit)
                );
            }
        } else {
            if (limit === -1) {
                return this.afs.collection(collection, (ref) => ref);
            } else {
                return this.afs.collection(collection, (ref) => ref.limit(limit));
            }
        }
    }

    // getFirestoreDataSubscriptionFromFunctions(
    //   userKey: string,
    //   collection: FirestoreCollection,
    //   filterName: string = '',
    //   filterValue: any = '',
    //   whereFilterOp: firebase.firestore.WhereFilterOp = '==',
    //   limit: number = -1
    // ): Promise<Query> {

    //   const getFireStoreDataRequest: GetFireStoreDataRequest = {
    //     collection,
    //     filterName,
    //     filterValue,
    //     whereFilterOp,
    //     limit,
    //     apiKey: environment.apiKey,
    //     userKey: userKey
    //   };

    //   return this.postToFunctions(apiUrlEnum.data_v1.get_fire_store_data, getFireStoreDataRequest)
    //     .then(reqResponse => {

    //       if (reqResponse.success) {
    //         return reqResponse.data;
    //       } else {
    //         console.error(reqResponse.message);
    //         alert(reqResponse.message);
    //       }
    //     }).catch(exception => {
    //       this.teamsLog('mobile', 'exception', 'databaseCallSet', collection);
    //       console.log(exception.message);
    //     });
    // }

    setFireStoreDataFromFunctions(
        userKey: string,
        collection: FirestoreCollection,
        data: any
    ): Promise<string> {
        const setFireStoreDataRequest: SetFireStoreDataRequest = {
            collection,
            apiKey: environment.apiKey,
            userKey: userKey,
            data,
        };

        return this.postToFunctions(
            apiUrlEnum.data_v1.set_fire_store_data,
            setFireStoreDataRequest
        )
            .then((reqResponse) => {
                if (reqResponse.success) {
                    return reqResponse.data;
                } else {
                    console.error(reqResponse);
                }
            })
            .catch((exception) => {
                this.teamsLog("mobile", "exception", "databaseCallSet", collection);
                console.log(exception.message);
            });
    }

    updateFireStoreDataFromFunctions(
        userKey: string,
        collection: FirestoreCollection,
        data: any
    ): Promise<string> {
        const setFireStoreDataRequest: SetFireStoreDataRequest = {
            collection,
            apiKey: environment.apiKey,
            userKey: userKey,
            data,
        };

        return this.postToFunctions(
            apiUrlEnum.data_v1.update_fire_store_data,
            setFireStoreDataRequest
        )
            .then((reqResponse) => {
                if (reqResponse.success) {
                    return reqResponse.data.key;
                } else {
                    console.error(reqResponse);
                }
            })
            .catch((exception) => {
                this.teamsLog("mobile", "exception", "databaseCallSet", collection);
                console.log(exception.message);
            });
    }

    deleteFireStoreDataFromFunctions(
        userKey: string,
        collection: FirestoreCollection,
        key: string
    ): Promise<void> {
        const deleteFireStoreDataRequest: DeleteFireStoreDataRequest = {
            collection,
            key,
            apiKey: environment.apiKey,
            userKey: userKey,
        };

        return this.postToFunctions(
            apiUrlEnum.data_v1.delete_fire_store_data,
            deleteFireStoreDataRequest
        )
            .then((reqResponse) => {
                if (reqResponse.success) {
                    return reqResponse.data.key;
                } else {
                    console.error(reqResponse.message);
                }
            })
            .catch((exception) => {
                this.teamsLog("mobile", "exception", "databaseCallSet", collection);
                console.log(exception.message);
            });
    }

    deleteFireStoreData(
        collection: FirestoreCollection,
        key: string
    ): Promise<void> {
        return this.afs.collection(collection).doc(key).delete();
    }

    updateFireStoreData(
        collection: FirestoreCollection,
        data: any | ResponseServiceAction
    ): Promise<string> {
        return this.afs
            .collection(collection)
            .doc(data.key)
            .update(data)
            .then(() => {
                //  this.sendPushUpdateToFunctions(data);

                return data.key;
            })
            .catch((exception) => {
                this.teamsLog("mobile", "exception", "databaseCallSet", collection);
                console.log(exception.message);
            });
    }

    // Not used
    sendPushUpdateToFunctions(resposeServiceAction: any) {
        const responseServiceActionRequest: ResponseServiceActionRequest = {
            responseServiceActionKey: resposeServiceAction.key,
            responseServiceKey: resposeServiceAction.responseServiceKey,
        };
        this.postToFunctions(
            apiUrlEnum.responseService_v1.responsePushUpdate,
            responseServiceActionRequest
        )
            .then((reqResponse) => {
                if (reqResponse.success) {
                    return reqResponse.data.key;
                } else {
                    console.error(reqResponse.message);
                }
            })
            .catch((exception) => {
                console.log(exception.message);
            });
    }

    setFireStoreData(
        collection: FirestoreCollection,
        data: any,
        merge = false
    ): Promise<string> {
        if (!data.key) {
            data.key = this.afs.createId();
        }
        return this.afs
            .collection(collection)
            .doc(data.key)
            .set(data, { merge })
            .then(() => {
                return data.key;
            })
            .catch((exception) => {
                this.teamsLog("mobile", "exception", "databaseCallSet", collection);
                console.log(exception.message);
            });
    }

    getFireStoreKey(): string {
        return this.afs.createId();
    }

    querySnapshotToObject(snapshot: firebase.firestore.QuerySnapshot): any {
        const returnObj = [];
        snapshot.forEach((queryDocSnap) => {
            returnObj.push(queryDocSnap.data());
        });
        return returnObj;
    }

    async postToFunctions(apiUrlEnm: string, body: any, sendToTeams = true, functionURL = environment.functionsUrl): Promise<ReqResponse> {
        let response: ReqResponse = {
            success: null,
            message: null,
            error: null,
            data: null,
            dataExtended: null,
            dataExtended2: null,
            refId: null,
            responseCode: null,
        };
/*         
        try {
            const data = await this.http.post(functionURL + apiUrlEnm, body).toPromise();

            response = <ReqResponse>data;
        } 
        catch (error) {
            if (sendToTeams) {
                this.teamsLog("mobile", "exception", "ApiFunctionsCall", functionURL + apiUrlEnm);
            }

            console.error("Error:", error);

            response = {
                success: false,
                message: error.message,
                error: error,
            };
        } 
*/
        try {
            const idToken = await this.authService.user.getIdToken(false);

            if (!idToken) {
                throw new Error('ID token is null');
            }

            response = await this.http.post<ReqResponse>(functionURL + apiUrlEnm, body, {
                headers: {
                    'Authorization': 'Bearer ' + idToken,
                    'Content-Type': 'application/json',
                    'Project-Id': environment.firebase['PROJECT-ONE'].projectId
                }
            }).toPromise();
        }
        catch (error) {
            if (sendToTeams) {
                this.teamsLog("mobile", "exception", "ApiFunctionsCall", functionURL + apiUrlEnm);
            }

            console.error("Error:", error);

            response = {
                success: false,
                message: error.message,
                error: error,
            };
        }

        return response;
    }

    async postToExternalApiOld(apiUrlEnm: string, body: any, sendToTeams = true) {
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "TgPPq4sHhbyFl3yMkTeVHw==",
            }),
        };

        await this.http
            .post(apiUrlEnm, body, httpOptions)
            .toPromise()
            .then((data) => {
                var test = data;
                return data;
            })
            .catch((exception) => {
                console.error(exception);
            });
    }

    async postToExternalApi(
        url: string,
        body: any,
        httpOptions?: HttpOption
    ): Promise<ReqResponse> {
        let postObservable: Observable<Object>;

        if (httpOptions) {
            postObservable = this.http.post(url, body, httpOptions);
        } else {
            postObservable = this.http.post(url, body);
        }

        return postObservable
            .toPromise()
            .then(
                (data) => {
                    this.teamsLog("mobile", "info", "ApiExternalCall", url);
                    const response: ReqResponse = {
                        success: true,
                        data: data,
                        message: "Fetched",
                    };
                    return response;
                },
                (exception) => {
                    console.error("exception ", exception.message);
                    this.teamsLog("mobile", "exception", "ApiExternalCall", url);
                    const response: ReqResponse = {
                        success: false,
                        message: exception.message,
                        error: exception,
                    };
                    return response;
                }
            )
            .catch((exception) => {
                this.teamsLog("mobile", "exception", "ApiExternalCall", url);
                console.error("exception ", exception);
                const response: ReqResponse = {
                    success: false,
                    message: exception.message,
                    error: exception,
                };
                return response;
            });
    }

    teamsLog(platform: LogPlatform, type: LogType, action: LogAction, detail: string) {
        const log = {
            userDisplayName: this.loggedInUserDisplayName,
            userKey: this.loggedInUserKey,
            version: environment.version,
        };

        if (!log.userDisplayName) {
            log.userDisplayName = "Un Registered User";
        }
        if (!log.userKey) {
            log.userKey = "NA";
        }

        const teamsRequest: TeamsMessageRequest = {
            preText: platform + " - " + type + " ( " + action + " ) ",
            title: "Initator : *" + log.userDisplayName + "* (" + log.userKey + ")",
            text:
                "Action Initated Detail : " + detail + ", on Version : " + log.version,
            teamsGroupUrl: environment.teams.systemLogsUrl,
        };
        this.postToFunctions(
            apiUrlEnum.communication_v1.teams_send,
            teamsRequest,
            false
        );
    }
}
