import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from './auth.service'

@Injectable()
export class AuthGuardAdmin implements CanActivate {
    constructor(
        private authService: AuthService, 
        private router: Router) {
        console.debug('AuthGuardAdmin: instantiated');
    }

    canActivate(): boolean {
        if (!this.isAuthenticated()) {
            console.debug('canActivate: not authenticated');

            this.router.navigate(['/admin/pgs/login']);

            return false;
        }

        return true;
    }

    private isAuthenticated(): boolean {
        if (!this.authService.appUser || !this.authService.appUser.userDetail || !this.authService.appUser.userDetail.roles) {
            return false;
        }

        if (this.authService.appUser.userDetail.roles.journeyCreator) {
            return true;
        }

        if (this.authService.appUser.userDetail.roles.journeyEditor) {
            return true;
        }

        if (this.authService.appUser.userDetail.roles.journeyViewer) {
            return true;
        }

        if (this.authService.appUser.userDetail.roles.assetEditor) {
            return true;
        }

        if (this.authService.appUser.userDetail.roles.assetViewer) {
            return true;
        }


        return this.authService.appUser.userDetail.roles.admin;
    }
}
