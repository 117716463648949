import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from "environments/environment";

import { AuthService } from 'app/core/auth.service';

interface QueryParams {
    [key: string]: string | number | boolean;
}

@Injectable({
    providedIn: 'root',
})
export class FunctionDataService {

    // Constructor for the FunctionDataService class
    constructor(private authService: AuthService, private http: HttpClient) { 
        console.debug('FunctionDataService: instantiated');
    }

/*  
    get_fire_store_key: '/data/get-firestore-key/v1', - Does not exist in functions [Alucard]
*/

    async getFirestoreData(data: any) {
        const response = await this.functionPost('get-firestore-data', data);

        return response;
    }

    async getFirestoreDataStartAt(data: any) {
        const response = await this.functionPost('get-firestore-data-start-at', data);

        return response;
    }

    async getFirestoreDataSubscription(data: any) {
        const response = await this.functionPost('get-firestore-data-subscription', data);

        return response;
    }

    async setFirestoreData(data: any): Promise<any> {
        const response = await this.functionPost('set-firestore-data', data);

        return response;
    }

    async updateFirestoreData(data: any): Promise<any> {
        const response = await this.functionPost('update-firestore-data', data);

        return response;
    }

    async deleteFirestoreData(data: any): Promise<any> {
        const response = await this.functionPost('delete-firestore-data', data);

        return response;
    }

    private async functionGet<T = any>(functionName: string, paramsObj?: QueryParams, url?: string): Promise<T>  {
        url = url || environment.functionsUrl;

        let params = new HttpParams();
        
        if (paramsObj) {
            for (const key in paramsObj) {
                const value = paramsObj[key];

                params = params.append(key, value.toString());
            }
        }

        const idToken = await this.authService.user.getIdToken(false);

        if (!idToken) {
            throw new Error('ID token is null');
        }

        return this.http.get<T>(`${url}/apiData/v1/${functionName}`, {
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json',
            },
            params
        }).toPromise();
    } 
    
    private async functionPut<T = any>(functionName: string, data: any, paramsObj?: QueryParams, url?: string): Promise<T>  {
        url = url || environment.functionsUrl;

        let params = new HttpParams();
        
        if (paramsObj) {
            for (const key in paramsObj) {
                const value = paramsObj[key];

                params = params.append(key, value.toString());
            }
        }

        const idToken = await this.authService.user.getIdToken(false);

        if (!idToken) {
            throw new Error('ID token is null');
        }

        return this.http.put<T>(`${url}/apiData/v1/${functionName}`, data, {
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json',
            },
            params
        }).toPromise();
    }

    private async functionPost<T = any>(functionName: string, data?: any, paramsObj?: QueryParams, url?: string): Promise<T> {
        url = url || environment.functionsUrl;

        let params = new HttpParams();

        if (paramsObj) {
            for (const key in paramsObj) {
                const value = paramsObj[key];
                params = params.append(key, value.toString());
            }
        }

        const idToken = await this.authService.user.getIdToken(false);

        if (!idToken) {
            throw new Error('ID token is null');
        }

        return this.http.post<T>(`${url}/apiData/v1/${functionName}`, data, {
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json',
            },
            params
        }).toPromise();
    }

    private async functionDelete<T = any>(functionName: string, paramsObj?: QueryParams, url?: string): Promise<T>  {
        url = url || environment.functionsUrl;

        let params = new HttpParams();
        
        if (paramsObj) {
            for (const key in paramsObj) {
                const value = paramsObj[key];

                params = params.append(key, value.toString());
            }
        }

        const idToken = await this.authService.user.getIdToken(false);

        if (!idToken) {
            throw new Error('ID token is null');
        }

        return this.http.delete<T>(`${url}/apiData/v1/${functionName}`, {
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json',
            },
            params
        }).toPromise();
    }
}