import { Injectable } from '@angular/core';

declare var $: any;

@Injectable()
export class TablesDotNet {

  constructor(
  ) { }

  loadDataTableConfig(dataTableName: string) {
    setTimeout(() => {
      $('#' + dataTableName).DataTable({
        'pagingType': 'full_numbers',
        'bDestroy': true,
        'lengthMenu': [
          [5, 10, 25, -1],
          [5, 10, 25, 'All']
        ],
        responsive: true,
        language: {
          search: '_INPUT_',
          searchPlaceholder: 'Search records',
        }
      });
    }, 0);
  }

  destroyDataTableConfig(dataTableName: string) {
    $('#' + dataTableName).DataTable().destroy();
  }


}
