var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import Swal from 'sweetalert2';
import { CommunicationService } from 'app/services/communication/communication.service';
import { DataService } from 'app/services/dataService/data.service';
import { apiUrlEnum } from 'app/enums/api-endpoints.enums';
import { timeStampDataService } from "app/services/function/function.timestamp.service";
var PaymentCompletedComponent = /** @class */ (function () {
    function PaymentCompletedComponent(route, activatedRoute, http, communicationService, dataService, timestampService) {
        this.route = route;
        this.activatedRoute = activatedRoute;
        this.http = http;
        this.communicationService = communicationService;
        this.dataService = dataService;
        this.timestampService = timestampService;
    }
    PaymentCompletedComponent.prototype.ngOnInit = function () {
        JSON.parse(this.route.snapshot.paramMap.get('client'));
        this.activatedRoute.queryParams.subscribe(function (params) {
            localStorage.setItem('peachPaymentCheckoutId', params['id']);
        });
        Swal.fire({
            title: 'Processing Receipt',
            text: "Please Wait",
            icon: 'info',
            cancelButtonText: 'Ok',
            buttonsStyling: true,
            heightAuto: false
        });
        this.checkReceipt();
    };
    PaymentCompletedComponent.prototype.checkReceipt = function () {
        return __awaiter(this, void 0, void 0, function () {
            var proxyurl, path, httpOptions;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('Verifying purchase');
                        proxyurl = 'https://cors-anywhere.herokuapp.com/';
                        path = environment.paymentGateway.hostUrl + 'v1/checkouts/' +
                            localStorage.getItem('peachPaymentCheckoutId') +
                            '/payment?entityId=' +
                            environment.paymentGateway.entityId;
                        httpOptions = {
                            headers: new HttpHeaders({
                                'Authorization': 'Bearer ' + environment.paymentGateway.bearerAuthToken,
                            })
                        };
                        return [4 /*yield*/, this.http.get(proxyurl + path, httpOptions).toPromise().then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            this.peachResponseVerify = response;
                                            _a = this.peachResponseVerify.result.code;
                                            switch (_a) {
                                                case '000.000.000': return [3 /*break*/, 1];
                                                case '000.100.110': return [3 /*break*/, 3];
                                                case '000.100.111': return [3 /*break*/, 5];
                                                case '000.100.112': return [3 /*break*/, 7];
                                            }
                                            return [3 /*break*/, 9];
                                        case 1: return [4 /*yield*/, this.setSuccessfulTransaction()];
                                        case 2:
                                            _b.sent();
                                            this.communicationService.sendEmail('tech@response24.co.za', 'Subscription has been bought for user with email: ' +
                                                this.peachResponseVerify.customer['email'] +
                                                ' for client : ' +
                                                localStorage.getItem('peachPaymentUserClientName') +
                                                ' AMOUNT: R' + localStorage.getItem('peachPaymentUserMobilePrice'), 'R24 Transaction succeeded');
                                            return [3 /*break*/, 10];
                                        case 3: return [4 /*yield*/, this.setSuccessfulTransaction()];
                                        case 4:
                                            _b.sent();
                                            this.communicationService.sendEmail('tech@response24.co.za', 'Subscription has been bought for user with email: ' +
                                                this.peachResponseVerify.customer['email'] +
                                                ' for client : ' +
                                                localStorage.getItem('peachPaymentUserClientName'), 'Request successfully processed in Merchant in Integrator Test Mode');
                                            return [3 /*break*/, 10];
                                        case 5: return [4 /*yield*/, this.setSuccessfulTransaction()];
                                        case 6:
                                            _b.sent();
                                            this.communicationService.sendEmail('tech@response24.co.za', 'Subscription has been bought for user with email: ' +
                                                this.peachResponseVerify.customer['email'] +
                                                ' for client : ' +
                                                localStorage.getItem('peachPaymentUserClientName'), 'Request successfully processed in Merchant in Validator Test Mode');
                                            return [3 /*break*/, 10];
                                        case 7: return [4 /*yield*/, this.setSuccessfulTransaction()];
                                        case 8:
                                            _b.sent();
                                            this.communicationService.sendEmail('tech@response24.co.za', 'Subscription has been bought for user with email: ' +
                                                this.peachResponseVerify.customer['email'] +
                                                ' for client : ' +
                                                localStorage.getItem('peachPaymentUserClientName'), 'Request successfully processed in Merchant in Connector Test Mode');
                                            return [3 /*break*/, 10];
                                        case 9:
                                            this.errorModal(this.peachResponseVerify.result.description);
                                            return [3 /*break*/, 10];
                                        case 10: return [2 /*return*/];
                                    }
                                });
                            }); }).catch(function (error) {
                                console.log(error.message);
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentCompletedComponent.prototype.setSuccessfulTransaction = function () {
        return __awaiter(this, void 0, void 0, function () {
            var timestamp, subscriptionReciept, that;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('PAYMENT SUCCEED!');
                        return [4 /*yield*/, this.timestampService.getServerTimeStampData().then(function (data) {
                                timestamp = data;
                            }).catch(function (rejection) {
                                console.error(rejection);
                                Swal.fire({
                                    title: "Error Creating Timestamp for Transaction.",
                                    text: rejection,
                                    icon: "error",
                                    confirmButtonText: "Ok",
                                });
                                return;
                            })];
                    case 1:
                        _a.sent();
                        subscriptionReciept = {
                            userKey: localStorage.getItem('peachPaymentUserId'),
                            userFirstName: localStorage.getItem('peachPaymentUserFirstName'),
                            userLastName: localStorage.getItem('peachPaymentUserLastName'),
                            reciept: JSON.stringify(this.peachResponseVerify),
                            recieptTransationId: this.peachResponseVerify.id,
                            peachPaymentResponseId: localStorage.getItem('peachPaymentCheckoutId'),
                            peachPaymentTransactionId: localStorage.getItem('peachPaymentReceiptId'),
                            groupKey: localStorage.getItem('peachPaymentGroupKey'),
                            peachPaymentRegistrationId: this.peachResponseVerify.registrationId,
                            // peachPaymentEntityId: this.peachResponseVerify.id,
                            paymentGateway: 'Peach Payment',
                            productId: 'mobile.peach.payment',
                            productName: 'Monthly Premium Subscription (911 Response 24)',
                            dateSubscribed: timestamp,
                            paymentDate: timestamp,
                            // tslint:disable-next-line: radix
                            amount: parseInt(localStorage.getItem('peachPaymentUserMobilePrice')),
                            paymentType: 'INITIAL',
                            paymentStatus: 'PAID',
                            currency: 'ZAR',
                            gateWayUrl: environment.paymentGateway.hostUrl,
                            production: environment.production
                        };
                        console.log(subscriptionReciept);
                        that = this;
                        // Remove all promo codes claimed for this user.
                        // const userKey: UserKey = {
                        //   key: subscriptionReciept.userKey,
                        // }
                        // this.dataService.postToFunctions(apiUrlEnum.userAuth_v1.user_remove_promo_codes, userKey)
                        this.dataService.postToFunctions(apiUrlEnum.subscription_v1.start_subscription, subscriptionReciept)
                            .then(function (reqResponse) {
                            if (reqResponse.success) {
                                that.success();
                            }
                            else {
                                _this.dataService.teamsLog('web', 'criticalException', 'codeExecution', 'Failed to start subscription');
                            }
                        }).catch(function (error) {
                            console.error(error.message);
                            _this.dataService.teamsLog('web', 'criticalException', 'codeExecution', 'Failed to start subscription');
                            that.errorPurchase(error.message);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentCompletedComponent.prototype.success = function () {
        Swal.fire({
            title: 'Success!',
            text: "You have been successfully subscribed",
            icon: 'success',
            cancelButtonText: 'Ok',
            buttonsStyling: true,
            heightAuto: false
        });
    };
    PaymentCompletedComponent.prototype.errorPurchase = function (error) {
        Swal.fire({
            title: 'Error Occurred.',
            text: error,
            icon: 'error',
            confirmButtonText: 'Ok',
            cancelButtonText: 'Contact Support',
            showCancelButton: true,
            buttonsStyling: true,
            heightAuto: false
        }).then(function (result) {
            if (result.dismiss) {
                window.open('mailto:tech@response24.co.za?subject=In%20App%20Purchase%20Failure');
            }
        });
    };
    PaymentCompletedComponent.prototype.errorModal = function (reason) {
        Swal.fire({
            title: 'Error Occurred.',
            text: reason,
            icon: 'error',
            confirmButtonText: 'Ok',
            showCancelButton: true,
            buttonsStyling: true,
            heightAuto: false
        }).then(function (result) {
            if (result.dismiss) {
                window.open('mailto:tech@response24.co.za?subject=UserKey: ' +
                    localStorage.getItem('peachPaymentUserId') +
                    '. Payment Error: ' + reason);
            }
        });
    };
    return PaymentCompletedComponent;
}());
export { PaymentCompletedComponent };
