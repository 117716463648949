var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ElementRef, SecurityContext } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//import { AngularFireAuth } from '@angular/fire/auth'
//import { AuthService } from '../../../core/auth.service';
import { Globals } from 'app/globals';
//import { UserService } from 'app/services/user/user.service';
import { DateFormatting } from 'app/providers/date-formatting';
import { UploadService } from 'app/services/upload/upload.service';
import Swal from 'sweetalert2';
import { DataService } from 'app/services/dataService/data.service';
import { environment } from 'environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { timeStampDataService } from "app/services/function/function.timestamp.service";
var PromoCodeComponent = /** @class */ (function () {
    function PromoCodeComponent(sanitizer, route, element, 
    //_auth: AuthService, 
    //public userService: UserService, 
    uploadService, dataService, router, 
    //_afAuth: AngularFireAuth, 
    dateFormatting, g, timestampService) {
        this.sanitizer = sanitizer;
        this.route = route;
        this.element = element;
        this.uploadService = uploadService;
        this.dataService = dataService;
        this.router = router;
        this.dateFormatting = dateFormatting;
        this.g = g;
        this.timestampService = timestampService;
        this.branding = environment.branding;
        this.test = new Date();
        this.hasClaimed = false;
        this.sidebarVisible = false;
        this.bgstyle = this.sanitizer.sanitize(SecurityContext.STYLE, "url(\"" + this.branding.background + "\")");
        //this.auth = _auth;
        //this.afAuth = _afAuth;
    }
    PromoCodeComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var couponCode, _a, _b, _c, navbar, body, card_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        //   this.clientKey = JSON.parse(this.route.snapshot.paramMap.get('clientKey'))
                        this.g.loading = true;
                        couponCode = JSON.parse(this.route.snapshot.paramMap.get('CDe'));
                        _a = this;
                        _c = (_b = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData('groupCoupons', 'redeemCode', couponCode).toPromise()];
                    case 1:
                        _a.coupon = _c.apply(_b, [_d.sent()])[0];
                        if (!this.coupon || this.coupon.redeemCount >= this.coupon.redeemLimit) {
                            this.g.loading = false;
                            Swal.fire({
                                title: 'Promotion Has Ended',
                                text: 'Sorry but this Promotion is no longer Active',
                                icon: 'info',
                                confirmButtonText: 'Close',
                            });
                        }
                        else {
                            navbar = this.element.nativeElement;
                            this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
                            body = document.getElementsByTagName('body')[0];
                            body.classList.add('promo-code');
                            card_1 = document.getElementsByClassName('card')[0];
                            setTimeout(function () {
                                // after 1000 ms we add the class animated to the login/register card
                                card_1.classList.remove('card-hidden');
                            }, 500);
                            this.g.loading = false;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PromoCodeComponent.prototype.claim = function () {
        return __awaiter(this, void 0, void 0, function () {
            var timestamp, userClaimedGroupCoupons, clientGroups, _a, _b, clientGroup, userClaimedPromoGroup;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.g.loading = true;
                        return [4 /*yield*/, this.timestampService.getServerTimeStampData().then(function (data) {
                                timestamp = data;
                            }).catch(function (rejection) {
                                console.error(rejection);
                                Swal.fire({
                                    title: "Error Creating Timestamp for Claim.",
                                    text: rejection,
                                    icon: "error",
                                    confirmButtonText: "Ok",
                                });
                                return;
                            })];
                    case 1:
                        _c.sent();
                        userClaimedGroupCoupons = {
                            email: this.email,
                            groupCoupon: this.coupon,
                            promoCodeKey: this.coupon.redeemCode,
                            dateCreated: timestamp,
                        };
                        return [4 /*yield*/, this.dataService.setFireStoreData('userClaimedGroupCoupons', userClaimedGroupCoupons)
                            // >> set user default group link aswell.
                        ];
                    case 2:
                        _c.sent();
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData('userGroups', 'clientKey', this.coupon.clientKey).toPromise()];
                    case 3:
                        clientGroups = _b.apply(_a, [_c.sent()]);
                        clientGroup = clientGroups.find(function (x) { return x.autoGenerated; });
                        userClaimedPromoGroup = {
                            email: this.email,
                            groupInviteKey: clientGroup.invitationCode,
                            userGroup: clientGroup,
                            hasbeenActivated: false,
                            dateCreated: timestamp,
                        };
                        return [4 /*yield*/, this.dataService.setFireStoreData('userClaimedPromoGroup', userClaimedPromoGroup)
                            // >> set user default group link aswell.
                        ];
                    case 4:
                        _c.sent();
                        // >> set user default group link aswell.
                        if (!this.coupon.redeemCount) {
                            this.coupon.redeemCount = 1;
                        }
                        else {
                            this.coupon.redeemCount++;
                        }
                        if (!this.coupon.claimedUserEmails) {
                            this.coupon.claimedUserEmails = [];
                        }
                        this.coupon.claimedUserEmails.push(this.email);
                        this.dataService.setFireStoreData('groupCoupons', this.coupon);
                        this.hasClaimed = true;
                        this.g.loading = false;
                        Swal.fire({
                            title: this.coupon.name + ' Claimed!',
                            text: 'Please download and Register on the Response24 App, then Go to Subscriptions and the Promo Code will be automatically applied',
                            icon: 'success',
                            confirmButtonText: 'Close',
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return PromoCodeComponent;
}());
export { PromoCodeComponent };
