import { Injectable } from '@angular/core';


@Injectable()

export class ObjectTransformations {
    constructor(
    ) { }

    snapshotToArray(snapshot): any[] {
        const returnArr = [];

        snapshot.forEach(function (childSnapshot) {
            const item = childSnapshot.val();
            item.key = childSnapshot.key;

            returnArr.push(item);
        });
        return returnArr;
    };

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}
