import {
    ResponseServiceCategoryType,
    ResponseServiceIconType,
    ResponseServiceIconColorType,
    ResponseServiceActionStateType,
    ResponseServiceActionExternalProviderType,
} from 'app/types/responseService.types';

export let ResponseServiceCategoryEnum = {
    medical:
        { value: 'medical' as ResponseServiceCategoryType, viewValue: 'Medical' },
    armedResponse:
        { value: 'armedResponse' as ResponseServiceCategoryType, viewValue: 'Armed Response' },

    publicService:
        { value: 'publicService' as ResponseServiceCategoryType, viewValue: 'Public Service' },

    requestDriver:
        { value: 'requestDriver' as ResponseServiceCategoryType, viewValue: 'Request Driver' },

    general:
        { value: 'general' as ResponseServiceCategoryType, viewValue: 'General' },

}

export let IconEnum = {
    ambulance:
        { value: '/assets/icon/generic/ambulance.svg' as ResponseServiceIconType, viewValue: 'Ambulance' },
    shield:
        { value: '/assets/icon/generic/shield.svg' as ResponseServiceIconType, viewValue: 'Shield' },
    familyHands:
        { value: '/assets/icon/generic/friends_fam.svg' as ResponseServiceIconType, viewValue: 'Family with Hands' },
    centerMan:
        { value: '/assets/icon/generic/support.png' as ResponseServiceIconType, viewValue: 'Man in Center' },
    news:
        { value: '/assets/icon/generic/news.svg' as ResponseServiceIconType, viewValue: 'News' },
    sos:
        { value: '/assets/icon/generic/sos.svg' as ResponseServiceIconType, viewValue: 'SOS' },
}

export let IconColorEnum = {
    blue:
        { value: 'icon-blue' as ResponseServiceIconColorType, viewValue: 'Blue' },
    teal:
        { value: 'icon-teal' as ResponseServiceIconColorType, viewValue: 'Teal' },
    red:
        { value: 'icon-red' as ResponseServiceIconColorType, viewValue: 'Red' },
    orange:
        { value: 'icon-orange' as ResponseServiceIconColorType, viewValue: 'Orange' }

}

export let ResponseServiceActionStateEnum = {
    completed:
        { value: 'completed' as ResponseServiceActionStateType, viewValue: 'Completed' },
    terminated:
        { value: 'terminated' as ResponseServiceActionStateType, viewValue: 'Terminated' },
    open:
        { value: 'open' as ResponseServiceActionStateType, viewValue: 'Open' },
    pickedUp:
        { value: 'pickedUp' as ResponseServiceActionStateType, viewValue: 'Picked Up' },
    stale:
        { value: 'stale' as ResponseServiceActionStateType, viewValue: 'Stale' },
    phoned: { value: 'phoned' as ResponseServiceActionStateType, viewValue: 'Phoned' },
    awaitResponderPickup:
        { value: 'awaitResponderPickup' as ResponseServiceActionStateType, viewValue: 'Await Responder Pickup' },
    awaitNOCCompletionConfirmation:
        { value: 'awaitNOCCompletionConfirmation' as ResponseServiceActionStateType, viewValue: 'Await NOC Completion Confirmation' },
    awaitResponderCompletionConfirmation:
    {
        value: 'awaitResponderCompletionConfirmation' as ResponseServiceActionStateType,
        viewValue: 'Await Responder Completion Confirmation'
    }
}
export let ResponseServiceActionExternalProviderEnum = {
    er24:
    {
        value: 'er24' as ResponseServiceActionExternalProviderType,
        viewValue: 'ER 24', responseServiceCategoryType: 'medical' as ResponseServiceCategoryType
    }
}


