import { Inject, Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AuthService } from 'app/core/auth.service';

import { FIREBASE_REFERENCES } from 'app/core/firebase/firebase.module';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponseServiceAction } from 'app/interfaces/response-service.interface';

/**
 * Represents an event in the ResponseServiceActionsManagementService.
 */
export interface ResponseServiceActionsManagementEvent {
    type: 'added' | 'modified' | 'removed';
    data: ResponseServiceAction;
    id: string;
}

@Injectable({
    providedIn: 'root'
})
export class ResponseServiceActionsManagementService {
    /**
     * Creates an instance of ResponseServiceActionsManagementService.
     * @param authService - The authentication service.
     * @param firestore - The AngularFirestore instance.
     */
    constructor(private authService: AuthService, @Inject(FIREBASE_REFERENCES.ONE_FIRESTORE) private readonly firestore: AngularFirestore) {
        console.debug('ResponseServiceActionsManagementService: instantiated');
    }

    /**
     * Watches the response service actions management objects.
     * If no client key is provided, it uses the client key of the authenticated user.
     * @param clientKey - The client key (optional).
     * @returns An observable of ResponseServiceActionsManagementEvent[].
     */
    watchResponseServiceActionsManagementObjects(clientKey?: string): Observable<ResponseServiceActionsManagementEvent[]> {
        let collection: AngularFirestoreCollection<ResponseServiceAction>;

        const chosenClientKey = clientKey || this.authService.appUser.userDetail.clientKey;

        collection = this.firestore.collection<ResponseServiceAction>('responseServiceActions', ref => ref.where('clientKey', '==', chosenClientKey));

        return collection.snapshotChanges().pipe(
            map(events => {
                return events.map(event => {
                    return { 
                        type: event.type as 'added' | 'modified' | 'removed',
                        data: event.payload.doc.data(),
                        id: event.payload.doc.id
                    };
                })
            })
        );
    }

    /**
     * Watches a specific response service actions management object.
     * @param key - The key of the object to watch.
     * @returns An observable of ResponseServiceActionsManagementEvent.
     */
    watchResponseServiceActionsManagementObject(key: string): Observable<ResponseServiceActionsManagementEvent> {
        const docRef = this.firestore.collection('responseServiceActions').doc<ResponseServiceAction>(key);

        return docRef.snapshotChanges().pipe(
            map(event => {
                return { 
                    type: event.type as 'added' | 'modified' | 'removed',
                    data: event.payload.data(),
                    id: event.payload.id
                };
            })
        );
    }
}
