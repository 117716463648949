<app-loader></app-loader>

<div class="wrapper wrapper-full-page">
  <div class="page-header login-page header-filter" filter-color="black"
  [style.background-image]="bgstyle"  style="background-size: cover; background-position: top center;">
    <div class="container">
      <div class="col-lg-4 col-md-6 col-sm-6 ml-auto mr-auto">
        <form class="form" method="" action="">
          <div class="card card-login card-hidden">
            <div class="card-header card-header-primary text-center">
              <h4 class="card-title">Promotional Code</h4>
              <div class="social-line">
                <a>
                  {{coupon?.name}}
                </a>
              </div>
            </div>
            <div class="card-body">
              <div class="col-lg-12">
                <img src="{{branding.logo}}" class="img-small center-block" />
              </div>

              <span class="bmd-form-group"  *ngIf="!hasClaimed">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="material-icons">email</i>
                    </span>
                  </div>
                  <input type="email" class="form-control" [(ngModel)]="email" name="email" placeholder="Email...">
                </div>
              </span>


              <div class="card-footer justify-content-center" style="width: 100%;" *ngIf="hasClaimed">
                <a
                  href='https://play.google.com/store/apps/details?id=b2c.response24.co.za&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
                    alt='Get it on Google Play'
                    src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                    style="width: 100%;" /></a>
              </div>
              <div class="card-footer justify-content-center" style="width: 100%;" *ngIf="hasClaimed">
                <a href="https://apps.apple.com/za/app/911-response-24/id1449829438?mt=8"
                  style="display:inline-block;overflow:hidden;background:url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2019-01-20&kind=iossoftware&bubble=ios_apps) no-repeat;width:135px;height:40px;"></a>
              </div>


            </div>
            <div class="card-footer justify-content-center"  *ngIf="!hasClaimed">
              <a class="btn btn-primary btn-link btn-lg" (click)="claim()">Claim</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>