import { Inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'app/core/auth.service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Client } from 'app/interfaces/client.interface';
import { FIREBASE_REFERENCES } from 'app/core/firebase/firebase.module';

@Injectable({
    providedIn: 'root'
})
export class ClientManagementService {
    constructor(private authService: AuthService, @Inject(FIREBASE_REFERENCES.ONE_FIRESTORE) private readonly firestore: AngularFirestore) {
        console.debug('ClientManagementService: instantiated');
    }

    watchResourceManagementObjects(): Observable<Client[]> {
        const collection = this.firestore.collection<Client>('clients');

        return collection.snapshotChanges().pipe(
            map(events => {
                return events.map(event => {
                        return event.payload.doc.data() as Client;
                    });
            })
        );
    }

    watchResourceManagementObject(key: string): Observable<Client> {
        const docRef = this.firestore.collection('clients').doc<Client>(key);
    
        return docRef.snapshotChanges().pipe(
            map(event => {
                return event.payload.data() as Client;
            })
        );
    }

    // Fetch all clients
    async getAllResourceManagementObjects(): Promise<Client[]> {
        try {
            const clientsSnapshot = await this.firestore.collection<Client>('clients')
                .get()
                .toPromise();

            const clients: Client[] = clientsSnapshot.docs.map(doc => doc.data() as Client);

            return clients;
        }
        catch (error) {
            console.error('Error fetching all clients:', error);
            throw error;
        }
    }

    // Fetch specific client by its key
    async getResourceManagementObject(key: string): Promise<Client> {
        try {
            const client = await this.firestore.doc<Client>(`clients/${key}`)
                .valueChanges()
                .toPromise();

            return client;
        }
        catch (error) {
            console.error(`Error fetching client with key ${key}:`, error);
            throw error;
        }
    }
}