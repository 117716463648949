var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AngularFirestore } from "@angular/fire/firestore";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
import 'firebase/auth'; // for authentication
import 'firebase/storage'; // for storage
import 'firebase/database'; // for realtime database
import 'firebase/firestore'; // for cloud firestore
import 'firebase/messaging'; // for cloud messaging
import 'firebase/functions'; // for cloud functions
import { apiUrlEnum } from "app/enums/api-endpoints.enums";
import { FunctionDataService } from "app/services/function/function.data.service";
import { AuthService } from "app/core/auth.service";
import * as i0 from "@angular/core";
import * as i1 from "../../core/auth.service";
import * as i2 from "../function/function.data.service";
import * as i3 from "@angular/common/http";
var DataService = /** @class */ (function () {
    function DataService(authService, afs, plusDB, 
    //private afs: AngularFirestore, 
    functionDataService, http) {
        this.authService = authService;
        this.afs = afs;
        this.plusDB = plusDB;
        this.functionDataService = functionDataService;
        this.http = http;
    }
    DataService.prototype.getFirestoreData = function (collection, filterName, filterValue, whereFilterOp, limit) {
        if (filterName === void 0) { filterName = ""; }
        if (filterValue === void 0) { filterValue = ""; }
        if (whereFilterOp === void 0) { whereFilterOp = "=="; }
        if (limit === void 0) { limit = 10000; }
        if (filterName !== "") {
            return this.afs
                .collection(collection, function (ref) {
                return ref.where(filterName, whereFilterOp, filterValue).limit(limit);
            })
                .get();
        }
        else {
            return this.afs.collection(collection, function (ref) { return ref.limit(limit); }).get();
        }
    };
    DataService.prototype.queryFirestore = function (collection, filters, limit) {
        if (filters === void 0) { filters = []; }
        return __awaiter(this, void 0, void 0, function () {
            var query, _i, filters_1, filter, snapshot;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = this.afs.collection(collection).ref;
                        console.debug("Getting data from collection: " + collection);
                        // Add each filter to the query
                        for (_i = 0, filters_1 = filters; _i < filters_1.length; _i++) {
                            filter = filters_1[_i];
                            query = query.where(filter.filterName, filter.whereFilterOp, filter.filterValue);
                            console.debug("Applied filter - " + filter.filterName + " " + filter.whereFilterOp + " " + filter.filterValue);
                        }
                        // Limit the number of documents
                        if (limit) {
                            query = query.limit(limit);
                            console.debug("Limiting results to " + limit + " documents");
                        }
                        return [4 /*yield*/, query.get()];
                    case 1:
                        snapshot = _a.sent();
                        console.debug("Received " + snapshot.size + " documents");
                        return [2 /*return*/, snapshot];
                }
            });
        });
    };
    DataService.prototype.queryFirestorePaged = function (collection, filters, limit, startAfterDocument, startBeforeDocument, orderBy, direction) {
        if (filters === void 0) { filters = []; }
        if (limit === void 0) { limit = 5; }
        if (startAfterDocument === void 0) { startAfterDocument = null; }
        if (startBeforeDocument === void 0) { startBeforeDocument = null; }
        if (direction === void 0) { direction = 'asc'; }
        return __awaiter(this, void 0, void 0, function () {
            var query, _i, filters_2, filter, snapshot;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = this.afs.collection(collection).ref;
                        // Set query order direction
                        query = query.orderBy(orderBy, direction);
                        console.debug("Getting data from collection: " + collection);
                        // Add each filter to the query
                        for (_i = 0, filters_2 = filters; _i < filters_2.length; _i++) {
                            filter = filters_2[_i];
                            query = query.where(filter.filterName, filter.whereFilterOp, filter.filterValue);
                            console.debug("Applied filter - " + filter.filterName + " " + filter.whereFilterOp + " " + filter.filterValue);
                        }
                        // Add startAfter or startBefore to the query if applicable
                        if (startAfterDocument && direction === 'asc') {
                            query = query.startAfter(startAfterDocument);
                        }
                        else if (startBeforeDocument && direction === 'desc') {
                            query = query.startAfter(startBeforeDocument);
                        }
                        // Limit the number of documents
                        query = query.limit(limit);
                        console.debug("Limiting results to " + limit + " documents");
                        return [4 /*yield*/, query.get()];
                    case 1:
                        snapshot = _a.sent();
                        console.debug("Received " + snapshot.size + " documents");
                        // If direction is 'desc', reverse the order of the documents
                        if (direction === 'desc') {
                            snapshot.docs.reverse();
                        }
                        return [2 /*return*/, snapshot];
                }
            });
        });
    };
    DataService.prototype.getFirestoreDataFromFunctions = function (userKey, collection, filterName, filterValue, whereFilterOp, limit, orderBy, orderbyDirection, startAfter, retryCount) {
        var _this = this;
        if (filterName === void 0) { filterName = ""; }
        if (filterValue === void 0) { filterValue = ""; }
        if (whereFilterOp === void 0) { whereFilterOp = "=="; }
        if (limit === void 0) { limit = -1; }
        if (orderBy === void 0) { orderBy = ""; }
        if (orderbyDirection === void 0) { orderbyDirection = "asc"; }
        if (startAfter === void 0) { startAfter = -1; }
        if (retryCount === void 0) { retryCount = 1; }
        var getFireStoreDataRequest = {
            collection: collection,
            filterName: filterName,
            filterValue: filterValue,
            whereFilterOp: whereFilterOp,
            limit: limit,
            orderBy: orderBy,
            orderbyDirection: orderbyDirection,
            startAfter: startAfter,
            apiKey: environment.apiKey,
            userKey: userKey,
        };
        console.log("-> Getting from Functions");
        // Commented out because its logging user uuid's
        //console.log(getFireStoreDataRequest);
        return this.postToFunctions(apiUrlEnum.data_v1.get_fire_store_data, getFireStoreDataRequest)
            .then(function (reqResponse) {
            if (reqResponse.success) {
                return reqResponse.data;
            }
            else {
                console.error(reqResponse);
                if (retryCount <= 1) {
                    return _this.getFirestoreDataFromFunctions(userKey, collection, filterName, filterValue, whereFilterOp, limit, orderBy, orderbyDirection, startAfter, 2);
                }
                else {
                    throw reqResponse.error;
                }
            }
        })
            .catch(function (exception) {
            console.error(exception.message);
            // alert('Failed to fetch data, please reload page.')
            //   throw (exception)
        });
    };
    DataService.prototype.getFirestoreDataFromFunctionsStartAt = function (userKey, collection, startAt, orderBy, limit) {
        if (startAt === void 0) { startAt = 0; }
        if (orderBy === void 0) { orderBy = ""; }
        if (limit === void 0) { limit = -1; }
        var getFireStoreDataRequest = {
            collection: collection,
            limit: limit,
            orderBy: orderBy,
            startAt: startAt,
            userKey: userKey,
        };
        console.log("-> Getting from Functions");
        console.log(getFireStoreDataRequest);
        return this.postToFunctions(apiUrlEnum.data_v1.get_fire_store_data_start_at, getFireStoreDataRequest)
            .then(function (reqResponse) {
            if (reqResponse.success) {
                return reqResponse.data;
            }
            else {
                console.error(reqResponse);
            }
        })
            .catch(function (exception) {
            console.error(exception.message);
            //    alert('Failed to fetch data, please reload page.')
            //  throw (exception)
        });
    };
    DataService.prototype.getFirestoreDataSubscription = function (collection, filterName, filterValue, whereFilterOp, limit, orderBy, orderbyDirection) {
        if (filterName === void 0) { filterName = ""; }
        if (filterValue === void 0) { filterValue = ""; }
        if (whereFilterOp === void 0) { whereFilterOp = "=="; }
        if (limit === void 0) { limit = -1; }
        if (orderBy === void 0) { orderBy = ""; }
        if (orderbyDirection === void 0) { orderbyDirection = "desc"; }
        if (filterName !== "") {
            if (limit === -1) {
                return this.afs.collection(collection, function (ref) {
                    return ref.where(filterName, whereFilterOp, filterValue);
                });
            }
            else {
                return this.afs.collection(collection, function (ref) {
                    return ref.where(filterName, whereFilterOp, filterValue).limit(limit);
                });
            }
        }
        else if (orderBy !== "") {
            if (limit === -1) {
                return this.afs.collection(collection, function (ref) {
                    return ref.orderBy(orderBy, orderbyDirection);
                });
            }
            else {
                return this.afs.collection(collection, function (ref) {
                    return ref.orderBy(orderBy, orderbyDirection).limit(limit);
                });
            }
        }
        else {
            if (limit === -1) {
                return this.afs.collection(collection, function (ref) { return ref; });
            }
            else {
                return this.afs.collection(collection, function (ref) { return ref.limit(limit); });
            }
        }
    };
    // getFirestoreDataSubscriptionFromFunctions(
    //   userKey: string,
    //   collection: FirestoreCollection,
    //   filterName: string = '',
    //   filterValue: any = '',
    //   whereFilterOp: firebase.firestore.WhereFilterOp = '==',
    //   limit: number = -1
    // ): Promise<Query> {
    //   const getFireStoreDataRequest: GetFireStoreDataRequest = {
    //     collection,
    //     filterName,
    //     filterValue,
    //     whereFilterOp,
    //     limit,
    //     apiKey: environment.apiKey,
    //     userKey: userKey
    //   };
    //   return this.postToFunctions(apiUrlEnum.data_v1.get_fire_store_data, getFireStoreDataRequest)
    //     .then(reqResponse => {
    //       if (reqResponse.success) {
    //         return reqResponse.data;
    //       } else {
    //         console.error(reqResponse.message);
    //         alert(reqResponse.message);
    //       }
    //     }).catch(exception => {
    //       this.teamsLog('mobile', 'exception', 'databaseCallSet', collection);
    //       console.log(exception.message);
    //     });
    // }
    DataService.prototype.setFireStoreDataFromFunctions = function (userKey, collection, data) {
        var _this = this;
        var setFireStoreDataRequest = {
            collection: collection,
            apiKey: environment.apiKey,
            userKey: userKey,
            data: data,
        };
        return this.postToFunctions(apiUrlEnum.data_v1.set_fire_store_data, setFireStoreDataRequest)
            .then(function (reqResponse) {
            if (reqResponse.success) {
                return reqResponse.data;
            }
            else {
                console.error(reqResponse);
            }
        })
            .catch(function (exception) {
            _this.teamsLog("mobile", "exception", "databaseCallSet", collection);
            console.log(exception.message);
        });
    };
    DataService.prototype.updateFireStoreDataFromFunctions = function (userKey, collection, data) {
        var _this = this;
        var setFireStoreDataRequest = {
            collection: collection,
            apiKey: environment.apiKey,
            userKey: userKey,
            data: data,
        };
        return this.postToFunctions(apiUrlEnum.data_v1.update_fire_store_data, setFireStoreDataRequest)
            .then(function (reqResponse) {
            if (reqResponse.success) {
                return reqResponse.data.key;
            }
            else {
                console.error(reqResponse);
            }
        })
            .catch(function (exception) {
            _this.teamsLog("mobile", "exception", "databaseCallSet", collection);
            console.log(exception.message);
        });
    };
    DataService.prototype.deleteFireStoreDataFromFunctions = function (userKey, collection, key) {
        var _this = this;
        var deleteFireStoreDataRequest = {
            collection: collection,
            key: key,
            apiKey: environment.apiKey,
            userKey: userKey,
        };
        return this.postToFunctions(apiUrlEnum.data_v1.delete_fire_store_data, deleteFireStoreDataRequest)
            .then(function (reqResponse) {
            if (reqResponse.success) {
                return reqResponse.data.key;
            }
            else {
                console.error(reqResponse.message);
            }
        })
            .catch(function (exception) {
            _this.teamsLog("mobile", "exception", "databaseCallSet", collection);
            console.log(exception.message);
        });
    };
    DataService.prototype.deleteFireStoreData = function (collection, key) {
        return this.afs.collection(collection).doc(key).delete();
    };
    DataService.prototype.updateFireStoreData = function (collection, data) {
        var _this = this;
        return this.afs
            .collection(collection)
            .doc(data.key)
            .update(data)
            .then(function () {
            //  this.sendPushUpdateToFunctions(data);
            return data.key;
        })
            .catch(function (exception) {
            _this.teamsLog("mobile", "exception", "databaseCallSet", collection);
            console.log(exception.message);
        });
    };
    // Not used
    DataService.prototype.sendPushUpdateToFunctions = function (resposeServiceAction) {
        var responseServiceActionRequest = {
            responseServiceActionKey: resposeServiceAction.key,
            responseServiceKey: resposeServiceAction.responseServiceKey,
        };
        this.postToFunctions(apiUrlEnum.responseService_v1.responsePushUpdate, responseServiceActionRequest)
            .then(function (reqResponse) {
            if (reqResponse.success) {
                return reqResponse.data.key;
            }
            else {
                console.error(reqResponse.message);
            }
        })
            .catch(function (exception) {
            console.log(exception.message);
        });
    };
    DataService.prototype.setFireStoreData = function (collection, data, merge) {
        var _this = this;
        if (merge === void 0) { merge = false; }
        if (!data.key) {
            data.key = this.afs.createId();
        }
        return this.afs
            .collection(collection)
            .doc(data.key)
            .set(data, { merge: merge })
            .then(function () {
            return data.key;
        })
            .catch(function (exception) {
            _this.teamsLog("mobile", "exception", "databaseCallSet", collection);
            console.log(exception.message);
        });
    };
    DataService.prototype.getFireStoreKey = function () {
        return this.afs.createId();
    };
    DataService.prototype.querySnapshotToObject = function (snapshot) {
        var returnObj = [];
        snapshot.forEach(function (queryDocSnap) {
            returnObj.push(queryDocSnap.data());
        });
        return returnObj;
    };
    DataService.prototype.postToFunctions = function (apiUrlEnm, body, sendToTeams, functionURL) {
        if (sendToTeams === void 0) { sendToTeams = true; }
        if (functionURL === void 0) { functionURL = environment.functionsUrl; }
        return __awaiter(this, void 0, void 0, function () {
            var response, idToken, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        response = {
                            success: null,
                            message: null,
                            error: null,
                            data: null,
                            dataExtended: null,
                            dataExtended2: null,
                            refId: null,
                            responseCode: null,
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.authService.user.getIdToken(false)];
                    case 2:
                        idToken = _a.sent();
                        if (!idToken) {
                            throw new Error('ID token is null');
                        }
                        return [4 /*yield*/, this.http.post(functionURL + apiUrlEnm, body, {
                                headers: {
                                    'Authorization': 'Bearer ' + idToken,
                                    'Content-Type': 'application/json',
                                    'Project-Id': environment.firebase['PROJECT-ONE'].projectId
                                }
                            }).toPromise()];
                    case 3:
                        response = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        if (sendToTeams) {
                            this.teamsLog("mobile", "exception", "ApiFunctionsCall", functionURL + apiUrlEnm);
                        }
                        console.error("Error:", error_1);
                        response = {
                            success: false,
                            message: error_1.message,
                            error: error_1,
                        };
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/, response];
                }
            });
        });
    };
    DataService.prototype.postToExternalApiOld = function (apiUrlEnm, body, sendToTeams) {
        if (sendToTeams === void 0) { sendToTeams = true; }
        return __awaiter(this, void 0, void 0, function () {
            var httpOptions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        httpOptions = {
                            headers: new HttpHeaders({
                                "Content-Type": "application/json",
                                Authorization: "TgPPq4sHhbyFl3yMkTeVHw==",
                            }),
                        };
                        return [4 /*yield*/, this.http
                                .post(apiUrlEnm, body, httpOptions)
                                .toPromise()
                                .then(function (data) {
                                var test = data;
                                return data;
                            })
                                .catch(function (exception) {
                                console.error(exception);
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DataService.prototype.postToExternalApi = function (url, body, httpOptions) {
        return __awaiter(this, void 0, void 0, function () {
            var postObservable;
            var _this = this;
            return __generator(this, function (_a) {
                if (httpOptions) {
                    postObservable = this.http.post(url, body, httpOptions);
                }
                else {
                    postObservable = this.http.post(url, body);
                }
                return [2 /*return*/, postObservable
                        .toPromise()
                        .then(function (data) {
                        _this.teamsLog("mobile", "info", "ApiExternalCall", url);
                        var response = {
                            success: true,
                            data: data,
                            message: "Fetched",
                        };
                        return response;
                    }, function (exception) {
                        console.error("exception ", exception.message);
                        _this.teamsLog("mobile", "exception", "ApiExternalCall", url);
                        var response = {
                            success: false,
                            message: exception.message,
                            error: exception,
                        };
                        return response;
                    })
                        .catch(function (exception) {
                        _this.teamsLog("mobile", "exception", "ApiExternalCall", url);
                        console.error("exception ", exception);
                        var response = {
                            success: false,
                            message: exception.message,
                            error: exception,
                        };
                        return response;
                    })];
            });
        });
    };
    DataService.prototype.teamsLog = function (platform, type, action, detail) {
        var log = {
            userDisplayName: this.loggedInUserDisplayName,
            userKey: this.loggedInUserKey,
            version: environment.version,
        };
        if (!log.userDisplayName) {
            log.userDisplayName = "Un Registered User";
        }
        if (!log.userKey) {
            log.userKey = "NA";
        }
        var teamsRequest = {
            preText: platform + " - " + type + " ( " + action + " ) ",
            title: "Initator : *" + log.userDisplayName + "* (" + log.userKey + ")",
            text: "Action Initated Detail : " + detail + ", on Version : " + log.version,
            teamsGroupUrl: environment.teams.systemLogsUrl,
        };
        this.postToFunctions(apiUrlEnum.communication_v1.teams_send, teamsRequest, false);
    };
    DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject("project-one-firestore"), i0.ɵɵinject("project-two-firestore"), i0.ɵɵinject(i2.FunctionDataService), i0.ɵɵinject(i3.HttpClient)); }, token: DataService, providedIn: "root" });
    return DataService;
}());
export { DataService };
