import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResponseAction } from './user-management.component';

@Component({
    selector: 'user-management-dialog.component',
    templateUrl: './user-management-dialog.component.html',
    styleUrls: [
        './user-management-dialog.component.css'
    ]
})
export class UserManagementDialogComponent {
    dialogTitle: string;
    selectedKey: string;

    constructor(public dialogRef: MatDialogRef<UserManagementDialogComponent>, @Inject(MAT_DIALOG_DATA) public data?: ResponseAction[]) {
        this.dialogTitle = 'Response Selector';

        this.selectedKey = data && data.length > 0 ? data[0].key : '';
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        this.dialogRef.close(this.selectedKey);
    }
}