import { MatSelectionList } from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';
var LinkPassengerComponent = /** @class */ (function () {
    function LinkPassengerComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.allPassengers = [];
        this.linkedPassengers = [];
        if (data.existingPassengerKeys && data.existingPassengerKeys.length > 0) {
            this.linkedPassengers = data.passengers.filter(function (p) { return data.existingPassengerKeys.includes(p.key); });
            this.allPassengers = data.passengers.filter(function (p) { return !data.existingPassengerKeys.includes(p.key); });
        }
        else {
            this.allPassengers = data.passengers.slice();
        }
    }
    LinkPassengerComponent.prototype.addAll = function () {
        var _a;
        (_a = this.linkedPassengers).push.apply(_a, this.allPassengers);
        this.allPassengers = [];
    };
    LinkPassengerComponent.prototype.addSelected = function () {
        var _a;
        var _this = this;
        (_a = this.linkedPassengers).push.apply(_a, this.allPassengersList.selectedOptions.selected.map(function (option) { return option.value; }));
        this.allPassengers = this.allPassengers.filter(function (p) { return !_this.linkedPassengers.includes(p); });
    };
    LinkPassengerComponent.prototype.removeSelected = function () {
        var _a;
        var _this = this;
        (_a = this.allPassengers).push.apply(_a, this.linkedPassengersList.selectedOptions.selected.map(function (option) { return option.value; }));
        this.linkedPassengers = this.linkedPassengers.filter(function (p) { return !_this.allPassengers.includes(p); });
    };
    LinkPassengerComponent.prototype.removeAll = function () {
        var _a;
        (_a = this.allPassengers).push.apply(_a, this.linkedPassengers);
        this.linkedPassengers = [];
    };
    LinkPassengerComponent.prototype.onCancel = function () {
        this.dialogRef.close();
    };
    LinkPassengerComponent.prototype.onSubmit = function () {
        var linkedKeys = this.linkedPassengers.map(function (p) { return p.key; });
        this.dialogRef.close(linkedKeys);
    };
    return LinkPassengerComponent;
}());
export { LinkPassengerComponent };
