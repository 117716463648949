import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'app/core/auth.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../core/auth.service";
var ResponseServiceActionsManagementService = /** @class */ (function () {
    /**
     * Creates an instance of ResponseServiceActionsManagementService.
     * @param authService - The authentication service.
     * @param firestore - The AngularFirestore instance.
     */
    function ResponseServiceActionsManagementService(authService, firestore) {
        this.authService = authService;
        this.firestore = firestore;
        console.debug('ResponseServiceActionsManagementService: instantiated');
    }
    /**
     * Watches the response service actions management objects.
     * If no client key is provided, it uses the client key of the authenticated user.
     * @param clientKey - The client key (optional).
     * @returns An observable of ResponseServiceActionsManagementEvent[].
     */
    ResponseServiceActionsManagementService.prototype.watchResponseServiceActionsManagementObjects = function (clientKey) {
        var collection;
        var chosenClientKey = clientKey || this.authService.appUser.userDetail.clientKey;
        collection = this.firestore.collection('responseServiceActions', function (ref) { return ref.where('clientKey', '==', chosenClientKey); });
        return collection.snapshotChanges().pipe(map(function (events) {
            return events.map(function (event) {
                return {
                    type: event.type,
                    data: event.payload.doc.data(),
                    id: event.payload.doc.id
                };
            });
        }));
    };
    /**
     * Watches a specific response service actions management object.
     * @param key - The key of the object to watch.
     * @returns An observable of ResponseServiceActionsManagementEvent.
     */
    ResponseServiceActionsManagementService.prototype.watchResponseServiceActionsManagementObject = function (key) {
        var docRef = this.firestore.collection('responseServiceActions').doc(key);
        return docRef.snapshotChanges().pipe(map(function (event) {
            return {
                type: event.type,
                data: event.payload.data(),
                id: event.payload.id
            };
        }));
    };
    ResponseServiceActionsManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResponseServiceActionsManagementService_Factory() { return new ResponseServiceActionsManagementService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject("project-one-firestore")); }, token: ResponseServiceActionsManagementService, providedIn: "root" });
    return ResponseServiceActionsManagementService;
}());
export { ResponseServiceActionsManagementService };
