import { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JourneyManagementObject } from 'app/interfaces/journey-management/journey-management-object.interface';

@Component({
    selector: 'admin-dashboard-journey-overview-dialog',
    templateUrl: './admin-dashboard-journey-overview-dialog.component.html',
    styleUrls: [
        './admin-dashboard-journey-overview-dialog.component.css'
    ]
})
export class AdminDashboardJourneyOverviewDialogComponent implements OnInit {

    constructor(private dialogRef: MatDialogRef<AdminDashboardJourneyOverviewDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { journeyManagementObject: JourneyManagementObject }) {

    }

    ngOnInit() {

    }

    getPassengerNames(keys: string[]): string[] {
        if (!keys || keys.length === 0) {
            return [];
        }
    
        const names: string[] = [];
    
        for (const key of keys) {
            const passenger = this.data.journeyManagementObject.passengerInformation.find(p => p.key === key);
            if (passenger) {
                names.push(passenger.name);
            }
        }
    
        return names;
    }

    onClose(): void {
        this.dialogRef.close();
    }
}