import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { ResponseServiceAction } from 'app/interfaces/response-service.interface';
import { HttpOption } from "app/interfaces/global.interface";

import { DataService } from '../dataService/data.service';

@Injectable()
export class CustomClientService {

    private readonly headers: HttpHeaders;

    // Constructor for CustomClientService class
    constructor(private dataService: DataService) {
        // Setting up HTTP headers for requests
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'TgPPq4sHhbyFl3yMkTeVHw=='
        });
    }

    // Function to handle the status change of a responseServiceAction
    async stratPayResponseServiceStatusChange(responseServiceAction: ResponseServiceAction) {
        // Creating HTTP options object for API requests
        const httpOptions: HttpOption = {
            headers: this.headers
        };

        console.debug('CustomClientService: stratPayResponseServiceStatusChange - start');

        // Switch based on responseServiceKey
        switch (responseServiceAction.responseServiceKey) {
            case 'Dm42fceZJNPBUTeZcGvB':
                {
                    // Perform API call
                    await this.dataService.postToExternalApi('https://uatmiapi.mivoice.app/api/emergency/r24/update', responseServiceAction, httpOptions).then(reqResponse => {
                        console.debug('CustomClientService: API Response - ', reqResponse);
                    }).catch(exception => {
                        console.error('CustomClientService: Error on API call - ', exception);
                    });

                    break;
                }
            case 'iriTSRdqtQ5KSQ0Yska1':
            case 'g9ApCSkYUqKBjvPXkP0q':
                {
                    // Perform API call
                    await this.dataService.postToExternalApi('https://miapi.mivoice.app/api/emergency/r24/update', responseServiceAction, httpOptions).then(reqResponse => {
                        console.debug('CustomClientService: API Response - ', reqResponse);
                    }).catch(exception => {
                        console.error('CustomClientService: Error on API call - ', exception);
                    });

                    break;
                }
            default:
                // Handle invalid responseServiceKey
                console.debug('CustomClientService: Invalid responseServiceKey');
        }

        console.debug('CustomClientService: stratPayResponseServiceStatusChange - end');
    }

    /* async stratPayResponseServiceStatusChange(responseServiceAction?: ResponseServiceAction) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'TgPPq4sHhbyFl3yMkTeVHw=='
          }),
        };
    
        if (responseServiceAction.responseServiceKey == 'Dm42fceZJNPBUTeZcGvB') {
          this.dataService.postToExternalApi('https://uatmiapi.mivoice.app/api/emergency/r24/update', responseServiceAction, httpOptions
          ).then(reqResponse => {
            console.log(reqResponse)
          }).catch(exception => {
            console.error(exception)
          })
        } else if (responseServiceAction.responseServiceKey == 'iriTSRdqtQ5KSQ0Yska1' || responseServiceAction.responseServiceKey == 'g9ApCSkYUqKBjvPXkP0q') {
          this.dataService.postToExternalApi('https://miapi.mivoice.app/api/emergency/r24/update', responseServiceAction, httpOptions
          ).then(reqResponse => {
            console.log(reqResponse)
          }).catch(exception => {
            console.error(exception)
          })
        }
      }   */
}
