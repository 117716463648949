import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-image-modal",
  templateUrl: "./image-modal.component.html",
  styleUrls: ["./image-modal.component.scss"],
})
export class ImageModalComponent implements OnInit {
  imageUrl: string;
  loading: boolean = true;
  showFallback: boolean = false;

  onImageLoad(): void {
    this.loading = false;
    this.showFallback = false;
  }

  onImageError(): void {
    this.loading = false;
    this.showFallback = true;
  }

  constructor(@Inject(MAT_DIALOG_DATA) data: { imageUrl: string }) {
    this.imageUrl = data.imageUrl;
  }

  ngOnInit() {}
}
