import { Component, Inject, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PassengerInformation } from 'app/interfaces/journey-management/journey-management-object.interface';

@Component({
    selector: 'app-link-passenger-dialog',
    templateUrl: './link-passenger-dialog.component.html',
    styleUrls: [
        './link-passenger-dialog.component.css',
    ],
})
export class LinkPassengerComponent {
    @ViewChild('allPassengersList', { static: false }) allPassengersList: MatSelectionList;
    @ViewChild('linkedPassengersList', { static: false }) linkedPassengersList: MatSelectionList;
    
    allPassengers: PassengerInformation[] = [];
    linkedPassengers: PassengerInformation[] = [];

    constructor(private dialogRef: MatDialogRef<LinkPassengerComponent>, @Inject(MAT_DIALOG_DATA) public data: { linkCollectionName: string, passengers: PassengerInformation[], existingPassengerKeys?: string[] }) {
        if (data.existingPassengerKeys && data.existingPassengerKeys.length > 0) {
            this.linkedPassengers = data.passengers.filter(p => data.existingPassengerKeys.includes(p.key));
            this.allPassengers = data.passengers.filter(p => !data.existingPassengerKeys.includes(p.key));
          } 
          else {
            this.allPassengers = [...data.passengers];
          }
    }

    addAll(): void {
        this.linkedPassengers.push(...this.allPassengers);
        this.allPassengers = [];
    }

    addSelected(): void {
        this.linkedPassengers.push(...this.allPassengersList.selectedOptions.selected.map(option => option.value));
        this.allPassengers = this.allPassengers.filter(p => !this.linkedPassengers.includes(p));
    }

    removeSelected(): void {
        this.allPassengers.push(...this.linkedPassengersList.selectedOptions.selected.map(option => option.value));
        this.linkedPassengers = this.linkedPassengers.filter(p => !this.allPassengers.includes(p));
    }

    removeAll(): void {
        this.allPassengers.push(...this.linkedPassengers);
        this.linkedPassengers = [];
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        const linkedKeys = this.linkedPassengers.map(p => p.key);
        this.dialogRef.close(linkedKeys);
    }
}