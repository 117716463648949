import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: [
        './message-dialog.component.css',
    ],
})
export class MessageDialogComponent {
    userMessage: string = '';

    constructor(private dialogRef: MatDialogRef<MessageDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { title: string, question?: string, minLength?: number }) { }

    get isSubmitEnabled(): boolean {
        return (!this.data.minLength || this.userMessage.length >= this.data.minLength);
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        if (this.data.minLength) {
            this.dialogRef.close(this.userMessage);
        }
        else {
            this.dialogRef.close(`User Message: ${this.userMessage}`);
        }
    }
}