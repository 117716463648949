<h2 mat-dialog-title>Service Planner</h2>

<mat-dialog-content>
  <!-- <mat-card>
    <mat-card-content>
      <h3>Service Details</h3>
      <section></section>
    </mat-card-content>
  </mat-card> -->

  <div class="row">
    <div class="col-sm-4">
      <table>
        <tr>
          <td><strong>Date:</strong></td>
          <td>{{ data.serviceInformation.date | date:'dd MMM yyyy hh:mm a' }}</td>
        </tr>
        <tr>
          <td><strong>Details:</strong></td>
          <td>{{ data.serviceInformation.detailsOfService }}</td>
        </tr>
        <tr>
          <td><strong>Requested Resources:</strong></td>
          <td>
            <span *ngFor="let resource of data.serviceInformation.requiredResources; let i = index">
              {{ resource }}{{ i < data.serviceInformation.requiredResources.length - 1 ? ',' : '' }}
            </span>
          </td>
        </tr>
      </table>
    </div>
    <div class="col-sm-8">
      <table>
        <tr>
          <td><strong>Pick Up Address:</strong></td>
          <td>{{ data.serviceInformation.startAddress.formattedAddress }}</td>
        </tr>
        <tr>
          <td><strong>Drop Off Address:</strong></td>
          <td>{{ data.serviceInformation.endAddress.formattedAddress }}</td>
        </tr>
        <tr>
          <td><strong>Aiport Protocol Required:</strong></td>
          <td>{{ data.serviceInformation.isProtocolRequired }}</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <strong>Special Requests:</strong> {{ data.serviceInformation.specialRequests }}
    </div>
  </div>
  <!-- <mat-divider></mat-divider> -->
  <mat-horizontal-stepper [linear]="isLinear" #stepper (selectionChange)="onStepChange($event)">
    <mat-step>
      <ng-template matStepLabel>Select Resources</ng-template>
      <mat-list>
        <mat-list-item *ngFor="let resource of data.availableResources">
          <mat-checkbox [checked]="isResourceSelected(resource)" (change)="toggleResourceSelection(resource)">{{ resource.firstName }} {{ resource.lastName }} <strong>{{ getResourceSchedule(resource.key) }}</strong></mat-checkbox>
        </mat-list-item>
      </mat-list>
      <button mat-button matStepperNext>Next</button>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Assign Roles to Resources</ng-template>
      <div *ngFor="let resource of selectedResources; let i = index">
        <div class="row">
          <div class="col-sm-2">
            <span>{{ resource.firstName }} {{ resource.lastName }}</span>
          </div>
          <div class="col-sm-10">
            <mat-radio-group [(ngModel)]="resourceRoleSelections[resource.key]" (change)="assignResourceRole(resource, $event.value)">
              <mat-radio-button *ngFor="let role of resourceRoles" [value]="role">{{ role }}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <hr *ngIf="i < selectedResources.length - 1">
      </div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Select Assets</ng-template>
      <mat-list>
        <mat-list-item *ngFor="let asset of data.availableAssets">
          <mat-checkbox [checked]="isAssetSelected(asset)" (change)="toggleAssetSelection(asset)">{{ asset.make }} {{ asset.model }} {{ asset.registrationNumber }} <strong>{{ getAssetSchedule(asset.key) }}</strong></mat-checkbox>
        </mat-list-item>
      </mat-list>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Assign Roles to Assets</ng-template>
      <div *ngFor="let asset of selectedAssets; let i = index">
        <div class="row">
          <div class="col-sm-2">
            <span>{{ asset.make }} {{ asset.model }} {{ asset.registrationNumber }}</span>
          </div>
          <div class="col-sm-10">
            <mat-radio-group [(ngModel)]="assetRoleSelections[asset.key]" (change)="assignAssetRole(asset, $event.value)">
              <mat-radio-button *ngFor="let role of assetRoles" [value]="role">{{ role }}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <hr *ngIf="i < selectedAssets.length - 1">
      </div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Assign Resources to Assets</ng-template>
      <div *ngFor="let asset of selectedAssets; let i = index">
        <div class="row">
          <div class="col-sm-12">
            <h4>{{ asset.make }} {{ asset.model }} {{ asset.registrationNumber }} - {{ getAssetRole(asset.key) }} {{ isMaxCountReached(asset) === true ? 'Maximum Capacity Assigned' : '' }}</h4>
            <mat-list>
              <mat-list-item *ngFor="let resource of selectedResources">
                <mat-checkbox #checkbox [disabled]="!checkbox.checked && isMaxCountReached(asset)" [checked]="isResourceAssignedToAsset(asset, resource)" (change)="toggleAssetResourceAssignment(asset, resource)">{{ resource.firstName }} {{ resource.lastName }} - {{ getResourceRole(resource.key) }}</mat-checkbox>
              </mat-list-item>
            </mat-list>
            <hr *ngIf="i < selectedAssets.length - 1">
          </div>
        </div>
      </div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Additional Settings</ng-template>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Enter Additional Notes</mat-label>
        <input matInput [(ngModel)]="additionalNotes" placeholder="Type notes here...">
      </mat-form-field>
      
      <h4>Auto Dispatch Resources</h4>
        <mat-checkbox [checked]="(this.autoDispatch)" (change)="toggleAutoDispatch()"> Dispatch Resources automatically before scheduled time.
        </mat-checkbox>
        <mat-radio-group *ngIf="(this.autoDispatch)" aria-label="Select an option" [value]="this.autoDispatchTime ? this.autoDispatchTime.toString(): '5'" (change)="setAutoDispatchTime($event)">
          <br><br>
          <mat-radio-button value=5>5 min</mat-radio-button>
          <mat-radio-button value=10>10 min</mat-radio-button>
          <mat-radio-button value=15>15 min</mat-radio-button>
          <mat-radio-button value=20>20 min</mat-radio-button>
          <mat-radio-button value=25>25 min</mat-radio-button>
          <mat-radio-button value=30>30 min</mat-radio-button>
          <mat-radio-button value=35>35 min</mat-radio-button>
          <mat-radio-button value=40>40 min</mat-radio-button>
          <mat-radio-button value=45>45 min</mat-radio-button>
          <mat-radio-button value=50>50 min</mat-radio-button>
        </mat-radio-group>
      <br><br>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Review</ng-template>
      <h3>Selected Resources</h3>
      <ul>
        <li *ngFor="let resource of selectedResources">{{ resource.firstName }} {{ resource.lastName }} - {{ getResourceRole(resource.key) }}</li>
      </ul>
      <h3>Selected Assets</h3>
      <ul>
        <li *ngFor="let asset of selectedAssets">{{ asset.make }} {{ asset.model }} {{ asset.registrationNumber }} - {{ getAssetRole(asset.key) }}</li>
      </ul>
      <h3>Resource Assignments to Assets</h3>
      <div *ngFor="let assetKey of assetKeys">
        <strong>{{ getAssetName(assetKey) }}</strong>
        <ul>
          <li *ngFor="let resource of assetResourceAssignments[assetKey]">{{ resource.firstName }} {{ resource.lastName }}</li>
        </ul>
      </div>
      <h3>Additional Settings</h3>
      <ul>
        <li>Additional Notes: {{ additionalNotes }}</li>
        <li>Auto Dispatch Resources: {{ autoDispatch }}</li>
        <li *ngIf="(autoDispatch)">Auto Dispatch Time: {{ autoDispatchTime }} min</li>
      </ul>
      <button mat-button matStepperPrevious>Back</button>
    </mat-step>
  </mat-horizontal-stepper>
</mat-dialog-content>

<mat-dialog-actions class="right-aligned-actions">
  <button mat-button (click)="onCancel()" class="btn" [disabled]="extraConfirmation">Cancel</button>
  <button mat-button (click)="onConfirm()" class="btn btn-primary" [disabled]="!canSave || extraConfirmation">Confirm</button>
</mat-dialog-actions>