import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'privacy-policy-whitelabel',
  templateUrl: './privacy-policy-whitelabel.component.html',
  styleUrls: ['./privacy-policy-whitelabel.component.scss'],
})
export class PrivacyPolicyWhiteLabel implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
