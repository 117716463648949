<h2 mat-dialog-title>{{ dialogTitle }}</h2>

<mat-dialog-content>
  <form [formGroup]="requestManagementForm" autocomplete="off">
    <div class="request-details">
      <mat-grid-list cols="8" rowHeight="66.5px" gutterSize="8px">
        <mat-grid-tile colspan="2">
          <mat-form-field>
            <input matInput formControlName="requestorName" placeholder="Requestor Name *">
            <mat-error *ngIf="requestManagementForm.get('requestorName').invalid && requestManagementForm.get('requestorName').touched">
              Requestor Name is required.
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <mat-form-field>
            <input matInput formControlName="telephoneNumber" placeholder="Telephone Number *">
            <mat-error *ngIf="requestManagementForm.get('telephoneNumber').invalid && requestManagementForm.get('telephoneNumber').touched">
              Telephone Number is required.
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <mat-form-field>
            <input matInput formControlName="companyName" placeholder="Company Name *">
            <mat-error *ngIf="requestManagementForm.get('companyName').invalid && requestManagementForm.get('companyName').touched">
              Company Name is required.
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <mat-form-field>
            <input matInput formControlName="fmnoNumber" placeholder="FMNO Number *">
            <mat-error *ngIf="requestManagementForm.get('fmnoNumber').invalid && requestManagementForm.get('fmnoNumber').touched">
              FMNO Number is required.
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <mat-form-field>
            <input matInput formControlName="customChargeCode" placeholder="Custom Charge Code *">
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="right-aligned-actions">
  <button mat-raised-button (click)="onCancel()" class="btn">Cancel</button>
  <button mat-raised-button (click)="onSubmit()" class="btn btn-primary">Save Changes</button>
</mat-dialog-actions>