import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  private _router: Subscription;


  constructor(private router: Router) {
  }

  ngOnInit() {
    const hostName = window.location.href

    console.log(hostName);

    switch (hostName) {
      case 'https://admin.911response24.com/':
        window.location.replace('admin/pgs/login');
        break;
      case 'https://provider.911response24.com/':
        window.location.replace('provider/pgs/login');
        break;
      // case 'https://client.911response24.com/':
      //   window.location.replace('client/pgs/login');
      //   break;

      default:
        // window.location.replace('provider/pgs/login');
        break;
    }

  }
}
