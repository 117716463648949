/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "./image-modal.component";
var styles_ImageModalComponent = [i0.styles];
var RenderType_ImageModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageModalComponent, data: {} });
export { RenderType_ImageModalComponent as RenderType_ImageModalComponent };
function View_ImageModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["diameter", "50"], ["mode", "indeterminate"], ["role", "progressbar"], ["strokeWidth", "4"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"], strokeWidth: [1, "strokeWidth"] }, null)], function (_ck, _v) { var currVal_3 = "50"; var currVal_4 = "4"; _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ImageModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "fallback-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unable to load the image. Please check the URL or try again later."]))], null, null); }
export function View_ImageModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageModalComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["class", "preview-image"]], [[8, "src", 4]], [[null, "load"], [null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.onImageLoad() !== false);
        ad = (pd_0 && ad);
    } if (("error" === en)) {
        var pd_1 = (_co.onImageError() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageModalComponent_2)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "mat-dialog-actions"], ["matDialogActions", ""]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i7.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["mat-button", ""], ["matDialogClose", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).dialogRef.close(i1.ɵnov(_v, 10).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(10, 606208, null, 0, i7.MatDialogClose, [[2, i7.MatDialogRef], i1.ElementRef, i7.MatDialog], { _matDialogClose: [0, "_matDialogClose"] }, null), (_l()(), i1.ɵted(-1, 0, [" Close "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.showFallback; _ck(_v, 5, 0, currVal_2); var currVal_7 = ""; _ck(_v, 10, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.imageUrl; _ck(_v, 3, 0, currVal_1); var currVal_3 = (i1.ɵnov(_v, 9).disabled || null); var currVal_4 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); var currVal_5 = (i1.ɵnov(_v, 10).ariaLabel || null); var currVal_6 = i1.ɵnov(_v, 10).type; _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ImageModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-modal", [], null, null, null, View_ImageModalComponent_0, RenderType_ImageModalComponent)), i1.ɵdid(1, 114688, null, 0, i11.ImageModalComponent, [i7.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageModalComponentNgFactory = i1.ɵccf("app-image-modal", i11.ImageModalComponent, View_ImageModalComponent_Host_0, {}, {}, []);
export { ImageModalComponentNgFactory as ImageModalComponentNgFactory };
