import * as moment from 'moment';

import { BaseFirebaseObject } from '../base-firebase/base-firebase-object.interface';

// Move enums when complete [Alucard]
export enum FuelType {
    Petrol = 'Petrol',
    Diesel = 'Diesel',
    Electric = 'Electric',
    Hybrid = 'Hybrid'
}

export enum VehicleStatus {
    Available = 'Available',
    InUse = 'In Use',
    Maintenance = 'Maintenance',
    Retired = 'Retired'
}

export interface AssetManagementObject extends BaseFirebaseObject {
    vehicleType: string;
    make: string;
    model: string;
    year?: number;
    registrationNumber: string;
    capacity: number;
    fuelType: FuelType;
    state: VehicleStatus;
}