
import { Injectable } from '@angular/core';
import {
    ResponseServiceCategoryEnum,
    IconEnum,
    IconColorEnum,
    ResponseServiceActionStateEnum,
    ResponseServiceActionExternalProviderEnum,
} from 'app/enums/responseService.enums';
import { Icon, ViewValueString, ExternalResponseServiceProvider } from 'app/interfaces/response-service.interface';
import { ResponseServiceCategoryType } from 'app/types/responseService.types';
import { Subscription } from 'rxjs';
import { Tower } from 'app/interfaces/general/tower.interface';

@Injectable()
export class GlobalService {
    timer: any
    /* userDetailSubscriptions: Subscription[] = [];
    responseServiceActionsSubscription: Subscription;
    responseServiceActionSubscription: Subscription;
    responseServiceSubscription: Subscription; */
    alertAudio: HTMLAudioElement;
    clientTowers: Tower[];

    constructor() { }

    getAllResponseServiceCategories(): ViewValueString[] {
        return [
            {
                value: ResponseServiceCategoryEnum.armedResponse.value,
                viewValue: ResponseServiceCategoryEnum.armedResponse.viewValue
            },
            {
                value: ResponseServiceCategoryEnum.medical.value,
                viewValue: ResponseServiceCategoryEnum.medical.viewValue
            },
            {
                value: ResponseServiceCategoryEnum.general.value,
                viewValue: ResponseServiceCategoryEnum.general.viewValue
            },
            {
                value: ResponseServiceCategoryEnum.publicService.value,
                viewValue: ResponseServiceCategoryEnum.publicService.viewValue
            },
            {
                value: ResponseServiceCategoryEnum.requestDriver.value,
                viewValue: ResponseServiceCategoryEnum.requestDriver.viewValue
            },
        ]
    }

    getAllIcons(): Icon[] {
        return [
            {
                value: IconEnum.ambulance.value,
                viewValue: IconEnum.ambulance.viewValue
            },
            {
                value: IconEnum.centerMan.value,
                viewValue: IconEnum.centerMan.viewValue
            },
            {
                value: IconEnum.familyHands.value,
                viewValue: IconEnum.familyHands.viewValue
            },
            {
                value: IconEnum.news.value,
                viewValue: IconEnum.news.viewValue
            },
            {
                value: IconEnum.shield.value,
                viewValue: IconEnum.shield.viewValue
            },
            {
                value: IconEnum.sos.value,
                viewValue: IconEnum.sos.viewValue
            }
        ]
    }

    getAllIconColors(): ViewValueString[] {
        return [
            {
                value: IconColorEnum.blue.value,
                viewValue: IconColorEnum.blue.viewValue
            },
            {
                value: IconColorEnum.teal.value,
                viewValue: IconColorEnum.teal.viewValue
            },
            {
                value: IconColorEnum.red.value,
                viewValue: IconColorEnum.red.viewValue
            },
            {
                value: IconColorEnum.orange.value,
                viewValue: IconColorEnum.orange.viewValue
            },
        ]
    }

    getAllResponseServiceActionStates(): ViewValueString[] {
        return [
            {
                value: ResponseServiceActionStateEnum.completed.value,
                viewValue: ResponseServiceActionStateEnum.completed.viewValue
            },
            {
                value: ResponseServiceActionStateEnum.terminated.value,
                viewValue: ResponseServiceActionStateEnum.terminated.viewValue
            },
            {
                value: ResponseServiceActionStateEnum.open.value,
                viewValue: ResponseServiceActionStateEnum.open.viewValue
            },
            {
                value: ResponseServiceActionStateEnum.pickedUp.value,
                viewValue: ResponseServiceActionStateEnum.pickedUp.viewValue
            },
            {
                value: ResponseServiceActionStateEnum.stale.value,
                viewValue: ResponseServiceActionStateEnum.stale.viewValue
            },
            {
                value: ResponseServiceActionStateEnum.awaitResponderPickup.value,
                viewValue: ResponseServiceActionStateEnum.awaitResponderPickup.viewValue
            }
        ]
    }

    responseServiceActionExternalProviders(responseServiceCategoryType: ResponseServiceCategoryType): ExternalResponseServiceProvider[] {
        const providers: ExternalResponseServiceProvider[] = [
            {
                value: ResponseServiceActionExternalProviderEnum.er24.value,
                viewValue: ResponseServiceActionExternalProviderEnum.er24.viewValue,
                responseServiceCategoryType: 'medical'
            }
        ]
        return providers.filter(x => x.responseServiceCategoryType === responseServiceCategoryType)
    }

    /* unsubscribeAllSubscriptions() {

        if (this.userDetailSubscriptions) {
            this.userDetailSubscriptions.forEach(sub => {
                sub.unsubscribe();
            });
            this.userDetailSubscriptions = [];
        }
        if (this.responseServiceActionSubscription) {
            this.responseServiceActionSubscription.unsubscribe()
            this.responseServiceActionSubscription = undefined;
        }
        if (this.responseServiceSubscription) {
            this.responseServiceSubscription.unsubscribe()
            this.responseServiceSubscription = undefined;
        }
    } */

    uniqueInviteCode(): string {
        const random = Math.floor(100000 + Math.random() * 900000)
        return random.toString()
    }

    getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    daysDiffTillNow(actDate : Date) {

        if ( actDate === undefined || actDate === null) {
          return 0
        }
        const diff = Math.floor(new Date().getTime() - actDate.getTime());
        const day = 1000 * 60 * 60 * 24;
        const days = Math.floor(diff / day);
      
        return days;
      }
      
}
