import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "environments/environment";
import { AuthService } from 'app/core/auth.service';
import * as firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
import { apiUrlEnum } from 'app/enums/api-endpoints.enums';

interface QueryParams {
    [key: string]: string | number | boolean;
}

export interface ServerTimestamp {
    timestamp: number;
}

@Injectable({
    providedIn: 'root',
})
export class timeStampDataService {

    // Constructor for the FunctionDataService class
    constructor(private authService: AuthService, private http: HttpClient) { 
        console.debug('TimeStampDataService: instantiated');
    }
/*  
    get_fire_store_key: '/data/get-firestore-key/v1', - Does not exist in functions [Alucard]
*/

    async getServerTimeStampData(): Promise<firebase.firestore.Timestamp> {
        console.debug(`Getting timestamp...`);
        try {
            return await this.functionGet().then(data => { 
                // string to "any" object first
                console.debug(`Timestamp returned.`);
                if (data.timestamp) {
                    let timestamp = data as ServerTimestamp;
                    console.debug(`Timestamp value is: ${timestamp.timestamp}`);
                    console.debug("Server time returned sucessfully. Using now.")
                   return firebase.firestore.Timestamp.fromMillis(timestamp.timestamp);
                } else {
                    console.debug("Server time not returned - cannot continue.");
                    return Promise.reject("Server time not returned - cannot continue.");
                }
              });
        } catch (exception) {
            console.debug(`No timestamp returned. Error on fetch: ${exception}`);
            return Promise.reject(exception);
        }
    }

    private async functionGet<T = any>(): Promise<T>  {
        let url =  environment.functionsUrl;

        const idToken = await this.authService.user.getIdToken(false);

        if (!idToken) {
            throw new Error('ID token is null');
        }

        return this.http.get<T>(`${url}${apiUrlEnum.timestamp_v1.get_server_timestamp}`, {
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json',
                'Project-Id': environment.firebase['PROJECT-ONE'].projectId
            },
        }).toPromise();
    } 
}