import { Injectable } from '@angular/core';

import { TwilioCallRequest, TwilioMessageRequest, TwilioWhatsappRequest } from 'app/interfaces/communication.interface';
import { apiUrlEnum } from 'app/enums/api-endpoints.enums';

import { DataService } from '../dataService/data.service';

@Injectable()
export class TwilioService {

    constructor(private dataService: DataService) { }

    async call(twilioNumber: string, fromNumber: string, toNumber: string) {
        const twilioCallRequest: TwilioCallRequest = {
            twilioNumber: twilioNumber,
            toNumber: toNumber,
            fromNumber: fromNumber
        }

        this.dataService.postToFunctions(apiUrlEnum.communication_v1.twillio_call, twilioCallRequest).then(reqResponse => {
            console.log(reqResponse)
        }).catch(exception => {
            console.error(exception)
        });
    }

    async message(twilioNumber: string, toNumber: string, message: string) {
        const twilioMessageRequest: TwilioMessageRequest = {
            twilioNumber: twilioNumber,
            toNumber: toNumber,
            message: message
        }

        this.dataService.postToFunctions(apiUrlEnum.communication_v1.twillio_send_message, twilioMessageRequest).then(reqResponse => {
            console.log(reqResponse.message)
        })
    }

    async whatsapp(twilioNumber: string, toNumber: string, body: string) {
        const twilioWhatsappRequest: TwilioWhatsappRequest = {
            twilioNumber: twilioNumber,
            toNumber: toNumber,
        }

        this.dataService.postToFunctions(apiUrlEnum.communication_v1.twillio_send_whatsapp, twilioWhatsappRequest).then(reqResponse => {
            console.log(reqResponse.message)
        })
    }
}
