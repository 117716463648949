// PROD
export const environment = {
  production: false,
  version: '2',
  response24ClientKey: '-LT75cDlOhB3sr6T3P7A',
  response24ER24RSKey: 'Ou4OHkLNviCT9vRf5Tk5',
  response24ARSKey: 'JFxWpoQ7HiObxHBC9fyz',
  fromEmail: 'tech@response24.co.za',
  apiKey: '443K-LK3k-33klio-344kii99',
  firebase: {
    'PROJECT-ONE': {
      apiKey: "AIzaSyABMFBv_LxSpSiLQho90hZ_WaKQo-UDyIs",
      authDomain: "response24-gw-uat.firebaseapp.com",
      projectId: "response24-gw-uat",
      databaseURL: "https://response24-gw-uat-default-rtdb.firebaseio.com", //Shouldn't realy be used anymore...
      storageBucket: "response24-gw-uat.appspot.com",
      messagingSenderId: "174409745117",
      appId: "1:174409745117:web:6f6332955921580cf65134",
      measurementId: "G-SD95JD71E3"
    },
    'PROJECT-TWO': {
      apiKey: "AIzaSyB9xDpZGe6WzgU1Suo9yG1FDsmguH4Inok",
      authDomain: "response-24-plus---group-uat.firebaseapp.com",
      projectId: "response-24-plus---group-uat",
      storageBucket: "response-24-plus---group-uat.appspot.com",
      messagingSenderId: "576862246206",
      appId: "1:576862246206:web:8337de0ae883d4ee18c86f"
    },
  },
  functionsUrl: 'https://us-central1-response24-gw-uat.cloudfunctions.net',
  functionsUrlPlusGroup1: 'https://us-central1-response24-gw-uat.cloudfunctions.net',
  functionsUrlPlusGroup2: null,
  functionsUrlPlusGroup3: null,
  hostedUrl: 'https://response24-gw-uat.firebaseapp.com',
  hostedUrlNoHttp: 'response24-gw-uat.firebaseapp.com',

  hostedUrlAdminPortal: '/admin/pgs',
  hostedUrlClientPortal: '/client/pgs',
  hostedUrlProviderPortal: '/provider/pgs',
  paymentGateway: {
    // PROD
    entityId: '',
    bearerAuthToken: '',
    hostUrl: ''
  },
  teams: {
    systemManagementUrl: 'https://911grr.webhook.office.com/webhookb2/39b316f0-76ba-4292-a1ed-43263d895241@6c922770-0835-4d86-a077-1cede03ca624/IncomingWebhook/12c5d1ca87234574b9d83d905688b2f0/5355fc31-fb71-4e27-a0fb-f7638b29501a',
    systemLogsUrl: 'https://911grr.webhook.office.com/webhookb2/39b316f0-76ba-4292-a1ed-43263d895241@6c922770-0835-4d86-a077-1cede03ca624/IncomingWebhook/b069e5f3954f4b1e81bc8fea1b1c5698/5355fc31-fb71-4e27-a0fb-f7638b29501a',
    systemAlerts: 'https://911grr.webhook.office.com/webhookb2/39b316f0-76ba-4292-a1ed-43263d895241@6c922770-0835-4d86-a077-1cede03ca624/IncomingWebhook/bca74a3b10f04e7d950cfb012acc1cf9/5355fc31-fb71-4e27-a0fb-f7638b29501a'
  },
  branding: {
    appleIcon: './assets/branding_driver_uat/apple-icon.png',
    background: './assets/branding_driver_uat/background.png',
    companyLogo: './assets/branding_driver_uat/company-logo.jpeg',
    favicon: './assets/branding_driver_uat/favicon.png',
    logo:'./assets/branding_driver_uat/logo1.png',
    whiteIcon: './assets/branding_driver_uat/white-icon.png',
    whiteLogo: './assets/branding_driver_uat/white-logo.png',
    plusUsersDescription: 'Plus'
  },
  plusFirebaseFunctionsUrls: [
    { 'clientKey': '-LT75cDlOhB3sr6T3P7A', 'clientName': 'Response24', 'plusFunctionUrl': 'https://us-central1-response24-gw-uat.cloudfunctions.net' }
  ]
};