/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./intercom.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./intercom.component";
import * as i3 from "ng-intercom";
var styles_IntercomComponent = [i0.styles];
var RenderType_IntercomComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IntercomComponent, data: {} });
export { RenderType_IntercomComponent as RenderType_IntercomComponent };
export function View_IntercomComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["id", "privacy_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["TERMS AND CONDITIONS RESPONSE24USA - SECURITY APPLICATION END USER AGREEMENT (EULA) "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" ccccccccccccccccccccc "]))], null, null); }
export function View_IntercomComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "intercom", [], null, null, null, View_IntercomComponent_0, RenderType_IntercomComponent)), i1.ɵdid(1, 1163264, null, 0, i2.IntercomComponent, [i3.Intercom], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IntercomComponentNgFactory = i1.ɵccf("intercom", i2.IntercomComponent, View_IntercomComponent_Host_0, {}, {}, []);
export { IntercomComponentNgFactory as IntercomComponentNgFactory };
