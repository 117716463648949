import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'app/core/auth.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../core/auth.service";
var UserManagementService = /** @class */ (function () {
    /**
     * Creates an instance of UserManagementService.
     * @param authService - The authentication service.
     * @param firestore - The AngularFirestore instance.
     */
    function UserManagementService(authService, firestore) {
        this.authService = authService;
        this.firestore = firestore;
        console.debug('UserManagementService: instantiated');
    }
    /**
     * Watches the user management objects for a specific client.
     * If no client key is provided, it uses the client key of the authenticated user.
     * @param clientKey - The client key to filter the user management objects.
     * @returns An observable that emits an array of UserManagementEvent objects.
     */
    UserManagementService.prototype.watchUserManagementObjects = function (clientKey) {
        var collection;
        var chosenClientKey = clientKey || this.authService.appUser.userDetail.clientKey;
        collection = this.firestore.collection('users', function (ref) { return ref.where('clientKey', '==', chosenClientKey); });
        return collection.snapshotChanges().pipe(map(function (events) {
            return events.map(function (event) {
                return {
                    type: event.type,
                    data: event.payload.doc.data(),
                    id: event.payload.doc.id
                };
            });
        }));
    };
    /**
     * Watches a specific user management object.
     * @param key - The key of the user management object to watch.
     * @returns An observable that emits a UserManagementEvent object.
     */
    UserManagementService.prototype.watchUserManagementObject = function (key) {
        var docRef = this.firestore.collection('users').doc(key);
        return docRef.snapshotChanges().pipe(map(function (event) {
            return {
                type: event.type,
                data: event.payload.data(),
                id: event.payload.id
            };
        }));
    };
    UserManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserManagementService_Factory() { return new UserManagementService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject("project-two-firestore")); }, token: UserManagementService, providedIn: "root" });
    return UserManagementService;
}());
export { UserManagementService };
