

export let apiUrlEnum = {
  userAuth_v1:
  {
    verify_msisdn: '/userAuth/verify-msisdn/v1',
    user_update_auth_profile: '/userAuth/update-user-auth-profile/v1',
    user_create_auth_profile: '/userAuth/create-auth-profile/v1',
    user_delete_user_from_system: '/userAuth/delete-user-from-system/v1',
    user_link_to_client_group: '/userAuth/link-to-client-group/v1',
    user_link_dependent_to_member: '/userAuth/link-dependent-to-member/v1',
    user_remove_promo_codes: '/userAuth/remove-promo-codes/v1',
  },
  responseService_v1:
  {
    action: '/responseService/action/v1',
    actionER24: '/responseService/action-er24/v1',
    actionTower: '/responseService/action-tower/v1',
    initiateResponderCompletionConfirmation: '/responseService/initiate-responder-completion-confirmation/v1',
    initiateActionCompletion: '/responseService/initiate-action-completion/v1',
    transferResponseServiceActionHandler: '/responseService/transfer-response-service-action-handler/v1',
    moveToHistory: '/responseService/move-to-history/v1',
    responsePushUpdate: '/responseService/response-push-update/v1'
  },
  timestamp_v1:
  {
    get_server_timestamp: '/timeStamp/currentTime/v1',
  },
  journeyManagement_v1:
  {
    actionResponse: '/journeyManagement/action/v1',
    completeResponse: '/journeyManagement/initiate-action-completion/v1',
    moveJourneyManagementToHistory: '/journeyManagement/move-to-history/v1'
  },
  broadcast_v1:
  {
    sendBroadcastMessage: '/broadcast/sendBroadcast/v1',
  },
  communication_v1:
  {
    twillio_call: '/communication/twilio/call/v1',
    twillio_send_message: '/communication/twilio/send-message/v1',
    twillio_send_whatsapp: '/communication/twilio/send-whatsapp/v1',
    email_send: '/communication/email/send/v1',
    teams_send: '/communication/teams/send/v1',
    sms_send: '/communication/sms/send/v1',
    push_all_client_users: '/push/all-client-users/v1',
    push_user: '/communication/push/user/v1'
  },
  location_v1: {
    address_from_lat_long: '/location/address-from-lat-long/v1',
    places_near_lat_long: '/location/places-near-lat-long/v1'
  },
  subscription_v1: {
    get_latest_active_receipt: '/subscriptions/get-latest-active-receipt/v1',
    get_receipts: '/subscriptions/get-receipts/v1',
    start_subscription: '/subscriptions/start-subscription/v1'
  },
  netshield_v1: {
    update_device_location: '/netshield/update-device-location/v1',
    close_device_action_request: '/netshield/close-device-action-request/v1'
  },
  data_v1: {
    get_fire_store_data: '/data/get-firestore-data/v1',
    get_fire_store_data_start_at: '/data/get-firestore-data-start-at/v1',
    get_fire_store_data_subscription: '/data/get-firestore-data-subscription/v1',
    set_fire_store_data: '/data/set-firestore-data/v1',
    update_fire_store_data: '/data/update-firestore-data/v1',
    get_fire_store_key: '/data/get-firestore-key/v1',
    delete_fire_store_data: '/data/delete-firestore-data/v1'
  } ,
  info: {
    log: '/info/app-log/v1',
  },
   plus_functions: {
    update_user_auth: '/user/update-user-auth/v1',
    delete_user_auth: '/user/delete-user-auth/v1',
    push_notification: '/push_notifications/create/v1',
  },
}
