import { Inject, Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AuthService } from 'app/core/auth.service';

import { EMPTY, Observable, combineLatest, merge, pipe } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { UserDetail } from 'app/interfaces/user.interface';
import { ResponseService } from 'app/interfaces/response-service.interface';
import { JourneyManagementObject, ServiceInformation } from 'app/interfaces/journey-management/journey-management-object.interface';
import { FIREBASE_REFERENCES } from 'app/core/firebase/firebase.module';

@Injectable({
    providedIn: 'root'
})
export class ResourceManagementService {
    private allUserDetails: UserDetail[] = [];
    private linkedUserDetails: UserDetail[] = [];

    constructor(
        private authService: AuthService, 
        @Inject(FIREBASE_REFERENCES.ONE_FIRESTORE) private readonly firestore: AngularFirestore,
        //private firestore: AngularFirestore
    ) {
        console.debug('ResourceManagementService: instantiated');
    }

    private chunkArray<T>(array: T[], chunkSize: number): T[][] {
        let results = [];

        for (let i = 0; i < array.length; i += chunkSize) {
            results.push(array.slice(i, i + chunkSize));
        }

        return results;
    }

    /* watchLinkedResourceManagementObjects(serviceInformation?: ServiceInformation[]): Observable<UserDetail[]> {
        let resourceKeys = [];

        serviceInformation.map((service) => {
            service.linkedAssetsResources.map((linkedResources) => {
                linkedResources.associatedResources.map((resource) => {
                    resourceKeys.push(resource.resource.key);
                })
            })
        })

        if (resourceKeys.length == 0) {
            return EMPTY;
        }


        let chunks = this.chunkArray(resourceKeys, 10);

        const observables = chunks.map(chunk => {
            return this.firestore.collection<UserDetail>('users', ref => ref.where('key', 'in', chunk))
                .snapshotChanges()
                .pipe(map(events => {
                    return events.map<UserDetail>(event => {
                        return event.payload.doc.data() as UserDetail;
                    });
                }));
            });

        let AllObservables = merge(...observables)

        return AllObservables.pipe(       
            tap((updatedBatch: UserDetail[]) => {
                for (const updatedUser of updatedBatch) {
                    const index = this.linkedUserDetails.findIndex(user => user.key === updatedUser.key);

                    if (index === -1) {
                        this.linkedUserDetails.push(updatedUser);
                    } 
                    else {
                        this.linkedUserDetails[index] = updatedUser;
                    }
                }
            }),
            map(() => {
                return [...this.linkedUserDetails];
            })
        );
    } */

    watchLinkedResourceManagementObjects(journeyKey: string, clientKey?: string): Observable<UserDetail[]> {
        if (!journeyKey) {
            console.warn('Journey key is not provided.');
            return EMPTY;
        }
    
        return this.firestore.doc<JourneyManagementObject>(`journeys/${journeyKey}`)
            .snapshotChanges()
            .pipe(
                switchMap(journey => {
                    const journeyManagementObject = journey.payload.data();
    
                    if (!journeyManagementObject.serviceInformation) {
                        console.warn('No service information found.');
                        return EMPTY;
                    }
    
                    const userDetailObjects: UserDetail[] = journeyManagementObject.serviceInformation.reduce((acc, service) => {
                        if (service.linkedAssetsResources && service.linkedAssetsResources.length > 0) {
                            const userDetails = service.linkedAssetsResources.reduce((resAcc, linkedAsset) => {
                                if (linkedAsset.associatedResources && linkedAsset.associatedResources.length > 0) {
                                    return resAcc.concat(linkedAsset.associatedResources.map(res => res.resource));
                                }
                                return resAcc;
                            }, []);
                            return acc.concat(userDetails as UserDetail);
                        }
                        return acc;
                    }, []);
    
                    if (userDetailObjects.length === 0) {
                        console.warn('No user details found.');
                        return EMPTY;
                    }

                    const userDetailKeys = userDetailObjects.map(userDetail => userDetail.key);

                    const userDetailKeyChunks = this.chunkArray(userDetailKeys, 10);

                    const observables = userDetailKeyChunks.map(chunk => {
                        return this.firestore.collection<UserDetail>(
                            'users', ref => ref.where('key', 'in', chunk)
                        ).valueChanges();
                    });
    
                    return combineLatest(observables).pipe(
                        map(arrays => [].concat(...arrays))
                    );
                }),
                catchError(error => {
                    console.error('Error occurred:', error);
                    return EMPTY;
                })
            );
    }

    watchResourceManagementObjects(clientKey?: string): Observable<UserDetail[]> {
        let collection: AngularFirestoreCollection<UserDetail>;
        
        if (!clientKey && this.authService.appUser.userClient.isJourneyManagementOversightAdmin)
        {
            collection = this.firestore.collection<ResponseService>('responseServices');
        }
        else {
            const chosenClientKey = clientKey || this.authService.appUser.userDetail.clientKey;

            collection = this.firestore.collection<ResponseService>('responseServices', ref => ref.where('clientKey', '==', chosenClientKey));
        }

        return collection.snapshotChanges()
            .pipe(
                map(events => events.map(a => a.payload.doc.data() as ResponseService)),
                tap(() => {
                    this.allUserDetails = [];
                }),
                map(responseServices => {
                    let combined: string[] = [];

                    for (let responseService of responseServices) {
                        if (responseService.category == 'requestDriver') {
                            for (let driver of responseService.drivers) {
                                if (!combined.includes(driver.key)) {
                                    combined.push(driver.key);
                                }
                            }
                        }
                    }
                    
                    return this.chunkArray(combined, 10);
                }),
                mergeMap(chunkedKeys => {
                    const observables = chunkedKeys.map(chunk => {
                        return this.firestore.collection<UserDetail>('users', ref => ref.where('key', 'in', chunk))
                            .snapshotChanges()
                            .pipe(map(events => {
                                return events.map<UserDetail>(event => {
                                    return event.payload.doc.data() as UserDetail;
                                });
                            }));
                        });

                    return merge(...observables)
                }),
                tap((updatedBatch: UserDetail[]) => {
                    for (const updatedUser of updatedBatch) {
                        const index = this.allUserDetails.findIndex(user => user.key === updatedUser.key);

                        if (index === -1) {
                            this.allUserDetails.push(updatedUser);
                        } 
                        else {
                            this.allUserDetails[index] = updatedUser;
                        }
                    }
                }),
                map(() => {
                    return [...this.allUserDetails];
                })
            );
    }

    watchResourceManagementObject(key: string): Observable<UserDetail> {
        const docRef = this.firestore.collection('users').doc<UserDetail>(key);
    
        return docRef.snapshotChanges().pipe(
            map(event => {
                return event.payload.data() as UserDetail;
            })
        );
    }

    // Fetch all resources
    async getAllResourceManagementObjects(clientKey?: string): Promise<UserDetail[]> {
        let collection: AngularFirestoreCollection<UserDetail>;
        
        if (!clientKey && this.authService.appUser.userClient.isJourneyManagementOversightAdmin)
        {
            collection = this.firestore.collection<ResponseService>('responseServices');
        }
        else {
            const chosenClientKey = clientKey || this.authService.appUser.userDetail.clientKey;

            collection = this.firestore.collection<ResponseService>('responseServices', ref => ref.where('clientKey', '==', chosenClientKey));
        }

        try {
            const resources: UserDetail[] = [];

            const responseServicesSnapshot = await collection.get().toPromise();

            const responseServices: ResponseService[] = [];

            responseServicesSnapshot.forEach(doc => {
                responseServices.push(doc.data() as ResponseService);
            });

            let combined: string[] = [];

            for (let responseService of responseServices) {
                for (let driver of responseService.drivers) {
                    if (!combined.includes(driver.key)) {
                        combined.push(driver.key);
                    }
                }
            }

            const chunkedKeys = this.chunkArray(combined, 10);

            const promises = chunkedKeys.map(chunk => {
                return this.firestore.collection<UserDetail>('users', ref => ref.where('key', 'in', chunk)).get().toPromise();
            });

            const snapshots = await Promise.all(promises);

            for (let snapshot of snapshots) {
                snapshot.forEach(doc => {
                        resources.push(doc.data() as UserDetail);
                });
            }

            return resources;
        }
        catch (error) {
            console.error('Error fetching all resources:', error);
            throw error;
        }
    }

    // Fetch specific resource by its key
    async getResourceManagementObject(key: string): Promise<UserDetail> {
        try {
            const resource = await this.firestore.doc<UserDetail>(`users/${key}`)
                .valueChanges()
                .toPromise();

            return resource;
        }
        catch (error) {
            console.error(`Error fetching resource with key ${key}:`, error);
            throw error;
        }
    }
}