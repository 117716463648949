import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AuthService } from 'app/core/auth.service';
import { FirebaseHelper } from 'app/helpers/firebase.helper';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as moment from 'moment';
import { BookingManagementObject } from 'app/interfaces/booking-management/booking-management-object.interface';
import { FIREBASE_REFERENCES } from 'app/core/firebase/firebase.module';
import { Inject } from '@angular/core';

export class BookingManagementService {
    private readonly collectionName: string = 'bookings';
    private readonly batchLimit: number = 250;

    constructor(private authService: AuthService, @Inject(FIREBASE_REFERENCES.ONE_FIRESTORE) private readonly firestore: AngularFirestore) {
        console.debug('BookingManagementService: instantiated');
    }

    private chunkArray<T>(array: T[], chunkSize: number): T[][] {
        let results = [];

        for (let i = 0; i < array.length; i += chunkSize) {
            results.push(array.slice(i, i + chunkSize));
        }

        return results;
    }

    watchBookings(serviceKey?: string, itemKey?: string): Observable<BookingManagementObject[]> {
        let collection: AngularFirestoreCollection<BookingManagementObject>;

        if (serviceKey && itemKey) {
            collection = this.firestore.collection<BookingManagementObject>(this.collectionName, ref => 
                ref.where('serviceKey', '==', serviceKey)
                   .where('itemKey', '==', itemKey));
        }
        else {
            collection = this.firestore.collection<BookingManagementObject>(this.collectionName, ref => ref.orderBy('date'));
        }

        return collection.snapshotChanges().pipe(
            map(events => 
                events.map(event => {
                    return FirebaseHelper.convertFromFirebase(event.payload.doc.data() as BookingManagementObject);
            }))
        );
    }

    watchBookingsByDate(specificDate: string): Observable<BookingManagementObject[]> {
        const collection = this.firestore.collection<BookingManagementObject>(this.collectionName, ref => 
            ref.where('date', '==', specificDate));

        return collection.snapshotChanges().pipe(
            map(events => events.map(event => {
                return FirebaseHelper.convertFromFirebase(event.payload.doc.data() as BookingManagementObject);
            })),
            filter(bookings => bookings.length > 0)
        );
    }

    watchBookingsByDates(specificDates: string[], clientKey?: string): Observable<BookingManagementObject[]> {
        if (specificDates.length === 0) {
            throw new Error('Dates array should not be empty');
        }

        const dateChunks = this.chunkArray(specificDates, 10);

        const observables: Observable<BookingManagementObject[]>[] = dateChunks.map(dateChunk => {
            let collection: AngularFirestoreCollection<BookingManagementObject>;

            if (!clientKey && this.authService.appUser.userClient.isJourneyManagementOversightAdmin) {
                collection = this.firestore.collection<BookingManagementObject>(this.collectionName, ref => ref.where('date', 'in', dateChunk));
            }
            else {
                const chosenClientKey = clientKey || this.authService.appUser.userDetail.clientKey;

                collection = this.firestore.collection<BookingManagementObject>(this.collectionName, ref => ref.where('clientKey', '==', chosenClientKey).where('date', 'in', dateChunk));
            }

            return collection.snapshotChanges().pipe(
                map(events => {
                    return events.map(event => {
                        return FirebaseHelper.convertFromFirebase(event.payload.doc.data() as BookingManagementObject);
                    });
                }),
                filter(bookings => bookings.length > 0)
            );
        });

        return combineLatest(observables).pipe(
            map(arrays => [].concat(...arrays))
        );
    }

    async addBookingManagementObjects(bookings: BookingManagementObject[]): Promise<BookingManagementObject[]> {
        if (bookings.length > this.batchLimit) {
            throw new Error(`Exceeded the batch limit of ${this.batchLimit}`);
        }

        try {
            const clientKey = this.authService.appUser.userClient.key;
            const clientName = this.authService.appUser.userClient.name;
            const creatorClientKey = this.authService.appUser.userClient.key;
            const creatorClientName = this.authService.appUser.userClient.name;
            const initiatorKey = this.authService.appUser.userDetail.key;
            const initiatorName = `${this.authService.appUser.userDetail.firstName} ${this.authService.appUser.userDetail.lastName}`;
            const timestamp = moment();

            const batch = this.firestore.firestore.batch();

            const addedBookings: BookingManagementObject[] = [];

            bookings.forEach(booking => {
                const newId = this.firestore.createId();

                booking.key = newId;
                booking.clientKey = clientKey;
                booking.clientName = clientName;
                booking.creatorClientKey = creatorClientKey;
                booking.creatorClientName = creatorClientName;
                booking.initiatorKey = initiatorKey;
                booking.initiatorName = initiatorName;
                booking.dateInitiated = timestamp;

                booking = FirebaseHelper.convertForFirebase(booking);

                const newDocRef = this.firestore.collection(this.collectionName).doc(newId);

                batch.set(newDocRef.ref, booking);

                addedBookings.push(booking);
            });

            await batch.commit();

            return addedBookings;
        }
        catch (error) {
            console.error('Error creating new bookings:', error);
            throw error;
        }
    }

    async addBookingManagementObject(booking: BookingManagementObject): Promise<BookingManagementObject> {
        try {
            const newId = this.firestore.createId();

            booking.key = newId;
            booking.clientKey = this.authService.appUser.userClient.name;;
            booking.clientName = this.authService.appUser.userClient.name;
            booking.creatorClientKey = this.authService.appUser.userClient.key;
            booking.creatorClientName = this.authService.appUser.userClient.name;
            booking.initiatorKey = this.authService.appUser.userDetail.key;
            booking.initiatorName = `${this.authService.appUser.userDetail.firstName} ${this.authService.appUser.userDetail.lastName}`;
            booking.dateInitiated = moment();

            booking = FirebaseHelper.convertForFirebase(booking);

            const newDocRef = this.firestore.collection(this.collectionName).doc(newId);

            await newDocRef.set(booking);

            return booking;
        }
        catch (error) {
            console.error('Error creating new booking:', error);
            throw error;
        }
    }

    async updateBookingManagementObjects(bookings: BookingManagementObject[]): Promise<void> {
        if (bookings.length > this.batchLimit) {
            throw new Error(`Exceeded the batch limit of ${this.batchLimit}`);
        }

        try {
            const batch = this.firestore.firestore.batch();

            bookings.forEach(booking => {
                const docRef = this.firestore.firestore.collection(this.collectionName).doc(booking.key);

                booking = FirebaseHelper.convertForFirebase(booking);

                batch.update(docRef, booking);
            });

            return await batch.commit();
        }
        catch (error) {
            console.error('Error updating booking:', error);
            throw error;
        }
    }

    async updateBookingManagementObject(booking: BookingManagementObject): Promise<void> {
        try {
            booking = FirebaseHelper.convertForFirebase(booking);

            return await this.firestore.collection(this.collectionName).doc(booking.key).update(booking);
        }
        catch (error) {
            console.error('Error updating booking:', error);
            throw error;
        }
    }

    async deleteBookingManagementObjects(keys: string[]): Promise<void> {
        if (keys.length > this.batchLimit) {
            throw new Error(`Exceeded the batch limit of ${this.batchLimit}`);
        }

        try {
            const batch = this.firestore.firestore.batch();

            keys.forEach(key => {
                const docRef = this.firestore.firestore.collection(this.collectionName).doc(key);

                batch.delete(docRef);
            });

            return await batch.commit();
        }
        catch (error) {
            console.error('Error deleting bookings in batch:', error);
            throw error;
        }
    }

    async deleteBookingManagementObject(key: string): Promise<void> {
        try {
            return await this.firestore.collection(this.collectionName).doc(key).delete();
        }
        catch (error) {
            console.error(`Error deleting booking with key ${key}:`, error);
            throw error;
        }
    }

    /* async getAllBookings(): Promise<BookingManagementObject[]> {
        const collection = this.firestore.collection<BookingManagementObject>(this.collectionName, ref => ref.orderBy('date'));
        
        try {
            const bookings = await collection.valueChanges()
                .pipe(
                    map(bookingArray => bookingArray.map(booking => FirebaseHelper.convertFromFirebase(booking)))
                )
                .toPromise();

            return bookings;
        }
        catch (error) {
            console.error('Error fetching all bookings:', error);
            throw error;
        }
    } */

    /* async getBookingByKey(key: string): Promise<BookingManagementObject> {
        try {
            const booking = await this.firestore.doc<BookingManagementObject>(`${this.collectionName}/${key}`)
                .valueChanges()
                .pipe(
                    map(bookingObj => FirebaseHelper.convertFromFirebase(bookingObj))
                )
                .toPromise();

            return booking;
        }
        catch (error) {
            console.error(`Error fetching booking with key ${key}:`, error);
            throw error;
        }
    } */
}