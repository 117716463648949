import { Component, OnInit, ElementRef, SecurityContext } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//import { AngularFireAuth } from '@angular/fire/auth'
//import { AuthService } from '../../../core/auth.service';
import { Globals } from 'app/globals';
//import { UserService } from 'app/services/user/user.service';
import { DateFormatting } from 'app/providers/date-formatting';
import { UploadService } from 'app/services/upload/upload.service';
import Swal from 'sweetalert2';
import { DataService } from 'app/services/dataService/data.service';
import { GroupCoupon, UserClaimedPromoCodeEmail, UserClaimedPromoGroup, UserGroup } from 'app/interfaces/user.interface';
import { environment } from 'environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { timeStampDataService } from "app/services/function/function.timestamp.service";
@Component({
    selector: 'promo-code-cmp',
    templateUrl: './promo-code.component.html',
})

export class PromoCodeComponent implements OnInit {
    public branding = environment.branding;
    test: Date = new Date();
    public toggleButton: any;
    public sidebarVisible: boolean;
    //public auth: AuthService;
    //public afAuth: AngularFireAuth
    public email: string;
    hasClaimed = false;
    coupon: GroupCoupon;
    public bgstyle;

    constructor(
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private element: ElementRef, 
        //_auth: AuthService, 
        //public userService: UserService, 
        public uploadService: UploadService, 
        public dataService: DataService, 
        public router: Router, 
        //_afAuth: AngularFireAuth, 
        public dateFormatting: DateFormatting, 
        public g: Globals,
        public timestampService: timeStampDataService
    ) {
        this.sidebarVisible = false;
        this.bgstyle = this.sanitizer.sanitize(SecurityContext.STYLE, `url("${this.branding.background}")`);
        //this.auth = _auth;
        //this.afAuth = _afAuth;
    }

    async ngOnInit() {
        //   this.clientKey = JSON.parse(this.route.snapshot.paramMap.get('clientKey'))
        this.g.loading = true;
        const couponCode = JSON.parse(this.route.snapshot.paramMap.get('CDe'))
        this.coupon = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData('groupCoupons', 'redeemCode', couponCode).toPromise())[0]

        if (!this.coupon || this.coupon.redeemCount >= this.coupon.redeemLimit) {
            this.g.loading = false;
            Swal.fire({
                title: 'Promotion Has Ended',
                text: 'Sorry but this Promotion is no longer Active',
                icon: 'info',
                confirmButtonText: 'Close',
            })
        } else {
            const navbar: HTMLElement = this.element.nativeElement;
            this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
            const body = document.getElementsByTagName('body')[0];
            body.classList.add('promo-code');
            const card = document.getElementsByClassName('card')[0];
            setTimeout(function () {
                // after 1000 ms we add the class animated to the login/register card
                card.classList.remove('card-hidden');
            }, 500);

            this.g.loading = false;
        }
    }

    async claim() {
        this.g.loading = true;
        let timestamp;
          await this.timestampService.getServerTimeStampData().then(data => { 
            timestamp = data;
          }).catch(rejection => {
              console.error(rejection);
              Swal.fire({
                  title: "Error Creating Timestamp for Claim.",
                  text: rejection,
                  icon: "error",
                  confirmButtonText: "Ok",
              });
              return;
          });

        const userClaimedGroupCoupons: UserClaimedPromoCodeEmail = {
            email: this.email,
            groupCoupon: this.coupon,
            promoCodeKey: this.coupon.redeemCode,
            dateCreated: timestamp,
        }

        await this.dataService.setFireStoreData('userClaimedGroupCoupons', userClaimedGroupCoupons)

        // >> set user default group link aswell.
        const clientGroups: UserGroup[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData('userGroups', 'clientKey', this.coupon.clientKey).toPromise())
        const clientGroup = clientGroups.find(x => x.autoGenerated)

        const userClaimedPromoGroup: UserClaimedPromoGroup = {
            email: this.email,
            groupInviteKey: clientGroup.invitationCode,
            userGroup: clientGroup,
            hasbeenActivated: false,
            dateCreated: timestamp,
        }
        await this.dataService.setFireStoreData('userClaimedPromoGroup', userClaimedPromoGroup)
        // >> set user default group link aswell.


        if (!this.coupon.redeemCount) {
            this.coupon.redeemCount = 1;
        } else {
            this.coupon.redeemCount++;
        }

        if (!this.coupon.claimedUserEmails) {
            this.coupon.claimedUserEmails = [];
        }
        this.coupon.claimedUserEmails.push(this.email);

        this.dataService.setFireStoreData('groupCoupons', this.coupon)
        this.hasClaimed = true

        this.g.loading = false;
        Swal.fire({
            title: this.coupon.name + ' Claimed!',
            text: 'Please download and Register on the Response24 App, then Go to Subscriptions and the Promo Code will be automatically applied',
            icon: 'success',
            confirmButtonText: 'Close',
        })
    }
}
