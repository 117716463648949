<h2 mat-dialog-title>Journey Management Overview</h2>

<mat-dialog-content>
  <mat-tab-group style="max-height: 450px; min-height: 450px">
    <!-- Request Details Tab -->
    <mat-tab label="Request Details">
      <table>
        <tr>
          <td><strong>Name:</strong></td>
          <td>{{ data.journeyManagementObject.requestDetails.requestorName }}</td>
        </tr>
        <tr>
          <td><strong>Telephone:</strong></td>
          <td>{{ data.journeyManagementObject.requestDetails.telephoneNumber }}</td>
        </tr>
        <tr>
          <td><strong>Project:</strong></td>
          <td>{{ data.journeyManagementObject.requestDetails.projectName }}</td>
        </tr>
        <tr>
          <td><strong>Company Name:</strong></td>
          <td>{{ data.journeyManagementObject.requestDetails.companyName }}</td>
        </tr>
        <tr>
          <td><strong>FMNO Number:</strong></td>
          <td>{{ data.journeyManagementObject.requestDetails.fmnoNumber }}</td>
        </tr>
        <tr *ngIf="data.journeyManagementObject.requestDetails.customChargeCode">
          <td><strong>Charge Code:</strong></td>
          <td>{{ data.journeyManagementObject.requestDetails.customChargeCode }}</td>
        </tr>
        <tr *ngIf="data.journeyManagementObject.clientName">
          <td><strong>Client:</strong></td>
          <td>{{ data.journeyManagementObject.clientName }}</td>
        </tr>
        <tr>
          <td colspan="2"><mat-divider></mat-divider></td>
        </tr>
      </table>
    </mat-tab>

    <!-- Passenger Information Tab -->
    <mat-tab label="Passenger Information">
      <table *ngFor="let passenger of data.journeyManagementObject.passengerInformation">
        <tr>
          <td><strong>Name:</strong></td>
          <td>{{ passenger.name }}</td>
        </tr>
        <tr>
          <td><strong>Nationality:</strong></td>
          <td>{{ passenger.nationality }}</td>
        </tr>
        <tr>
          <td><strong>Passport:</strong></td>
          <td>{{ passenger.passportNumber }}</td>
        </tr>
        <tr>
          <td><strong>Contact:</strong></td>
          <td>{{ passenger.contactTelephoneNumber }}</td>
        </tr>
        <tr>
          <td><strong>Email:</strong></td>
          <td>{{ passenger.contactEmail }}</td>
        </tr>
        <tr>
          <td colspan="2"><mat-divider></mat-divider></td>
        </tr>
      </table>
    </mat-tab>

    <!-- Travel Information Tab -->
    <mat-tab label="Travel Information">
      <table *ngFor="let travel of data.journeyManagementObject.travelInformation">
        <tr>
          <td><strong>Date:</strong></td>
          <td>{{ travel?.date?.format ? (travel.date.format('DD MMM YYYY hh:mm a')) : 'N/A' }}</td>
        </tr>
        <tr>
          <td><strong>Flight Number:</strong></td>
          <td>{{ travel.flightNumber }}</td>
        </tr>
        <tr *ngIf="travel.protocolArrival">
          <td><strong>Protocol Arrival:</strong></td>
          <td>Selected</td>
        </tr>
        <tr *ngIf="travel.protocolDeparture">
          <td><strong>Protocol Departure:</strong></td>
          <td>Selected</td>
        </tr>
        <tr>
          <td><strong>Accommodation:</strong></td>
          <td>{{ travel.accommodation }}</td>
        </tr>
        <tr>
          <td><strong>Details:</strong></td>
          <td>{{ travel.flightOrDetails }}</td>
        </tr>
        <tr>
          <td><strong>Linked Passengers:</strong></td>
          <td>
            <span *ngFor="let name of getPassengerNames(travel.linkedPassengers); let i = index">
              {{ name }}{{ i < travel.linkedPassengers.length - 1 ? ',' : '' }} </span>
          </td>
        </tr>
        <tr>
          <td colspan="2"><mat-divider></mat-divider></td>
        </tr>
      </table>
    </mat-tab>

    <!-- Service Information Tab -->
    <mat-tab label="Service Information">
      <table *ngFor="let service of data.journeyManagementObject.serviceInformation">
        <tr>
          <td><strong>Date:</strong></td>
          <td>{{ service?.date?.format ? (service.date.format('DD MMM YYYY hh:mm a')) : 'N/A' }}</td>
        </tr>
        <tr>
          <td><strong>Pick Up Address:</strong></td>
          <td>{{ service.startAddress.formattedAddress }}</td>
        </tr>
        <tr>
          <td><strong>Pick Up Coordinates:</strong></td>
          <td>Longitude: {{ service.startAddress.longitude }} Latitude: {{ service.startAddress.latitude }}
          </td>
        </tr>
        <tr>
          <td><strong>Drop Off Address:</strong></td>
          <td>{{ service.endAddress.formattedAddress }}</td>
        </tr>
        <tr>
          <td><strong>Drop Off Coordinates:</strong></td>
          <td>Longitude: {{ service.endAddress.longitude }} Latitude: {{ service.endAddress.latitude }}</td>
        </tr>
        <tr>
          <td><strong>Details:</strong></td>
          <td>{{ service.detailsOfService }}</td>
        </tr>
        <tr>
          <td><strong>Requested Resources:</strong></td>
          <td>
            <span *ngFor="let resource of service.requiredResources; let i = index">
              {{ resource }}{{ i < service.requiredResources.length - 1 ? ',' : '' }} </span>
          </td>
        </tr>
        <tr>
          <td><strong>Linked Passengers:</strong></td>
          <td>
            <span *ngFor="let name of getPassengerNames(service.linkedPassengers); let i = index">
              {{ name }}{{ i < service.linkedPassengers.length - 1 ? ',' : '' }} </span>
          </td>
        </tr>
        <tr>
          <td><strong>Special Request Notes:</strong></td>
          <td>{{ service.specialRequests }}</td>
        </tr>
        <tr>
          <td colspan="2"><mat-divider></mat-divider></td>
        </tr>
      </table>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions class="right-aligned-actions">
  <button mat-button (click)="onClose()" class="btn btn-primary">Close</button>
</mat-dialog-actions>