import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from "environments/environment";

import { AuthService } from 'app/core/auth.service';

interface QueryParams {
    [key: string]: string | number | boolean;
}

@Injectable({
    providedIn: 'root',
})
export class FunctionResponseServiceService {

    // Constructor for the FunctionResponseServiceService class
    constructor(private authService: AuthService, private http: HttpClient) { 
        console.debug('FunctionResponseServiceService: instantiated');
    }

/*  
    initiateResponderCompletionConfirmation: '/responseService/initiate-responder-completion-confirmation/v1', - Does not exist in functions [Alucard]
    responsePushUpdate: '/responseService/response-push-update/v1' - Does not exist in functions [Alucard]
*/

    async action(data: any) {
        const response = await this.functionPost('action', data);

        return response;
    }

    async actionER24(data: any) {
        const response = await this.functionPost('action-er24', data);

        return response;
    }

    async actionTower(data: any) {
        const response = await this.functionPost('action-tower', data);

        return response;
    }

    /* async initiateResponderCompletionConfirmation(data: any): Promise<any> {
        const response = await this.functionPost('initiate-responder-completion-confirmation', data);

        return response;
    } */

    async initiateActionCompletion(data: any): Promise<any> {
        const response = await this.functionPost('initiate-action-completion', data);

        return response;
    }

    async transferResponseServiceActionHandler(data: any): Promise<any> {
        const response = await this.functionPost('transfer-response-service-action-handler', data);

        return response;
    }

    async moveToHistory(data: any): Promise<any> {
        const response = await this.functionPost('move-to-history', data);

        return response;
    }

    /* async responsePushUpdate(data: any): Promise<any> {
        const response = await this.functionPost('response-push-update', data);

        return response;
    } */

    private async functionGet<T = any>(functionName: string, paramsObj?: QueryParams, url?: string): Promise<T>  {
        url = url || environment.functionsUrl;

        let params = new HttpParams();
        
        if (paramsObj) {
            for (const key in paramsObj) {
                const value = paramsObj[key];

                params = params.append(key, value.toString());
            }
        }

        const idToken = await this.authService.user.getIdToken(false);

        if (!idToken) {
            throw new Error('ID token is null');
        }

        return this.http.get<T>(`${url}/apiResponseService/v1/${functionName}`, {
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json',
            },
            params
        }).toPromise();
    } 
    
    private async functionPut<T = any>(functionName: string, data: any, paramsObj?: QueryParams, url?: string): Promise<T>  {
        url = url || environment.functionsUrl;

        let params = new HttpParams();
        
        if (paramsObj) {
            for (const key in paramsObj) {
                const value = paramsObj[key];

                params = params.append(key, value.toString());
            }
        }

        const idToken = await this.authService.user.getIdToken(false);

        if (!idToken) {
            throw new Error('ID token is null');
        }

        return this.http.put<T>(`${url}/apiResponseService/v1/${functionName}`, data, {
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json',
            },
            params
        }).toPromise();
    }

    private async functionPost<T = any>(functionName: string, data?: any, paramsObj?: QueryParams, url?: string): Promise<T> {
        url = url || environment.functionsUrl;

        let params = new HttpParams();

        if (paramsObj) {
            for (const key in paramsObj) {
                const value = paramsObj[key];
                params = params.append(key, value.toString());
            }
        }

        const idToken = await this.authService.user.getIdToken(false);

        if (!idToken) {
            throw new Error('ID token is null');
        }

        return this.http.post<T>(`${url}/apiResponseService/v1/${functionName}`, data, {
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json',
            },
            params
        }).toPromise();
    }

    private async functionDelete<T = any>(functionName: string, paramsObj?: QueryParams, url?: string): Promise<T>  {
        url = url || environment.functionsUrl;

        let params = new HttpParams();
        
        if (paramsObj) {
            for (const key in paramsObj) {
                const value = paramsObj[key];

                params = params.append(key, value.toString());
            }
        }

        const idToken = await this.authService.user.getIdToken(false);

        if (!idToken) {
            throw new Error('ID token is null');
        }

        return this.http.delete<T>(`${url}/apiResponseService/v1/${functionName}`, {
            headers: {
                'Authorization': 'Bearer ' + idToken,
                'Content-Type': 'application/json',
            },
            params
        }).toPromise();
    }
}