import { Component, OnInit, ElementRef, OnDestroy, SecurityContext } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//import { AngularFireAuth } from '@angular/fire/auth';
//import { AuthService } from 'app/core/auth.service';
//import { AuthService } from '../../../core/auth.service';
import { Globals } from 'app/globals';
//import { UserService } from 'app/services/user/user.service';
import { DateFormatting } from 'app/providers/date-formatting';
import { UploadService } from 'app/services/upload/upload.service';
import Swal from 'sweetalert2';
import { DataService } from 'app/services/dataService/data.service';
import { UserGroup, UserClaimedPromoGroup } from 'app/interfaces/user.interface';
import { Client } from 'app/interfaces/client.interface';
import { environment } from 'environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { timeStampDataService } from "app/services/function/function.timestamp.service";

@Component({
    selector: 'app-group-share-code-cmp',
    templateUrl: './group-share-code.component.html',
})

export class GroupShareComponent implements OnInit {
    public branding = environment.branding;
    test: Date = new Date();
    public toggleButton: any;
    public sidebarVisible: boolean;
    //public auth: AuthService;
    //public afAuth: AngularFireAuth
    public email: string;
    hasClaimed = false;
    userGroup: UserGroup;
    client: Client;
    public bgstyle;

    constructor(
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private element: ElementRef, 
        //auth: AuthService, 
        //public userService: UserService, 
        public uploadService: UploadService, 
        public dataService: DataService, 
        public router: Router, 
        //_afAuth: AngularFireAuth, 
        public dateFormatting: DateFormatting, 
        public g: Globals,
        public timestampService: timeStampDataService
    ) {
        this.sidebarVisible = false;
        this.bgstyle = this.sanitizer.sanitize(SecurityContext.STYLE, `url("${this.branding.background}")`);
        //this.auth = _auth;
        //this.afAuth = _afAuth;
    }

    async ngOnInit() {
        //   this.clientKey = JSON.parse(this.route.snapshot.paramMap.get('clientKey'))
        this.g.loading = true;
        const groupInviteCode = JSON.parse(this.route.snapshot.paramMap.get('GIc'))
        this.userGroup = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData('userGroups', 'invitationCode', groupInviteCode).toPromise())[0]
        this.client = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData('clients', 'key', this.userGroup.clientKey).toPromise())[0]

        if (!this.userGroup) {
            this.g.loading = false;
            Swal.fire({
                title: 'No Group Found',
                text: 'Sorry but the code you entered is incorrect',
                icon: 'info',
                confirmButtonText: 'Close',
            })
        } else {
            const navbar: HTMLElement = this.element.nativeElement;
            this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
            const body = document.getElementsByTagName('body')[0];
            body.classList.add('promo-code');
            const card = document.getElementsByClassName('card')[0];
            setTimeout(function () {
                // after 1000 ms we add the class animated to the login/register card
                card.classList.remove('card-hidden');
            }, 500);

            this.g.loading = false;
        }
    }

    async claimGroupCode() {
        this.g.loading = true;

        let timestamp;
        await this.timestampService.getServerTimeStampData().then(data => { 
        timestamp = data;
        }).catch(rejection => {
            console.error(rejection);
            Swal.fire({
                title: "Error Creating Timestamp for Claim.",
                text: rejection,
                icon: "error",
                confirmButtonText: "Ok",
            });
            return;
        });

        const userClaimedPromoGroup: UserClaimedPromoGroup = {
            email: this.email,
            groupInviteKey: this.userGroup.invitationCode,
            userGroup: this.userGroup,
            hasbeenActivated: false,
            dateCreated: timestamp,
        }

        await this.dataService.setFireStoreData('userClaimedPromoGroup', userClaimedPromoGroup)

        if (!this.userGroup.invitationUrlRedemptions) {
            this.userGroup.invitationUrlRedemptions = 0
        }
        this.userGroup.invitationUrlRedemptions++;

        this.dataService.setFireStoreData('userGroups', this.userGroup)
        this.hasClaimed = true
        this.g.loading = false;
        Swal.fire({
            title: 'You have been Linked',
            text: 'Please download and Register on the 911 Response24 App',
            icon: 'success',
            confirmButtonText: 'Close',
        })
    }
}
