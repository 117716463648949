import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { TimezonePickerModule } from 'ng2-timezone-selector';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
  MatFormFieldModule,
  MatDividerModule,
} from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AppComponent } from './app.component';
import { FooterModule } from './shared/footer/footer.module';
import { FixedpluginModule } from './shared/fixedplugin/fixedplugin.module';
import { AppRoutes } from './app.routing';
import { CoreModule } from './core/core.module';
import { Globals } from './globals';
import { ComponentsModule } from './components/components.module';
import { DateFormatting } from './providers/date-formatting';
import { NumberFormatting } from './providers/number-formatting';
import { ObjectTransformations } from './providers/object-transformations';
import { TablesDotNet } from './providers/tables-dot-net';
import { DatePipe } from '@angular/common';
import { GlobalService } from 'app/services/global/global.service';
import { CommunicationService } from './services/communication/communication.service';
import { PrivacyPolicy } from './pages/public/privacy-policy/privacy-policy.component';
import { PrivacyPolicyWhiteLabel } from './pages/public/privacy-policy-whitelabel/privacy-policy-whitelabel.component';
import { PrivacyPolicyUsa } from './pages/public/privacy-policy-usa/privacy-policy-usa.component';
import { TermsAndConditionsUsa } from './pages/public/terms-and-conditions-usa/terms-and-conditions-usa.component';



//import { AngularFirestoreModule } from '@angular/fire/firestore';
import { PaymentComponent } from './pages/public/payment/payment.component';
import { PaymentCompletedComponent } from './pages/public/payment-completed/payment-completed.component';
//import { UserService } from './services/user/user.service';
import { ExportService } from './services/export/export.service';
import { ClientService } from './services/client/client.service';
import { LocationService } from './services/location/location.service';
import { TwilioService } from './services/twilio/twilio.service';
import { ResponseServiceService } from './services/response-service/response-service.firebase.service';
import { UploadService } from './services/upload/upload.service';
import { PromoCodeComponent } from './pages/public/promo-code/promo-code.component';
import { GroupShareComponent } from './pages/public/group-share-code/group-share-code.component';
import { IntercomComponent } from './pages/public/intercom/intercom.component';
import { IntercomModule } from 'ng-intercom';
import { CustomClientService } from './services/customClients/customClient.service';
import { PrivacyPolicyAfriform } from './pages/public/privacy-policy-afriform/privacy-policy-afriform.component';

import { FunctionDataService } from './services/function/function.data.service';
import { MatTableResponsiveModule } from './shared/mat-table-responsive/mat-table-responsive.module';
import { FunctionResponseServiceService } from './services/function/function.ResponseService.service'

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { AssetManagementService } from './services/asset-management/asset-management.service';
import { AssetManagementDialogComponent } from './pages/admin/admin-pages/asset-management/asset-management-dialog.component';
import { ConfirmationDialogComponent } from './pages/Common/confirmation-dialog.component';
import { MessageDialogComponent } from './pages/Common/message-dialog.component';
import { ResourceManagementService } from './services/resource-management/resource-management.service';
import { AdminDashboardJourneyManagementAssignmentDialogComponent } from './pages/admin/admin-pages/dashboard/admin-dashboard-journey-management-assignment-dialog.component';
import { ClientManagementService } from './services/client-management/client-management.service';
import { LinkPassengerComponent } from './pages/Common/link-passenger-dialog.component';
import { BookingManagementService } from './services/booking-management/booking-management.service';
import { AdminDashboardJourneyOverviewDialogComponent } from './pages/admin/admin-pages/dashboard/admin-dashboard-journey-overview-dialog.component';
import { RequestManagementDialogComponent } from './pages/admin/admin-pages/journey-management/request-management-dialog.component';
import { UserManagementService } from './services/user-managment/user-management.service';
import { UserManagementDialogComponent } from './pages/provider/provider-pages/user-management/user-management-dialog.component';
import { timeStampDataService } from './services/function/function.timestamp.service';
import { ImageModalComponent } from '../app/pages/provider/provider-pages/response-service-action/response-service-action/image-modal/image-modal.component';
import { ResponseServiceActionsManagementService } from './services/response-service-actions-management/response-service-actions-management.service';

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatDividerModule,
    MatTableResponsiveModule,
    TimezonePickerModule,
  ]
})
export class MaterialModule { }

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes),
    //HttpModule,
    MaterialModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDTjYKGpkfuf4d9xLx_PY7IFBnNs_rCyeM'
    }),
    
    
    HttpClientModule,
    FooterModule,
    FixedpluginModule,
    TimezonePickerModule,
    ComponentsModule,
    //AngularFirestoreModule,
    IntercomModule.forRoot({
      appId: 'dbdmmuo7', // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),
    
    ReactiveFormsModule,
  ],
  declarations: [
    AppComponent,
    PrivacyPolicyWhiteLabel,
    PrivacyPolicyAfriform,
    PrivacyPolicyUsa,
    TermsAndConditionsUsa,
    PrivacyPolicy,
    PaymentComponent,
    IntercomComponent,
    PromoCodeComponent,
    GroupShareComponent,
    PaymentCompletedComponent,
    AssetManagementDialogComponent,
    ConfirmationDialogComponent,
    LinkPassengerComponent,
    MessageDialogComponent,
    AdminDashboardJourneyManagementAssignmentDialogComponent,
    AdminDashboardJourneyOverviewDialogComponent,
    RequestManagementDialogComponent,
    ImageModalComponent,
    UserManagementDialogComponent,
  ],
  providers: [
    DateFormatting,
    NumberFormatting,
    ObjectTransformations,
    Globals,
    TablesDotNet,
    DatePipe,
    UploadService,
    GlobalService,
    TwilioService,
    //UserService,
    ExportService,
    FunctionDataService,
    FunctionResponseServiceService,
    LocationService,
    ResponseServiceService,
    ClientService,
    CommunicationService,
    CustomClientService,
    AssetManagementService,
    BookingManagementService,
    ResourceManagementService,
    ClientManagementService,
    UserManagementService,
    ResponseServiceActionsManagementService,
    GoogleMapsAPIWrapper,
    timeStampDataService,
    // { provide: ErrorHandler, useClass: RollbarErrorHandler },
    // { provide: RollbarService, useFactory: rollbarFactory }
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ],
  entryComponents: [
    AssetManagementDialogComponent,
    ConfirmationDialogComponent,
    LinkPassengerComponent,
    MessageDialogComponent,
    AdminDashboardJourneyManagementAssignmentDialogComponent,
    AdminDashboardJourneyOverviewDialogComponent,
    RequestManagementDialogComponent,
    ImageModalComponent,
    UserManagementDialogComponent,
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
