import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssetManagementObject, FuelType, VehicleStatus } from 'app/interfaces/asset-management/asset-management-object.interface';
import { RequestManagementObject } from 'app/interfaces/request-management/request-management-object.interface';

@Component({
    selector: 'request-management-dialog.component',
    templateUrl: './request-management-dialog.component.html',
    styleUrls: [
        './request-management-dialog.component.css'
    ]
})
export class RequestManagementDialogComponent {
    dialogTitle: string;

    requestManagementForm: FormGroup;

    fuelTypes: string[] = Object.values(FuelType);
    vehicleStatuses: string[] = Object.values(VehicleStatus);

    constructor(public dialogRef: MatDialogRef<RequestManagementDialogComponent>, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data?: RequestManagementObject) {
        this.dialogTitle = data ? 'Update Client' : 'Add Client';
        
        this.requestManagementForm = this.formBuilder.group({
            requestorName: ['', Validators.required],
            telephoneNumber: ['',  Validators.pattern('[- +()0-9]+')],
            companyName: ['', Validators.required],
            fmnoNumber: ['', Validators.required],
            customChargeCode: [null, Validators.required],
        });

        if (this.data) {
            this.requestManagementForm.patchValue(this.data);
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        if (this.requestManagementForm.valid) {
            const requestManagementObject: AssetManagementObject = this.requestManagementForm.value;

            if (this.data) {
                requestManagementObject.key = this.data.key;
            }

            this.dialogRef.close(requestManagementObject);
        }
    }
}