import * as firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions

import * as moment from 'moment';

export class FirebaseHelper {
    static convertForFirebase<T>(obj: T, errorForUndefined: boolean = false): T {
        for (const key in obj) {
            const prop = obj[key];

            if (moment.isMoment(prop)) {
                obj[key] = firebase.firestore.Timestamp.fromDate(prop.toDate()) as any;
            }
            else if (typeof prop === 'object' && prop !== null) {
                obj[key] = this.convertForFirebase(prop, errorForUndefined);
            }
            else if (prop === undefined) {
                if (errorForUndefined) {
                    throw new Error(`Property ${key} is undefined and cannot be saved to Firebase.`);
                } 
                else {
                    delete obj[key];
                }
            }
        }

        return obj;
    }

    static convertFromFirebase<T>(obj: T): T {
        for (const key in obj) {
            const prop = obj[key];

            if (prop instanceof firebase.firestore.Timestamp) {
                obj[key] = moment(prop.toDate()) as any;
            }
            else if (typeof prop === 'object' && prop !== null) {
                obj[key] = this.convertFromFirebase(prop);
            }
        }

        return obj;
    }
}