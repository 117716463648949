<h2 mat-dialog-title>Link Passengers to {{ data.linkCollectionName }}</h2>

<mat-dialog-content class="content">
  <div class="row">
    <div class="col-sm-5 text-center">
      <h3>All Passengers</h3>
    </div>
    <div class="col-sm-2"></div>
    <div class="col-sm-5 text-center">
      <h3>Linked Passengers</h3>
    </div>
  </div>
  <div class="row align-items-center">
    <div class="col-sm-5" style="padding: 15px;">
      <mat-card>
        <mat-selection-list #allPassengersList class="scrollable-list">
          <mat-list-option *ngFor="let passenger of allPassengers" [value]="passenger">
            {{ passenger.name }}
          </mat-list-option>
        </mat-selection-list>
      </mat-card>
    </div>
    <div class="col-sm-2">
      <div class="row">
        <div class="col-sm-12">
          <button mat-button (click)="addAll()" class="btn fill btn-primary">&gt;&gt;</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <button mat-button (click)="addSelected()" class="btn fill btn-primary">&gt;</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <button mat-button (click)="removeSelected()" class="btn fill btn-primary">&lt;</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <button mat-button (click)="removeAll()" class="btn fill btn-primary">&lt;&lt;</button>
        </div>
      </div>
    </div>
    <div class="col-sm-5" style="padding: 15px;">
      <mat-card>
        <mat-selection-list #linkedPassengersList class="scrollable-list">
          <mat-list-option *ngFor="let passenger of linkedPassengers" [value]="passenger">
            {{ passenger.name }}
          </mat-list-option>
        </mat-selection-list>
      </mat-card>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="right-aligned-actions">
  <button mat-button (click)="onCancel()" class="btn">Cancel</button>
  <button mat-button (click)="onSubmit()" class="btn btn-primary">Submit</button>
</mat-dialog-actions>