import { Injectable } from '@angular/core';
import { apiUrlEnum } from 'app/enums/api-endpoints.enums';
import { ResponseServiceActionStateHistory, ResponseServiceAction, ResponseServiceActionChats } from 'app/interfaces/response-service.interface';
import { PushToUser } from 'app/interfaces/communication.interface';
import { UserDetail } from 'app/interfaces/user.interface';
import { ResponseServiceActionStateType } from 'app/types/responseService.types';
import { ResponderStatusType } from 'app/types/responseService.types';
import { AuthService } from '../../core/auth.service';
import { DataService } from '../dataService/data.service';
import { CustomClientService } from '../customClients/customClient.service';
import Swal from 'sweetalert2';
import { timeStampDataService } from "app/services/function/function.timestamp.service";

interface RespondersDetail {
    userDetail: UserDetail;
    distanceAway: number;
    outsideRadius: boolean;
    responderStatus: ResponderStatusType;
    marker: any;
}

@Injectable()
export class ResponseServiceService {

    // Constructor for the ResponseServiceService class
    constructor(private dataService: DataService, private authService: AuthService, private customClientService: CustomClientService,public timestampService: timeStampDataService) { 
        console.debug('ResponseServiceService: instantiated');
    }

    // Method to add history to a response service action
    async addResponderActionSaveStateHistory(message: string, state: ResponseServiceActionStateType, responseServiceAction: ResponseServiceAction, data?: any): Promise<string | void> {
        console.debug('ResponseServiceService: addResponderActionSaveStateHistory - start');

        try {
            let timestamp;
            await this.timestampService.getServerTimeStampData().then(data => { 
                timestamp = data;
            }).catch(rejection => {
                console.error(rejection);
                Swal.fire({
                    title: "Error Creating Timestamp for State History Update.",
                    text: rejection,
                    icon: "error",
                    confirmButtonText: "Ok",
                });
                return;
            });
            // Creating history object
            const historyObject: ResponseServiceActionStateHistory = {
                changeOwnerDisplayName: this.authService.appUser.userDetail.firstName + ' ' + this.authService.appUser.userDetail.lastName,
                changeOwnerKey: this.authService.appUser.userDetail.key,
                changeOwnerClientName: this.authService.appUser.userClient.name,
                changeOwnerClientKey: this.authService.appUser.userClient.key,
                dateUpdated: timestamp,
                state: state,
                message: message
            }

            // If additional data is provided, include it in the history object
            if (data) {
                historyObject.data = data;
            }

            // Push history object into response service action's state history
            responseServiceAction.stateHistory.push(historyObject)

            // Notify custom client service about response service status change
            this.customClientService.stratPayResponseServiceStatusChange(responseServiceAction)

            console.debug('ResponseServiceService: addResponderActionSaveStateHistory - end');
            return await this.dataService.updateFireStoreData('responseServiceActions', responseServiceAction);
        } 
        catch (exception) {
            console.error('ResponseServiceService: addResponderActionSaveStateHistory - error', exception);
        }
    }

    // Method to add a chat message to a response service action
    async addResponderActionChat(message: string, responseServiceAction: ResponseServiceAction, responders: RespondersDetail[]): Promise<string | void> {
        console.debug('ResponseServiceService: addResponderActionChat - start');

        try {
            let timestamp;
            await this.timestampService.getServerTimeStampData().then(data => { 
                timestamp = data;
            }).catch(rejection => {
                console.error(rejection);
                Swal.fire({
                    title: "Error Creating Timestamp for Chat.",
                    text: rejection,
                    icon: "error",
                    confirmButtonText: "Ok",
                });
                return;
            });
            // Creating chat object
            const chatObject: ResponseServiceActionChats = {
                responderKey: this.authService.appUser.userDetail.key,
                responderDisplayName: `${this.authService.appUser.userDetail.firstName} ${this.authService.appUser.userDetail.lastName}`,
                clientName: this.authService.appUser.userClient.name,
                clientKey: this.authService.appUser.userClient.key,
                date: timestamp,
                message: message
            }
    
            // If the responseChats array in the responseServiceAction object is null (i.e., no chats exist yet),
            // we initialize it to an empty array to avoid null pointer exceptions.
            if (responseServiceAction.responseChats == null) {
                responseServiceAction.responseChats = [];
            }
    
            // The chat object is then added to the responseChats array
            responseServiceAction.responseChats.push(chatObject);
    
            // Notify custom client service about response service status change.
            this.customClientService.stratPayResponseServiceStatusChange(responseServiceAction);
    
            responders.forEach(responder => {
                const pushToUser: PushToUser = {
                    message: message,
                    title: this.authService.appUser.userDetail.displayName,
                    fcmToken: responder.userDetail.fcmToken,
                    userKey: this.authService.appUser.userClient.key,
                };
    
                this.dataService.postToFunctions(
                    apiUrlEnum.communication_v1.push_user,
                    pushToUser,
                );
            });

            // [Alucard] Consider this to the above as a performance improvement and add to custom function
            /* let promises = responders.map(responder => {
                const pushToUser: PushToUser = {
                    message: message,
                    title: this.authService.appUser.userDetail.displayName,
                    fcmToken: responder.userDetail.fcmToken,
                    userKey: this.authService.appUser.userClient.key,
                };
            
                // `postToFunctions` needs to return a promise
                return this.dataService.postToFunctions(
                    apiUrlEnum.communication_v1.push_user,
                    pushToUser,
                );
            });
            
            // Run the promises in parallel
            Promise.all(promises).then((responses) => {
                // responses is an array of all the responses
                console.debug('All push notifications sent successfully');
            })
            .catch((error) => {
                console.error('Error sending push notifications', error);
            }); */

            console.debug('ResponseServiceService: addResponderActionChat - end');
            return await this.dataService.updateFireStoreData('responseServiceActions', responseServiceAction);
        } 
        catch (exception) {
            console.error('ResponseServiceService: addResponderActionChat - error', exception);
        }
    }

    // Method to update a chat message status
    async updateMessageStatus(chatMessage: ResponseServiceActionChats, responseServiceAction: ResponseServiceAction): Promise<string | void> {
        console.debug('ResponseServiceService: updateMessageStatus - start');

        try {
            // Find the chat message in the response service action's chats, and update its read status
            responseServiceAction.responseChats.find(async (foundChatMessage) => {
                if (foundChatMessage == chatMessage) {
                    let timestamp;
                    await this.timestampService.getServerTimeStampData().then(data => { 
                        timestamp = data;
                    }).catch(rejection => {
                        console.error(rejection);
                        Swal.fire({
                            title: "Error Creating Timestamp for Chate Message Update.",
                            text: rejection,
                            icon: "error",
                            confirmButtonText: "Ok",
                        });
                        return;
                    });
                    foundChatMessage.read = timestamp;
                }
            });

            // Notify custom client service about response service status change
            this.customClientService.stratPayResponseServiceStatusChange(responseServiceAction)

            console.debug('ResponseServiceService: updateMessageStatus - end');
            return await this.dataService.updateFireStoreData('responseServiceActions', responseServiceAction);
        } 
        catch (exception) {
            console.error('ResponseServiceService: updateMessageStatus - error', exception);
        }
    }
}
