<h2 mat-dialog-title>{{ dialogTitle }}</h2>

<mat-dialog-content>
  <mat-radio-group [(ngModel)]="selectedKey">
    <mat-list>
      <mat-list-item *ngFor="let action of data">
        <mat-radio-button [value]="action.key">{{ action.responseServiceName }} - {{ action.dateInitiated }} 
          <div class="label-with-badge"
            [ngClass]="action.state" matBadge="8" matBadgePosition="before" matBadgeColor="accent">
            {{ action.state }}
          </div>
        </mat-radio-button>
      </mat-list-item>
    </mat-list>
  </mat-radio-group>
</mat-dialog-content>

<mat-dialog-actions class="right-aligned-actions">
  <button mat-raised-button (click)="onCancel()" class="btn">Cancel</button>
  <button mat-raised-button (click)="onSubmit()" class="btn btn-primary">Open Response</button>
</mat-dialog-actions>