<div id="privacy_content">
    <h2>RESPONSE24 USA PRIVACY POLICY</h2>
    <p>



        RESPONSE24 USA PRIVACY POLICY


        PREAMBLE

        The nature of the Response24 App is such that we must be able to use Personal Information supplied by you to us
        to assist you in time of need.

        We cannot do so without your permission which is granted irretrievably and without conditions by downloading the
        Response24 App.

        The permissions so granted will never be abused by us, sold to Third Parties or acted outside the scope of this
        document.

        We collect data either directly from you with what you provide us, or through our website by the use of cookies.


        JURISDICTION

        This Privacy Policy (“Policy”) is subject to the Laws of the United States of America and governs the
        collection, management and disclosure of personal information that Response24 USA (“We”, “Us” or “Our”) collects
        through the Response24 Application (“Application”) as well as the Response24 Control Room (“Control Room”).

        We respect your rights to responsible management of your personal information. For the purposes of this policy,
        personal information is defined in accordance with the definition provided in the appropriate and applicable
        legislation.

        Your use of the Application is subject to this policy which is permission based. You decide what we can use and
        how we use it. By using this Application, you signify your consent, or if you are younger than 18, you
        acknowledge that you have obtained consent from a parent or legal guardian and that you are not in any way
        obscuring the duty to obtain such consent, to processing of your personal information in accordance with this
        Privacy Policy.

        Note that Response24 USA may amend this Policy from time to time. All amended terms shall be effective
        immediately upon the revised Policy being made available and any subsequent activity in relation to the
        Application shall be governed by such amended terms and conditions of usage.



        IMPORTANT: IF YOU DO NOT AGREE WITH ANY TERM IN THIS POLICY, PLEASE DO NOT USE THIS APPLICATION OR SUBMIT ANY
        PERSONALLY IDENTIFIABLE INFORMATION THROUGH THIS APPLICATION.

        This Policy was last revised in July 2020.

        You are advised to regularly check the website at www.response24usa.com for any amendments and updates.

        Enquires: info@response24usa.com


        DEFINITIONS

        “Application” means the Response24 Application that allows Users to request emergency assistance and download to
        your phone/device.

        “Child” shall mean a natural person under the age of 18 years.

        “Competent Person” means a person who is legally competent to consent to any action or decision being taken in
        respect of a matter concerning a child. This includes a parent and the legal definition of a guardian.

        “Cookies” means to be files with a small amount of data that are commonly used as anonymous unique identifies.
        These are sent to your browser from the websites that you visit and are stored on your device’s internal memory.
        This Service does not use these cookies explicitly. However, the app may use third-party code and libraries that
        use cookies to collect information and improve their services. You have the option to either accept or refuse
        these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may
        not be able to use some portions of this Service.

        “Log Data” means whenever you use our Service, in a case of an error in the app, we collect data and information
        (through third-party products) on your phone called Log Data. This Log Data may include information such as your
        device’s internet protocol (IP) address, device name, operating system version, the configuration of the app
        when utilizing our Service, the time and date of your use of the Service, and other statistics.







        “Personal Information” means personal information includes information related to the race, gender, sex,
        pregnancy, marital status, national, ethnic or social origin, colour, sexual orientation, age, physical or
        mental health, well-being, disability, religion, conscience, belief, culture, language and birth of a person;
        information relating to the education or the medical, financial, criminal or employment history of a person; any
        identifying number, symbol, e-mail address, physical address, telephone number, location information, online
        identifier or other particular assignment to a person; the biometric information of a person; the personal
        opinions, views or preferences of a person; correspondence sent by a person that is implicitly or explicitly of
        a private or confidential nature or further correspondence that would reveal the contents of the original
        correspondence; the views or opinions of another individual about a person; and the name of a person if it
        appears with other Personal Information relating to the person or if the disclosure of the name itself would
        review information about a person.

        “Special Personal Information” means personal information includes information related to the religious or
        philosophical beliefs, race or ethnic origin, trade union membership, political persuasion, health or sex life
        or biometric information of a person; or the criminal behaviour of a data subject to the extent that such
        information relates:

        a) to the alleged commission by a person of any offence; or
        b) any proceedings in respect of any offence allegedly committed by the person or the disposal of such
        proceedings.

        “User/s” means any individuals who make use of or access the Application. Note that this is a wider definition
        than merely the one paying for the RESPONSE24 App.


        1. Understanding Personal Information

        The term “Personal Information” here has the meaning given to it in the Act. Generally, it is any information
        that can be used to personally identify you. This may include your name, surname, address, telephone number
        and/or email address as described above. Should the information we collect personally identify you, or you are
        reasonably identifiable from it, the information will be considered personal information. Your Personal
        Information will not be shared, sold, rented or disclosed other than as described in this Privacy Policy and
        according to the permissions that you grant to us by using the RESPONSE24 App.








        The processing of Personal Information shall include the collection, receipt, recording, organization,
        collation, storage, updating or modification, retrieval, alteration, consultation, use; dissemination by means
        of transmission, distribution or making available in any other form; or merging, linking, as well as blocking,
        degradation, erasure or destruction of information. Response24USA strives to ensure that the conditions set out
        in the Act and all the measures that give effect to such conditions, are complied with when processing Personal
        Information. In particular, Response24USA strives to ensure that Personal Information and Special Personal
        Information is processed in a manner that is lawful, adequate, relevant and not excessive given the purpose of
        collection.

        2. Collection of Personal Information

        2.1 Response24USA collects and processes information that can be used to identify you personally that is
        necessary to providing, facilitating and maintaining the Application and associated services.

        2.2 Subject to consent, we collect Personal Information/Special Personal Information from the following
        entities:

        a) natural persons over 18;
        b) natural persons under the age of 18, we collect Special Personal Information only with the consent of a
        Competent Person;
        c) Registered Application users; and
        d) Individuals who send enquiries or requests to our contact email address.

        2.3 Children’s Privacy: We do not knowingly collect personal identifiable information from children younger than
        18. If we discover that a child under 18 has provided us with personal information, we will immediately delete
        this from our servers. If you are a parent or guardian and you are aware that your child has provided us with
        personal information, please contact us to that we will be able to perform the necessary actions. As a Competent
        Person, on behalf of the child, you consent to the collection, processing, sharing and storage of Special
        Personal Information for the purpose of facilitating access to the Application.

        2.4 Personal Information which may be collected by Response24USA includes:

        a) First and last name;
        b) Age;
        c) State of residence;
        d) Location information;
        e) Physical Address;
        f) Mobile phone number; and/or
        g) Email address.

        2.5 Response24USA collects and processes Personal Information that is provided to them by Users and/or a
        Competent Person in the following instances:

        a) On registration for use of the Application and associated services;
        b) Upon use of the Application;
        c) Upon requesting emergency assistance; and
        d) Via correspondence via email, fax, telephone and mail service.

        2.6. Response24USA retains records for a period that is longer than is required by law and you hereby consent to
        such retention of Personal Information/Special Personal Information.

        2.6 There may be instances where we may also collect information that is not Personal Information/Special
        Personal Information because it does not identify you or anyone else (we may collect anonymous answers to
        surveys or aggregated information about how you use our Application).

        2.7 If You use our Application, we use tracking technologies to collect information such your activity or the
        information you request. This information collected is kept confidential. The use of tracking technologies
        enables us to provide the emergency response service.

        2.8 Your location and privacy are of utmost importance to us. We have given you the option to share your
        location so that we are able to determine your location in an emergency. Your location is only shared with
        providers that you choose to share it with, including medical response providers, armed response providers or
        friends and family. During an emergency, your location will be sent to our providers to assist you as quickly as
        possible but will be unshared as soon as an emergency is completed.


        3. Use and Purpose of Collecting Personal Information

        3.1 The primary purpose for collecting and processing Your Personal Information is to enable us to provide you
        access to the Application and the emergency response service. Response24USA also collects and processes Personal
        Information in order:

        a) to identify the User to affect the Application registration process;
        b) to provide information to emergency response operators when assistance is requested;
        c) to re-confirm user information whenever the User logs onto a new device;
        d) to communicate with the User;
        e) to verify the User’s identity;
        f) to process and respond to any complaint made by You;



        g) to enable ease of use of the Application;
        h) to assess the performance of the Application and emergency response service;
        i) to engage in legitimate electronic communications and transactions with You;
        j) to provide feedback on any related enquiries;
        k) to provide You with direct marketing material;
        l) to update our records and keep Your contact details up to date; and
        m) to comply with any law, rule, regulation, lawful and binding determination, decision or direction of a
        regulator, or in co-operation with any governmental authority of any country.

        3.2 We are also able to use the information collected, including the voice recordings, to improve our internal
        technical and business processes. We research users’ habits, measure traffic patterns in the aggregate, analyse
        trends, administer the Application, track users’ actions and gather broad demographic information.

        3.3 In some instances, Response24USA may also collect sensitive information about users, such as information
        their racial or ethnic origin. Consent must be obtained for the collection of such information.



        4. Consent for Processing Personal Information

        4.1 By using the Response24 Application, you hereby consent to Your Personal Information (including Special
        Personal Information) for the abovementioned purposes, associated purposes and to:

        a) update our records;
        b) keep Your contact details up to date; and
        c) to comply with any law, rule, regulation, lawful and binding determination, decision or direction of a
        regulator, or co-operate with any governmental authority of any country.

        4.2 By Using the Application, you represent that you are of the age of 18 or where you are under 18, a Competent
        Person has given consent to allow you to utilize this Application. This consent is effective on access to the
        Application.

        4.3 You, or a Competent Person in the case of a child, consent/s to the processing of Personal
        Information/Special Personal Information. As a Competent Person you agree that giving such consent means that
        You have given Response24 permission to collect and process the child’s Special Personal Information in the ways
        described in this document.



        4.4 You understand that your consent to the processing of Your Personal Information/Special Personal Information
        by Response24 pertains to the provision of Information and the Application; as well as associated purposes.

        4.5 Should You select the option to join the Response24 mailing list and receive news alerts, you consent to the
        receipt of direct marketing communications regarding all business activities and news.

        4.6 You expressly consent to Response24 retaining Your Personal Information/Special Personal Information once
        your relationship with Response24 has been terminated and using the information for aggregate, statistical and
        reporting purposes.



        5. Handling of Personal Information

        5.1 Response24 endeavours to comply with all laws and regulations applicable to Response24 pertaining to
        information and communications privacy. The Response24USA Privacy Policy strives to be compliant to the EU
        General Data Protection Regulations (GDPR) and is. However, should there be a clash between legal systems, the
        laws governing the United States of America will be followed since Response24USA is an American based and
        registered company.

        5.2 Response24 seeks to ensure the quality, accuracy and confidentiality of Personal Information in its
        possession. You warrant that all Personal Information supplied by You is both true and correct at the time of
        provision. In the event of any aspect of this Personal Information changing post submission, it is Your
        responsibility to immediately notify Response24 of the said changes by email to: info@response24usa.com.

        5.3 You agree to indemnify Response24USA, its officers, directors, employees, agents and suppliers harmless from
        and against any claims, damages, actions and liabilities including without limitation, indirect, incidental,
        special, consequential or punitive damages arising out Response24USA’s reliance on your personal information
        should your Personal Information contain any errors or inaccuracies.

        5.4 You warrant that You have the authority, permissions and consent to provide Response24USA with any
        third-party information submitted to Response24USA.









        5.5 Response24USA will take all reasonable measures to ensure that Your Personal Information is appropriately
        safeguarded. These precautions include, but are not limited to: firewalls, access control mechanisms via private
        keys, and software protection for information security.


        6. Third Party Access to Personal Information

        6.1 Response24USA may share Your Personal Information with authorized third parties such as service providers to
        Response24USA. These include, but are not limited to, data hosting service providers, emergency service
        providers like ambulances and private security companies.

        6.2 When emergency assistance is requested via the Application, Response24USA will share your Personal
        Information with third parties such as the emergency responder. You consent to this sharing of information. They
        cannot assist you without the data.

        6.3 Response24USA does not permit these parties to use such information for any other purpose than to perform
        the service that Response24USA has instructed them to provide. All processing is compatible with such purpose.

        6.4 Response24USA may appoint certain agents, third parties and/or service providers which operate outside the
        borders of the United States of America. In these circumstances Response24USA will be required to transmit Your
        Personal Information outside the United States of America. The purpose of the trans-border transfer of Your
        Personal Information may include, but not limited to: data hosting and storage. Your expressly consent to the
        trans-border flow of Your Personal Information. All reasonable measures to ensure that Your Personal Information
        is appropriately safeguarded will be taken. These precautions include, but are not limited to, firewalls, access
        control mechanisms via private keys, and software protection for information security.


        7. Retention and Restriction of Records

        7.1 On receipt of instructions from You, Response24USA will destroy or delete a record of Personal Information
        about you which Response24USA is no longer authorized to retain. This is subject to certain exceptions such as
        retaining records that Response24USA is required by law to retain. Response24USA is entitled to retain
        de-identified information for authorized or legitimate purposes.





        7.2 In the event that You wish to revoke all consent pertaining to Your Personal Information and/or you would
        like Response24USA to remove and/or delate Your Personal Information entirely, you must contact Response24USA
        via email: info@response24usa.com.

        7.3 Records relating to the provision of information to and/or by you can be retaining by Response24USA in terms
        of legislative records retention provisions and includes Your Personal Information and/or Special Personal
        Information.

        7.4 Response24USA retains records for a period that is longer than is required by law for statistical or
        research purposes.

        7.5 Response24USA will not sell or abuse your records outside what is agreed to and explained here.



        8. Updating Personal Information

        8.1 You may request access to any Personal Information/Special Personal Information we hold about you at any
        time by contacting us at: info@response24usa.com.

        8.2 If You believe that Personal Information/Special Personal Information we hold about You is incorrect,
        incomplete or inaccurate, or outdated (say when you got married or moved houses or medical change), then you may
        request us to amend it. We will consider if the information requires amendment. If we do not agree that there
        are ground for amendment or that we cannot amend the information as required, we will retain a note about the
        request.

        8.3 Where we hold information that you are entitled to access, we will try to provide you with suitable means of
        accessing is (for example, by mailing or emailing it to you). We may charge you a fee for our administrative and
        other reasonable costs in providing the information to You. We will not charge for making the request and will
        not charge for making any corrections or updates to Your Personal Information/Special Personal Information.

        8.4 There may be instances where we cannot grant you access to the Personal Information/Special Personal
        Information we hold. We may need to refuse access if granting access would interfere with the privacy of others
        or if it would result in a breach of confidentiality. Should this be the case, we will give you written reasons
        for any refusal.




        9. Disclosure of Personal Information

        9.1 Business records relating to the provision of information or the Application to You can be retained by
        Response24USA in terms of legislative records retention provisions and includes Your Personal Information.
        Records can be retained for operational purposes or for evidential purposes. Where Response24USA shares Your
        Personal Information/ Special Personal Information with Employees and other Users, Response24USA does not permit
        such parties to use the information for any other purpose than those pertaining using, maintaining and/or
        running the Application and associated services.

        9.2 We may disclose Your Personal Information/Special Personal Information to:

        a) our employees to enable us to operate;
        b) fulfil requests by You, and to otherwise provide information and services to You;
        c) suppliers and other third parties with whom we have commercial relationships, for business and related
        purposes; and/or
        d) any organization for any authorized purpose with Your express consent.

        9.3 We may further disclose Your Personal Information/Special Personal Information in the following instances:

        a) to comply with the law or legal processes;
        b) to protect Response24USA’s rights, facilities and other property;
        c) to protect Response24USA against any misuse or unauthorized use of the Application; and/or
        d) to protect Users or third parties affected negatively by Your action in using the Application.

        9.4 We will disclose Personal Information/Special Personal Information to our service providers who use software
        programmes and hardware devices to monitor usage to identify unauthorized attempts to access, upload, damage or
        alter information that it has stored.

        9.5 Personal Information is stored on servers both inside and outside the United States of America.

        9.6 We have placed security safeguards to protect Your Personal Information/Special Personal Information through
        agreements concluded with third party service providers. We will not be held liable for any illegal activity or
        other activity that may result in a claim for damages due to lack of protection of Your Personal
        Information/Special Personal Information whilst under the care of the third party.



        10. Security of Personal Information

        10.1 We take reasonable steps to ensure Your Personal Information/Special Personal Information is protected from
        misuse, loss and from unauthorized access, modification or disclosure. We may hold Your Personal Information in
        either electronic or hard copy form. We use third parties who may have access to Your Personal Information and
        who have undertaken to protect Your Personal Information.

        10.2 Should an unauthorized person/s gain access to Your Personal Information Response24USA will contact you
        within a reasonable period of time to inform you of such access/breach.

        10.3 As our Application is linked to the Internet, and the Internet is inherently insecure, we cannot provide
        any assurance regarding the security of transmission of information that you communicate to us online. We also
        cannot guarantee that the information that you supply will not be intercepted while being transmitted over the
        Internet. Accordingly, any Personal Information/Special Personal Information or other information which You
        transmit to us online is transmitted at Your own risk.

        10.4 Links to Other Sites: This Service may contain links to other sites. If you click on a third-party link,
        you will be directed to that site. Please note these external websites are not operated by us. We have no
        control over, nor do we assume any responsibility for the content, privacy policies or practices of any
        third-party sites or services. We strongly advise you to review the privacy policies of these websites.


        11. Data Subject Participation

        Should You have enquiries concerning Personal Information processed by Response24USA, you can request feedback
        that describes the Personal Information held by Response24USA, and information about third parties, or
        categories of third parties who provide services to Response24USA, who have, or have had, access to the
        information. You may request Response24USA to correct or delete Your Personal Information in its possession or
        under its control that is inaccurate, irrelevant, excessive, misleading or obtained unlawfully; or destroy or
        delete a Personal Information that Response24USA is no longer authorized to retain. As a User, you have the
        right to request access, correction or deletion of Your Personal Information.








        12. Indemnity

        You agree to indemnify Response24USA, its officers, directors, employees, agents, licensors, suppliers and
        members harmless from and against any claims, damages, actions and liabilities including without limitation,
        loss of profits, direct, indirect, incidental, special, consequential or punitive damages arising out of
        Response24USA’s reliance on Your Personal Information should Your Personal Information contain any errors,
        inaccuracies or be irrelevant to the use of the Application.


        13. Contacting Us

        13.1 Should you have any questions about this Privacy Policy, or require a correction to be made to Your
        Personal Information that Response24USA keeps on record, or a copy of the record itself, or should You feel that
        you would like to object to the collection, use or processing of Your Personal Information by Response24USA, or
        that you would like to delete.







        July 2020


    </p>

</div>