import { MatDialogRef } from '@angular/material/dialog';
var AdminDashboardJourneyOverviewDialogComponent = /** @class */ (function () {
    function AdminDashboardJourneyOverviewDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    AdminDashboardJourneyOverviewDialogComponent.prototype.ngOnInit = function () {
    };
    AdminDashboardJourneyOverviewDialogComponent.prototype.getPassengerNames = function (keys) {
        if (!keys || keys.length === 0) {
            return [];
        }
        var names = [];
        var _loop_1 = function (key) {
            var passenger = this_1.data.journeyManagementObject.passengerInformation.find(function (p) { return p.key === key; });
            if (passenger) {
                names.push(passenger.name);
            }
        };
        var this_1 = this;
        for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
            var key = keys_1[_i];
            _loop_1(key);
        }
        return names;
    };
    AdminDashboardJourneyOverviewDialogComponent.prototype.onClose = function () {
        this.dialogRef.close();
    };
    return AdminDashboardJourneyOverviewDialogComponent;
}());
export { AdminDashboardJourneyOverviewDialogComponent };
