import { Inject, Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AuthService } from 'app/core/auth.service';

import { FIREBASE_REFERENCES } from 'app/core/firebase/firebase.module';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserDetail } from 'app/interfaces-plus/user.interface';

/**
 * Represents an event in the user management system.
 */
export interface UserManagementEvent {
    type: 'added' | 'modified' | 'removed';
    data: UserDetail;
    id: string;
}

@Injectable({
    providedIn: 'root'
})
export class UserManagementService {
    /**
     * Creates an instance of UserManagementService.
     * @param authService - The authentication service.
     * @param firestore - The AngularFirestore instance.
     */
    constructor(private authService: AuthService, @Inject(FIREBASE_REFERENCES.TWO_FIRESTORE) private readonly firestore: AngularFirestore) {
        console.debug('UserManagementService: instantiated');
    }

    /**
     * Watches the user management objects for a specific client.
     * If no client key is provided, it uses the client key of the authenticated user.
     * @param clientKey - The client key to filter the user management objects.
     * @returns An observable that emits an array of UserManagementEvent objects.
     */
    watchUserManagementObjects(clientKey?: string): Observable<UserManagementEvent[]> {
        let collection: AngularFirestoreCollection<UserDetail>;

        const chosenClientKey = clientKey || this.authService.appUser.userDetail.clientKey;

        collection = this.firestore.collection<UserDetail>('users', ref => ref.where('clientKey', '==', chosenClientKey));

        return collection.snapshotChanges().pipe(
            map(events => {
                return events.map(event => {
                    return { 
                        type: event.type as 'added' | 'modified' | 'removed',
                        data: event.payload.doc.data(),
                        id: event.payload.doc.id
                    };
                })
            })
        );
    }

    /**
     * Watches a specific user management object.
     * @param key - The key of the user management object to watch.
     * @returns An observable that emits a UserManagementEvent object.
     */
    watchUserManagementObject(key: string): Observable<UserManagementEvent> {
        const docRef = this.firestore.collection('users').doc<UserDetail>(key);
    
        return docRef.snapshotChanges().pipe(
            map(event => {
                return { 
                    type: event.type as 'added' | 'modified' | 'removed',
                    data: event.payload.data(),
                    id: event.payload.id
                };
            })
        );
    }
}
