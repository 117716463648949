<div id="privacy_content">
    <h2>TERMS AND CONDITIONS
        RESPONSE24USA - SECURITY APPLICATION END USER AGREEMENT (EULA)
    </h2>
    <p>

        This Mobile Application End User Agreement (“Agreement”) is a binding agreement between you (“you”) - the user
        (“User”) - and Response24USA (“Response24USA”). This Agreement governs your use of the Response24USA application
        (the “Application”) on your mobile device (“Mobile Devices”; either Android or IOS), including all documentation
        related to the Application.

        The Application is licensed, not sold, to you. By downloading, installing, accessing and/or using the
        Application and/or subscribing to or otherwise using the services provided by Response24USA, you (a) acknowledge
        that you have read and understand this agreement; (b) represent that you are legally entitled to enter into a
        binding agreement; and (c) accept this agreement and agree that you are legally bound by its terms. If you do
        not agree to these terms, do not download, install or use the Application and delete it from your Mobile Device.

        The Application has specifically been developed for the use with certain Mobile Devices that incorporate GPS
        (global positioning facility). It is therefore assumed that you (a) understand the functionality and
        restrictions of your Mobile Device and (b) understand and are bound by any other terms and conditions that exist
        when using your Mobile Device and any networks that may provide services to your Mobile Device.

        Your right to use the Application may be revoked at any time and you will have no claim against anybody in such
        event, nor will you dispute the legality of such actions or question the facts that lead to such a decision.

        Response24USA may amend this Agreement at any time. All amended terms will be effective automatically and
        immediately upon the publication of the revised Agreement, and any subsequent activity in relation to the
        Application will be governed by such amended Agreement. This Agreement was last revised on 4 August 2020.

        1. SERVICES

        (a) Mobile Device owned or otherwise controlled by you, Response24USA will aim to assist you by providing
        services (the “Services”) to you by implementing Response24USA’s then prevailing protocols (“Protocols”)
        relating to alerts.

        (b) In all events, the scope of the Services will be subject to the Protocols. The Protocols will be as
        determined by Response24USA from time to time by its discretion and will include some or all of the following:
        (i) attempt to contact you telephonically to ascertain the nature of your alert and the assistance required by
        you,
        (ii) detecting your location via your Mobile Device,
        (iii) alerting emergency services of your location and of your alert,
        (iv) relaying medical information provided by you, if any, to emergency medical service providers and
        (v) notifying your nominated next of kin of your alert.




        (c) The Services will be conditional upon this Agreement, including your activating the Services, your complying
        with such conditions for such activation as Response24USA may stipulate from time to time, you paying such fees,
        charges and/or subscriptions (“Subscriptions”) as Response24USA may determine from time to time and you
        furnishing Response24USA with such information as it may require from time to time. This service will only work
        in areas that have assigned security and medical emergency first responders.


        2. SUBSCRIPTIONS

        All Subscriptions will be payable by you to Response24USA from time to time without deduction or set off, free
        of bank charges and exchange and in such a manner as Response24USA may determine from time to time. Value Added
        Tax will be payable by you to Response24USA on the Subscriptions.

        Time will be of the essence in relation to payment of all Subscriptions. Without derogating from the generality
        of its remedies, Response24USA will be entitled to raise interest on any Subscriptions that are overdue for
        payment at its banker’s prevailing prime rate from time to time. If at any time any payment shall be overdue,
        then, without prejudice to any other legal remedy, Response24USA, without prior notice, may cease the operation
        of the Application and all services provided thereunder until payment is made, and may at any time cancel the
        remainder of the contract and recover from the User all monies due, or which may become due, under this
        Agreement for the duration hereof together with payment of such damages as Response24USA may have sustained.


        3. THIRD-PARTY SERVICE PROVIDERS

        (a) Response24USA provides only security services and not ambulance, emergency medical or any similar services.
        You acknowledge and understand the offered security services are limited as to those contemplated in the
        Protocols as set out in clause 1 above.

        (b) Any person who raises, or causes to raise, an alert may be liable for all and any costs associated with such
        an alert. You specifically indemnify and hold Response24USA harmless against any such claims that may arise due
        the use of the Application and/or the Services. In addition to the Subscriptions, you will be liable for, and
        indemnify and hold Response24USA harmless against, all charges raised by Third-Party Service Providers arising
        from the Services at the Third-Party Service Providers’ then prevailing rates from time to time.

        (c) You acknowledge that although Response24USA may from time to time recommend Third-Party Service Providers
        and/or enter into cooperation agreements with them, Response24USA makes no representations and gives no
        warranties regarding any aspect of the Services provided to you by Third-Party Service Providers. You waive and
        abandon any claims of any nature whatsoever against Response24USA relating in any manner to any goods or
        services provided to you by any Third-Party Service Providers. In simple terms, Response24USA is not and cannot
        be held liable in law for the actions or omissions of Third-Party Service Providers.


        4. LICENSE GRANT

        Response24USA grants you a limited, non-exclusive, and non-transferable license to:

        (a) download, install and use the Application for your personal, non-commercial use on a single Mobile Device
        strictly in accordance with this Agreement and solely for the purposes of the Services; and

        (b) access and use the Services made available by or otherwise accessible through the Application on the Mobile
        Device, strictly in accordance with this Agreement and the terms of use applicable to such Services.


        5. LICENSE RESTRICTIONS

        You will not:

        (a) copy the Application;

        (b) modify, translate, adapt, or otherwise create derivative works or improvements, whether or not patentable,
        of the Application;

        (c) reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain access to the
        source code of the Application or any part thereof;

        (d) remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent, or other intellectual
        property or proprietary rights notices from the Application, including any copy thereof;

        (e) rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available the
        Application, or any features or functionality of the Application, to any third party for any reason, including
        by making the Application available on a network where it is capable of being accessed by more than one device
        at any time;

        (f) remove, disable, circumvent, or otherwise create or implement any workaround to any copy protection, rights
        management, or security features in or protecting the Application;

        (g) use the Application in, or in association with, the design, construction, maintenance, or operation of any
        hazardous environments or systems, including any power generation systems; aircraft navigation or communication
        systems, air traffic control systems or any other transport management systems; safety-critical applications,
        including medical or life-support systems, vehicle operation applications or any police, fire, or other safety
        response systems; and military or aerospace applications, weapons systems, or environments;

        (h) Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Response24USA website or Application;


        (i) Except as may be the result of standard search engine or internet browser usage, use, launch, develop, or
        distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or
        offline reader that accesses the Application, or using or launching any unauthorised script or other software;

        (j) Attempt to bypass any measures of the Application designed to prevent or restrict access to the Application
        or any portion of the Application;

        (k) Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of
        the Application to you;

        (l) Doing anything harmful that might cross the line of “I’ll know it when I see it”; or

        (m) You may not use the Application for any purpose that is unlawful or prohibited by these terms of Service,
        any applicable additional or amended terms of Service, or any other conditions or notices that are made
        available on the Application or website. Unauthorised use of this Application may result in Response24USA
        instituting a claim for damages against you and/or you may be found guilty of a statutory and/or criminal
        offence. By using or accessing the Application, you agree to refrain from the following conduct:

        i) transmitting any worms, viruses, trojan horses, time bombs, or cancelbots and/or other codes or malware of a
        destructive nature. This includes not interfering with the operation of any of the tools, scripts, routines or
        codes of the Application and/or the services;

        ii) intercepting any data or personal information of any User or third party transmitted on or via the
        Application;

        iii) using the Application to perform or promote any act that is unlawful, misleading, malicious, defamatory, or
        discriminatory;

        iv) performing any action that may disable, override or impair the efficient and/or proper operation or working
        of the Application;

        v) sharing content, taking or mobilising action using the Application in a way that infringes or violates any
        person's rights or the laws of any jurisdiction;

        vi) utilising the Application for any illegal or unauthorised purposes; and

        vii) violating any laws in any jurisdiction, including but not limited to any intellectual property laws.


        6. TERM AND TERMINATION

        (a) The term of Agreement commences when you download the Application and will continue in effect until
        terminated by you or Response24USA as set forth in this clause (6).

        (b) You may terminate this Agreement by deleting the Application and all copies thereof from your Mobile Device.

        (c) Response24USA may terminate this Agreement at any time without notice. In addition, this Agreement will
        terminate immediately and automatically without any notice if you violate any of the terms and conditions of
        this Agreement.

        (d) Upon termination:
        (i) your right to receive the Services will cease;
        (ii) all rights granted to you under this Agreement will also terminate; and
        (iii) you must cease all use of the Application and delete all copies of the Application from your Mobile Device
        and account.

        (e) Termination will not limit any of Response24USA's rights or remedies at law or in equity.


        7. RESERVATION OF RIGHTS

        You acknowledge and agree that the Application is provided under license, and not sold to you. You do not
        acquire any ownership interest in the Application under this Agreement, or any other rights thereto, other than
        to use the Application in accordance with the license granted, and subject to all terms, conditions, and
        restrictions, under this Agreement.

        Response24USA and its licensors and service providers reserve and will retain their entire right, title, and
        interest in and to the Application, including all copyrights, trademarks, and other intellectual property rights
        therein or relating thereto, except as expressly granted to you in this Agreement.


        8. COLLECTION AND USE OF YOUR INFORMATION

        You acknowledge that when you download, install, or use the Application, Response24USA may use automatic means
        (including, for example, cookies and web beacons) to collect information about your Mobile Device and about your
        use of the Application. You also may be required to provide certain information about yourself as a condition to
        downloading, installing, or using the Application or certain of its features or functionality.

        You explicitly agree that your personal information, including that information contemplated in clause 1(b), may
        be made available to third parties during the Services. All information Response24USA collects through or in
        connection with this Application is subject to your Security Provider’s Privacy Policy [this is available on
        request directly from your Security Provider]. By downloading, installing, using, and providing information to
        or through this Application, you consent to all actions taken by us with respect to your information in
        compliance with the Privacy Policy. Note that you must be above 13 years of age to use this Application.






        9. GEOGRAPHIC RESTRICTIONS

        The Services are based in the Republic of South Africa and provided for access and use only by persons located
        in the Republic of South Africa. You acknowledge that you may not be able to access all or some of the Services
        outside of the Republic of South Africa and that access thereto may not be legal by certain persons or in
        certain countries. If you access the Services from outside the Republic of South Africa, you are responsible for
        compliance with local laws.


        10. UPDATES

        Response24USA may from time to time in its sole discretion develop and provide Application updates, which may
        include upgrades, bug fixes, patches, other error corrections, and/or new features (collectively, including
        related documentation, “Updates”). Updates may also modify or delete in their entirety certain features and
        functionality. You agree that Response24USA has no obligation to provide any Updates or to continue to provide
        or enable any particular features or functionality. Based on your Mobile Device settings, when your Mobile
        Device is connected to the internet, either:

        (a) the Application will automatically download and install all available Updates; or

        (b) you may receive notice of or be prompted to download and install available Updates.
        You will promptly download and install all Updates and acknowledge and agree that the Application or portions
        thereof may not properly operate should you fail to do so. You further agree that all Updates will be deemed
        part of the Application and be subject to all terms and conditions of this Agreement.


        11. THIRD-PARTY MATERIALS

        The Application may display, include, or make available third-party content (including data, information,
        applications, and other products, services, and/or materials) or provide links to third-party websites or
        services, including through third-party advertising (“Third-Party Materials”). You acknowledge and agree that
        Response24USA is not responsible for Third-Party Materials, including their accuracy, completeness, timeliness,
        validity, copyright compliance, legality, decency, quality, or any other aspect thereof. Response24USA does not
        assume and will not have any liability or responsibility to you or any other person or entity for any
        Third-Party Materials. Third-Party Materials and links thereto are provided solely as a convenience to you, and
        you access and use them entirely at your own risk and subject to such third parties' terms and conditions.


        12. FORCE MAJEURE

        Response24USA will not be in breach of this Agreement, or otherwise be liable to you, because of any delay in
        performance, or non-performance, of any of its obligations under this Agreement to the extent that such delay or
        non-performance is due to any Force Majeure. For the purposes of this clause (12), “Force Majeure” means, any
        circumstances beyond the reasonable control of Response24USA and which will be deemed to include:

        (a) any riot, commotion, disorder, strike, lock-out, go-slows, labour disturbances, work stoppages, acts of
        piracy or acts of sabotage, industrial action or labour unrest;

        (b) inadequate supply of materials, packaging, merchandise, equipment, supplies, material, or electricity;

        (c) war, hostilities (whether war be declared or not), invasion, rebellion, terrorism, revolution, insurrection,
        military or usurped power, strikes of a political nature, any act or campaign of terrorism, acts of foreign
        enemies;

        (d) natural catastrophes such as an earthquake, hurricane, typhoon or volcanic activity or flooding, lightening
        and specific incidents of exceptional adverse weather conditions;

        (e) compliance with any law;

        (f) an act of God;

        (g) limitations on or lack of cellular coverage, congestion, network coverage, dropped connections, the non- or
        defective performance of wireless enabled devices, content to or from you if your Mobile Device is not switched
        on or is not configured correctly, your GPS being activated and fully functional. your answer not being in the
        correct format, your Mobile Device not being within coverage or for any other reason being unreachable, you
        having a bar on text/WAP/Java services on your Mobile Device, you being a prepaid subscriber and you having run
        out of calling credit, your message mail box or phone memory being full, atmospheric conditions and other causes
        of interference to the Services and/or your Mobile Device;

        (h) a lack of electricity or insufficient mobile device battery life.


        13. DISCLAIMER OF WARRANTIES

        The Application is provided to the end user “as is” and with all faults and defects without warranty of any
        kind. To the maximum extent permitted under applicable law, Response24USA, on its own behalf and on behalf of
        its affiliates and their respective licensors and service providers (including the Third-Party Service Providers
        and those supporting this App technically), expressly disclaims all warranties, whether express, implied,
        statutory, or otherwise, with respect to the Application and/or Services and/or any aspect of them, including
        all implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, and
        warranties that may arise out of course of dealing, course of performance, usage, or trade practice. Without
        limitation to the foregoing, Response24USA provides no warranty or undertaking, and makes no representation of
        any kind that the Application or the Services will meet your requirements, achieve any intended results, be
        compatible, or work with any other software, applications, systems, or services, operate without interruption,
        meet any performance or reliability standards or be error-free, or that any errors or defects can or will be
        corrected. Without derogating from the foregoing:

        (a) you acknowledge that the availability of the Services is subject to the following requirements for the
        functionality of the Application:

        (i) the Mobile Device used to access the Application must be powered on;

        (ii) the Mobile Device used to access the Application must not be damaged such that it is unable to communicate
        (transmit) data to Response24USA or support services;

        (iii) the Mobile Device used to access the Application shall have text messaging capability;

        (iv) the Mobile Device used to access the Application must be within the relevant User’s communication network
        operator’s data coverage area;

        (v) your data communication services from the relevant network operator must be enabled and operational; and

        (vi) the GPS on the Mobile Device used to access the Application must be enabled.

        (b) Response24USA does not warrant that the mobile application portion of the Application will be compatible
        with your Mobile Device;

        (c) Response24USA does not warrant the correct functioning of the Application;

        (d) Response24USA does not warrant the response of the Third-Party Service Providers, nor does Response24USA
        warrant the quality of their services, the reaction time or if such Third-Party Service Providers will respond
        to you at all;

        (e) Response24USA does not warrant that the Services will be available to you at any or all times;

        (f) Response24USA does not warrant that the Application or the Services meet your requirements, work in
        combination with any hardware or software applications or third-party services, be uninterrupted, error-free, or
        without risk to, or loss of, any information, data, software or applications contained therein;

        (g) The Services are subject to normal constraints of the respective carriers of data and information; and

        (h) The use of the Application and the Services carries inherent risk and you agree to such risk and
        Response24USA has no liability of whatever nature if you use the Application, the Services or any component
        thereof.

        (i) Your subscription, whilst paid, gives you 6 (six) mobile armed responses a year without charge to you.

        (j) The mobile armed responses are broken down as follows: 4 (four) inside your Local Radius Footprint and 2
        (two) inside the National Footprint.

        (k) Mobile armed response services are described as the sending of armed security officers from a registered
        security company to assist you on demand.

        (l) Your local radius is described as the immediate area within 50kms in which you work or live. The burden is
        upon you to change your local radius if needs be – unless changed we will use whatever is on our documents /
        data base as being correct and true.

        (m) The National Footprint is described as outside your Local Radius but yet inside South Africa.

        (n) Your subscription, whilst paid, gives you 12 (twelve) medical emergency responses a year per member without
        charge to you.

        (o) A member is described and understood to be the one to whom the App is registered to and not necessarily the
        one paying for the App (it might be a gift). Should you wish to include family members or others, they too must
        buy a license or they will not be covered.

        (p) Note that you stay responsible for your medical costs incurred. We only make the alarm on your behalf. We
        don’t pay for your medical treatment.

        (q) Should you exceed your agreed limitations as set out above, a call out fee of R650.00 (Six Hundred and Fifty
        Rand) will be charged to your security bill for Mobile Armed response services for every additional call out.
        The above is subject to a 12-month subscription cycle. After the 12-month cycle is completed, regardless if you
        used the services or not, a new cycle starts. The call outs are not transferable.


        14. LIMITATION OF LIABILITY

        To the fullest extent permitted by applicable law, in no event will Response24USA or its affiliates, or any of
        its or their respective licensors or service providers (including the Third-Party Service Providers), have any
        liability arising from or related to your use of or inability to use the Application or the content and Services
        for:

        (a) Personal injury, property damage, lost profits, cost of substitute goods or services, loss of data, loss of
        goodwill, business interruption, computer failure or malfunction, or any other consequential, incidental,
        indirect, exemplary, special, or punitive damages.

        (b) Direct damages in amounts that in the aggregate exceed the amount actually paid by you for the Application
        or the actual paid proceeds of any insurances held by Response24USA in relation thereto.

        The foregoing limitations will apply whether such damages arise out of breach of contract, delict (including
        negligence), or otherwise and regardless of whether such damages were foreseeable or Response24USA was advised
        of the possibility of such damages. Some jurisdictions do not allow certain limitations of liability so some or
        all of the above limitations of liability may not apply to you.


        15. SOLE REMEDY

        Your sole and exclusive remedy should you be dissatisfied with the Service or Application is to delete the
        Application and cease using the Services.


        16. INDEMNIFICATION

        You agree to indemnify, defend, and hold harmless Response24USA and its officers, directors, employees, agents,
        affiliates and successors, and assign from and against any and all losses, damages, liabilities, deficiencies,
        claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever
        kind, including attorneys' fees, arising from or relating to your use or misuse of the Application or your
        breach of this Agreement, including but not limited to the content you submit or make available through this
        Application.


        17. SEVERABILITY

        If any provision of this Agreement is illegal or unenforceable under applicable law, the remainder of the
        provision will be amended to achieve as closely as possible the effect of the original term and all other
        provisions of this Agreement will continue in full force and effect.


        18. GOVERNING LAW AND DISPUTES

        This Agreement will be governed by the laws of the Republic of South Africa.

        a) Informal Negotiations
        To expedite resolution and control the cost of any dispute, controversy, or claim related to these terms of use
        (each a “Dispute” and collectively, the “Disputes”) brought by either you or us (individually, a “Party” and
        collectively, the “Parties”), the Parties agree to first attempt to negotiate any Dispute (except those Disputes
        expressly provided below) informally for at least thirty (30) days before initiating arbitration. Such informal
        negotiations commence upon written notice from one Party to the other Party.

        b) Binding Arbitration
        If the Parties are unable to resolve a Dispute through informal negotiations, the Dispute (except those Disputes
        expressly excluded below) will be finally and exclusively resolved by binding arbitration. You understand that
        without this provision, you would have the right to sue in court. The arbitration shall be commenced and
        conducted under the Arbitration Foundation of Southern Africa (AFSA). The arbitration may be conducted in
        person, through the submission of documents, by phone, or online. The arbitrator will make a decision in
        writing, but need not provide a statement of reasons unless requested by either Party. The arbitrator must
        follow applicable law, and any award may be challenged if the arbitrator fails to do so. Except where otherwise
        required by the applicable rules or applicable law, the arbitration will take place in Johannesburg.
        Application of the United Nations Convention on Contracts for the International Sale of Goods and the Uniform
        Computer Information Transaction Act (UCITA) are excluded from these terms of use.

        In no event shall any Dispute brought by either Party related in any way to the Application be commenced more
        than thirty days after the cause of action arose. If this provision is found to be illegal or unenforceable,
        then neither Party will elect to arbitrate any Dispute falling within that portion of this provision found to be
        illegal or unenforceable, and such Dispute shall be decided by a court of competent jurisdiction within the
        courts listed for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that
        court.

        c) Restrictions
        The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the
        full extent permitted by law,
        (i) no arbitration shall be joined with any other proceeding;
        (ii) there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilise class
        action procedures; and
        (iii) there is no right or authority for any Dispute to be brought in a purported representative capacity on
        behalf of the general public or any other persons.

        d) Exceptions to Informal Negotiations and Arbitration
        The Parties agree that the following Disputes are not subject to the above provisions concerning informal
        negotiations and binding arbitration:
        (i) any Disputes seeking to enforce or protect, or concerning the validity of, any of the intellectual property
        rights of a Party;
        (ii) any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorised
        use; and
        (iii) any claim for injunctive/interim relief.

        If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any
        Dispute falling within that portion of this provision found to be illegal or unenforceable, and such Dispute
        shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and the
        Parties agree to submit to the personal jurisdiction of that court.


        19. LIMITATION OF TIME TO FILE CLAIMS

        Any cause of action or claim you may have arising out of or relating to this Agreement or the Application must
        be commenced within thirty days after the cause of action accrues, otherwise such cause of action or claim is
        permanently barred.


        20. ENTIRE AGREEMENT

        This Agreement and our Privacy Policy constitute the entire agreement between you and Response24USA with respect
        to the Application and supersede all prior or contemporaneous understandings and agreements, whether written or
        oral, with respect to the Application.






        21. WAIVER

        No failure to exercise, and no delay in exercising, on the part of either party, any right or any power
        hereunder will operate as a waiver thereof, nor will any single or partial exercise of any right or power
        hereunder preclude further exercise of that or any other right hereunder. In the event of a conflict between
        this Agreement and any applicable purchase or other terms, the terms of this Agreement will govern.


        22. USER DATA

        We or our technical support will maintain certain data that you transmit to the Application or our website for
        the purpose of managing the performance of the Application, as well as data relating to your use of the
        Application. Although we perform regular routine backups of data, you are solely responsible for all data that
        you transmit or that relates to any activity you have undertaken using the Application. You agree that we shall
        have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action
        against us arising from any such loss or corruption of such data.


        23. ELECTRONIC COMMUNICATIONS, TRANSACTIONS AND SIGNATURES

        Visiting our website or using the Application, sending us emails, and completing online forms constitute
        electronic communications. You consent to receive electronic communications, and you agree that all agreements,
        notices, disclosures, and other communications we provide to you electronically, via email, satisfy any legal
        requirement that such communication be in writing. You hereby agree to the use of electronic signatures,
        contracts, orders, and other records, and to electronic delivery of notices, policies, and records of
        transactions initiated or completed by us or via the Application. You hereby waive any rights or requirements
        under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original
        signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any
        means other than electronic means.

        Information that must be provided by Response24USA to you in compliance with Section 43 of the Electronic
        Communications and Transactions Act 25 of 2002 (as amended) is available from Response24USA on request. You are
        advised to regularly check with them in terms of Service for any amendments or updates.

        Description of products and/or services offered: Mobile Medical and Security Response App
        supported by 911 Response24.

        Manner of payment for products and/or services offered: Monthly credit card payment. Once
        off registration per licence with monthly deductions.

        In any conflict between the provisions of this agreement and the content of the website, the provisions set out
        herein shall prevail.




        This App is supported and the technology owned by Response24USA. The following contains further information on
        Response24USA and represents the information that must be provided by Response24USA to you in compliance with
        Section 43 of the Electronic Communications and Transactions Act 25 of 2002 (as amended). You are advised to
        regularly check this section of the terms of Service for any amendments or updates.
        Full Name: 911 Rapid Response (Pty) Ltd t/a Response24 (Pty) Ltd
        Legal Status: Private Limited Company registered under South African law
        Company Registration Number: 2017/173766/07
        Place of Registration: Johannesburg
        Directors: Marlize Holtzhausen
        Physical Address: Caxton House, 368 Jan Smuts Ave, Craighall, Johannesburg, 2196
        Postal Address: Caxton House, 368 Jan Smuts Ave, Craighall, Johannesburg, 2196
        Telephone Number: 010 312 5026
        E-mail Address: info@response24.co.za
        Website Address: www.response24.co.za

        24. NETWORK ACCESS AND DEVICES

        You are responsible for obtaining the data network access necessary to use the Services. Your mobile network’s
        data and messaging rates and fees may apply if you access or use the Services from a wireless-enabled device and
        you shall be responsible for such rates and fees.

        You are responsible for acquiring and updating compatible hardware or devices necessary to access and use the
        Services and Applications and any updates thereto. Response24USA does not guarantee that the Services, or any
        portion thereof, will function on any particular hardware or devices. In addition, the Services may be subject
        to malfunctions and delays inherent in the use of the internet and electronic communications.

        Every effort is made to ensure that the Application is operational. However, Response24USA takes no
        responsibility for, and will not be liable for, the Application being temporarily unavailable due to technical
        issues beyond the control of Response24USA. Response24USA makes no warranty that the User's access to the
        Application will not be uninterrupted. Response24USA or its technical support may carry out maintenance or
        introduce new facilities and functions from time to time. The User agrees and accepts that as a result specific
        interruptions and unavailability of the Application and/or Service may occur.




    </p>

</div>