import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { ResponseService } from 'app/interfaces/response-service.interface';
import { UserDetailforResponderActivity } from 'app/interfaces/user.interface';
import { ClientNoCActivity } from 'app/interfaces/client.interface';
import { DatePipe } from '@angular/common';
// Define a type for Firebase timestamps
type FirebaseTimestamp = {
    seconds: number;
    nanoseconds: number;
};

@Injectable()
export class ExportService {
    constructor(private datePipe: DatePipe) { }

    /**
     * Check if a given object is a Firebase timestamp.
     *
     * @param obj - The object to check.
     * @returns - True if the object is a Firebase timestamp; otherwise, false.
     */
    private isFirebaseTimestamp(obj: any): obj is FirebaseTimestamp {

        // Date to Timestamp        
        const isTimestamp = obj && typeof obj === 'object' && 'seconds' in obj && 'nanoseconds' in obj;

        if (!isTimestamp) {
            ///console.debug(`Object is not a Firebase timestamp: ${JSON.stringify(obj)}`);
        }

        return isTimestamp;
    }
    
    /**
     * Convert a Firebase timestamp to a JavaScript Date object.
     *
     * @param timestamp - The Firebase timestamp to convert.
     * @returns - The converted Date object.
     */
    private convertTimestamp(timestamp: FirebaseTimestamp): Date {
        //console.debug(`Converting Firebase timestamp: ${JSON.stringify(timestamp)}`);

        return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    }
    
    /**
     * Flatten an object and its nested properties into a single-level object.
     *
     * @param obj - The object to flatten.
     * @param prefix - The prefix to use for flattened property names.
     * @returns - The flattened object.
     */
    private flattenObject<T>(obj: T, prefix = ''): { [k: string]: any; } {
        //console.debug(`Flattening object: ${JSON.stringify(obj)}`);
        return Object.keys(obj).reduce((acc, k) => {
            const pre = prefix.length ? prefix + '.' : '';
    
            if (this.isFirebaseTimestamp(obj[k])) {
                acc[pre + k] = this.convertTimestamp(obj[k]).toLocaleString();
            }
            else if (Array.isArray(obj[k])) {
                acc[pre + k] = obj[k].map(item => this.flattenObject(item));
            }
            else if (typeof obj[k] === 'object' && obj[k] !== null && !(obj[k] instanceof Date)) {
                Object.assign(acc, this.flattenObject(obj[k], pre + k));
            }
            else {
                acc[pre + k] = obj[k];
            }
        
            return acc;
        }, {});
    }
    

    // let arrayOfStringArrays: string[][] = [
    //    ["apple", "banana", "cherry"],
    //    ["dog", "cat", "rabbit"],
    //    ["red", "green", "blue"]
    //    ];
    /**
     * Export data to an Excel file.
     *
     * @param data - The data to export.
     * @param fileName - The name of the file to create.
     * @param sheetName - The name of the worksheet to create.
     * @param exclude - An array of property names to exclude from the export.
     */
    exportToExcel<T>(data: T[], fileName: string, sheetName: string, exclude: string[] = []): void {
        //console.debug(`Exporting data to Excel: ${JSON.stringify({ fileName, sheetName, exclude })}`);
        
        // Preprocess the data to exclude certain properties and flatten objects
        const filteredData = data.map(obj => {
            let newObj = this.flattenObject(obj);

            for (let key in newObj) {
                if (exclude.includes(key)) {
                    delete newObj[key];
                }
            }
    
            return newObj;
        });

     /*    var test = JsonToTable(data);

        debugger; */

        //convert to array of array
        let dataArr: any[][] = [];
        let headers = Object.keys(filteredData[0]); // Assuming non-empty dataset
        dataArr.push(headers);

        for (let obj of filteredData) {
            let row = headers.map(header => obj[header] === undefined ? null : obj[header]);
            dataArr.push(row);
        }

        

        // Create a new worksheet, with the created data
        //const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredData);
        const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataArr);
    
        // Create a new workbook, with the created worksheet
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    
        try {
            // Write the workbook to a file
            XLSX.writeFile(workbook, fileName + '.xlsx');
            console.debug(`Export successful: ${fileName}.xlsx`);
        } catch (err) {
            console.error(`Export failed: ${err}`);
        }
    }

    text_truncate = function(str, length, ending) {
        if (ending == null) {
            ending = '...';
        }
            
        if (str.length > length) {
            return str.substring(0, length - ending.length) + ending;
        } else {
            return str;
        }
    };

    async exportMultipleClientsToExcel<T>(responseServices: ResponseService[],responders: UserDetailforResponderActivity[],clientNOCActivity: ClientNoCActivity[], fileName: string, exclude: string[] = [],include:string[] = []): Promise <void> {
        //console.debug(`Exporting data to Excel: ${fileName}`);

        // Create a new workbook, with the created worksheet
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();

        //1 - Generate first Sheet - Which is the Clients NOC Activity
        // Preprocess the data to exclude certain properties and flatten objects
        const filteredData = clientNOCActivity.map(obj => {
            let newObj = this.flattenObject(obj);  
            
            for (let key in newObj) {
                if (exclude.includes(key)) {
                    delete newObj[key];
                }
            }

            return newObj;
        });

        //convert to array of array
        let dataArr: any[][] = [];
        var clientwscols = [];
        let headers = Object.keys(filteredData[0]); // Assuming non-empty dataset
        dataArr.push(headers);

        for (let obj of filteredData) {
            let row = headers.map(header => obj[header] == undefined ? null : obj[header]);
            dataArr.push(row);
        }

        for (var i = 0; i < headers.length; i++) {  // columns length added
            clientwscols.push({ wch: headers[i].length + 25 })
        }
        // Create a new worksheet, with the created data
        //const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredData);
        const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataArr);
              worksheet["!cols"] = clientwscols;
    
        XLSX.utils.book_append_sheet(workbook, worksheet, "Client NOC Activity");

        for await (const responseService of responseServices) {
            //Lets get all the Responders UserDetail Records
            const filteredResponders = [];
            
            for await (const responder of responseService.responders) {
                const responderDetail = responders.find((x)=>x.key == responder.key);
                if (responderDetail) { 
                    const responderDetailToShow = this.flattenObject(responderDetail);
                    for (let key in responderDetailToShow) {
                        if (!include.includes(key)) {
                            delete responderDetailToShow[key];
                        }
                    }
                    filteredResponders.push(responderDetailToShow);
                } 
            };
    
            //convert to array of array
            let respondersArr: any[][] = [];
            var wscols = [];
            if (filteredResponders.length >= 1) {
                let responderHeaders = Object.keys(filteredResponders[0]); // Assuming non-empty dataset
                    respondersArr.push(responderHeaders);

                    for (var i = 0; i < responderHeaders.length; i++) {  // columns length added
                        wscols.push({ wch: responderHeaders[i].length + 30 })
                    }
        
                for await (let obj of filteredResponders) {
                    let row = responderHeaders.map(header => obj[header] === undefined ? null : obj[header]);
                    respondersArr.push(row);
                }
            }

            const responseServiceWorksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(respondersArr);
            responseServiceWorksheet["!cols"] = wscols;

            XLSX.utils.book_append_sheet(workbook, responseServiceWorksheet,`${ this.text_truncate(`${responseService.clientName} - ${responseService.key}`,30,null)}`);
        };
    
        try {
            // Write the workbook to a file
            var date = this.datePipe.transform(new Date(), 'dd-mm-yyyy-hh-mm-ss')
            XLSX.writeFile(workbook, fileName +` - ${date}.xlsx`);
            console.debug(`Export successful: ${fileName}.xlsx`);
        } catch (err) {
            console.error(`Export failed: ${err}`);
        }
    }
}