import { MatDialogRef } from '@angular/material/dialog';
var MessageDialogComponent = /** @class */ (function () {
    function MessageDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.userMessage = '';
    }
    Object.defineProperty(MessageDialogComponent.prototype, "isSubmitEnabled", {
        get: function () {
            return (!this.data.minLength || this.userMessage.length >= this.data.minLength);
        },
        enumerable: true,
        configurable: true
    });
    MessageDialogComponent.prototype.onCancel = function () {
        this.dialogRef.close();
    };
    MessageDialogComponent.prototype.onSubmit = function () {
        if (this.data.minLength) {
            this.dialogRef.close(this.userMessage);
        }
        else {
            this.dialogRef.close("User Message: " + this.userMessage);
        }
    };
    return MessageDialogComponent;
}());
export { MessageDialogComponent };
