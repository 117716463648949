var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ObjectTransformations } from "app/providers/object-transformations";
import { AngularFirestore } from "@angular/fire/firestore";
import { DataService } from "../dataService/data.service";
import { environment } from "environments/environment";
import { GlobalService } from "../global/global.service";
import { IconColorEnum } from "app/enums/responseService.enums";
import { AuthService } from "../../core/auth.service";
import * as firebase from 'firebase/app';
import 'firebase/auth'; // for authentication
import 'firebase/storage'; // for storage
import 'firebase/database'; // for realtime database
import 'firebase/firestore'; // for cloud firestore
import 'firebase/messaging'; // for cloud messaging
import 'firebase/functions'; // for cloud functions
import { DataPlusService } from "../dataService/data.plus.service";
var uploadFiles = require("./uploadFiles.json");
// This service will be used to
// 1. Upload data from Excell spreadsheets
// 2. Gett data over from old database to new databse
// 3. Batch Update records
var UploadService = /** @class */ (function () {
    function UploadService(
    //private afs: AngularFirestore,
    afs, ot, dataServicePlus, dataService, globalService, 
    /* private userService: UserService */
    authService) {
        this.afs = afs;
        this.ot = ot;
        this.dataServicePlus = dataServicePlus;
        this.dataService = dataService;
        this.globalService = globalService;
        this.authService = authService;
    }
    UploadService.prototype.uploadStuff = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    UploadService.prototype.countDBUsers = function () {
        return __awaiter(this, void 0, void 0, function () {
            var allClientUsers, androidUsers, iosUsers;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataServicePlus.getFirestoreDataFromFunctions("this.authService.appUser.userDetail.key", "users", "clientKey", "-LpeIFZjbEAKWnrK-qap", "==", -1, "", "asc", -1, 1, environment.functionsUrlPlusGroup3)];
                    case 1:
                        allClientUsers = _a.sent();
                        androidUsers = allClientUsers.filter(function (x) { return x.devicePlatform === "Android"; });
                        iosUsers = allClientUsers.filter(function (x) { return x.devicePlatform !== "Android"; });
                        console.log("android users : " + androidUsers.length);
                        console.log("ios users : " + iosUsers.length);
                        return [2 /*return*/];
                }
            });
        });
    };
    // -LmK34ULX5d7WhOpmGi7
    UploadService.prototype.uploadTowersFireStore = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _files, currentImport, clients, responseeServices, _loop_1, this_1, xx, x, towerClient, ohshit, _i, currentImport_1, _element;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _files = uploadFiles;
                        currentImport = _files;
                        clients = [];
                        responseeServices = [];
                        _loop_1 = function (_element) {
                            var element, towers, _a, _b, _i, towers_1, iterator, _c, _d, responseService, responseServiceKey;
                            return __generator(this, function (_e) {
                                switch (_e.label) {
                                    case 0:
                                        element = _element;
                                        _b = (_a = this_1.dataService).querySnapshotToObject;
                                        return [4 /*yield*/, this_1.dataService.getFirestoreData("towers", "siteId", element.siteId).toPromise()];
                                    case 1:
                                        towers = _b.apply(_a, [_e.sent()]);
                                        if (!(towers && towers[0])) return [3 /*break*/, 5];
                                        xx = ":::sdfsd";
                                        _i = 0, towers_1 = towers;
                                        _e.label = 2;
                                    case 2:
                                        if (!(_i < towers_1.length)) return [3 /*break*/, 5];
                                        iterator = towers_1[_i];
                                        if (!(iterator.siteId === element.siteId)) return [3 /*break*/, 4];
                                        return [4 /*yield*/, this_1.dataService.deleteFireStoreDataFromFunctions("", "towers", iterator.key)];
                                    case 3:
                                        _e.sent();
                                        x = "";
                                        _e.label = 4;
                                    case 4:
                                        _i++;
                                        return [3 /*break*/, 2];
                                    case 5:
                                        towerClient = clients.find(function (x) { return x.key === element.clientKey; });
                                        if (!!towerClient) return [3 /*break*/, 7];
                                        _d = (_c = this_1.dataService).querySnapshotToObject;
                                        return [4 /*yield*/, this_1.dataService.getFirestoreData("clients", "key", element.clientKey).toPromise()];
                                    case 6:
                                        towerClient = _d.apply(_c, [_e.sent()])[0];
                                        clients.push(towerClient);
                                        _e.label = 7;
                                    case 7:
                                        responseService = towerClient.responseServices.find(function (x) { return x.name === "Armed Response" || x.name === "Armed Response "; });
                                        if (!responseService) {
                                            responseService = towerClient.responseServices.find(function (x) { return x.category === "armedResponse"; });
                                        }
                                        responseServiceKey = responseService.key;
                                        element.providerKey = responseServiceKey;
                                        element.providerName = responseServiceKey;
                                        element.clientName = towerClient.name;
                                        if (!element.providerKey || element.providerKey.length <= 0) {
                                            ohshit = "";
                                        }
                                        element.key = this_1.afs.createId();
                                        element = this_1.setTowerSearchName(element);
                                        return [4 /*yield*/, this_1.afs.collection("towers").doc(element.key).set(element)];
                                    case 8:
                                        _e.sent();
                                        console.log("Added Element " + element.key);
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _i = 0, currentImport_1 = currentImport;
                        _a.label = 1;
                    case 1:
                        if (!(_i < currentImport_1.length)) return [3 /*break*/, 4];
                        _element = currentImport_1[_i];
                        return [5 /*yield**/, _loop_1(_element)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.updateSheetTowersToBidvest_Jaques = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _files, currentImport, clients, responseeServices, xxx, _i, currentImport_2, _element, element, xxx, towers, _a, _b, newTower, shit, p, xx;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _files = uploadFiles;
                        currentImport = _files;
                        clients = [];
                        responseeServices = [];
                        xxx = [];
                        _i = 0, currentImport_2 = currentImport;
                        _c.label = 1;
                    case 1:
                        if (!(_i < currentImport_2.length)) return [3 /*break*/, 8];
                        _element = currentImport_2[_i];
                        element = _element;
                        xxx = element["siteId"];
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("towers", "siteId", element["siteId"]).toPromise()];
                    case 2:
                        towers = _b.apply(_a, [_c.sent()]);
                        element.name = element.name + " - " + element["siteIdWithPrefix"] + " - " + element["batterySerialNumber"];
                        if (!(towers && towers.length === 2)) return [3 /*break*/, 6];
                        newTower = towers.find(function (x) { return x.providerLinkedResponseServiceName === "Battery Tracking Team"; });
                        if (!!newTower) return [3 /*break*/, 3];
                        console.log("no Battery Tracking Team :  " + element.serialNumber);
                        return [3 /*break*/, 5];
                    case 3:
                        element.linkedEntity = newTower;
                        element.linkedEntityInitiatorType = "tower";
                        element.linkedEntityKey = newTower.key;
                        element.linkedEntityName = newTower.siteName;
                        delete element["siteId"];
                        delete element["siteIdWithPrefix"];
                        delete element["batterySerialNumber"];
                        return [4 /*yield*/, this.dataService.setFireStoreData("devices", element)];
                    case 4:
                        _c.sent();
                        console.log("setting Device :  " + element.name);
                        _c.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        shit = towers;
                        p = "shit happened here";
                        console.log("no TOWERS1 :  " + element["siteId"]);
                        _c.label = 7;
                    case 7:
                        _i++;
                        return [3 /*break*/, 1];
                    case 8:
                        xx = xxx;
                        console.log(JSON.stringify(xxx));
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.addTowerPrefixToBidvest = function () {
        return __awaiter(this, void 0, void 0, function () {
            var towers, _i, towers_2, tower, firstletter, secondletter, word, i, word, i, word, i;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        towers = [];
                        _a.label = 1;
                    case 1:
                        if (!towers) return [3 /*break*/, 11];
                        return [4 /*yield*/, this.loadTowers(30, towers[towers.length - 1], 'clientKey', '-Lh0LtkaBPxE0mU2K1np')];
                    case 2:
                        towers = _a.sent();
                        _i = 0, towers_2 = towers;
                        _a.label = 3;
                    case 3:
                        if (!(_i < towers_2.length)) return [3 /*break*/, 10];
                        tower = towers_2[_i];
                        firstletter = tower.siteId.charAt(0);
                        secondletter = tower.siteId.charAt(1);
                        if (!(secondletter === 'T')) return [3 /*break*/, 5];
                        tower.siteId = tower.siteId.replace('T', '');
                        tower.siteId_search = [];
                        word = "";
                        for (i = 0; i < tower.siteId.length; i++) {
                            word = word.concat(tower.siteId.charAt(i)).toLocaleLowerCase();
                            tower.siteId_search.push(word);
                        }
                        return [4 /*yield*/, this.dataService.updateFireStoreData('towers', tower)];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 9];
                    case 5:
                        if (!(firstletter === 'T' && tower.siteId_search[0] !== 't')) return [3 /*break*/, 7];
                        tower.siteId_search = [];
                        word = "";
                        for (i = 0; i < tower.siteId.length; i++) {
                            word = word.concat(tower.siteId.charAt(i)).toLocaleLowerCase();
                            tower.siteId_search.push(word);
                        }
                        return [4 /*yield*/, this.dataService.updateFireStoreData('towers', tower)];
                    case 6:
                        _a.sent();
                        return [3 /*break*/, 9];
                    case 7:
                        if (!(firstletter !== 'T')) return [3 /*break*/, 9];
                        tower.siteId = 'T' + tower.siteId;
                        tower.siteId_search = [];
                        word = "";
                        for (i = 0; i < tower.siteId.length; i++) {
                            word = word.concat(tower.siteId.charAt(i)).toLocaleLowerCase();
                            tower.siteId_search.push(word);
                        }
                        return [4 /*yield*/, this.dataService.updateFireStoreData('towers', tower)];
                    case 8:
                        _a.sent();
                        _a.label = 9;
                    case 9:
                        _i++;
                        return [3 /*break*/, 3];
                    case 10: return [3 /*break*/, 1];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.loadTowers = function (limit, startAfter, filterName, filterValue, whereFilterOp) {
        if (filterName === void 0) { filterName = ''; }
        if (filterValue === void 0) { filterValue = ''; }
        if (whereFilterOp === void 0) { whereFilterOp = '=='; }
        return __awaiter(this, void 0, void 0, function () {
            var that, towers;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        that = this;
                        towers = [];
                        if (!(limit < 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions('', 'towers', filterName, filterValue, '==', -1, '', 'asc', -1, 1)];
                    case 1:
                        towers = _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions('', 'towers', filterName, filterValue, whereFilterOp, limit, 'key', 'asc', startAfter)];
                    case 3:
                        towers = _a.sent();
                        _a.label = 4;
                    case 4:
                        console.log(towers);
                        towers = towers.filter(function (x) { return x.clientKey === '-Lh0LtkaBPxE0mU2K1np'; });
                        return [2 /*return*/, towers];
                }
            });
        });
    };
    UploadService.prototype.ImportTowersToBidvest_Jaques_2022 = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _files, currentImport, clients, responseeServices, nobatteryTrackingTeam, xxx, _i, currentImport_3, _element, element, xxx, towers, _a, _b, newTower, devices, _c, _d, shit_1, shit, p, ppp, xx;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _files = uploadFiles;
                        currentImport = _files;
                        clients = [];
                        responseeServices = [];
                        nobatteryTrackingTeam = [];
                        xxx = [];
                        _i = 0, currentImport_3 = currentImport;
                        _e.label = 1;
                    case 1:
                        if (!(_i < currentImport_3.length)) return [3 /*break*/, 10];
                        _element = currentImport_3[_i];
                        element = _element;
                        xxx = element["siteId"];
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("towers", "siteId", element["siteId"]).toPromise()];
                    case 2:
                        towers = _b.apply(_a, [_e.sent()]);
                        element.name = element.name + " - " + element["siteIdWithPrefix"] + " - " + element["batterySerialNumber"];
                        if (!(towers && towers.length === 2)) return [3 /*break*/, 8];
                        newTower = towers.find(function (x) { return x.providerLinkedResponseServiceName === "Battery Tracking Team"; });
                        if (!!newTower) return [3 /*break*/, 3];
                        console.log("no Battery Tracking Team :  " + element["siteId"]);
                        nobatteryTrackingTeam.push(element["siteId"]);
                        return [3 /*break*/, 7];
                    case 3:
                        _d = (_c = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("devices", "serialNumber", element.serialNumber).toPromise()];
                    case 4:
                        devices = _d.apply(_c, [_e.sent()]);
                        if (!(devices && devices.length >= 2)) return [3 /*break*/, 5];
                        console.log("MORE THAN 1 DEVICE :  " + element.serialNumber);
                        shit_1 = devices;
                        return [3 /*break*/, 7];
                    case 5:
                        if (devices && devices.length === 1) {
                            element.key = devices[0].key;
                        }
                        element.linkedEntity = newTower;
                        element.linkedEntityInitiatorType = "tower";
                        element.linkedEntityKey = newTower.key;
                        element.linkedEntityName = newTower.siteName;
                        delete element["siteId"];
                        delete element["siteIdWithPrefix"];
                        delete element["batterySerialNumber"];
                        return [4 /*yield*/, this.dataService.setFireStoreData("devices", element)];
                    case 6:
                        _e.sent();
                        console.log("setting Device :  " + element.name);
                        _e.label = 7;
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        shit = towers;
                        p = "shit happened here";
                        console.log("NO TOWERS1 :  " + element["siteId"]);
                        _e.label = 9;
                    case 9:
                        _i++;
                        return [3 /*break*/, 1];
                    case 10:
                        ppp = nobatteryTrackingTeam;
                        console.log("ssss");
                        console.log("ssss");
                        xx = xxx;
                        console.log(JSON.stringify(xxx));
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.deleteDevicesToBidvest_Jaques = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _files, currentImport, clients, responseeServices, xxx, nobatteryTrackingTeam, noTower, _i, currentImport_4, _element, element, xxx, devices, _a, _b, _c, devices_1, iterator, xxx, ppp;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _files = uploadFiles;
                        currentImport = _files;
                        clients = [];
                        responseeServices = [];
                        xxx = [];
                        nobatteryTrackingTeam = [];
                        noTower = [];
                        _i = 0, currentImport_4 = currentImport;
                        _d.label = 1;
                    case 1:
                        if (!(_i < currentImport_4.length)) return [3 /*break*/, 9];
                        _element = currentImport_4[_i];
                        element = _element;
                        xxx = element["siteId"];
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("devices", "serialNumber", element.serialNumber).toPromise()];
                    case 2:
                        devices = _b.apply(_a, [_d.sent()]);
                        if (!(devices && devices.length === 1)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.dataService.deleteFireStoreData("devices", devices[0].key)];
                    case 3:
                        _d.sent();
                        console.log("deleted " + devices[0].key);
                        _d.label = 4;
                    case 4:
                        if (!devices || devices.length <= 0) {
                            console.log("No device! ");
                        }
                        if (!(devices.length > 1)) return [3 /*break*/, 8];
                        _c = 0, devices_1 = devices;
                        _d.label = 5;
                    case 5:
                        if (!(_c < devices_1.length)) return [3 /*break*/, 8];
                        iterator = devices_1[_c];
                        return [4 /*yield*/, this.dataService.deleteFireStoreData("devices", iterator.key)];
                    case 6:
                        _d.sent();
                        console.log("device more than 1! deleted " + iterator.key);
                        _d.label = 7;
                    case 7:
                        _c++;
                        return [3 /*break*/, 5];
                    case 8:
                        _i++;
                        return [3 /*break*/, 1];
                    case 9:
                        xxx = noTower;
                        ppp = nobatteryTrackingTeam;
                        console.log("ssss");
                        console.log("ssss");
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.updateSheetTowersToBidvest_Jaques_negativeTest = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _files, currentImport, clients, responseeServices, xxx, nobatteryTrackingTeam, noTower, _i, currentImport_5, _element, element, xxx, towers, _a, _b, newTower, xxx, ppp;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _files = uploadFiles;
                        currentImport = _files;
                        clients = [];
                        responseeServices = [];
                        xxx = [];
                        nobatteryTrackingTeam = [];
                        noTower = [];
                        _i = 0, currentImport_5 = currentImport;
                        _c.label = 1;
                    case 1:
                        if (!(_i < currentImport_5.length)) return [3 /*break*/, 4];
                        _element = currentImport_5[_i];
                        element = _element;
                        xxx = element["siteId"];
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("towers", "siteId", element["siteId"]).toPromise()];
                    case 2:
                        towers = _b.apply(_a, [_c.sent()]);
                        element.name = element.name + " - " + element["siteIdWithPrefix"] + " - " + element["batterySerialNumber"];
                        if (towers && towers.length === 2) {
                            newTower = towers.find(function (x) { return x.providerLinkedResponseServiceName === "Battery Tracking Team"; });
                            if (!newTower) {
                                console.log("no Battery Tracking Team :  " + element["siteId"]);
                                nobatteryTrackingTeam.push(element["siteId"]);
                            }
                            else if (towers && towers.length <= 1) {
                                console.log("ONLY 1 TOWER " + towers[0].siteId);
                                //   await this.dataService.setFireStoreData("towers", towers[0]);
                                //   await this.dataService.setFireStoreData("towers", towers[1]);
                            }
                            else {
                                console.log("NO TOWER FOUND FOR SITE ID : " + xxx);
                                // element.linkedEntity = newTower;
                                // element.linkedEntityInitiatorType = "tower";
                                // element.linkedEntityKey = newTower.key;
                                // element.linkedEntityName = newTower.siteName;
                                // delete element["siteId"];
                                // delete element["siteIdWithPrefix"];
                                // delete element["batterySerialNumber"];
                                // await this.dataService.setFireStoreData("devices", element);
                                // console.log("setting Device :  " + element.name);
                            }
                        }
                        else {
                            noTower.push(element["siteId"]);
                            console.log("no TOWERS1 :  " + element["siteId"]);
                        }
                        _c.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        xxx = noTower;
                        ppp = nobatteryTrackingTeam;
                        console.log("ssss");
                        console.log("ssss");
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.updateSheetTowersToBidvest = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _files, currentImport, clients, responseeServices, xxx, _i, currentImport_6, _element, element, towers, _a, _b, cccc, first, first1, first2, second, second1, second2, xx;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _files = uploadFiles;
                        currentImport = _files;
                        clients = [];
                        responseeServices = [];
                        xxx = [];
                        _i = 0, currentImport_6 = currentImport;
                        _c.label = 1;
                    case 1:
                        if (!(_i < currentImport_6.length)) return [3 /*break*/, 4];
                        _element = currentImport_6[_i];
                        element = _element;
                        if (!(element.siteId !== "299" && element.siteId !== "96" && element.siteId !== "118" && element.siteId !== "84" && element.siteId !== "1")) return [3 /*break*/, 3];
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("towers", "siteId", element.siteId).toPromise()];
                    case 2:
                        towers = _b.apply(_a, [_c.sent()]);
                        if (towers && towers[0]) {
                            // newTower.providerLinkedResponseServiceKey = "DsSj3e4R6USmb0b7Cu7Y";
                            // newTower.providerLinkedResponseServiceName = "Battery Tracking Team";
                            // newTower.providerKey = "-Lh0LtkaBPxE0mU2K1np";
                            // newTower.providerName = "Bidvest";
                            if (towers[0].providerLinkedResponseServiceName === "Armed Response") {
                                cccc = towers[0].siteId;
                            }
                            first = JSON.parse(JSON.stringify(towers[0].providerLinkedResponseServiceKey));
                            first1 = JSON.parse(JSON.stringify(towers[0].providerLinkedResponseServiceName));
                            first2 = JSON.parse(JSON.stringify(towers[0].providerName));
                            second = JSON.parse(JSON.stringify(towers[1].providerLinkedResponseServiceKey));
                            second1 = JSON.parse(JSON.stringify(towers[1].providerLinkedResponseServiceName));
                            second2 = JSON.parse(JSON.stringify(towers[1].providerName));
                            towers[0].providerLinkedResponseServiceKey = second;
                            towers[0].providerLinkedResponseServiceName = second1;
                            towers[0].providerName = second2;
                            towers[1].providerLinkedResponseServiceKey = first;
                            towers[1].providerLinkedResponseServiceName = first1;
                            towers[1].providerName = first2;
                            // for (const iterator of towers) {
                            towers[0].clientName = "Bidvest";
                            towers[1].clientName = "Bidvest";
                            towers[0].clientKey = "-Lh0LtkaBPxE0mU2K1np";
                            towers[1].clientKey = "-Lh0LtkaBPxE0mU2K1np";
                            if (towers[0].providerLinkedResponseServiceKey === "DsSj3e4R6USmb0b7Cu7Y") {
                                towers[0].siteName = towers[0].siteName + " - Battery Tracking";
                            }
                            if (towers[1].providerLinkedResponseServiceKey === "DsSj3e4R6USmb0b7Cu7Y") {
                                towers[1].siteName = towers[1].siteName + " - Battery Tracking";
                            }
                            //   await this.dataService.setFireStoreData("towers", towers[0]);
                            //   await this.dataService.setFireStoreData("towers", towers[1]);
                            // }
                        }
                        _c.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        xx = xxx;
                        console.log(JSON.stringify(xxx));
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.checkIfDeviceshasLinkedTower = function () {
        return __awaiter(this, void 0, void 0, function () {
            var devices, _a, _b, _i, devices_2, device, towers, _c, _d, towersSearch, _e, _f;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("devices").toPromise()];
                    case 1:
                        devices = _b.apply(_a, [_g.sent()]);
                        _i = 0, devices_2 = devices;
                        _g.label = 2;
                    case 2:
                        if (!(_i < devices_2.length)) return [3 /*break*/, 9];
                        device = devices_2[_i];
                        console.log(device.linkedEntityInitiatorType);
                        _d = (_c = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("towers", "key", device.linkedEntityKey).toPromise()];
                    case 3:
                        towers = _d.apply(_c, [_g.sent()]);
                        if (!(towers && towers[0])) return [3 /*break*/, 4];
                        return [3 /*break*/, 8];
                    case 4:
                        if (!device.linkedEntity) return [3 /*break*/, 8];
                        console.log("NO TOWER! - " + device.linkedEntity.siteId + " - " + device.linkedEntityName);
                        if (!device.linkedEntity) return [3 /*break*/, 8];
                        _f = (_e = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("towers", "siteId", device.linkedEntity.siteId).toPromise()];
                    case 5:
                        towersSearch = _f.apply(_e, [_g.sent()]);
                        if (!(towersSearch && towersSearch[0])) return [3 /*break*/, 8];
                        console.log("TOWER FOUND!  Old key : " + device.linkedEntityKey + " New key : " + towersSearch[0].key + " - " + device.key);
                        device.linkedEntityKey = towersSearch[0].key;
                        device.linkedEntity = towersSearch[0];
                        if (!(device.linkedEntity.siteId === towersSearch[0].siteId)) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.afs.collection("devices").doc(device.key).set(device)];
                    case 6:
                        _g.sent();
                        return [3 /*break*/, 8];
                    case 7:
                        console.log("WTF!?!?!");
                        console.log(device.linkedEntity.siteId);
                        console.log(towersSearch[0].siteId);
                        _g.label = 8;
                    case 8:
                        _i++;
                        return [3 /*break*/, 2];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.editTowersFireStore = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _files, currentImport, clients, responseeServices, _loop_2, this_2, towerClient, ohshit, _i, currentImport_7, _element;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _files = uploadFiles;
                        currentImport = _files;
                        clients = [];
                        responseeServices = [];
                        _loop_2 = function (_element) {
                            var element, towers, _a, _b, _c, _d, responseService;
                            return __generator(this, function (_e) {
                                switch (_e.label) {
                                    case 0:
                                        element = _element;
                                        _b = (_a = this_2.dataService).querySnapshotToObject;
                                        return [4 /*yield*/, this_2.dataService.getFirestoreData("towers", "siteId", element.siteId).toPromise()];
                                    case 1:
                                        towers = _b.apply(_a, [_e.sent()]);
                                        if (!(towers && towers[0])) return [3 /*break*/, 5];
                                        element = towers[0];
                                        towerClient = clients.find(function (x) { return x.key === element.clientKey; });
                                        if (!!towerClient) return [3 /*break*/, 3];
                                        _d = (_c = this_2.dataService).querySnapshotToObject;
                                        return [4 /*yield*/, this_2.dataService.getFirestoreData("clients", "key", element.clientKey).toPromise()];
                                    case 2:
                                        towerClient = _d.apply(_c, [_e.sent()])[0];
                                        clients.push(towerClient);
                                        _e.label = 3;
                                    case 3:
                                        responseService = towerClient.responseServices.find(function (x) { return x.name === "Armed Response" || x.name === "Armed Response "; });
                                        if (!responseService) {
                                            responseService = towerClient.responseServices.find(function (x) { return x.category === "armedResponse"; });
                                        }
                                        element.providerKey = towerClient.key;
                                        element.providerName = towerClient.name;
                                        element.providerLinkedResponseServiceKey = responseService.key;
                                        element.providerLinkedResponseServiceName = responseService.name;
                                        element.clientName = towerClient.name;
                                        if (!element.providerKey || element.providerKey.length <= 0) {
                                            ohshit = "";
                                        }
                                        element = this_2.setTowerSearchName(element);
                                        return [4 /*yield*/, this_2.afs.collection("towers").doc(element.key).set(element)];
                                    case 4:
                                        _e.sent();
                                        console.log("Added Element " + element.key);
                                        _e.label = 5;
                                    case 5: return [2 /*return*/];
                                }
                            });
                        };
                        this_2 = this;
                        _i = 0, currentImport_7 = currentImport;
                        _a.label = 1;
                    case 1:
                        if (!(_i < currentImport_7.length)) return [3 /*break*/, 4];
                        _element = currentImport_7[_i];
                        return [5 /*yield**/, _loop_2(_element)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.runGroupsUpdateUsersClientAuthToBooleans = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userAuths, _a, _b, _i, userAuths_1, userAuth;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("usersClientAuth").toPromise()];
                    case 1:
                        userAuths = _b.apply(_a, [_c.sent()]);
                        _i = 0, userAuths_1 = userAuths;
                        _c.label = 2;
                    case 2:
                        if (!(_i < userAuths_1.length)) return [3 /*break*/, 5];
                        userAuth = userAuths_1[_i];
                        userAuth.isActive = userAuth.isActive.toString() === "true";
                        userAuth.isAdmin = userAuth.isAdmin.toString() === "true";
                        return [4 /*yield*/, this.dataService.setFireStoreData("usersClientAuth", userAuth)];
                    case 3:
                        _c.sent();
                        _c.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.fixTowerLatsAndLongss = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    UploadService.prototype.deleteGroupUsers = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userGroups, _i, userGroups_1, usergroup;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups")];
                    case 1:
                        userGroups = _a.sent();
                        _i = 0, userGroups_1 = userGroups;
                        _a.label = 2;
                    case 2:
                        if (!(_i < userGroups_1.length)) return [3 /*break*/, 5];
                        usergroup = userGroups_1[_i];
                        delete usergroup["users"];
                        console.log("removing -- " + usergroup.name);
                        return [4 /*yield*/, this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups", usergroup)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    // async checkClientsExist() {
    //     console.log('starting')
    //     const that = this;
    //     await this.fire.database()
    //         .ref('clients')
    //         .once('value').then(async function (snapshot) {
    //             const clients = ((<Client[]>that.ot.snapshotToArray(snapshot)))
    //             const newClients: Client[] = await that.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, 'clients').toPromise()
    //         }).catch(a => {
    //             console.log(a)
    //         })
    // }
    UploadService.prototype.checkUsersNotExisting = function () {
        return __awaiter(this, void 0, void 0, function () {
            var that;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("starting");
                        that = this;
                        return [4 /*yield*/, this.afs.firestore.app
                                .database()
                                .ref("users")
                                .once("value")
                                .then(function (snapshot) {
                                return __awaiter(this, void 0, void 0, function () {
                                    var userDetails, newUserDetails, _a, _b, total, count, _loop_3, this_3, _i, userDetails_1, userDetail;
                                    return __generator(this, function (_c) {
                                        switch (_c.label) {
                                            case 0:
                                                userDetails = that.ot.snapshotToArray(snapshot);
                                                _b = (_a = this.dataService).querySnapshotToObject;
                                                return [4 /*yield*/, that.dataService.getFirestoreData("users").toPromise()];
                                            case 1:
                                                newUserDetails = _b.apply(_a, [_c.sent()]);
                                                console.log(userDetails);
                                                total = userDetails.length;
                                                count = 0;
                                                console.log("Starting Check");
                                                _loop_3 = function (userDetail) {
                                                    var newUserDetail, client, clientGroups, premiumGroup, defaultGroup, selectedGroup, newUser;
                                                    return __generator(this, function (_a) {
                                                        switch (_a.label) {
                                                            case 0:
                                                                count++;
                                                                newUserDetail = newUserDetails.find(function (x) { return x.key === userDetail.key; });
                                                                if (!!newUserDetail) return [3 /*break*/, 3];
                                                                return [4 /*yield*/, that.dataService.getFirestoreDataFromFunctions(this_3.authService.appUser.userDetail.key, "clients", "key", userDetail.clientKey)];
                                                            case 1:
                                                                client = (_a.sent())[0];
                                                                return [4 /*yield*/, that.dataService.getFirestoreDataFromFunctions(this_3.authService.appUser.userDetail.key, "userGroups", "clientKey", userDetail.clientKey)];
                                                            case 2:
                                                                clientGroups = _a.sent();
                                                                premiumGroup = clientGroups.find(function (x) { return x.name === "Premium"; });
                                                                defaultGroup = clientGroups.find(function (x) { return x.name === "Default"; });
                                                                selectedGroup = void 0;
                                                                if (client) {
                                                                    if (userDetail.peachPaymentRegistrationId) {
                                                                        selectedGroup = premiumGroup;
                                                                    }
                                                                    else {
                                                                        selectedGroup = defaultGroup;
                                                                    }
                                                                    newUser = {
                                                                        key: userDetail.key,
                                                                        displayName: userDetail.displayName,
                                                                        email: userDetail.email,
                                                                        firstName: userDetail.firstName,
                                                                        lastName: userDetail.lastName,
                                                                        msisdn: userDetail.msisdn,
                                                                        addressLine1: userDetail.addressLine1,
                                                                        addressLine2: userDetail.addressLine2,
                                                                        roles: {
                                                                            admin: false,
                                                                            clientAdmin: false,
                                                                            manager: false,
                                                                            providerAdmin: false,
                                                                            user: true,
                                                                        },
                                                                        title: userDetail.title,
                                                                        isActive: userDetail.isActive,
                                                                        city: userDetail.city,
                                                                        country: userDetail.country,
                                                                        countryCode: userDetail.countryCode,
                                                                        gender: userDetail.gender,
                                                                        idNumber: userDetail.idNumber,
                                                                        dateOfBirth: userDetail.dateOfBirth,
                                                                        dateUpdated: userDetail.dateUpdated,
                                                                        groupKey: selectedGroup.key,
                                                                        personalizedApiKeyGenerated: false,
                                                                        lat: userDetail.lat,
                                                                        long: userDetail.long,
                                                                        locationAccuracy: userDetail.locationAccuracy,
                                                                        clientKey: userDetail.clientKey,
                                                                        redirectToStartup: false,
                                                                        subscriptionActive: userDetail.subscriptionActive,
                                                                        subscriptionActiveManualPayment: userDetail.subscriptionActiveManualPayment,
                                                                        subscriptionFreeDays: userDetail.subscriptionFreeDays,
                                                                        subscriptionLastPayedDate: userDetail.subscriptionLastPayedDate,
                                                                        subscriptionCancellationDate: userDetail.subscriptionCancellationDate,
                                                                        subscriptionPendingCancellation: userDetail.subscriptionPendingCancellation,
                                                                        mobileAppPrice: userDetail.mobileAppPrice,
                                                                        peachPaymentRegistrationId: userDetail.peachPaymentRegistrationId,
                                                                        peachPaymentEntityId: userDetail.peachPaymentEntityId,
                                                                        peachPaymentCurrency: userDetail.peachPaymentCurrency,
                                                                        responseServiceActionsResponder: [],
                                                                        responseServiceActionsInitiated: [],
                                                                        responseServiceActionActiveAndListening: false,
                                                                    };
                                                                    if (!newUser.isActive) {
                                                                        newUser.isActive = true;
                                                                    }
                                                                    if (!newUser.displayName) {
                                                                        newUser.displayName = newUser.firstName + " " + newUser.lastName;
                                                                    }
                                                                    that.clean(newUser);
                                                                    // if (!selectedGroup.users) {
                                                                    //     selectedGroup.users = [];
                                                                    // }
                                                                    // selectedGroup.users.push(newUser)
                                                                    //    await that.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key,'users', newUser)
                                                                    //    await that.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key,'userGroups', selectedGroup)
                                                                    console.log("SAVING NEW USER ");
                                                                    // console.log(that.clean(newUser))
                                                                    console.log("Key -> " + newUser.key);
                                                                    console.log("GrouKey -> " + selectedGroup.key);
                                                                }
                                                                else {
                                                                    // console.log('CLIENT NOT FOUND!!!')
                                                                    // console.log('CLIENT NOT FOUND!!!')
                                                                    // console.log('CLIENT NOT FOUND for useer!!!')
                                                                    // console.log(userDetail)
                                                                }
                                                                _a.label = 3;
                                                            case 3: return [2 /*return*/];
                                                        }
                                                    });
                                                };
                                                this_3 = this;
                                                _i = 0, userDetails_1 = userDetails;
                                                _c.label = 2;
                                            case 2:
                                                if (!(_i < userDetails_1.length)) return [3 /*break*/, 5];
                                                userDetail = userDetails_1[_i];
                                                return [5 /*yield**/, _loop_3(userDetail)];
                                            case 3:
                                                _c.sent();
                                                _c.label = 4;
                                            case 4:
                                                _i++;
                                                return [3 /*break*/, 2];
                                            case 5: return [2 /*return*/];
                                        }
                                    });
                                });
                            })
                                .catch(function (a) {
                                console.log(a);
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.clean = function (obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
    };
    UploadService.prototype.transferFromOldToNewDatabase = function () {
        return __awaiter(this, void 0, void 0, function () {
            var that;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("Starting Premium Check");
                        that = this;
                        return [4 /*yield*/, this.afs.firestore.app
                                .database()
                                .ref("users")
                                .once("value")
                                .then(function (snapshot) {
                                return __awaiter(this, void 0, void 0, function () {
                                    var userDetails, newUserDetails, _loop_4, _i, userDetails_2, userDetail;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                userDetails = that.ot.snapshotToArray(snapshot);
                                                return [4 /*yield*/, that.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "users")];
                                            case 1:
                                                newUserDetails = _a.sent();
                                                console.log("Starting Check");
                                                _loop_4 = function (userDetail) {
                                                    if (userDetail.peachPaymentRegistrationId) {
                                                        console.log("ACTIVE USER -> " + userDetail.firstName);
                                                        var newUserDetail = newUserDetails.find(function (x) { return x.key === userDetail.key; });
                                                        if (newUserDetail) {
                                                            if (newUserDetail.subscriptionActive !== userDetail.subscriptionActive) {
                                                                console.log("NOT ACTIVE! ENABLE!");
                                                                console.log(userDetail);
                                                                console.log(newUserDetail);
                                                                newUserDetail.subscriptionActive = userDetail.subscriptionActive;
                                                                newUserDetail.subscriptionActiveManualPayment = userDetail.subscriptionActiveManualPayment;
                                                                newUserDetail.subscriptionFreeDays = userDetail.subscriptionFreeDays;
                                                                newUserDetail.subscriptionLastPayedDate = userDetail.subscriptionLastPayedDate;
                                                                newUserDetail.subscriptionCancellationDate = userDetail.subscriptionCancellationDate;
                                                                newUserDetail.subscriptionPendingCancellation = userDetail.subscriptionPendingCancellation;
                                                                newUserDetail.mobileAppPrice = userDetail.mobileAppPrice;
                                                                newUserDetail.peachPaymentRegistrationId = userDetail.peachPaymentRegistrationId;
                                                                newUserDetail.peachPaymentEntityId = userDetail.peachPaymentEntityId;
                                                                newUserDetail.peachPaymentCurrency = userDetail.peachPaymentCurrency;
                                                                //    await that.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key,'users', newUserDetail)
                                                                console.log("ACTIVATED");
                                                            }
                                                            {
                                                                console.log("fine");
                                                            }
                                                        }
                                                        else {
                                                            console.log("USER NOT FOUND > " + userDetail.key);
                                                            console.log("USER NOT FOUND");
                                                            console.log("USER NOT FOUND");
                                                            console.log("USER NOT FOUND");
                                                            console.log("USER NOT FOUND");
                                                            console.log("USER NOT FOUND");
                                                            console.log("USER NOT FOUND");
                                                            console.log("USER NOT FOUND");
                                                            console.log("USER NOT FOUND");
                                                            console.log("USER NOT FOUND");
                                                            console.log("USER NOT FOUND");
                                                        }
                                                    }
                                                };
                                                for (_i = 0, userDetails_2 = userDetails; _i < userDetails_2.length; _i++) {
                                                    userDetail = userDetails_2[_i];
                                                    _loop_4(userDetail);
                                                }
                                                return [2 /*return*/];
                                        }
                                    });
                                });
                            })
                                .catch(function (a) {
                                console.log(a);
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.transferFromOldToNewDatabase_client = function () {
        return __awaiter(this, void 0, void 0, function () {
            var that;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("Starting Premium Check");
                        that = this;
                        return [4 /*yield*/, this.afs.firestore.app
                                .database()
                                .ref("clients")
                                .once("value")
                                .then(function (snapshot) {
                                return __awaiter(this, void 0, void 0, function () {
                                    var clients, o, client;
                                    return __generator(this, function (_a) {
                                        clients = that.ot.snapshotToArray(snapshot);
                                        console.log("Starting Check");
                                        console.log(clients);
                                        o = clients.find(function (x) { return x.key === "-Lv50acI1fu7tjIHr68r"; });
                                        client = {
                                            email: o.email,
                                            address: o.address,
                                            apiKey: o.apiKey,
                                            creatorClientKey: o.creatorClientKey,
                                            creatorClientName: o.creatorClientName,
                                            dateUpated: o.dateUpated,
                                            isActive: o.isActive,
                                            key: o.key,
                                            management: o.management,
                                            mobileProducts: ["friend", "news", "supportService"],
                                            name: o.name,
                                            phoneNumber: o.phoneNumber,
                                            responseServices: [],
                                        };
                                        that.clean(client);
                                        console.log("new client saving");
                                        console.log(client);
                                        return [2 /*return*/];
                                    });
                                });
                            })
                                .catch(function (a) {
                                console.log(a);
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.editGroups = function () {
        return __awaiter(this, void 0, void 0, function () {
            var stuffs, _i, stuffs_1, stuff, _loop_5, this_4, _a, _b, aaa;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups")];
                    case 1:
                        stuffs = _c.sent();
                        _i = 0, stuffs_1 = stuffs;
                        _c.label = 2;
                    case 2:
                        if (!(_i < stuffs_1.length)) return [3 /*break*/, 7];
                        stuff = stuffs_1[_i];
                        _loop_5 = function (aaa) {
                            var index;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(aaa.name === "Armed Resonse" || aaa.name === "Armed Reponse")) return [3 /*break*/, 2];
                                        index = stuff.responseServices.findIndex(function (x) { return x.key === aaa.key; });
                                        aaa.name = "Armed Response";
                                        aaa.description = "Armed Response";
                                        stuff.responseServices[index] = aaa;
                                        return [4 /*yield*/, this_4.dataService.setFireStoreDataFromFunctions(this_4.authService.appUser.userDetail.key, "userGroups", stuff)];
                                    case 1:
                                        _a.sent();
                                        console.log("SET FOR " + stuff.clientKey);
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        };
                        this_4 = this;
                        _a = 0, _b = stuff.responseServices;
                        _c.label = 3;
                    case 3:
                        if (!(_a < _b.length)) return [3 /*break*/, 6];
                        aaa = _b[_a];
                        return [5 /*yield**/, _loop_5(aaa)];
                    case 4:
                        _c.sent();
                        _c.label = 5;
                    case 5:
                        _a++;
                        return [3 /*break*/, 3];
                    case 6:
                        _i++;
                        return [3 /*break*/, 2];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.makeSurePaidUsersAreInPremium = function () {
        return __awaiter(this, void 0, void 0, function () {
            var users, groups, total, count, _loop_6, this_5, _i, users_1, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "users")];
                    case 1:
                        users = _a.sent();
                        return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups")];
                    case 2:
                        groups = _a.sent();
                        total = users.length;
                        count = 1;
                        _loop_6 = function (user) {
                            var thisUserGroup;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        count++;
                                        thisUserGroup = groups.find(function (x) { return x.key === user.groupKey; });
                                        // if(user.clientKey && user.clientKey === '-xxxxx'){
                                        //     user.clientKey = '-LT75cDlOhB3sr6T3P7A'
                                        //     user.groupKey = 'WYXbmJmjzPPiKvdxmqWz'
                                        //     console.log(user)
                                        //     await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key,'users', user)
                                        // }
                                        console.log(count + " / " + total);
                                        if (!(thisUserGroup.name === "Premium" && thisUserGroup.autoGenerated === true)) return [3 /*break*/, 2];
                                        console.log("Setting Premium User -> " + user.firstName);
                                        user.subscriptionActive = true;
                                        user.subscriptionActiveManualPayment = true;
                                        return [4 /*yield*/, this_5.dataService.setFireStoreDataFromFunctions(this_5.authService.appUser.userDetail.key, "users", user)];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        };
                        this_5 = this;
                        _i = 0, users_1 = users;
                        _a.label = 3;
                    case 3:
                        if (!(_i < users_1.length)) return [3 /*break*/, 6];
                        user = users_1[_i];
                        return [5 /*yield**/, _loop_6(user)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        _i++;
                        return [3 /*break*/, 3];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.makeSureResponseServiceClientKeyisNotUndefined = function () {
        return __awaiter(this, void 0, void 0, function () {
            var responseSerivce, total, count, _i, responseSerivce_1, resservice;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions("asdfasdf,", "responseServices")];
                    case 1:
                        responseSerivce = _a.sent();
                        total = responseSerivce.length;
                        count = 1;
                        _i = 0, responseSerivce_1 = responseSerivce;
                        _a.label = 2;
                    case 2:
                        if (!(_i < responseSerivce_1.length)) return [3 /*break*/, 5];
                        resservice = responseSerivce_1[_i];
                        count++;
                        if (!!resservice.providerKey) return [3 /*break*/, 4];
                        console.log("Provider Key empty" + resservice.clientName);
                        resservice.providerKey = resservice.clientKey;
                        resservice.providerName = resservice.clientName;
                        return [4 /*yield*/, this.dataService.setFireStoreDataFromFunctions("this.authService.appUser.userDetail.key", "responseServices", resservice)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.editResonseServices = function () {
        return __awaiter(this, void 0, void 0, function () {
            var responseServices, _i, responseServices_1, responseService;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices")];
                    case 1:
                        responseServices = _a.sent();
                        _i = 0, responseServices_1 = responseServices;
                        _a.label = 2;
                    case 2:
                        if (!(_i < responseServices_1.length)) return [3 /*break*/, 5];
                        responseService = responseServices_1[_i];
                        if (!(responseService.name === "Armed Resonse")) return [3 /*break*/, 4];
                        responseService.name = "Armed Response";
                        return [4 /*yield*/, this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", responseService)];
                    case 3:
                        _a.sent();
                        console.log("SET FOR " + responseService.name);
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.editStuffFirestore = function () {
        return __awaiter(this, void 0, void 0, function () {
            var stuffs, _i, stuffs_2, stuff;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices")];
                    case 1:
                        stuffs = _a.sent();
                        _i = 0, stuffs_2 = stuffs;
                        _a.label = 2;
                    case 2:
                        if (!(_i < stuffs_2.length)) return [3 /*break*/, 5];
                        stuff = stuffs_2[_i];
                        if (!(stuff.clientKey !== "-LT75cDlOhB3sr6T3P7A")) return [3 /*break*/, 4];
                        if (stuff.name === "Police" || stuff.description === "Public Police services") {
                            stuff.isServiceProvider = false;
                            stuff.providerLinkedResponseServiceKey = "Dm42fceZJNPBUTeZcGvB";
                            stuff.providerLinkedResponseServiceName = "Police";
                            stuff.providerKey = "-LT75cDlOhB3sr6T3P7A";
                            stuff.providerName = "Response 24";
                        }
                        if (stuff.description === "Public Ambulance Services") {
                            stuff.isServiceProvider = false;
                            stuff.providerLinkedResponseServiceKey = "GccgqaXxpRy15wK40anR";
                            stuff.providerLinkedResponseServiceName = "Ambulance";
                            stuff.providerKey = "-LT75cDlOhB3sr6T3P7A";
                            stuff.providerName = "Response 24";
                        }
                        if (stuff.description === "ER 24") {
                            stuff.isServiceProvider = false;
                            stuff.name = "ER 24";
                            delete stuff.providerLinkedResponseServiceKey;
                            delete stuff.providerLinkedResponseServiceName;
                            delete stuff.providerKey;
                            delete stuff.providerName;
                        }
                        return [4 /*yield*/, this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", stuff)];
                    case 3:
                        _a.sent();
                        console.log("Set for " + stuff);
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    // async upgradePayedUsers() {
    //     const allClientUsers: UserDetail[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, 'users').toPromise())
    //     for (const userDetail of allClientUsers) {
    //         if (userDetail.subscriptionActive) {
    //             const clientGroups: UserGroup[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, 'userGroups', 'clientKey', userDetail.clientKey).toPromise())
    //             const premium: UserGroup = clientGroups.find(x => x.name === 'Premium')
    //             userDetail.groupKey = premium.key
    //             console.log('Setting : >' + userDetail.firstName + ' -> ' + premium.name)
    //             //  await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key,'users', userDetail)
    //             premium.users.push(userDetail)
    //             //    await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key,'userGroups', premium)
    //             console.log(userDetail)
    //         }
    //     }
    // }
    UploadService.prototype.ccheck = function () {
        return __awaiter(this, void 0, void 0, function () {
            var responseServices, _i, responseServices_2, responseService;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices")];
                    case 1:
                        responseServices = _a.sent();
                        _i = 0, responseServices_2 = responseServices;
                        _a.label = 2;
                    case 2:
                        if (!(_i < responseServices_2.length)) return [3 /*break*/, 5];
                        responseService = responseServices_2[_i];
                        if (!responseService.isServiceProvider) return [3 /*break*/, 4];
                        if (!(responseService.name !== "Friends & Family" && responseService.clientKey !== "-LT75cDlOhB3sr6T3P7A")) return [3 /*break*/, 4];
                        console.log("IS SERVCIE PROVIDER => " + responseService.clientName);
                        console.log("Other provider => " + responseService.isServiceProvider);
                        responseService.isServiceProvider = false;
                        return [4 /*yield*/, this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", responseService)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.addDevices = function () {
        return __awaiter(this, void 0, void 0, function () {
            var devicesArr, counter, _i, devicesArr_1, serial, device;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        devicesArr = [
                            "95094144667975",
                            "65429044667785",
                            "65429044625809",
                            "65429044626203",
                            "65429044623077",
                            "65429044626146",
                            "65429044623549",
                            "65429044626229",
                            "65429044624901",
                            "65429044625841",
                            "65429044625338",
                            "65429044624984",
                            "65429044626237",
                            "65429044642226",
                            "65429044623275",
                            "65429044624810",
                            "65429044666654",
                            "65429044664600",
                            "65429044625767",
                            "63070019071462",
                            "65429044624695",
                            "65429044624711",
                            "65429044623309",
                            "65429044623325",
                            "65429044623531",
                            "65429044625320",
                            "65429044624729",
                            "65429044625213",
                            "65429044666696",
                            "65429044625866",
                            "65429044626286",
                            "65429044619471",
                            "65429044623853",
                            "65429044618853",
                            "65429044625346",
                            "65429044626088",
                            "65429044667371",
                            "65429044624422",
                            "65429044667447",
                            "65429044622947",
                            "65429044623283",
                            "65429044621477",
                            "65429044623895",
                            "65429044623341",
                            "65429044623366",
                            "65429044623051",
                            "65429044624752",
                            "65429044624760",
                            "65429044623176",
                            "65429044624414",
                            "65429044623069",
                            "65429044623168",
                            "65429044667736",
                            "65429044625429",
                            "65429044667975",
                            "65429044625700",
                            "65429044666670",
                            "65429044622889",
                            "65429044666688",
                            "65429044627029",
                            "65429044624372",
                            "65429044623432",
                            "65429044625304",
                            "65429044623846",
                            "65429044666704",
                            "63070019070886",
                            "63070018754399",
                            "63070019072775",
                            "234234243242424",
                            "7809274348",
                            "63070018754282",
                            "63070018751890",
                            "63070018752161",
                            "63070019071454",
                            "65429044666712",
                            "65429044623903",
                            "123456789012345",
                            "14495000027595",
                            "14495000028031",
                            "65429044623085",
                            "65429044622996",
                            "14495000028221",
                            "8809008695",
                            "8809008669",
                            "8809008581",
                            "2104025992",
                            "8809008616",
                            "8809008536",
                            "8809008642",
                            "8809008625",
                            "8809008595",
                            "8809008572",
                            "8809008635",
                            "5425758046119800000",
                            "13672910325643500000",
                            "8809008573",
                            "2104019322",
                            "65429044624216",
                            "65429044623101",
                            "8809008698",
                            "8809008671",
                            "8809008674",
                            "8809008614",
                            "6542004665CDC5",
                            "65429044623804",
                            "8391170515907470000",
                            "2322296596999970000",
                            "15723775669448600000",
                            "5995751501093290000",
                            "14495000027587",
                            "95094144667371",
                            "6542904466738F",
                            "65429044667751",
                            "65429044625197",
                        ];
                        counter = 1;
                        _i = 0, devicesArr_1 = devicesArr;
                        _a.label = 1;
                    case 1:
                        if (!(_i < devicesArr_1.length)) return [3 /*break*/, 4];
                        serial = devicesArr_1[_i];
                        device = {
                            clientKey: "-Lh0LtkaBPxE0mU2K1np",
                            clientName: "Bidvest",
                            name: "Netshield Device " + counter,
                            serialNumber: serial,
                        };
                        counter++;
                        console.log("Adding " + "Bidvest");
                        return [4 /*yield*/, this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "devices", device)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.deleteDataWhere = function () {
        return __awaiter(this, void 0, void 0, function () {
            var things, _i, things_1, thing;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices")];
                    case 1:
                        things = _a.sent();
                        _i = 0, things_1 = things;
                        _a.label = 2;
                    case 2:
                        if (!(_i < things_1.length)) return [3 /*break*/, 5];
                        thing = things_1[_i];
                        if (!(thing.name === "Premium" || thing.name === "Default")) return [3 /*break*/, 4];
                        console.log("deleteing");
                        console.log(thing);
                        return [4 /*yield*/, this.dataService.deleteFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", thing.key)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.removeAllOpenResponseServiceActions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userdetails, total, counter, _i, userdetails_1, userdetail;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("starting");
                        return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "users")];
                    case 1:
                        userdetails = _a.sent();
                        total = userdetails.length;
                        counter = 1;
                        for (_i = 0, userdetails_1 = userdetails; _i < userdetails_1.length; _i++) {
                            userdetail = userdetails_1[_i];
                            console.log("Running - " + counter + " of " + total);
                            counter++;
                            if ((userdetail.responseServiceActionsInitiated && userdetail.responseServiceActionsInitiated.length > 0) ||
                                (userdetail.responseServiceActionsResponder && userdetail.responseServiceActionsResponder.length > 0)) {
                                userdetail.responseServiceActionsInitiated = [];
                                userdetail.responseServiceActionsResponder = [];
                                //     await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key,'users', userdetail);
                                console.log("clearing out for -> " + userdetail.firstName);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.deleteAllClientData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var clientNameArr, _i, clientNameArr_1, clientName, client, userGroups, responseServices, _a, userGroups_2, userGroup, _b, responseServices_3, responseService;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        clientNameArr = ["aaaaaa", "bbbbb", "cccc", "dddddd", "Marlize Sec", "test"];
                        _i = 0, clientNameArr_1 = clientNameArr;
                        _c.label = 1;
                    case 1:
                        if (!(_i < clientNameArr_1.length)) return [3 /*break*/, 15];
                        clientName = clientNameArr_1[_i];
                        return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "clients", "name", clientName)];
                    case 2:
                        client = (_c.sent())[0];
                        return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups", "clientKey", client.key)];
                    case 3:
                        userGroups = _c.sent();
                        return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", "clientKey", client.key)];
                    case 4:
                        responseServices = _c.sent();
                        _a = 0, userGroups_2 = userGroups;
                        _c.label = 5;
                    case 5:
                        if (!(_a < userGroups_2.length)) return [3 /*break*/, 8];
                        userGroup = userGroups_2[_a];
                        console.log("deleteing " + userGroup.name);
                        return [4 /*yield*/, this.dataService.deleteFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups", userGroup.key)];
                    case 6:
                        _c.sent();
                        _c.label = 7;
                    case 7:
                        _a++;
                        return [3 /*break*/, 5];
                    case 8:
                        _b = 0, responseServices_3 = responseServices;
                        _c.label = 9;
                    case 9:
                        if (!(_b < responseServices_3.length)) return [3 /*break*/, 12];
                        responseService = responseServices_3[_b];
                        console.log("deleteing " + responseService.name);
                        return [4 /*yield*/, this.dataService.deleteFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", responseService.key)];
                    case 10:
                        _c.sent();
                        _c.label = 11;
                    case 11:
                        _b++;
                        return [3 /*break*/, 9];
                    case 12:
                        console.log("deleteing " + client.name);
                        return [4 /*yield*/, this.dataService.deleteFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "clients", client.key)];
                    case 13:
                        _c.sent();
                        _c.label = 14;
                    case 14:
                        _i++;
                        return [3 /*break*/, 1];
                    case 15: return [2 /*return*/];
                }
            });
        });
    };
    // async updatePremiumusersDetails() {
    //     console.log('updating starting')
    //     const userdetails: UserDetail[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, 'users', 'email', usermails).toPromise())[0]
    //     for (const usermails of userEmails) {
    //         const userdetail: UserDetail = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, 'users', 'email', usermails).toPromise())[0]
    //         console.log(userdetail)
    //         if (userdetail) {
    //             userdetail.groupKey = 'cUB3nnzGH46ki1CKKafj'
    //             userdetail.subscriptionActive = true;
    //             userdetail.subscriptionPendingCancellation = false;
    //             userdetail.peachPaymentRegistrationId = 'NA'
    //             userdetail.peachPaymentEntityId = 'NA'
    //             console.log('updating ' + usermails)
    //             await this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key,'users', userdetail)
    //         }
    //     }
    // }
    UploadService.prototype.giveUsersPremiumAccess = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userEmails, group, _i, userEmails_1, usermails, userdetail;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("updating starting");
                        userEmails = [
                            "andri@response24.co.za",
                            "lizelleh@response24.co.za",
                            "monya@response24.co.za",
                            "john@response24.co.za",
                            "brenda@response24.co.za",
                            "maryke@response24.co.za",
                            "marlize@response24.co.za",
                            "jacquesk@response24.co.za",
                            "amy@response24.co.za",
                            "lizelle@response24.co.za",
                            "lizelleh@response24.co.za",
                            "werner@response24.co.za",
                            "phillip@response24.co.za",
                        ];
                        return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups", "key", "cUB3nnzGH46ki1CKKafj")];
                    case 1:
                        group = (_a.sent())[0];
                        _i = 0, userEmails_1 = userEmails;
                        _a.label = 2;
                    case 2:
                        if (!(_i < userEmails_1.length)) return [3 /*break*/, 7];
                        usermails = userEmails_1[_i];
                        return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "users", "email", usermails)];
                    case 3:
                        userdetail = (_a.sent())[0];
                        console.log(userdetail);
                        if (!userdetail) return [3 /*break*/, 6];
                        userdetail.groupKey = "cUB3nnzGH46ki1CKKafj";
                        userdetail.subscriptionActive = true;
                        userdetail.subscriptionPendingCancellation = false;
                        userdetail.peachPaymentRegistrationId = "NA";
                        userdetail.peachPaymentEntityId = "NA";
                        console.log("GROPU =>  " + group.clientKey);
                        return [4 /*yield*/, this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "users", userdetail)];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups", group)];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        _i++;
                        return [3 /*break*/, 2];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.regenInviteCodes = function () {
        return __awaiter(this, void 0, void 0, function () {
            var groups, _loop_7, this_6, _i, groups_1, group;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups")];
                    case 1:
                        groups = _a.sent();
                        _loop_7 = function (group) {
                            var intviteCount, grp;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        intviteCount = groups.filter(function (x) { return x.invitationCode === group.invitationCode; });
                                        if (!(intviteCount.length > 1)) return [3 /*break*/, 2];
                                        grp = {
                                            key: group.key,
                                            invitationCode: this_6.globalService.uniqueInviteCode(),
                                        };
                                        return [4 /*yield*/, this_6.dataService.updateFireStoreDataFromFunctions(this_6.authService.appUser.userDetail.key, "userGroups", grp).catch(function (ex) {
                                                console.error(ex);
                                            })];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        };
                        this_6 = this;
                        _i = 0, groups_1 = groups;
                        _a.label = 2;
                    case 2:
                        if (!(_i < groups_1.length)) return [3 /*break*/, 5];
                        group = groups_1[_i];
                        return [5 /*yield**/, _loop_7(group)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.deleteFreeServices = function () {
        return __awaiter(this, void 0, void 0, function () {
            var responseServices, _i, responseServices_4, responseService;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices")];
                    case 1:
                        responseServices = _a.sent();
                        _i = 0, responseServices_4 = responseServices;
                        _a.label = 2;
                    case 2:
                        if (!(_i < responseServices_4.length)) return [3 /*break*/, 5];
                        responseService = responseServices_4[_i];
                        if (!(responseService.providerLinkedResponseServiceKey &&
                            responseService.clientKey !== environment.response24ClientKey &&
                            responseService.isPublicServiceProvider)) return [3 /*break*/, 4];
                        // uncomment to run update
                        // responseService.category = "armedResponse"
                        // responseService.description = "Armed Response"
                        // responseService.icon = IconEnum.shield.value;
                        // responseService.iconColor = IconColorEnum.blue.value;
                        console.log(responseService);
                        responseService.isPublicServiceProvider = false;
                        return [4 /*yield*/, this.dataService.updateFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", responseService)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.updateFreeServices = function () {
        return __awaiter(this, void 0, void 0, function () {
            var responseServices, _i, responseServices_5, responseService;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices")];
                    case 1:
                        responseServices = _a.sent();
                        _i = 0, responseServices_5 = responseServices;
                        _a.label = 2;
                    case 2:
                        if (!(_i < responseServices_5.length)) return [3 /*break*/, 5];
                        responseService = responseServices_5[_i];
                        if (!(responseService.name === "Test Alert" && responseService.clientKey !== "-LT75cDlOhB3sr6T3P7A")) return [3 /*break*/, 4];
                        // uncomment to run update
                        console.log(responseService.clientName + " - " + responseService.name);
                        responseService.name = "Test Alert";
                        responseService.description = "Test Alert";
                        responseService.providerLinkedResponseServiceName = "Test Alert";
                        responseService.iconColor = IconColorEnum.orange.value;
                        return [4 /*yield*/, this.dataService.updateFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", responseService)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.updateHistoricalResponseActions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var responseServiceActions, _a, _b, _i, responseServiceActions_1, responseServiceAction, changeDates, _c, _d, history_1, x, _e, _f, hist, histaaa, aaa, seconds, newDate, x;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("responseServiceActionsHistory3").toPromise()];
                    case 1:
                        responseServiceActions = _b.apply(_a, [_g.sent()]);
                        _i = 0, responseServiceActions_1 = responseServiceActions;
                        _g.label = 2;
                    case 2:
                        if (!(_i < responseServiceActions_1.length)) return [3 /*break*/, 5];
                        responseServiceAction = responseServiceActions_1[_i];
                        changeDates = false;
                        console.log("Running");
                        for (_c = 0, _d = responseServiceAction.movementHistory; _c < _d.length; _c++) {
                            history_1 = _d[_c];
                            try {
                                x = history_1.dateUpdated.toDate();
                            }
                            catch (error) {
                                changeDates = true;
                                break;
                            }
                        }
                        if (!changeDates) return [3 /*break*/, 4];
                        for (_e = 0, _f = responseServiceAction.movementHistory; _e < _f.length; _e++) {
                            hist = _f[_e];
                            histaaa = hist;
                            aaa = histaaa.dateUpdated;
                            seconds = aaa["_seconds"];
                            if (!seconds) {
                                seconds = aaa["seconds"];
                            }
                            newDate = this.toDateTime(seconds);
                            hist.dateUpdated = firebase.firestore.Timestamp.fromDate(newDate);
                        }
                        x = history;
                        console.log("CHANGES FOR " + responseServiceAction.movementHistory[0]);
                        return [4 /*yield*/, this.dataService.updateFireStoreData("responseServiceActionsHistory3", responseServiceAction)];
                    case 3:
                        _g.sent();
                        _g.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.toDateTime = function (secs) {
        var t = new Date(1970, 0, 1); // Epoch
        t.setSeconds(secs);
        return t;
    };
    UploadService.prototype.updateClientRESServices = function () {
        return __awaiter(this, void 0, void 0, function () {
            var clients, _a, _b, users, _c, _d, responseServices, _e, _f, emptyUsers, populatedUsers, _loop_8, this_7, _i, emptyUsers_1, user;
            var _this = this;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("clients").toPromise()];
                    case 1:
                        clients = _b.apply(_a, [_g.sent()]);
                        _d = (_c = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("users").toPromise()];
                    case 2:
                        users = _d.apply(_c, [_g.sent()]);
                        _f = (_e = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("responseServices").toPromise()];
                    case 3:
                        responseServices = _f.apply(_e, [_g.sent()]);
                        responseServices = responseServices.filter(function (x) { return x.category !== "armedResponse" && x.category !== "medical" && x.name === "Friends & Family"; });
                        emptyUsers = users.filter(function (x) { return !x.responseServiceFriendsKey; });
                        populatedUsers = users.filter(function (x) { return x.responseServiceFriendsKey; });
                        _loop_8 = function (user) {
                            var friendRepsonseService;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        console.log(user.key);
                                        friendRepsonseService = {
                                            category: "publicService",
                                            clientKey: user.clientKey,
                                            clientName: clients.find(function (x) { return x.key === user.clientKey; }).name,
                                            icon: "/assets/icon/generic/friends_fam.svg",
                                            iconColor: "icon-orange",
                                            isAllowedToHandleOtherActionsInParallel: true,
                                            isAvailableOnMobile: true,
                                            isDirectRequestToResponders: true,
                                            isMoreThanOneResponderAllowed: true,
                                            isNOCInChargeOfCompletion: false,
                                            isPublicServiceProvider: false,
                                            isServiceProvider: true,
                                            respondRadiusKm: 100,
                                            responders: [],
                                            isAutoGenerated: true,
                                            // DONT change this variable -> Doing lookups on Friends & Family.
                                            name: "Friends & Family",
                                            settings: {
                                                isCloseReasonFromList: false,
                                            },
                                            type: "friend",
                                        };
                                        console.log(friendRepsonseService);
                                        return [4 /*yield*/, this_7.dataService.setFireStoreDataFromFunctions(user.key, "responseServices", friendRepsonseService).then(function (firestoreKey) { return __awaiter(_this, void 0, void 0, function () {
                                                return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0:
                                                            user.responseServiceFriendsKey = firestoreKey;
                                                            return [4 /*yield*/, this.dataService.updateFireStoreDataFromFunctions(user.key, "users", user)];
                                                        case 1:
                                                            _a.sent();
                                                            return [2 /*return*/, friendRepsonseService];
                                                    }
                                                });
                                            }); })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_7 = this;
                        _i = 0, emptyUsers_1 = emptyUsers;
                        _g.label = 4;
                    case 4:
                        if (!(_i < emptyUsers_1.length)) return [3 /*break*/, 7];
                        user = emptyUsers_1[_i];
                        return [5 /*yield**/, _loop_8(user)];
                    case 5:
                        _g.sent();
                        _g.label = 6;
                    case 6:
                        _i++;
                        return [3 /*break*/, 4];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.updateFriendsResponseServices = function () {
        return __awaiter(this, void 0, void 0, function () {
            var responseServices, _a, _b, _i, responseServices_6, resService;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        console.log("-----START ");
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("responseServices").toPromise()];
                    case 1:
                        responseServices = _b.apply(_a, [_c.sent()]);
                        responseServices = responseServices.filter(function (x) { return x.category !== "armedResponse" && x.category !== "medical" && x.name === "Friends & Family"; });
                        _i = 0, responseServices_6 = responseServices;
                        _c.label = 2;
                    case 2:
                        if (!(_i < responseServices_6.length)) return [3 /*break*/, 5];
                        resService = responseServices_6[_i];
                        console.log(resService.providerName);
                        console.log(resService.providerKey);
                        resService.providerKey = resService.clientKey;
                        resService.providerName = resService.clientName;
                        return [4 /*yield*/, this.dataService.updateFireStoreData("responseServices", resService)];
                    case 3:
                        _c.sent();
                        console.log("updating " + resService.key);
                        _c.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.checkIsDirectTorespondersIsNotNull = function () {
        return __awaiter(this, void 0, void 0, function () {
            var responseServices, _a, _b, users, _c, _d, _loop_9, this_8, _i, responseServices_7, resService;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        console.log("-----START ");
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("responseServices").toPromise()];
                    case 1:
                        responseServices = _b.apply(_a, [_e.sent()]);
                        _d = (_c = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("users").toPromise()];
                    case 2:
                        users = _d.apply(_c, [_e.sent()]);
                        _loop_9 = function (resService) {
                            var userLIst, respServices, _a, _b, _i, respServices_1, iterator;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        if (!(resService.isDirectRequestToResponders === null || resService.isDirectRequestToResponders === undefined)) return [3 /*break*/, 3];
                                        userLIst = users.filter(function (x) { return x.clientKey === resService.clientKey; });
                                        if (!(!userLIst || userLIst.length <= 0)) return [3 /*break*/, 2];
                                        console.log("EMPTY!  " + resService.clientKey);
                                        _b = (_a = this_8.dataService).querySnapshotToObject;
                                        return [4 /*yield*/, this_8.dataService.getFirestoreData("responseServices", "clientKey", resService.clientKey).toPromise()];
                                    case 1:
                                        respServices = _b.apply(_a, [_c.sent()]);
                                        for (_i = 0, respServices_1 = respServices; _i < respServices_1.length; _i++) {
                                            iterator = respServices_1[_i];
                                            console.log("deleteing rEs -=> " + iterator.name);
                                            //   await this.dataService.deleteFireStoreDataFromFunctions('', 'responseServices', iterator.key)
                                        }
                                        console.log("Deleting Cleitn ->  " + resService.clientKey);
                                        console.log("Deleting Cleitn ->  " + resService.clientName);
                                        return [3 /*break*/, 3];
                                    case 2:
                                        console.log(">> NOT EMPTY!  " + resService.clientKey);
                                        console.log(">> NOT EMPTY!  " + resService.clientName);
                                        _c.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        };
                        this_8 = this;
                        _i = 0, responseServices_7 = responseServices;
                        _e.label = 3;
                    case 3:
                        if (!(_i < responseServices_7.length)) return [3 /*break*/, 6];
                        resService = responseServices_7[_i];
                        return [5 /*yield**/, _loop_9(resService)];
                    case 4:
                        _e.sent();
                        _e.label = 5;
                    case 5:
                        _i++;
                        return [3 /*break*/, 3];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    // if (client.key !== '-LT75cDlOhB3sr6T3P7A') {
    //     // uncomment to run update
    //     console.log(client.name)
    //     console.log(client.key)
    //     //   client.responseServices = client.responseServices.filter(x => x.name !== 'Ambulance')
    //     // const index = client.responseServices.findIndex(x => x.name === 'Police')
    //     // if (index > 0) {
    //     //     client.responseServices[index].name = 'Test Alert';
    //     //     client.responseServices[index].description = 'Test Alert';
    //     //     client.responseServices[index].providerLinkedResponseServiceName = 'Test Alert';
    //     //     client.responseServices[index].iconColor = IconColorEnum.orange.value;
    //     // }
    //     console.log(client.responseServices)
    //     //  await this.dataService.updateFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, 'clients', client)
    // }
    UploadService.prototype.updateGroups = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userGroups, _i, userGroups_3, userGroup, index;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups")];
                    case 1:
                        userGroups = _a.sent();
                        for (_i = 0, userGroups_3 = userGroups; _i < userGroups_3.length; _i++) {
                            userGroup = userGroups_3[_i];
                            index = userGroup.responseServices.findIndex(function (x) { return x.providerLinkedResponseServiceKey === "GccgqaXxpRy15wK40anR"; });
                            console.log(index);
                            // if (index >= 0) {
                            //     userGroup.responseServices[index].name = 'Test Alert';
                            //     userGroup.responseServices[index].description = 'Test Alert';
                            //     userGroup.responseServices[index].providerLinkedResponseServiceName = 'Test Alert';
                            //     userGroup.responseServices[index].iconColor = IconColorEnum.orange.value;
                            // }
                            console.log(userGroup.responseServices);
                            //   await this.dataService.updateFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, 'userGroups', userGroup)
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.deleteAllResponseServiceExceptR24 = function () {
        return __awaiter(this, void 0, void 0, function () {
            var responseServices, _i, responseServices_8, responseService;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices")];
                    case 1:
                        responseServices = _a.sent();
                        _i = 0, responseServices_8 = responseServices;
                        _a.label = 2;
                    case 2:
                        if (!(_i < responseServices_8.length)) return [3 /*break*/, 5];
                        responseService = responseServices_8[_i];
                        if (!(responseService.clientKey !== environment.response24ClientKey)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.dataService.deleteFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", responseService.key)];
                    case 3:
                        _a.sent();
                        console.log("deleted - > " + responseService.name);
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5:
                        console.log("done");
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.createDefaultGroupsAndAssignUsers = function () {
        return __awaiter(this, void 0, void 0, function () {
            var allClients, _i, allClients_1, client, er24, armedRes, ambulance, police, allClientUsers;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "clients")];
                    case 1:
                        allClients = _a.sent();
                        _i = 0, allClients_1 = allClients;
                        _a.label = 2;
                    case 2:
                        if (!(_i < allClients_1.length)) return [3 /*break*/, 11];
                        client = allClients_1[_i];
                        console.log("################### " + client.name);
                        return [4 /*yield*/, this.createOwnResponseService(client, "aQv6o5p3G4nQF6NQ2qh4")];
                    case 3:
                        er24 = _a.sent();
                        return [4 /*yield*/, this.createOwnResponseService(client, "GNlsXNbZ54wcBJVDlwH4")];
                    case 4:
                        armedRes = _a.sent();
                        return [4 /*yield*/, this.createLinkedResponseService(client, "GccgqaXxpRy15wK40anR")];
                    case 5:
                        ambulance = _a.sent();
                        return [4 /*yield*/, this.createLinkedResponseService(client, "Dm42fceZJNPBUTeZcGvB")];
                    case 6:
                        police = _a.sent();
                        return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "users", "clientKey", client.key)];
                    case 7:
                        allClientUsers = _a.sent();
                        return [4 /*yield*/, this.generateDefaultGroups(client, allClientUsers, er24, armedRes, ambulance, police)];
                    case 8:
                        _a.sent();
                        client.responseServices = [];
                        client.responseServices.push(er24, armedRes, ambulance, police);
                        return [4 /*yield*/, this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "clients", client)];
                    case 9:
                        _a.sent();
                        _a.label = 10;
                    case 10:
                        _i++;
                        return [3 /*break*/, 2];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.createOwnResponseService = function (client, key) {
        return __awaiter(this, void 0, void 0, function () {
            var aaService;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", "key", key)];
                    case 1:
                        aaService = _a.sent();
                        if (client.key === environment.response24ClientKey) {
                            return [2 /*return*/, aaService];
                        }
                        delete aaService.key;
                        aaService.isPublicServiceProvider = true;
                        aaService.clientKey = client.key;
                        aaService.clientName = client.name;
                        return [4 /*yield*/, this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", aaService)];
                    case 2:
                        _a.sent();
                        console.log("Done with AA " + client.name);
                        return [2 /*return*/, aaService];
                }
            });
        });
    };
    UploadService.prototype.createLinkedResponseService = function (client, key) {
        return __awaiter(this, void 0, void 0, function () {
            var responseService;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.getFirestoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", "key", key)];
                    case 1:
                        responseService = _a.sent();
                        if (client.key === environment.response24ClientKey) {
                            return [2 /*return*/, responseService];
                        }
                        delete responseService.key;
                        responseService.isPublicServiceProvider = false;
                        responseService.clientKey = client.key;
                        responseService.clientName = client.name;
                        responseService.providerKey = environment.response24ClientKey;
                        responseService.providerLinkedResponseServiceKey = key;
                        responseService.providerLinkedResponseServiceName = responseService.name;
                        return [4 /*yield*/, this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "responseServices", responseService)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, responseService];
                }
            });
        });
    };
    UploadService.prototype.generateDefaultGroups = function (client, clientUsers, er24, armedRes, ambulance, police) {
        return __awaiter(this, void 0, void 0, function () {
            var defaultGroup, premiumGroup, _i, clientUsers_1, clientUser;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        defaultGroup = {
                            key: this.afs.createId(),
                            mobilePrice: 0,
                            subscriptionFreeDays: 0,
                            autoGenerated: true,
                            clientKey: client.key,
                            responseServices: [ambulance, police],
                            // users: clientUsers,
                            mobileProducts: ["news", "supportService"],
                            name: "Default",
                            invitationCode: this.globalService.uniqueInviteCode(),
                        };
                        premiumGroup = {
                            key: this.afs.createId(),
                            mobilePrice: 59.99,
                            subscriptionFreeDays: 0,
                            autoGenerated: true,
                            clientKey: client.key,
                            mobileProducts: ["news", "supportService", "friend"],
                            responseServices: [er24, armedRes],
                            // users: [],
                            name: "Premium",
                            invitationCode: this.globalService.uniqueInviteCode(),
                        };
                        _i = 0, clientUsers_1 = clientUsers;
                        _a.label = 1;
                    case 1:
                        if (!(_i < clientUsers_1.length)) return [3 /*break*/, 4];
                        clientUser = clientUsers_1[_i];
                        clientUser.groupKey = defaultGroup.key;
                        return [4 /*yield*/, this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "users", clientUser)];
                    case 2:
                        _a.sent();
                        console.log("----> user : " + clientUser.firstName);
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [4 /*yield*/, this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups", defaultGroup)];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this.dataService.setFireStoreDataFromFunctions(this.authService.appUser.userDetail.key, "userGroups", premiumGroup)];
                    case 6:
                        _a.sent();
                        console.log("Groups Added for : " + client.name);
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.setUserDetails = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userGroups, _a, _b, users, _c, _d, _i, userGroups_4, userGroup, groupHasUsers;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("userGroups").toPromise()];
                    case 1:
                        userGroups = _b.apply(_a, [_e.sent()]);
                        _d = (_c = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("users").toPromise()];
                    case 2:
                        users = _d.apply(_c, [_e.sent()]);
                        _i = 0, userGroups_4 = userGroups;
                        _e.label = 3;
                    case 3:
                        if (!(_i < userGroups_4.length)) return [3 /*break*/, 6];
                        userGroup = userGroups_4[_i];
                        groupHasUsers = false;
                        if (!(userGroup.name === "Premium" && userGroup.clientKey !== environment.response24ClientKey)) return [3 /*break*/, 5];
                        console.log(userGroup.autoGenerated);
                        userGroup.autoGenerated = false;
                        return [4 /*yield*/, this.dataService.updateFireStoreData("userGroups", userGroup)];
                    case 4:
                        _e.sent();
                        _e.label = 5;
                    case 5:
                        _i++;
                        return [3 /*break*/, 3];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.removeEmptyPremiumGroups = function () {
        return __awaiter(this, void 0, void 0, function () {
            var users, _a, _b, _i, users_2, user;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("users").toPromise()];
                    case 1:
                        users = _b.apply(_a, [_c.sent()]);
                        console.log(users);
                        _i = 0, users_2 = users;
                        _c.label = 2;
                    case 2:
                        if (!(_i < users_2.length)) return [3 /*break*/, 5];
                        user = users_2[_i];
                        user.personalizedApiKeyGenerated = false;
                        console.log("setting for user + " + user.firstName);
                        return [4 /*yield*/, this.dataService.updateFireStoreData("users", user)];
                    case 3:
                        _c.sent();
                        _c.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.setClientSearchName = function () {
        return __awaiter(this, void 0, void 0, function () {
            var that, clients, _a, _b, _i, clients_1, client, word, i;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        that = this;
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("clients").toPromise()];
                    case 1:
                        clients = _b.apply(_a, [_c.sent()]);
                        _i = 0, clients_1 = clients;
                        _c.label = 2;
                    case 2:
                        if (!(_i < clients_1.length)) return [3 /*break*/, 5];
                        client = clients_1[_i];
                        console.log(client.name);
                        client.name_search = [];
                        word = "";
                        for (i = 0; i < client.name.length; i++) {
                            word = word.concat(client.name.charAt(i)).toLocaleLowerCase();
                            client.name_search.push(word);
                        }
                        console.log(client);
                        return [4 /*yield*/, this.dataService.updateFireStoreData("clients", client)];
                    case 3:
                        _c.sent();
                        _c.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.setTowerSearchName = function (tower) {
        // const limit = 1000;
        // const towers: Tower[] = this.dataService.querySnapshotToObject(await this.dataService.getFirestoreData('towers', 'clientKey', 'odMFzSy9Wo0zx1jOpVUH').toPromise())
        // for (const tower of towers) {
        console.log(tower.siteId);
        tower.siteId_search = [];
        var word = "";
        for (var i = 0; i < tower.siteId.length; i++) {
            word = word.concat(tower.siteId.charAt(i)).toLocaleLowerCase();
            tower.siteId_search.push(word);
        }
        tower.latitude = parseFloat(tower.latitude.toString().replace(",", "."));
        tower.longitude = parseFloat(tower.longitude.toString().replace(",", "."));
        console.log("UPDATING >>>>>> " + tower.key);
        //       await this.dataService.updateFireStoreData('towers', tower)
        //  }
        // if (towers.length === limit) {
        //     this.setTowerSearchName(towers[towers.length - 1].key)
        // };
        return tower;
    };
    UploadService.prototype.deleteTestClients = function () {
        return __awaiter(this, void 0, void 0, function () {
            var limit, clientKeys, _i, clientKeys_1, clientKey, client, _a, _b, userGroups, _c, _d, _e, userGroups_5, group;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        console.log("STARTING");
                        limit = 1000;
                        clientKeys = ["rar6UH1UnfDwjudp1dvz"];
                        _i = 0, clientKeys_1 = clientKeys;
                        _f.label = 1;
                    case 1:
                        if (!(_i < clientKeys_1.length)) return [3 /*break*/, 9];
                        clientKey = clientKeys_1[_i];
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("clients", "key", clientKey).toPromise()];
                    case 2:
                        client = _b.apply(_a, [_f.sent()])[0];
                        _d = (_c = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("userGroups", "clientKey", client.key).toPromise()];
                    case 3:
                        userGroups = _d.apply(_c, [_f.sent()]);
                        console.log("DELETING client >>>>>> " + client.name + " " + client.key);
                        return [4 /*yield*/, this.dataService.deleteFireStoreDataFromFunctions("", "clients", client.key)];
                    case 4:
                        _f.sent();
                        _e = 0, userGroups_5 = userGroups;
                        _f.label = 5;
                    case 5:
                        if (!(_e < userGroups_5.length)) return [3 /*break*/, 8];
                        group = userGroups_5[_e];
                        console.log("DELETING group name >>>>>> " + group.name);
                        console.log("DELETING group clientKey >>>>>> " + group.clientKey);
                        return [4 /*yield*/, this.dataService.deleteFireStoreDataFromFunctions("", "userGroups", group.key)];
                    case 6:
                        _f.sent();
                        _f.label = 7;
                    case 7:
                        _e++;
                        return [3 /*break*/, 5];
                    case 8:
                        _i++;
                        return [3 /*break*/, 1];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.findDuplicateClients = function () {
        return __awaiter(this, void 0, void 0, function () {
            var limit, clients, _a, _b, lookup, duplicates, _i, duplicates_1, duplicate, client, _c, _d, userGroups, _e, _f, users, _g, _h;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        console.log("STARTING");
                        limit = 1000;
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("clients").toPromise()];
                    case 1:
                        clients = _b.apply(_a, [_j.sent()]);
                        lookup = clients.reduce(function (a, e) {
                            a[e.name] = ++a[e.name] || 0;
                            return a;
                        }, {});
                        duplicates = clients.filter(function (e) { return lookup[e.name]; });
                        console.log(duplicates);
                        _i = 0, duplicates_1 = duplicates;
                        _j.label = 2;
                    case 2:
                        if (!(_i < duplicates_1.length)) return [3 /*break*/, 7];
                        duplicate = duplicates_1[_i];
                        console.log(duplicate.key + " " + duplicate.name);
                        _d = (_c = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("clients", "key", duplicate.key).toPromise()];
                    case 3:
                        client = _d.apply(_c, [_j.sent()])[0];
                        _f = (_e = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("userGroups", "clientKey", client.key).toPromise()];
                    case 4:
                        userGroups = _f.apply(_e, [_j.sent()]);
                        _h = (_g = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("users", "clientKey", client.key).toPromise()];
                    case 5:
                        users = _h.apply(_g, [_j.sent()]);
                        console.log("---------------------------------------------------");
                        console.log("CLIENT : " + client.key + " --> " + client.name);
                        console.log("GROUPS : " + userGroups.length);
                        console.log("USERS : " + users.length);
                        _j.label = 6;
                    case 6:
                        _i++;
                        return [3 /*break*/, 2];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    // async addMissingUsers() {
    //     console.log('STARTING')
    //     const clientKey = '-LT75cDlOhB3sr6T3P7A'
    //     const groupKey = 'WYXbmJmjzPPiKvdxmqWz'
    //     const userList: UserDetail[] = [
    //         {
    //             key: 'TQYRpLAUc5CKPrly6gAr',
    //             firstName: 'Barendine',
    //             lastName: '',
    //             msisdn: '+27829907770',
    //             email: 'barendine@yahoo.com',
    //             displayName: 'Barendine',
    //             isActive: true,
    //             mobileAppPrice: 59.99,
    //             roles: {
    //                 admin: false,
    //                 clientAdmin: false,
    //                 manager: false,
    //                 providerAdmin: false,
    //                 user: true
    //             },
    //             groupKey,
    //             clientKey
    //         },
    //         {
    //             key: 'QkkbtNOa2zWc5KmGZOVAy8jzdTx1',
    //             firstName: 'cihequip',
    //             lastName: '',
    //             msisdn: '+27760379705',
    //             email: 'cihequip@telkimsa.net',
    //             displayName: 'cihequip',
    //             isActive: true,
    //             mobileAppPrice: 59.99,
    //             roles: {
    //                 admin: false,
    //                 clientAdmin: false,
    //                 manager: false,
    //                 providerAdmin: false,
    //                 user: true
    //             },
    //             groupKey,
    //             clientKey
    //         },
    //         {
    //             key: 'QQLEWaWPrHVC4ZRG6qxepEnKyUD3',
    //             firstName: 'franche',
    //             lastName: 'vandenberg',
    //             msisdn: '+27735658279',
    //             email: 'franche.vandenberg@gmail.com',
    //             displayName: 'franche vandenberg',
    //             isActive: true,
    //             mobileAppPrice: 59.99,
    //             roles: {
    //                 admin: false,
    //                 clientAdmin: false,
    //                 manager: false,
    //                 providerAdmin: false,
    //                 user: true
    //             },
    //             groupKey,
    //             clientKey
    //         },
    //         {
    //             key: 'NoiSdtkhmAY5tGiBx34e257IDw02',
    //             firstName: 'manager',
    //             lastName: 'crimestp',
    //             msisdn: '+27664973839',
    //             email: 'manager@crimestp.co.za',
    //             displayName: 'manager crimestp',
    //             isActive: true,
    //             mobileAppPrice: 59.99,
    //             roles: {
    //                 admin: false,
    //                 clientAdmin: false,
    //                 manager: false,
    //                 providerAdmin: false,
    //                 user: true
    //             },
    //             groupKey,
    //             clientKey
    //         },
    //         {
    //             key: 'LEQmElDiIMYFDcqpO4qMuuJoMWG2',
    //             firstName: 'yolandie',
    //             lastName: 'venter',
    //             msisdn: '+27678934536',
    //             email: 'yolandie.venter@gmail.com',
    //             displayName: 'yolandie venter',
    //             isActive: true,
    //             mobileAppPrice: 59.99,
    //             roles: {
    //                 admin: false,
    //                 clientAdmin: false,
    //                 manager: false,
    //                 providerAdmin: false,
    //                 user: true
    //             },
    //             groupKey,
    //             clientKey
    //         },
    //         {
    //             key: 'L56jLI4ArzSGNE3Q7ixvr7BtNaD3',
    //             firstName: 'operations',
    //             lastName: 'crimestp',
    //             msisdn: '+27665600501',
    //             email: 'operations@crimestp.co.za',
    //             displayName: 'operations crimestp',
    //             isActive: true,
    //             mobileAppPrice: 59.99,
    //             roles: {
    //                 admin: false,
    //                 clientAdmin: false,
    //                 manager: false,
    //                 providerAdmin: false,
    //                 user: true
    //             },
    //             groupKey,
    //             clientKey
    //         },
    //         {
    //             key: 'JRzgJOwrVJWSrgpkxl0nUART7S93',
    //             firstName: 'wolfie',
    //             lastName: 'vanderwesthuizen',
    //             msisdn: '+27725651703',
    //             email: 'wolfie.vanderwesthuizen@gmail.com',
    //             displayName: 'wolfie vanderwesthuizen',
    //             isActive: true,
    //             mobileAppPrice: 59.99,
    //             roles: {
    //                 admin: false,
    //                 clientAdmin: false,
    //                 manager: false,
    //                 providerAdmin: false,
    //                 user: true
    //             },
    //             groupKey,
    //             clientKey
    //         },
    //         {
    //             key: 'IPaWkg6wqUOLiJEEmPAxzxTCnLX2',
    //             firstName: 'suzan',
    //             lastName: 'crime',
    //             msisdn: '+27725239657',
    //             email: 'suzancrime@mweb.co.za',
    //             displayName: 'suzan crime ',
    //             isActive: true,
    //             mobileAppPrice: 59.99,
    //             roles: {
    //                 admin: false,
    //                 clientAdmin: false,
    //                 manager: false,
    //                 providerAdmin: false,
    //                 user: true
    //             },
    //             groupKey,
    //             clientKey
    //         },
    //         {
    //             key: 'FfbRJCQc9mai7mDsi1ryY3rhiyY2',
    //             firstName: 'jeandre',
    //             lastName: 'senforce',
    //             msisdn: '+27791824562',
    //             email: 'jeandre@senforce.co.za',
    //             displayName: 'jeandre senforce',
    //             isActive: true,
    //             mobileAppPrice: 59.99,
    //             roles: {
    //                 admin: false,
    //                 clientAdmin: false,
    //                 manager: false,
    //                 providerAdmin: false,
    //                 user: true
    //             },
    //             groupKey,
    //             clientKey
    //         },
    //         {
    //             key: 'FcQ2pnuG6TQoPmqh33bkfdPwQZp1',
    //             firstName: 'operation',
    //             lastName: 'crimestp',
    //             msisdn: '+27820894606',
    //             email: 'operation@crimestp.co.za',
    //             displayName: 'operation crimestp',
    //             isActive: true,
    //             mobileAppPrice: 59.99,
    //             roles: {
    //                 admin: false,
    //                 clientAdmin: false,
    //                 manager: false,
    //                 providerAdmin: false,
    //                 user: true
    //             },
    //             groupKey,
    //             clientKey
    //         },
    //         {
    //             key: 'DBrgDoS26OcGfUXXIWdkQLG2l3L2',
    //             firstName: 'hrdepartment',
    //             lastName: 'crimestp',
    //             msisdn: '+27768164930',
    //             email: 'hrdepartment@crimestp.co.za',
    //             displayName: 'hrdepartment crimestp',
    //             isActive: true,
    //             mobileAppPrice: 59.99,
    //             roles: {
    //                 admin: false,
    //                 clientAdmin: false,
    //                 manager: false,
    //                 providerAdmin: false,
    //                 user: true
    //             },
    //             groupKey,
    //             clientKey
    //         },
    //         {
    //             key: 'BkWoeY2h67dVKLQ9wB1n',
    //             firstName: 'vanheerdenv',
    //             lastName: 'vanheerdenv',
    //             msisdn: '+27718805332',
    //             email: 'vanheerdenv121@gmail.com',
    //             displayName: 'vanheerdenv',
    //             isActive: true,
    //             mobileAppPrice: 59.99,
    //             roles: {
    //                 admin: false,
    //                 clientAdmin: false,
    //                 manager: false,
    //                 providerAdmin: false,
    //                 user: true
    //             },
    //             groupKey,
    //             clientKey
    //         },
    //         {
    //             key: '9LSa5QGEwWjNoIwCqjSS',
    //             firstName: 'phillip',
    //             lastName: '',
    //             msisdn: '+27766023251',
    //             email: 'phillip123@gmail.com',
    //             displayName: 'phillip ',
    //             isActive: true,
    //             mobileAppPrice: 59.99,
    //             roles: {
    //                 admin: false,
    //                 clientAdmin: false,
    //                 manager: false,
    //                 providerAdmin: false,
    //                 user: true
    //             },
    //             groupKey,
    //             clientKey
    //         },
    //         {
    //             key: '7lPQ8OVQ0hVxGMgl2AXW',
    //             firstName: 'meyer',
    //             lastName: 'chris',
    //             msisdn: '+27836596347',
    //             email: 'meyer.chris2@gmail.com',
    //             displayName: 'meyer chris',
    //             isActive: true,
    //             mobileAppPrice: 59.99,
    //             roles: {
    //                 admin: false,
    //                 clientAdmin: false,
    //                 manager: false,
    //                 providerAdmin: false,
    //                 user: true
    //             },
    //             groupKey,
    //             clientKey
    //         },
    //         {
    //             key: '7Uv4iBApZihG0vN4zwCJ3NrFTz42',
    //             firstName: 'hannelie',
    //             lastName: 'venter',
    //             msisdn: '+27827541796',
    //             email: 'hannelie.venter@gmail.com',
    //             displayName: 'hannelie venter',
    //             isActive: true,
    //             mobileAppPrice: 59.99,
    //             roles: {
    //                 admin: false,
    //                 clientAdmin: false,
    //                 manager: false,
    //                 providerAdmin: false,
    //                 user: true
    //             },
    //             groupKey,
    //             clientKey
    //         }
    //     ]
    //     for (const user of userList) {
    //         console.log('adding ->  ' + user.firstName)
    //         await this.dataService.setFireStoreData('users', user)
    //     }
    // }
    UploadService.prototype.runLater_CheckEmtpyProviderKeys = function (writeDatabase) {
        if (writeDatabase === void 0) { writeDatabase = false; }
        return __awaiter(this, void 0, void 0, function () {
            var responseServices, _a, _b, _i, responseServices_9, resService;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        console.log("-----START ");
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("responseServices").toPromise()];
                    case 1:
                        responseServices = _b.apply(_a, [_c.sent()]);
                        _i = 0, responseServices_9 = responseServices;
                        _c.label = 2;
                    case 2:
                        if (!(_i < responseServices_9.length)) return [3 /*break*/, 5];
                        resService = responseServices_9[_i];
                        if (!!resService.providerKey) return [3 /*break*/, 4];
                        console.log("NO KEY " + resService.name);
                        console.log("client : " + resService.clientName);
                        console.log("key : " + resService.key);
                        console.log("clientKey : " + resService.clientKey);
                        console.log("clientName : " + resService.clientName);
                        resService.providerKey = resService.clientKey;
                        resService.providerName = resService.clientName;
                        if (!writeDatabase) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.dataService.updateFireStoreData("responseServices", resService)];
                    case 3:
                        _c.sent();
                        _c.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5:
                        console.log("END");
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.runLater_FindResponseServicesWithNoLinkedClients = function (writeDatabase) {
        if (writeDatabase === void 0) { writeDatabase = false; }
        return __awaiter(this, void 0, void 0, function () {
            var responseServices, _a, _b, clients, _c, _d, users, _e, _f, _loop_10, this_9, _i, responseServices_10, responseService;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        console.log(">> Running : Check Response Services With no Linked Clients");
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("responseServices").toPromise()];
                    case 1:
                        responseServices = _b.apply(_a, [_g.sent()]);
                        _d = (_c = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("clients").toPromise()];
                    case 2:
                        clients = _d.apply(_c, [_g.sent()]);
                        _f = (_e = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("users").toPromise()];
                    case 3:
                        users = _f.apply(_e, [_g.sent()]);
                        _loop_10 = function (responseService) {
                            var client, foundUsers;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!!responseService.clientKey) return [3 /*break*/, 3];
                                        //  console.log('No CLIIENT KEY : ' + responseService.clientName)
                                        console.log("DELETING : " + responseService.clientName);
                                        if (!writeDatabase) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this_9.dataService.deleteFireStoreDataFromFunctions("", "responseServices", responseService.key)];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2: return [3 /*break*/, 6];
                                    case 3:
                                        client = clients.find(function (x) { return x.key === responseService.clientKey; });
                                        if (!!client) return [3 /*break*/, 6];
                                        foundUsers = users.filter(function (x) { return x.clientKey === responseService.clientKey; });
                                        if (!(foundUsers && foundUsers.length > 0)) return [3 /*break*/, 4];
                                        console.log("No CLIIENT BUT HAS USERS!!!! : " + responseService.clientName);
                                        return [3 /*break*/, 6];
                                    case 4:
                                        //console.log('No CLIIENT : ' + responseService.clientName)
                                        console.log("DELETING : " + responseService.clientName);
                                        if (!writeDatabase) return [3 /*break*/, 6];
                                        return [4 /*yield*/, this_9.dataService.deleteFireStoreDataFromFunctions("", "responseServices", responseService.key)];
                                    case 5:
                                        _a.sent();
                                        _a.label = 6;
                                    case 6: return [2 /*return*/];
                                }
                            });
                        };
                        this_9 = this;
                        _i = 0, responseServices_10 = responseServices;
                        _g.label = 4;
                    case 4:
                        if (!(_i < responseServices_10.length)) return [3 /*break*/, 7];
                        responseService = responseServices_10[_i];
                        return [5 /*yield**/, _loop_10(responseService)];
                    case 5:
                        _g.sent();
                        _g.label = 6;
                    case 6:
                        _i++;
                        return [3 /*break*/, 4];
                    case 7:
                        console.log("END");
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.runLater_FindUsersWithMissingData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var users, _a, _b, missingDataUsersFirstName, missingDataUsersLastName, missingDataUsersDisplayname;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        console.log(">> Running : Check Missing data for users");
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("users").toPromise()];
                    case 1:
                        users = _b.apply(_a, [_c.sent()]);
                        missingDataUsersFirstName = users.filter(function (x) { return !x.firstName || x.firstName === ""; });
                        missingDataUsersLastName = users.filter(function (x) { return !x.firstName || x.firstName === ""; });
                        missingDataUsersDisplayname = users.filter(function (x) { return !x.displayName || x.displayName === ""; });
                        console.log(missingDataUsersFirstName.length);
                        console.log(missingDataUsersLastName.length);
                        console.log(missingDataUsersDisplayname.length);
                        console.log("END");
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.runLater_CheckIfUsersHaveMapsAsPaymentDates = function (writeDatabase) {
        if (writeDatabase === void 0) { writeDatabase = false; }
        return __awaiter(this, void 0, void 0, function () {
            var users, _a, _b, _i, users_3, user, update, aaa, seconds, newDate, x, newDate, aaa, seconds, newDate, x, newDate, aaa, seconds, newDate, x, newDate;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        console.log(">> Running : Check User Dates are Not Map Objects");
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("users").toPromise()];
                    case 1:
                        users = _b.apply(_a, [_c.sent()]);
                        _i = 0, users_3 = users;
                        _c.label = 2;
                    case 2:
                        if (!(_i < users_3.length)) return [3 /*break*/, 5];
                        user = users_3[_i];
                        update = false;
                        if (user.dateUpdated) {
                            console.log(user.dateUpdated);
                            aaa = user.dateUpdated;
                            seconds = aaa["_seconds"];
                            if (!seconds) {
                                seconds = aaa["seconds"];
                            }
                            if (seconds) {
                                newDate = this.toDateTime(seconds);
                                user.dateUpdated = firebase.firestore.Timestamp.fromDate(newDate);
                            }
                            else {
                                console.log(user.dateUpdated);
                                x = user.dateUpdated;
                                newDate = new Date(x);
                                user.dateUpdated = firebase.firestore.Timestamp.fromDate(newDate);
                                console.log(">>>>>>" + user.dateUpdated);
                                update = true;
                            }
                        }
                        if (user.subscriptionCancellationDate) {
                            console.log(user.subscriptionCancellationDate);
                            aaa = user.subscriptionCancellationDate;
                            seconds = aaa["_seconds"];
                            if (!seconds) {
                                seconds = aaa["seconds"];
                            }
                            if (seconds) {
                                newDate = this.toDateTime(seconds);
                                user.subscriptionCancellationDate = firebase.firestore.Timestamp.fromDate(newDate);
                                //  console.log(user.subscriptionCancellationDate)
                            }
                            else {
                                console.log(user.subscriptionCancellationDate);
                                x = user.subscriptionCancellationDate;
                                newDate = new Date(x);
                                user.subscriptionCancellationDate = firebase.firestore.Timestamp.fromDate(newDate);
                                console.log(">>>>>>" + user.subscriptionCancellationDate);
                                update = true;
                            }
                        }
                        if (user.subscriptionLastPayedDate) {
                            console.log(">>> " + user.subscriptionLastPayedDate);
                            aaa = user.subscriptionLastPayedDate;
                            seconds = aaa["_seconds"];
                            if (!seconds) {
                                seconds = aaa["seconds"];
                            }
                            else {
                                console.log(">>> " + user.subscriptionLastPayedDate);
                                update = true;
                            }
                            if (seconds) {
                                newDate = this.toDateTime(seconds);
                                user.subscriptionLastPayedDate = firebase.firestore.Timestamp.fromDate(newDate);
                                // console.log('>>> ' + user.subscriptionActive)
                            }
                            else {
                                x = user.subscriptionLastPayedDate;
                                newDate = new Date(x);
                                user.subscriptionLastPayedDate = firebase.firestore.Timestamp.fromDate(newDate);
                                //  console.log('>>>>>>' + user.subscriptionLastPayedDate)
                                update = true;
                            }
                        }
                        if (!(writeDatabase && update)) return [3 /*break*/, 4];
                        console.log("-->>> " + user.subscriptionLastPayedDate);
                        return [4 /*yield*/, this.dataService.updateFireStoreData("users", user)];
                    case 3:
                        _c.sent();
                        console.log("UPDATED ->>>>>");
                        _c.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5:
                        console.log("END");
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.runLater_FindAndDeleteEmptyClientsWithNoUsers = function (writeDatabase) {
        if (writeDatabase === void 0) { writeDatabase = false; }
        return __awaiter(this, void 0, void 0, function () {
            var clients, _a, _b, users, _c, _d, responseServices, _e, _f, _loop_11, this_10, _i, clients_2, client;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        console.log(">> Running : Empty Clients with no Users Check");
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("clients").toPromise()];
                    case 1:
                        clients = _b.apply(_a, [_g.sent()]);
                        _d = (_c = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("users").toPromise()];
                    case 2:
                        users = _d.apply(_c, [_g.sent()]);
                        _f = (_e = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("responseServices").toPromise()];
                    case 3:
                        responseServices = _f.apply(_e, [_g.sent()]);
                        _loop_11 = function (client) {
                            var userLIst, resCheck, resSErvices, _i, resSErvices_1, resSrvc;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        userLIst = users.filter(function (x) { return x.clientKey === client.key; });
                                        if (!(!userLIst || userLIst.length <= 0)) return [3 /*break*/, 8];
                                        resCheck = responseServices.filter(function (x) { return x.providerLinkedResponseServiceKey === client.key; });
                                        if (!(resCheck && resCheck.length > 0)) return [3 /*break*/, 1];
                                        console.log(">> SOME ONE USEING ");
                                        return [3 /*break*/, 7];
                                    case 1:
                                        console.log("delete " + client.name);
                                        if (!writeDatabase) return [3 /*break*/, 3];
                                        return [4 /*yield*/, this_10.dataService.deleteFireStoreDataFromFunctions("", "clients", client.key)];
                                    case 2:
                                        _a.sent();
                                        _a.label = 3;
                                    case 3:
                                        resSErvices = responseServices.filter(function (x) { return x.clientKey === client.key; });
                                        _i = 0, resSErvices_1 = resSErvices;
                                        _a.label = 4;
                                    case 4:
                                        if (!(_i < resSErvices_1.length)) return [3 /*break*/, 7];
                                        resSrvc = resSErvices_1[_i];
                                        console.log("delete Service " + resSrvc.key);
                                        if (!writeDatabase) return [3 /*break*/, 6];
                                        return [4 /*yield*/, this_10.dataService.deleteFireStoreDataFromFunctions("", "responseServices", resSrvc.key)];
                                    case 5:
                                        _a.sent();
                                        _a.label = 6;
                                    case 6:
                                        _i++;
                                        return [3 /*break*/, 4];
                                    case 7: return [3 /*break*/, 9];
                                    case 8:
                                        console.log("nothing");
                                        _a.label = 9;
                                    case 9: return [2 /*return*/];
                                }
                            });
                        };
                        this_10 = this;
                        _i = 0, clients_2 = clients;
                        _g.label = 4;
                    case 4:
                        if (!(_i < clients_2.length)) return [3 /*break*/, 7];
                        client = clients_2[_i];
                        return [5 /*yield**/, _loop_11(client)];
                    case 5:
                        _g.sent();
                        _g.label = 6;
                    case 6:
                        _i++;
                        return [3 /*break*/, 4];
                    case 7:
                        console.log("END");
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.runLater_FindAndDeleteUnusedGroups = function (writeDatabase) {
        if (writeDatabase === void 0) { writeDatabase = false; }
        return __awaiter(this, void 0, void 0, function () {
            var clients, _a, _b, userGroups, _c, _d, _loop_12, this_11, _i, userGroups_6, userGrp;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        console.log(">> Running : Unused UserGroups Check");
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("clients").toPromise()];
                    case 1:
                        clients = _b.apply(_a, [_e.sent()]);
                        _d = (_c = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("userGroups").toPromise()];
                    case 2:
                        userGroups = _d.apply(_c, [_e.sent()]);
                        _loop_12 = function (userGrp) {
                            var usedClient;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        usedClient = clients.filter(function (x) { return x.key === userGrp.clientKey; });
                                        if (!(!usedClient || usedClient.length <= 0)) return [3 /*break*/, 2];
                                        console.log("Delete group For : " + userGrp.clientKey);
                                        if (!writeDatabase) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this_11.dataService.deleteFireStoreDataFromFunctions("", "userGroups", userGrp.key)];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        };
                        this_11 = this;
                        _i = 0, userGroups_6 = userGroups;
                        _e.label = 3;
                    case 3:
                        if (!(_i < userGroups_6.length)) return [3 /*break*/, 6];
                        userGrp = userGroups_6[_i];
                        return [5 /*yield**/, _loop_12(userGrp)];
                    case 4:
                        _e.sent();
                        _e.label = 5;
                    case 5:
                        _i++;
                        return [3 /*break*/, 3];
                    case 6:
                        console.log("END");
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.runLater_CheckIfClientGroupsAreCorrectWithDefaultGroup = function (writeDatabase) {
        if (writeDatabase === void 0) { writeDatabase = false; }
        return __awaiter(this, void 0, void 0, function () {
            var clients, _a, _b, userGroups, _c, _d, _loop_13, _i, clients_3, client;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        console.log(">> Running : Client UserGroups Check");
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("clients").toPromise()];
                    case 1:
                        clients = _b.apply(_a, [_e.sent()]);
                        _d = (_c = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("userGroups").toPromise()];
                    case 2:
                        userGroups = _d.apply(_c, [_e.sent()]);
                        _loop_13 = function (client) {
                            var clientGroups = userGroups.filter(function (x) { return x.clientKey === client.key; });
                            console.log("----------------");
                            for (var _i = 0, clientGroups_1 = clientGroups; _i < clientGroups_1.length; _i++) {
                                var group = clientGroups_1[_i];
                                console.log("Count : " + clientGroups.length);
                                var defaultCount = clientGroups.filter(function (x) { return x.autoGenerated; });
                                if (!defaultCount || defaultCount.length <= 0) {
                                    console.log(">>>>>> ERROR : " + client.name);
                                    console.log(">>>>>> ERROR : " + client.key);
                                }
                                else if (defaultCount.length === 1) {
                                }
                                if (defaultCount.length > 1) {
                                    console.log(">>>>>> ERROR : " + client.name);
                                    console.log(">>>>>> ERROR : " + client.key);
                                }
                            }
                        };
                        for (_i = 0, clients_3 = clients; _i < clients_3.length; _i++) {
                            client = clients_3[_i];
                            _loop_13(client);
                        }
                        console.log("END");
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadService.prototype.runLater_CheckIfUserFriendsResponseServiceExist = function (writeDatabase) {
        if (writeDatabase === void 0) { writeDatabase = false; }
        return __awaiter(this, void 0, void 0, function () {
            var users, _a, _b, responseServices, _c, _d, clients, _e, _f, _loop_14, this_12, _i, users_4, user;
            var _this = this;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        console.log(">> Running : Empty Friends Res Service Check");
                        _b = (_a = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("users").toPromise()];
                    case 1:
                        users = _b.apply(_a, [_g.sent()]);
                        _d = (_c = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("responseServices").toPromise()];
                    case 2:
                        responseServices = _d.apply(_c, [_g.sent()]);
                        _f = (_e = this.dataService).querySnapshotToObject;
                        return [4 /*yield*/, this.dataService.getFirestoreData("clients").toPromise()];
                    case 3:
                        clients = _f.apply(_e, [_g.sent()]);
                        _loop_14 = function (user) {
                            var userResService, friendRepsonseService_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        userResService = responseServices.find(function (x) { return x.key === user.responseServiceFriendsKey; });
                                        if (!!userResService) return [3 /*break*/, 2];
                                        console.log("NOT FOUND !: " + user.key);
                                        console.log(user.key);
                                        friendRepsonseService_1 = {
                                            category: "publicService",
                                            clientKey: user.clientKey,
                                            clientName: clients.find(function (x) { return x.key === user.clientKey; }).name,
                                            icon: "/assets/icon/generic/friends_fam.svg",
                                            iconColor: "icon-orange",
                                            isAllowedToHandleOtherActionsInParallel: true,
                                            isAvailableOnMobile: true,
                                            isDirectRequestToResponders: true,
                                            isMoreThanOneResponderAllowed: true,
                                            isNOCInChargeOfCompletion: false,
                                            isPublicServiceProvider: false,
                                            isServiceProvider: true,
                                            providerKey: clients.find(function (x) { return x.key === user.clientKey; }).key,
                                            providerName: clients.find(function (x) { return x.key === user.clientKey; }).name,
                                            respondRadiusKm: 100,
                                            responders: [],
                                            isAutoGenerated: true,
                                            // DONT change this variable -> Doing lookups on Friends & Family.
                                            name: "Friends & Family",
                                            settings: {
                                                isCloseReasonFromList: false,
                                            },
                                            type: "friend",
                                        };
                                        console.log(friendRepsonseService_1);
                                        return [4 /*yield*/, this_12.dataService.setFireStoreDataFromFunctions(user.key, "responseServices", friendRepsonseService_1).then(function (firestoreKey) { return __awaiter(_this, void 0, void 0, function () {
                                                return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0:
                                                            user.responseServiceFriendsKey = firestoreKey;
                                                            return [4 /*yield*/, this.dataService.updateFireStoreDataFromFunctions(user.key, "users", user)];
                                                        case 1:
                                                            _a.sent();
                                                            return [2 /*return*/, friendRepsonseService_1];
                                                    }
                                                });
                                            }); })];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        };
                        this_12 = this;
                        _i = 0, users_4 = users;
                        _g.label = 4;
                    case 4:
                        if (!(_i < users_4.length)) return [3 /*break*/, 7];
                        user = users_4[_i];
                        return [5 /*yield**/, _loop_14(user)];
                    case 5:
                        _g.sent();
                        _g.label = 6;
                    case 6:
                        _i++;
                        return [3 /*break*/, 4];
                    case 7:
                        console.log("END");
                        return [2 /*return*/];
                }
            });
        });
    };
    return UploadService;
}());
export { UploadService };
