<mat-spinner :diameter="50" :strokeWidth="4" *ngIf="loading"></mat-spinner>

<ng-container :class="{'d-none': loading || showFallback}">
  <img [src]="imageUrl" class="preview-image" (load)="onImageLoad()" (error)="onImageError()" alt="" />
</ng-container>

<ng-container *ngIf="showFallback">
  <!-- Display the fallback message here -->
  <div class="fallback-message">Unable to load the image. Please check the URL or try again later.</div>
</ng-container>

<div matDialogActions>
  <button mat-button matDialogClose>
    Close
  </button>
</div>