import { OnInit, ViewEncapsulation } from '@angular/core';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AssetManagementObject } from 'app/interfaces/asset-management/asset-management-object.interface';
import { AssetRoles, LinkedAssetResource, LinkedAssetRole, LinkedResourceRole, ResourceRoles, ServiceInformation } from 'app/interfaces/journey-management/journey-management-object.interface';
import { UserDetail } from 'app/interfaces/user.interface';

import * as short from 'short-uuid';
import { ItemTypeEnum, BookingManagementObject } from 'app/interfaces/booking-management/booking-management-object.interface';
import { ConfirmationDialogComponent } from 'app/pages/Common/confirmation-dialog.component';
import { MatRadioChange } from '@angular/material';
interface HasKey {
    key?: string;
}

@Component({
    selector: 'app-admin-dashboard-journey-management-assignment-dialog',
    templateUrl: './admin-dashboard-journey-management-assignment-dialog.component.html',
    styleUrls: [
        './admin-dashboard-journey-management-assignment-dialog.component.css'
    ],
    encapsulation: ViewEncapsulation.None,
})
export class AdminDashboardJourneyManagementAssignmentDialogComponent implements OnInit {
    resourceRoles = Object.values(ResourceRoles);
    selectedResources: UserDetail[] = [];
    resourceRoleSelections: { [resourceKey: string]: ResourceRoles } = {};
    
    assetRoles = Object.values(AssetRoles);
    selectedAssets: AssetManagementObject[] = [];
    assetRoleSelections: { [assetKey: string]: AssetRoles } = {};

    assetResourceAssignments: { [assetKey: string]: UserDetail[] } = {};

    additionalNotes: string;
    autoDispatch: boolean;
    autoDispatchTime: number;

    isLinear = true;
    canSave: boolean = false;

    selectedResourceKeys: Set<string> = new Set();
    selectedAssetKeys: Set<string> = new Set();

    extraConfirmation: boolean = false;

    constructor(private dialogRef: MatDialogRef<AdminDashboardJourneyManagementAssignmentDialogComponent>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: { serviceInformation: ServiceInformation, availableResources: UserDetail[], availableAssets: AssetManagementObject[], existingBookings: BookingManagementObject[] }) { }

    ngOnInit() {
        if (this.data && this.data.serviceInformation.linkedAssetsResources) {
            this.populateLinkedAssetResources(this.data.serviceInformation.linkedAssetsResources);
            this.additionalNotes = this.data.serviceInformation.additionalNotes;
            this.autoDispatch = this.data.serviceInformation.autoDispatch ? this.data.serviceInformation.autoDispatch : false;
            this.autoDispatchTime = this.data.serviceInformation.autoDispatchTime ? this.data.serviceInformation.autoDispatchTime : 0;
        }
    }

    private populateLinkedAssetResources(linkedAssetResources: LinkedAssetResource[]): void {
        this.isLinear = false;

        for (const linkedAssetResource of linkedAssetResources) {
            if (linkedAssetResource.asset) {
                this.selectedAssets.push(linkedAssetResource.asset.asset);
                this.selectedAssetKeys.add(linkedAssetResource.asset.asset.key);
                this.assetRoleSelections[linkedAssetResource.asset.asset.key] = linkedAssetResource.asset.assetRole;
            }

            linkedAssetResource.associatedResources.forEach(linkedResourceRole => {
                if (!linkedResourceRole || !linkedResourceRole.resource) {
                    return;
                }

                if (this.selectedResources.findIndex(r => r.key === linkedResourceRole.resource.key) === -1) {
                    this.selectedResources.push(linkedResourceRole.resource);
                    this.selectedResourceKeys.add(linkedResourceRole.resource.key);
                }

                this.resourceRoleSelections[linkedResourceRole.resource.key] = linkedResourceRole.resourceRole;

                if (linkedAssetResource.asset && linkedAssetResource.asset.asset) {
                    this.assetResourceAssignments[linkedAssetResource.asset.asset.key] = this.assetResourceAssignments[linkedAssetResource.asset.asset.key] || [];
                    this.assetResourceAssignments[linkedAssetResource.asset.asset.key].push(linkedResourceRole.resource);
                }
            });
        }

        this.assetKeys = Object.keys(this.assetResourceAssignments);
    }

    private toggleSelection<T extends HasKey>(items: T[], item: T, selections: { [key: string]: any }, keySet: Set<string>) {
        const index = items.findIndex(i => i.key === item.key);

        if (index !== -1) {
            items.splice(index, 1);
            keySet.delete(item.key);

            if (selections.hasOwnProperty(item.key)) {
                delete selections[item.key];
            }
        } 
        else {
            items.push(item);
            keySet.add(item.key);
        }
    }

    private isItemSelected<T extends HasKey>(keySet: Set<string>, item: T): boolean {
        return keySet.has(item.key);
    }

    toggleResourceSelection(resource: UserDetail) {
        this.toggleSelection(this.selectedResources, resource, this.resourceRoleSelections, this.selectedResourceKeys);

        if (this.assetResourceAssignments) {
            for (const assetKey in this.assetResourceAssignments) {
                this.assetResourceAssignments[assetKey] = this.assetResourceAssignments[assetKey].filter(user => user.key !== resource.key);
            }
        }

        this.assetKeys = Object.keys(this.assetResourceAssignments);
    }

    isResourceSelected(resource: UserDetail): boolean {
        return this.isItemSelected(this.selectedResourceKeys, resource);
    }

    assignResourceRole(resource: UserDetail, role: ResourceRoles) {
        this.resourceRoleSelections[resource.key] = role;
    }

    getResourceRole(resourceKey: string): string {
        const resourceRole = this.resourceRoleSelections[resourceKey];

        return resourceRole ? resourceRole : 'Unknown Role';
    }

    getResourceSchedule(resourceKey: string): string {
        let times: string[] = [];

        this.data.existingBookings.forEach(booking => {
            if (booking.itemType === ItemTypeEnum.Resource && booking.itemKey === resourceKey) {
                times.push(booking.time);
            }
        });

        if (times.length === 0) {
            //return 'The resource is not scheduled for today.';
            return '';
        }
        else {
            const timesString = times.join(', ');
            return ` - This resource has been scheduled for ${times.length} job${times.length > 1 ? 's' : ''} at the following times: ${timesString}`;
        }
    }

    toggleAssetSelection(asset: AssetManagementObject) {
        this.toggleSelection(this.selectedAssets, asset, this.assetRoleSelections, this.selectedAssetKeys);

        if (this.assetResourceAssignments && this.assetResourceAssignments[asset.key]) {
            delete this.assetResourceAssignments[asset.key];
        }

        this.assetKeys = Object.keys(this.assetResourceAssignments);
    }

    toggleAutoDispatch() {
        if (this.autoDispatch) {
            this.autoDispatch = false;
        } else {
            this.autoDispatch = true;
            if (this.autoDispatchTime == 0) {
                this.autoDispatchTime = 5;
            }
        } 
    }

    setAutoDispatchTime(event) {
        this.autoDispatchTime = event.value;  
    }

    isAssetSelected(asset: AssetManagementObject): boolean {
        return this.isItemSelected(this.selectedAssetKeys, asset);
    }

    assignAssetRole(asset: AssetManagementObject, role: AssetRoles) {
        this.assetRoleSelections[asset.key] = role;
    }

    getAssetRole(assetKey: string): string {
        const assetRole = this.assetRoleSelections[assetKey];

        return assetRole ? assetRole : 'Unknown Role';
    }

    getAssetSchedule(assetKey: string): string {
        let times: string[] = [];

        this.data.existingBookings.forEach(booking => {
            if (booking.itemType === ItemTypeEnum.Asset && booking.itemKey === assetKey) {
                times.push(booking.time);
            }
        });

        if (times.length === 0) {
            //return 'The asset is not scheduled for today.';
            return '';
        }
        else {
            const timesString = times.join(', ');
            return ` - This asset has been scheduled for ${times.length} job${times.length > 1 ? 's' : ''} at the following times: ${timesString}`;
        }
    }

    isMaxCountReached(asset: AssetManagementObject): boolean {
        if (!this.assetResourceAssignments[asset.key]) {
            this.assetResourceAssignments[asset.key] = [];
        }

        return this.assetResourceAssignments[asset.key].length >= asset.capacity;
    }

    isResourceAssignedToAsset(asset: AssetManagementObject, resource: UserDetail): boolean {
        const assignedResources = this.assetResourceAssignments[asset.key] || [];

        return assignedResources.includes(resource);
    }

    toggleAssetResourceAssignment(asset: AssetManagementObject, resource: UserDetail) {
        if (!this.assetResourceAssignments[asset.key]) {
            this.assetResourceAssignments[asset.key] = [];
        }
    
        const currentAssignmentsForAsset = this.assetResourceAssignments[asset.key].length;
        
        const isAlreadyAssignedToAsset = this.assetResourceAssignments[asset.key].includes(resource);
    
        if (!isAlreadyAssignedToAsset && currentAssignmentsForAsset < asset.capacity) {
            for (const key in this.assetResourceAssignments) {
                const index = this.assetResourceAssignments[key].findIndex(r => r === resource);

                if (index !== -1) {
                    this.assetResourceAssignments[key].splice(index, 1);
                }
            }
    
            this.assetResourceAssignments[asset.key].push(resource);
        } 
        else if (isAlreadyAssignedToAsset) {
            const index = this.assetResourceAssignments[asset.key].findIndex(r => r === resource);

            if (index !== -1) {
                this.assetResourceAssignments[asset.key].splice(index, 1);
            }
        }

        this.assetKeys = Object.keys(this.assetResourceAssignments);
    }

    assetKeys: string[] = [];

    /* get assetKeys() {
        return Object.keys(this.assetResourceAssignments);
    } */

    getAssetName(assetKey: string): string {
        const asset = this.selectedAssets.find(a => a.key === assetKey);

        return asset ? `${asset.make} ${asset.model} ${asset.registrationNumber}` : 'Unknown Asset';
    }

    onStepChange(event: StepperSelectionEvent): void {
        if (event.selectedIndex === 6) {
            this.canSave = true;
        }
        else {
            this.canSave = false;
        }
    }

    onConfirm(): void {
        const linkedAssetResources: LinkedAssetResource[] = [];

        let message: string = '';

        const allSelectedResources = new Set(this.selectedResources.map(resource => resource.key));

        for (let i = 0; i < this.selectedResources.length; i++) {
            const resource = this.selectedResources[i];

            if (!this.resourceRoleSelections[resource.key]) {
                message += 'Resource exists but no resource role is associated. ';

                break;
            }
        }
        
        this.selectedAssets.forEach(asset => {
            const assetRole: LinkedAssetRole = {
                asset: asset,
                assetRole: this.assetRoleSelections[asset.key]
            };

            if (asset && !assetRole.assetRole) {
                message += 'Asset exists but no asset role is associated. ';
            }

            const associatedResources: LinkedResourceRole[] = [];
            const assetResourceList = this.assetResourceAssignments[asset.key] || [];

            assetResourceList.forEach(resource => {
                const resourceRole: LinkedResourceRole = {
                    resource: resource,
                    resourceRole: this.resourceRoleSelections[resource.key]
                };

                if (resource && !resourceRole.resourceRole) {
                    message += 'Resource exists but no resource role is associated. ';
                }

                associatedResources.push(resourceRole);

                allSelectedResources.delete(resource.key);
            });

            if (associatedResources.length > 0 && !asset) {
                message += 'Associated resources exist but no asset is linked. ';
            }

            if (asset && associatedResources.length === 0) {
                message += 'Asset exists but no associated resources are linked. ';
            }

            linkedAssetResources.push({
                key: short.generate(),
                asset: assetRole,
                associatedResources: associatedResources
            });
        });

        if (allSelectedResources.size > 0) {
            message += 'Some resources were selected but not assigned to any assets. ';
        }

        if (message.length > 0) {
            this.extraConfirmation = true;

            message += 'Would you still like to save?';

            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                width: '25%',
                disableClose: true,
                data: {
                    message: message
                }
            });

            dialogRef.afterClosed().subscribe(async result => {
                this.extraConfirmation = false;

                if (result) {
                    this.dialogRef.close({
                        additionalNotes: this.additionalNotes,
                        autoDispatch: this.autoDispatch,
                        autoDispatchTime: this.autoDispatchTime,
                        linkedAssetResources: linkedAssetResources
                    });
                }
            });
        }
        else {
            this.dialogRef.close({
                additionalNotes: this.additionalNotes,
                autoDispatch: this.autoDispatch,
                autoDispatchTime: this.autoDispatchTime,
                linkedAssetResources: linkedAssetResources
            });
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}