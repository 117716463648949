import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

declare var $: any;

@Injectable()
export class NumberFormatting {

  constructor(
    public datePipe: DatePipe,
  ) { }

  RoundDecimal(decimals, value) {
    const valueString = value.toString().replace(',', '.');

    value = parseFloat(valueString);
    if (decimals !== 0) {
      value = Math.round(value * (decimals * 10)) / (decimals * 10);
    } else {
      value = Math.round(value);
    }
    return value;
  }

 public Generateuuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line:no-bitwise
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
