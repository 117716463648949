import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'privacy-policy-afriform',
  templateUrl: './privacy-policy-afriform.component.html',
  styleUrls: ['./privacy-policy-afriform.component.scss'],
})
export class PrivacyPolicyAfriform implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
