import { Inject, Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from "@angular/router";

import { AppUser, UserDetail } from "app/interfaces/user.interface";
import { PlusFirebaseFunctionsUrls } from "app/interfaces/general/environment.interface";

import { environment } from "environments/environment";

import * as firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
import { FIREBASE_REFERENCES } from "./firebase/firebase.module";

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    appUser: AppUser;

    private readonly plusFirebaseFunctionsUrls: PlusFirebaseFunctionsUrls[] = [];

    private _user: firebase.User;

    get user(): firebase.User {
        return this._user;
    }

    set user(value: firebase.User) {
        this._user = value;
    }

    //private userSubscription: Subscription | null = null;

    constructor(
        @Inject(FIREBASE_REFERENCES.ONE_FIRESTORE) private readonly angularFirestore: AngularFirestore,
        @Inject(FIREBASE_REFERENCES.ONE_FIREAUTH) private readonly angularFireAuth: AngularFireAuth,
        //private angularFireAuth: AngularFireAuth, 
        //private angularFirestore: AngularFirestore, 
        private router: Router) {
        angularFireAuth.authState.subscribe(user => this._user = user);

        this.plusFirebaseFunctionsUrls = environment.plusFirebaseFunctionsUrls;

        // Retrieve appUser from session storage
        const storedAppUser = sessionStorage.getItem('appUser');

        if (storedAppUser) {
            this.appUser = JSON.parse(storedAppUser);
        }

        console.debug('AuthService: instantiated');
    }

    private async loadUserData(userCredential: firebase.auth.UserCredential): Promise<void> {
        this.appUser = {
            userDetail: null,
            userClient: null,
            plusEnvironment: null
        };

        const doc = await this.angularFirestore.doc(`users/${userCredential.user.uid}`).get().toPromise();

        if (!doc.exists) {
            throw Error('User details not found!');
        }

        this.appUser.userDetail = doc.data();

        this.appUser.plusEnvironment = this.plusFirebaseFunctionsUrls.find(x => x.clientKey === this.appUser.userDetail.clientKey);

        const query = this.angularFirestore.collection('clients', ref => ref.where('key', '==', this.appUser.userDetail.clientKey).limit(1));
        const snapshot = await query.get().toPromise();

        if (snapshot.empty) {
            throw Error('Client details not found!');
        }

        this.appUser.userClient = snapshot.docs[0].data();

        /* this.dataService.loggedInUserDisplayName = this.appUser.userDetail.firstName + ' ' + this.appUser.userDetail.lastName;
        this.dataService.loggedInUserKey = userCredential.user.uid; */

        // Store appUser in session storage
        sessionStorage.setItem('appUser', JSON.stringify(this.appUser));
    }

    emailLogin(email: string, password: string): Promise<void> {
        return new Promise((resolve, reject) => {
            /* this.angularFireAuth.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
                
            }) */
            return this.angularFireAuth.auth.signInWithEmailAndPassword(email, password).then(async userCredential => {
                try {
                    await this.loadUserData(userCredential);
                    resolve(); // Login process completed successfully
                } catch (error) {
                    console.error('Login error', error);
                    reject(error);
                }
            }).catch(error => {
                console.error('Login error', error);
                reject(error);
            });
        });
    }

    /*  private subscribeForUserChanges(userId: string) {
            if (this.userSubscription) {
                this.userSubscription.unsubscribe();
            }
    
            this.userSubscription = this.angularFirestore.doc(`users/${userId}`).valueChanges().subscribe(user => {
                this.appUser.userDetail = user;
            });
        }
    
        private unsubscribeForUserChanges() {
            if (this.userSubscription) {
                this.userSubscription.unsubscribe();
                this.userSubscription = null;
            }
        } */

    emailRegister(user: UserDetail, password: string): Promise<void> {
        return new Promise((resolve, reject) => {
            return this.angularFireAuth.auth.createUserWithEmailAndPassword(user.email, password).then(async userCredential => {
                try {
                    await this.loadUserData(userCredential);
                    resolve(); // Registration process completed successfully
                } catch (error) {
                    console.error('Registration error', error);
                    reject(error);
                }
            }).catch(error => {
                console.error('Registration error', error);
                reject(error);
            });
        });
    }

    /* emailRegister2(user: UserDetail, password: string): Promise<firebase.auth.UserCredential> {
        return this.angularFireAuth.auth.createUserWithEmailAndPassword(user.email, password).then((credential) => {
            return credential;
        })
        .catch((error) => {
            throw error;
        });
    } */

    resetPasswordWithMail(email) {
        return new Promise((resolve, reject) => {
            this.angularFireAuth.auth.sendPasswordResetEmail(email).then(function () {
                resolve("Reset Email has been sent to " + email);
            }).catch(function (error) {
                reject(error);
                // An error happened.
            });
        });
    }

    signOut(returnUrl) {
        this.angularFireAuth.auth.signOut().then(() => {
            // Clear appUser from session storage
            sessionStorage.clear();
            //sessionStorage.removeItem('appUser');

            /* this.dataService.postToFunctions(
                apiUrlEnum.info.log,
                {
                    userKey: this.appUser.userDetail.key,
                    action: "Logged out",
                },
                false,
                environment.functionsUrl
            ); */

            delete this.appUser;
            /* delete this.dataService.loggedInUserKey;
            delete this.dataService.loggedInUserDisplayName; */

            this.router.navigate([returnUrl]);
        });
    }
}
