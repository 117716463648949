import * as moment from 'moment';
import { BaseFirebaseObject } from '../base-firebase/base-firebase-object.interface';

export enum ItemTypeEnum {
    Asset = 'Asset',
    Resource = 'Resource',
}

export interface BookingManagementObject extends BaseFirebaseObject {
    date: string;
    time: string;
    itemKey: string;
    itemType: ItemTypeEnum;
    serviceKey: string;
}
