import * as moment from 'moment';
import { BaseFirebaseObject } from '../base-firebase/base-firebase-object.interface';
import { AssetManagementObject } from '../asset-management/asset-management-object.interface';
import { UserDetail } from '../user.interface';

// Move enums when complete [Alucard]
export enum JourneyManagementStatus {
    OpenRequest = 'Open Request',
    ViewedRequest = 'Viewed Request',
    RiskPlanProcess = 'Risk Plan Process',
    ThreatAssessment = 'Threat Assessment',
    PlanCompiled = 'Plan Compiled',
    ManagerReview = 'Manager Review',
    SeniorManagementSignOff = 'Senior Management Sign Off',
    PlanningCompleted = 'Planning Completed',
    ServicesAssigned = 'Services Assigned',
    SharedWithClient = 'Shared With Client', // Is this needed? [Alucard]
    Scheduled = 'Scheduled',
    OnRoute = 'On Route',
    AwaitingNextAction = 'Awaiting Next Action',
    IncidentOnRoute = 'Incident On Route',
    Delayed = 'Delayed',
    Escalation = 'Escalation',
    SupportNeeded = 'Support Needed',
    Completed = 'Completed',
    Cancelled = 'Cancelled',
    Terminated = 'Terminated',
}

export enum ResourceRoles {
    Commander = 'Commander',
    CPO = 'CPO',
    SecurityDriver = 'Security Driver',
    MOPOL = 'MOPOL',
    /* ProtocolOfficer = 'Protocol Officer' */
}

export enum AssetRoles {
    PrincipalVehicle = 'Principal Vehicle',
    BackupVehicle = 'Backup Vehicle',
    LeadVehicle = 'Lead Vehicle',
}

export enum ServiceDetails {
    AirportJM = 'Airport JM',
    /* AirportProtocol = 'Airport protocol', */
    InCityJM = 'In City JM',
    OutOfCityJM = 'Out of City JM',
}

export interface JourneyManagementObject extends BaseFirebaseObject {
    requestDetails: RequestDetails;
    passengerInformation: PassengerInformation[];
    travelInformation: TravelInformation[];
    serviceInformation: ServiceInformation[];
    state: JourneyManagementStatus;
    isEscalated: boolean;
    escalationReason: string;
    email: string;
    ccEmail: string;
    stateHistory: ObjectHistory[];
}

export interface RequestDetails {
    requestorName: string;
    telephoneNumber: string;
    projectName: string;
    companyName: string;
    fmnoNumber: string;
    customChargeCode?: string;
}

export interface PassengerInformation {
    key: string;
    name: string;
    nationality: string;
    passportNumber: string;
    contactTelephoneNumber: string;
    contactEmail: string;
}

export interface TravelInformation {
    key: string;
    date: moment.Moment | firebase.firestore.Timestamp;
    flightNumber: string;
    timeETA: string;
    timeETD: string;
    protocolArrival: boolean;
    protocolDeparture: boolean;
    flightOrDetails?: string;
    accommodation: string;
    linkedPassengers: string[];
}

export interface ServiceInformation {
    key: string;
    date: moment.Moment | firebase.firestore.Timestamp;
    timeStart: string;
    startAddress: ServiceAddress;
    endAddress: ServiceAddress;
    detailsOfService: ServiceDetails;
    specialRequests: string;
    additionalNotes: string;
    requiredResources: ResourceRoles[];
    linkedAssetsResources: LinkedAssetResource[];
    isProtocolRequired: boolean;
    isArranged: boolean;
    autoDispatchTime: number;
    autoDispatch: boolean;
    isCompleted: boolean;
    linkedResponseServiceKey?: string;
    linkedPassengers: string[];
}

export interface LinkedAssetResource {
    key: string;
    asset?: LinkedAssetRole;
    associatedResources: LinkedResourceRole[];
}

export interface LinkedAssetRole {
    asset: AssetManagementObject;
    assetRole?: AssetRoles;
}

export interface LinkedResourceRole {
    resource: UserDetail;
    resourceRole?: ResourceRoles;
}

export interface ServiceAddress {
    longitude: number;
    latitude: number;
    formattedAddress: string;
}

export interface ObjectHistory {
    key: string;
    date: moment.Moment | firebase.firestore.Timestamp;
    message: string;
    state: JourneyManagementStatus;
    changeOwnerKey: string;
    changeOwnerName: string;
    changeOwnerClientKey: string;
    changeOwnerClientName: string;
}