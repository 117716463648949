<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>
  <mat-form-field>
    <textarea matInput [(ngModel)]="userMessage" [placeholder]="data.question || 'Any additional notes to add?'"></textarea>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions class="right-aligned-actions">
  <button mat-button (click)="onCancel()" class="btn">Cancel</button>
  <button mat-button [disabled]="!isSubmitEnabled" (click)="onSubmit()" class="btn btn-primary">Submit</button>
</mat-dialog-actions>